import axios from 'axios';
import { API_URL } from '../../constants/config';
import ls from 'localstorage-slim';
import Log from '../../lib/Log';
// enable global encryption
ls.config.encrypt = true;
export const getEQVs = async (payload) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };

  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/admin/list/eqv`, { params: payload, headers })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const removeEQVs = async (payload) => {
  try {
    const { roleId, ...data } = payload;
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };

    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}/admin/remove/${roleId}/eqv`, data, { headers })
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    Log.error(error);
  }
};

export const getEQVAssignIqvs = async (payload) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };
  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/admin/list/assignedEqvs`, { params: payload, headers })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};
export const getUserEQVIQVAssessors = async (payload) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };
  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/eqv/list/assignedAssessor`, { params: payload, headers })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getNotAssignedEQVIqvs = async (payload) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };
  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/admin/list/notAssignedEqvs`, { params: payload, headers })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const assignEQVIqvs = async (payload) => {
  /*
payload: {
"eqv":"",
"iqvs":[""]
}
*/
  try {
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };
    return await axios.post(`${API_URL}/admin/eqv/assign`, payload, { headers });
  } catch (err) {
    Log.error(err);
  }
};

export const unAssignEQVIqvs = async (payload) => {
  /*
api: /admin/iqv/unassign
method: POST
payload: {
"iqv":"",
"assessors":[""]
}
*/
  try {
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };
    return await axios.post(`${API_URL}/admin/eqv/unassign`, payload, { headers });
  } catch (err) {
    Log.error(err);
  }
};

export const getEQVPrograms = async (payload) => {
  /*
api: /eqv/list/program/assignedAssessor
method: GET
payload: {eqv}
*/
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };
  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/eqv/list/program/assignedAssessor`, { params: payload, headers })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};
export const getEQVApprentices = async (payload) => {
  /*
api: /eqv/list/learner/assignedAssessor
method: GET
payload: {eqv}
*/
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };
  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/eqv/list/learner/assignedAssessor`, { params: payload, headers })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const addReviewRating = async (payload, assessorId) => {
  /*
api: /admin/iqv/unassign
method: POST
payload: {
"iqv":"",
"assessors":[""]
}
*/
  // try {
  //   const headers = {
  //     Authorization: `Bearer ${ls.get('token')}`,
  //     organisationid: ls.get('orgId'),
  //   };
  //   return await axios.post(`${API_URL}/iqv/review-rating/assessor/${assessorId}/add-review-rating`, payload, { headers });
  // } catch (err) {
  //   Log.error(err);
  // }
};

export const getReviewRatingData = async (payload, assessorId) => {
  /*
api: /iqv/list/learner/assignedAssessor
method: GET
payload: {iqv}
*/
  // const headers = {
  //   Authorization: `Bearer ${ls.get('token')}`,
  //   organisationid: ls.get('orgId'),
  // };
  // return new Promise((resolve, reject) => {
  //   return axios
  //     .get(`${API_URL}/iqv/list/assessor/${assessorId}/review`, { params: payload, headers })
  //     .then((result) => resolve(result))
  //     .catch((error) => reject(error.response));
  // });
};

export const getEQVProgramLearner = async (payload) => {
  /*
api: /eqv/list/assessor/program/learner
method: GET
payload: {programId}
*/
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };

  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/eqv/list/assessor/program/learner`, { params: payload, headers })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getEQVLearnerPrograms = async (payload) => {
  /*
api: /eqv/list/assessor/learner/program
method: GET
payload: {memberId}
*/
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };

  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/eqv/list/assessor/learner/program`, { params: payload, headers })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const eqvVerifyMemberProgram = async (memberProgramId) => {
  /*
api: eqv/verify-member-program/:memberProgramId
method: post
*/
  try {
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };
    return await axios.post(`${API_URL}/eqv/verify-member-program/${memberProgramId}`, null, { headers });
  } catch (err) {
    Log.error(err);
  }
};

// body:{memberProgram:string}
export const getMemberProgramIqvs = async (payload) => {
  const { memberProgram, ...params } = payload;
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };
  try {
    return await axios.post(`${API_URL}/eqv/program/list/iqv`, { memberProgram }, { headers, params });
  } catch (err) {
    throw new Error(err);
  }
};
