import axios from 'axios';
import Log from '../lib/Log';
import { bucket_name } from 'constants/config';
export const fileUploader = async (files) => {
  try {
    let formData = new FormData();
    formData.append('fileToSend', files);
    formData.append('client', 'BT101');
    formData.append('bucket', bucket_name);

    const UPLOADER = `https://awsuploader.services.olive.media`;
    axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.post['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept';
    axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    return axios
      .post(`${UPLOADER}/upload`, formData)
      .then((res) => res)
      .catch((error) => error);
  } catch (error) {
    if (error.response) {
      Log.error('error in uploader res', error);
    } else {
      Log.error('504 error', error);
    }

    return error;
  }
};
