import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
};

export const RolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    storeRoles: (state, action) => {
      return {
        ...state,
        list: action.payload.data.map((role) => ({ id: role.id, name: role.name, identifier: role.identifier })),
      };
    },
  },
});

export const { storeRoles } = RolesSlice.actions;
export default RolesSlice.reducer;
