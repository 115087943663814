import { React, useState } from 'react';
import { forwardRef, Children } from 'react';
import Dropdown from 'react-myoneui/lib/Dropdown';
import { dotsIcon } from '../../../../constants/icons';
import ChangePasswordOffCanvas from './ChangePasswordOffCanvas';
// import { setOrgId } from '../../redux';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu

const CustomToggle = forwardRef(({ children, onClick }, ref) => (
  <div
    role="button"
    tabIndex={0}
    ref={ref}
    className="btn btn-icon rounded-circle"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it

const CustomMenu = forwardRef(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
  const [value, setValue] = useState('');

  return (
    <div
      ref={ref}
      style={style}
      className={className}
      aria-labelledby={labeledBy}
    >
      <ul className="list-unstyled mb-0">{Children.toArray(children).filter((child) => !value || child.props.children.toLowerCase().startsWith(value))}</ul>
    </div>
  );
});

function ProfileDropdownItem(props) {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Dropdown
        className="dropdown-select position-static"
        align="end"
      >
        <Dropdown.Toggle
          as={CustomToggle}
          id="dropdown-custom-components"
        >
          {dotsIcon}
        </Dropdown.Toggle>
        <Dropdown.Menu
          as={CustomMenu}
          className="dropdown-menu mw-dropdown-default"
        >
          <Dropdown.Item
            eventKey="1"
            onClick={handleShow}
          >
            Change Password
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <ChangePasswordOffCanvas
        show={show}
        onClose={() => setShow(false)}
      />
    </>
  );
}

export default ProfileDropdownItem;
