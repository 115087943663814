import { binIcon, clockGrayIcon, restoreIcon } from '../../constants/icons';
import Button from 'react-myoneui/lib/Button';
import { useEffect, useState } from 'react';
import { Spinner } from 'react-myoneui';
import { setToastBoxStatus } from 'redux';

function ApprenticeHoursList(props) {
  // canAdminPerformAction={{ isValidRole, can_manage_apprentices }}
  let { schedule, totalHour, description, deleteHour, deleted, restoreHour, canAdminPerformAction, status } = props;
  const [load, setLoad] = useState(false);
  useEffect(() => {
    setLoad(false);
  }, [deleted]);

  return (
    <div className={`apprentice-hours-list ${deleted ? 'deleted-hour-list' : ''}`}>
      <div className="apprentice-hours-list-info">
        <div className="apprentice-hours-list-header">
          <div className="icon-wrap">{clockGrayIcon}</div>
          {schedule && <h4>{schedule}</h4>}
        </div>
        {totalHour && <span>{totalHour}</span>}
        {description && <div dangerouslySetInnerHTML={{ __html: description }} />}
      </div>
      {((canAdminPerformAction.isValidRole && canAdminPerformAction.can_manage_apprentices) || !canAdminPerformAction.isValidRole) && status !== 'Completed' && (
        <>
          {!deleted ? (
            <>
              {load && (
                <div className="spinner-align-center">
                  <Spinner
                    animation="border"
                    size="sm"
                  />
                </div>
              )}
              {!load && (
                <Button
                  className="hour-remove-btn"
                  title="Remove"
                  onClick={() => {
                    setLoad(true);
                    deleteHour();
                  }}
                >
                  {binIcon}
                </Button>
              )}
            </>
          ) : (
            <>
              {load && (
                <div className="spinner-align-center">
                  <Spinner
                    animation="border"
                    size="sm"
                  />
                </div>
              )}

              {!load && status !== 'Completed' && (
                <Button
                  title="Restore"
                  className="restore-btn"
                  onClick={() => {
                    setLoad(true);
                    restoreHour();
                  }}
                >
                  {restoreIcon}
                </Button>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
export default ApprenticeHoursList;
