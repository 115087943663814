import { createSlice } from '@reduxjs/toolkit';
import Log from '../lib/Log';
const initialState = {
  sections: [],
  active_first: null,
  active_second: null,
  active_third: null,
  active_component: null,
  active_column: 1,
  active_section: null,
  row_index: null,
  firstCol: [],
  secondCol: [],
  thirdCol: [],
};

export const sectionSlice = createSlice({
  name: 'sections',
  initialState,
  reducers: {
    setFirstCol: (state, action) => {
      return { ...state, firstCol: action.payload };
    },
    setSecondCol: (state, action) => {
      return { ...state, secondCol: action.payload };
    },
    setThirdCol: (state, action) => {
      return { ...state, thirdCol: action.payload };
    },
    storeSections: (state, action) => {
      return { ...state, sections: action.payload };
    },
    changeActiveFirst: (state, action) => {
      return { ...state, active_first: action.payload };
    },
    changeActiveSecond: (state, action) => {
      return { ...state, active_second: action.payload };
    },
    changeActiveThird: (state, action) => {
      return { ...state, active_third: action.payload };
    },
    setSelectedProgramComponent: (state, action) => {
      return { ...state, active_component: action.payload };
    },
    setActiveColumn: (state, action) => {
      return { ...state, active_column: action.payload };
    },
    setActiveSection: (state, action) => {
      return { ...state, active_section: action.payload };
    },
    setRowIndex: (state, action) => {
      return { ...state, row_index: action.payload };
    },
  },
});

export const {
  storeSections,
  setRowIndex,
  changeActiveFirst,
  changeActiveSecond,
  changeActiveThird,
  setSelectedProgramComponent,
  setActiveColumn,
  setActiveSection,
  setFirstCol,
  setSecondCol,
  setThirdCol,
} = sectionSlice.actions;
export default sectionSlice.reducer;
