import { useSelector } from 'react-redux';
import TaxonomyState from '../hooks/TaxonomyState';
import { translate } from '../lib';
import { getTaxonomy, getOrgTaxonomySuperAdmin } from '../api';
import ls from 'localstorage-slim';
ls.config.encrypt = true;
export const learnerSidebarList = async (program) => {
  const member_program_id = ls.get('activeMemberProgramId');

  let result = await getTaxonomy();
  if (result) {
    return [
      {
        text: 'Lecture List',
        type: 'item',
        link: `/learner/program/${member_program_id}/lecture-list`,
        isReact: true,
        children: null,
        icon: null,
        isActive: false,
        isDynamic: false,
        isEnable: true,
      },
      {
        text: 'Task list',
        type: 'item',
        link: `/learner/program/${member_program_id}/task-list`,
        isReact: true,
        children: null,
        icon: null,
        isActive: false,
        isDynamic: false,
        isEnable: program?.enable_mandatory_option,
      },
      {
        text: result?.data?.target,
        type: 'item',
        link: `/learner/program/${member_program_id}/target`,
        isReact: true,
        children: null,
        icon: null,
        isActive: false,
        isDynamic: false,
        isEnable: true,
      },
      // {
      //   text: 'Tutors',
      //   type: 'item',
      //   link: '/learner/program/tutors',
      //   isReact: true,
      //   children: null,
      //   icon: null,
      //   isActive: false,
      //   isDynamic: false,
      // },
      // {
      //   text: 'Document Bank',
      //   type: 'item',
      //   link: '/learner/program/document-bank',
      //   isReact: true,
      //   children: null,
      //   icon: null,
      //   isActive: false,
      //   isDynamic: false,
      // },
      {
        text: 'Quick Access',
        type: 'item',
        link: `/learner/program/${member_program_id}/quick-links`,
        isReact: true,
        children: null,
        icon: null,
        isActive: false,
        isDynamic: false,
        isEnable: true,
      },
      {
        text: 'Resubmission',
        type: 'item',
        link: `/learner/program/${member_program_id}/resubmission/`,
        isReact: true,
        isEnable: true,
        count: '21',
        className: 'feedback-sidebar-wrap',
      },
      {
        text: 'Custom Fields',
        type: 'item',
        link: `/learner/program/${member_program_id}/custom-fields`,
        isReact: true,
        children: null,
        icon: null,
        isActive: false,
        isDynamic: false,
        isEnable: true,
      },
      // Program report
      {
        text: 'Reports',
        type: 'item',
        link: `/learner/program/${member_program_id}/reports`,
        isReact: true,
        children: null,
        icon: null,
        isActive: false,
        isDynamic: false,
        isEnable: true,
      },
    ];
  }
};
