import { memo, useRef, useEffect, useState } from 'react';
import { Row, Col, Card, Spinner } from 'react-myoneui';
import { cameraIcon, explainIcon, externalLink } from 'constants/icons';
import Log from 'lib/Log';
import TextEditor from 'components/TextEditor';
import { fileUploader } from 'api';
import { useDispatch, useSelector } from 'react-redux';
import { storeFormData } from '../../../redux/FormDataSlice';
import DOMPurify from 'dompurify';
import ViewModel from 'modules/learner/components/ViewModal';
function InputEvidence({ data, mode }) {
  const state = useSelector((state) => state.formData.data.find((item) => item.id === data.id));
  const formData = useSelector((state) => state.formData.data);
  const dispatch = useDispatch();
  const [showEditor, setShowEditor] = useState(false);
  const uploader = useRef(null);
  const [loading, setLoading] = useState(false);
  const [showCamera, setShowCamera] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [fileType, setFileType] = useState('');
  const purify = DOMPurify(window);
  useEffect(() => {
    setShowEditor(true);
  }, [state]);

  const fileUpload = async (e) => {
    try {
      if (mode !== 'preview') {
        setLoading(true);
        let result = await fileUploader(e.target.files[0]);
        if (result) {
          let imageLocation = result?.data?.data[0]?.fileToSend?.Location;
          //set_image_url(result?.data?.data[0]?.fileToSend?.Location);
          let cloneFormData = JSON.parse(JSON.stringify(formData));
          let obj = cloneFormData.find((item) => item.id === data.id);
          let index = cloneFormData.indexOf(obj);
          obj.image_url = imageLocation;
          cloneFormData[index] = obj;
          dispatch(storeFormData(cloneFormData));
        }
        setLoading(false);
      }
    } catch (err) {
      Log.error(err);
    }
  };
  const valueControl = (value) => {
    try {
      if (mode !== 'preview') {
        let cloneFormData = JSON.parse(JSON.stringify(formData));
        let obj = cloneFormData.find((item) => item.id === data.id);
        let index = cloneFormData.indexOf(obj);
        obj.value = value;
        cloneFormData[index] = obj;
        dispatch(storeFormData(cloneFormData));
      }
    } catch (error) {
      Log.error(error);
    }
  };
  const triggerUploader = () => {
    try {
      if (mode !== 'preview') {
        uploader.current.click();
      }
    } catch (error) {
      Log.error(error);
    }
  };

  return (
    <>
      <Col
        md={12}
        className="evidence-block"
      >
        <h3 className="evidenceLabel">{data.label}</h3>
        <Row className="evidence-row">
          <Col
            md={4}
            xl={3}
          >
            <input
              type="file"
              className="file-input d-none"
              ref={uploader}
              accept="image/png, image/gif, image/jpeg,image/jpg"
              onChange={fileUpload}
              disabled={mode === 'readonly'}
            />
            {state.image_url && state.image_url.length > 0 ? (
              <div
                className={`profile-image-container ${mode}`}
                onMouseEnter={() => setShowCamera(true)}
                onMouseLeave={() => setShowCamera(false)}
              >
                <img
                  className="profile-image"
                  src={state.image_url}
                />
                {showCamera && (
                  <div className="overlay-container">
                    <div
                      className="expand"
                      onClick={() => setModalStatus(true)}
                    >
                      {externalLink}
                    </div>
                    {mode !== 'readonly' && (
                      <div
                        className="camera-icon"
                        onClick={triggerUploader}
                      >
                        {cameraIcon}
                      </div>
                    )}
                  </div>
                )}
                {loading && (
                  <div className="overlay-container">
                    <Spinner animation="border" />
                  </div>
                )}
              </div>
            ) : (
              <Card
                className="evidence-thumbnail"
                onClick={triggerUploader}
              >
                <Card.Body className="img-wrap">
                  {loading ? <Spinner animation="border" /> : cameraIcon}

                  <span className="txt">200x200</span>
                </Card.Body>
                <Card.Footer>
                  <div className="helperText"> {state.profile_img_helper_text}</div>
                </Card.Footer>
              </Card>
            )}
          </Col>

          <Col
            md={8}
            xl={9}
          >
            {mode == 'readonly' ? (
              <div
                className="observation-block observation-pointer-block observation-read-only"
                style={{ marginBottom: '0px', maxHeight: '100%' }}
              >
                <h5 className="small-text-title">{state?.label}</h5>
                <div dangerouslySetInnerHTML={{ __html: purify.sanitize(state?.value ? state.value : '<p>&nbsp;</p>') }} />
              </div>
            ) : (
              <Card>
                {showEditor && (
                  <TextEditor
                    className={mode === 'preview' ? 'disabled' : ''}
                    placeholder={state.placeholder}
                    value={state.value}
                    onChange={valueControl}
                  />
                )}
              </Card>
            )}
          </Col>
        </Row>
      </Col>
      <ViewModel
        onClose={() => setModalStatus(false)}
        show={modalStatus}
        fileType={state?.image_url?.split('.').pop()}
        fileLink={state?.image_url}
        fileName={''}
      />
    </>
  );
}
export default memo(InputEvidence);
