import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  access_token: '',
  idp: '',
  eventAuthToken: '',
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setConfigData: (state, action) => {
      // console.log(state,"state")
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      for (let x in action.payload) {
        if (x in state) {
          state[x] = action.payload[x];
        }
      }
    },
    resetConfigData: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setConfigData, resetConfigData } = configSlice.actions;

export default configSlice.reducer;
