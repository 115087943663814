import { useEffect, useState } from 'react';
import { CircleImage } from 'react-myoneui';
import Button from 'react-myoneui/lib/Button';
import { formatDate } from '../../utils/formatTime';
import { useSelector } from 'react-redux';
import Log from 'lib/Log';
import ls from 'localstorage-slim';
// Images
import { removeIcon, filesIcon } from '../../constants/icons';
import { addLog } from 'api/log';
ls.config.encrypt = true;
const OberservationEvidence = ({ data = {}, status, title = '', totalMb = '', viewBtn = true, removeBtn = true, downloadBtn, type, link, onShowModel = () => {}, onDelete = () => {} }) => {
  const [isImage, setIsImage] = useState(null);
  const [isPdf, setIsPdf] = useState(null);
  const selectedComponent = useSelector((state) => state.sections.active_component);
  const role = ls.get('role');
  useEffect(() => {
    if (type == 'jpg' || type == 'png' || type == 'jpeg' || type == 'mp3' || type == 'mp4') {
      setIsImage(true);
    } else {
      setIsImage(false);
    }

    if (type === 'pdf') {
      setIsPdf(true);
    } else {
      setIsPdf(false);
    }
  });

  const showPDF = () => {
    window.open(link, '_blank');
  };

  return (
    <div className="information-block information-block-sm-pd bg-ghost-white mb-3">
      <div className="information-wrap">
        <div className="information-holder">
          <CircleImage className="circle-wrap circle-wrap-xs bg-white">{filesIcon}</CircleImage>
          <div className="information-detail">
            {title && <h4 title={title}>{title}</h4>}
            {totalMb && <span>{totalMb}</span>}

            {data.createdAt && <span> {formatDate(data.createdAt)},</span>}
            {data.attached_by && <span>{data?.attached_by?.first_name && data?.attached_by?.last_name && data?.attached_by?.first_name + ' ' + data?.attached_by?.last_name}</span>}
          </div>
        </div>
        <div className="button-wrap">
          {isImage && (
            <>
              <Button
                variant="information-white-bg"
                className="information-white-bg btn-sm btn-round"
                onClick={() => onShowModel({ link, type })}
              >
                view
              </Button>
            </>
          )}
          {isPdf && (
            <>
              {/* <Button variant="information-white-bg" onClick={() => showPDF()} className="information-white-bg btn-sm btn-round">
                View
              </Button> */}
            </>
          )}
          <Button
            variant="information-white-bg"
            className="information-white-bg btn-sm btn-round"
            onClick={downloadBtn}
          >
            Download
          </Button>
          {/* IQV and EQV should not remove evidences */}

          {removeBtn && status !== 'Completed' && status !== 'Submitted' && role !== 'eqv' && role !== 'iqv' && (
            <Button
              variant="information-white-bg"
              className="information-white-bg btn-sm btn-round"
              onClick={() => onDelete(data)}
            >
              {removeIcon}
              Remove
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
export default OberservationEvidence;
