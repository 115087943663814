import React from 'react';
import { CircleImage } from 'react-myoneui';
import { removeIcon, filesIcon, youTubeIcon, attachmentIcon, imageIcon, zipIcon, pdfIcon } from '../../../../../../constants/icons';

const AddResourseInfoBlock = (props) => {
  const { id, name, description, cirleImage, onClick, type } = props;

  // const showFileIcon = (ext) => {
  //   switch (ext) {
  //     case 'pdf':
  //       return pdfIcon;
  //     case 'jpg':
  //       return imageIcon;
  //     case 'peg':
  //       return imageIcon;
  //     case 'png':
  //       return imageIcon;
  //     case 'mp4':
  //       return youTubeIcon;
  //     case 'mp3':
  //       return youTubeIcon;
  //     case 'zip':
  //       return zipIcon;
  //     default:
  //       return filesIcon;
  //   }
  // };

  const clickHandler = () => {
    if (onClick) {
      onClick({
        id,
        name,
        description,
        image: cirleImage,
      });
    }
  };
  return (
    <div
      className={`information-block information-block-sm-pd bg-ghost-white ${props?.className || ''}`}
      onClick={clickHandler}
    >
      <div className="information-wrap">
        <div className="information-holder">
          {/* {cirleImage && <CircleImage className="circle-wrap circle-wrap-xs bg-white">{url && <img src={url} alt="image" />}</CircleImage>} */}
          <CircleImage className="circle-wrap circle-wrap-xs bg-white">{filesIcon}</CircleImage>
          <div className="information-detail">
            {name && <h4>{name}</h4>}
            {description && <span>{description}</span>}
          </div>
        </div>
        {props.children}
      </div>
    </div>
  );
};

export default AddResourseInfoBlock;
