import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [] };

export const QuizDataSlice = createSlice({
  name: 'quizData',
  initialState,
  reducers: {
    storeQuizData: (state, action) => {
      state.data = action.payload;
    },
    addQuizData: (state, action) => {
      state.data.push(action.payload);
    },
    flushQuizData: () => initialState,
    updateValue: (state, action) => {
      const { questionId, index, updates, type } = action.payload;
      //  questionId
      const questionIndex = state.data.findIndex((item) => item.questionId === questionId);
      if (questionIndex >= 0) {
        // handle radio input type
        if (type === 'radio') {
          // If type is radio, update to have only the latest value
          state.data[questionIndex].selectedOptions = [updates];
        } else {
          // other input type handler
          const selectedOptionIndex = state.data[questionIndex].selectedOptions.findIndex((option) => option.index === index);
          if (selectedOptionIndex >= 0) {
            // if answer already exits than we update only value such as checked, value
            state.data[questionIndex].selectedOptions[selectedOptionIndex] = {
              ...state.data[questionIndex].selectedOptions[selectedOptionIndex],
              ...updates,
            };
          } else {
            // adding new answer id not exists
            state.data[questionIndex].selectedOptions.push(updates);
          }
        }
      } else {
        // adding answer of new question if question not exist
        const newQuestion = {
          questionId,
          selectedOptions: [updates],
        };
        state.data.push(newQuestion);
      }
    },
  },
});

export const { storeQuizData, addQuizData, flushQuizData, updateValue } = QuizDataSlice.actions;
export default QuizDataSlice.reducer;
