import { trashIcon } from '../../../../../../constants/icons';
import HeaderTemplate from './HeaderTemplate';
import ls from 'localstorage-slim';
import LinkWrapper from 'components/LinkWrapper';

ls.config.encrypt = true;
const ContextMenuTemplate = ({ unAssignUsers, assessorName }) => {
  return ({ tableManager }) => {
    return (
      <>
        <div className="rgt-cell-header-sticky rgt-context-menu">
          <div className="grid-table-header">
            <HeaderTemplate tableManager={tableManager} />
            <div className="grid-table-inner-header">
              <LinkWrapper
                href="#top"
                onClick={unAssignUsers}
              >
                {trashIcon}
                <span className="fw-bold fs-10"> Unassign {assessorName}</span>
              </LinkWrapper>
            </div>
          </div>
        </div>
      </>
    );
  };
};

export default ContextMenuTemplate;
