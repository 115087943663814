import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: false,
  okText: 'OK',
  cancelText: 'Cancel',
  info: null,
  question: '',
  activeId: null,
  name: '',
};

export const confirmBoxSlice = createSlice({
  name: 'confirmBox',
  initialState,
  reducers: {
    setConfirmBoxStatus: (state, action) => {
      return {
        ...state,
        status: action.payload,
      };
    },
    setConfirmBoxOKText: (state, action) => {
      return {
        ...state,
        okText: action.payload,
      };
    },
    setConfirmBoxCancelText: (state, action) => {
      return {
        ...state,
        cancelText: action.payload,
      };
    },
    setConfirmBoxInfoText: (state, action) => {
      return {
        ...state,
        info: action.payload,
      };
    },
    setConfirmBoxActiveId: (state, action) => {
      return {
        ...state,
        activeId: action.payload,
      };
    },
    setConfirmBoxQuestionText: (state, action) => {
      return {
        ...state,
        question: action.payload,
      };
    },
    setConfirmBoxName: (state, action) => {
      return {
        ...state,
        name: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setConfirmBoxStatus, setConfirmBoxOKText, setConfirmBoxActiveId, setConfirmBoxCancelText, setConfirmBoxInfoText, setConfirmBoxQuestionText } = confirmBoxSlice.actions;

export default confirmBoxSlice.reducer;
