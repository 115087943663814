import React from 'react';
import { useState } from 'react';

import { CircleImage, CircleImageContent } from 'react-myoneui';
import { assessorIcon, learnerIcon, learnerProfileIcon, learnersIcon } from 'constants/icons';

const NameTemplate = ({ value, data, tableManager }) => {
  const [isImage, setIsImage] = useState(true);

  const {
    config: {
      additionalProps: { cell: additionalProps = {} },
    },
  } = tableManager;

  const classNames = ('rgt-cell-inner rgt-text-truncate ' + (additionalProps.className || '')).trim();

  const handleImage = () => {
    setIsImage(false);
  };
  return (
    <div
      {...additionalProps}
      className={classNames}
      title={value}
    >
      <div className="d-flex gap-3 align-items-center">
        <CircleImage className="circle-wrap circle-wrap-md">
          <CircleImage className="circle-wrap circle-wrap-md">
            {isImage ? (
              data?.profile_image?.length > 0 ? (
                <img
                  src={data?.profile_image}
                  alt={data?.name}
                  onError={handleImage}
                />
              ) : (
                <>{learnersIcon}</>
              )
            ) : (
              <>{learnersIcon}</>
            )}
          </CircleImage>
        </CircleImage>
        <span className="grid-text-overflow fw-bold">{value}</span>
      </div>
    </div>
  );
};

export default NameTemplate;
