import { getPlainTextFromHtmlText, truncateParagraph } from 'lib/common';
import React, { useEffect, useState } from 'react';

function DescriptionSeeMore(props) {
  const [showMore, setShowMore] = useState(true);
  const [screenWidth, setScreenWidth] = useState(null);
  const { content = '' } = props;

  const resize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    if (window) {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, []);

  return (
    <h6>
      <span className="program-intro-info">{truncateParagraph(getPlainTextFromHtmlText(content), screenWidth, showMore)?.content}</span>
      {showMore && truncateParagraph(getPlainTextFromHtmlText(content), screenWidth, showMore)?.more && (
        <span
          className="show-program-info"
          onClick={() => setShowMore(!showMore)}
        >
          Show More
        </span>
      )}
    </h6>
  );
}

export default DescriptionSeeMore;
