import { memo, useEffect, useState } from 'react';
import { Col, Card } from 'react-myoneui';
import TextEditor from 'components/TextEditor';
import { useDispatch, useSelector } from 'react-redux';
import { storeFormData } from '../../../redux/FormDataSlice';
import Log from 'lib/Log';
import DOMPurify from 'dompurify';
function InputTextarea({ data, mode }) {
  const state = useSelector((state) => state.formData.data.find((item) => item.id === data.id));
  const formData = useSelector((state) => state.formData.data);
  const dispatch = useDispatch();
  const [showEditor, setShowEditor] = useState(false);
  const purify = DOMPurify(window);
  const valueControl = (value) => {
    try {
      let cloneFormData = JSON.parse(JSON.stringify(formData));
      let obj = cloneFormData.find((item) => item.id === data.id);
      let index = cloneFormData.indexOf(obj);
      obj.value = value;
      cloneFormData[index] = obj;
      dispatch(storeFormData(cloneFormData));
    } catch (error) {
      Log.error(error);
    }
  };

  useEffect(() => {
    setShowEditor(true);
  }, [state]);
  return (
    <>
      <Col
        className="py-2"
        md={state?.col_width}
        name="formElement"
      >
        {mode === 'readonly' ? (
          <div className="observation-block observation-pointer-block observation-read-only">
            <h5 className="small-text-title">{state?.label}</h5>
            <div dangerouslySetInnerHTML={{ __html: purify.sanitize(state?.value ? state.value : '<p>&nbsp;</p>') }} />
          </div>
        ) : (
          <>
            <label className="form-builder-label form-label">{state?.label}</label>
            <Card>
              {showEditor && (
                <TextEditor
                  placeholder={data?.placeholder}
                  className={mode === 'preview' ? 'disabled' : ''}
                  value={state?.value ? state.value : '<p>&nbsp;</p>'}
                  onChange={valueControl}
                />
              )}
            </Card>
          </>
        )}
      </Col>
    </>
  );
}
export default memo(InputTextarea);
