import { closeIcon } from 'constants/icons';
import { useRef } from 'react';
import { Button, Modal, Spinner } from 'react-myoneui';

function ViewModel(props) {
  const { onClose, show, fileType, fileLink, fileName } = props;
  const ViewModelClose = () => onClose();
  const brokenImage = useRef(null);
  const setBrokenImage = (obj) => {
    brokenImage.current.src = '/oops.jpg';
  };
  const brokenVideo = useRef(null);
  const setBrokenVideo = (obj) => {
    brokenImage.current.src = '/oops.jpg';
  };
  return (
    <>
      <Modal
        className="receipt-view-modal"
        show={show}
        onHide={ViewModelClose}
        backdrop="static"
        keyboard={false}
        contentClassName="bg-transparent"
        centered
      >
        <Modal.Header>
          <Modal.Title>{fileName}</Modal.Title>
          <Button
            variant="icon"
            className="rounded-circle"
            href="#"
            role="button"
            onClick={ViewModelClose}
          >
            {closeIcon}
          </Button>
        </Modal.Header>
        <Modal.Body className="loading-content-wrap">
          {/* <div className="spinner-middle">
            <Spinner animation="border" />
          </div> */}
          <div className="resource-file-wrap">
            {(fileType == 'jpg' || fileType == 'png' || fileType == 'jpeg') && (
              <img
                ref={brokenImage}
                onError={() => setBrokenImage()}
                src={fileLink}
                alt="n"
                loading="lazy"
              />
            )}
            {(fileType == 'mp3' || fileType == 'mp4') && (
              <video
                height="auto"
                controls
                ref={brokenVideo}
                onError={() => setBrokenVideo()}
              >
                <source
                  src={fileLink}
                  type="video/mp4"
                />
              </video>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ViewModel;
