import { memo } from 'react';
import { Form, Col } from 'react-myoneui';
function InputTextarea({ data, mode, question_number }) {
  return (
    <>
      <Col
        className="py-2"
        // md={state?.col_width}
        name="formElement"
      >
        <Form.Label
          htmlFor={data?.title}
          className="form-builder-label"
        >
          {question_number}. {data?.title}
        </Form.Label>
        {data?.options?.length > 0
          ? data.options.map((item, index) => {
              return (
                <div
                  className="mb-1 ms-2"
                  key={index}
                >
                  <Form.Control
                    className="h-25 text-area-height"
                    type="text"
                    as="textarea"
                    tabIndex="4"
                    id={item?.index}
                    aria-describedby="passwordHelpBlock"
                    placeholder={item?.placeholder}
                    value={item?.value}
                  />
                </div>
              );
            })
          : ''}
      </Col>
    </>
  );
}
export default memo(InputTextarea);
