import { memo } from 'react';
import { sectionGridIcon, sectionMenuIcon, multipleLineIcon, wifiIcon, singleLineIcon, imagesIcon } from 'constants/icons';
function DragElement({ field = {} }) {
  const renderFormElementIcon = (type) => {
    switch (type) {
      case 'text':
        return singleLineIcon;
      case 'textarea':
        return multipleLineIcon;
      case 'image':
        return imagesIcon;
      case 'evidence':
        return imagesIcon;
    }
  };
  const showIcon = () => {
    switch (field.name) {
      case 'section':
        return sectionMenuIcon;
      case 'sub-section':
        return wifiIcon;

      case 'text':
        return renderFormElementIcon(field.field_type);
      default:
        return sectionMenuIcon;
    }
  };
  return (
    <div className="form-builder-elements-section">
      <div className="form-builder-elements-grid">{sectionGridIcon}</div>
      <div className="form-builder-elements-info">
        <div className="form-builder-elements-icon">{showIcon()}</div>
        <div className="form-builder-elements-intro">
          <h4>{field.title}</h4>
          <span>{field.sub_heading || field.description}</span>
        </div>
      </div>
    </div>
  );
}
export default memo(DragElement);
