import React, { forwardRef, useEffect } from 'react';
import Slider from 'react-slick';
import Reminders from './Reminders';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import thumbnail1 from 'assets/images/thumbnail1.png';
import thumbnail2 from 'assets/images/thumbnail2.png';
import thumbnail3 from 'assets/images/thumbnail3.png';
import thumbnail4 from 'assets/images/thumbnail4.png';
import thumbnail5 from 'assets/images/thumbnail5.png';
import thumbnail6 from 'assets/images/thumbnail6.png';

const RemindersSlider = forwardRef(({ onSlideChange, onTotalSlidesUpdate }, ref) => {
  const remindersData = [
    {
      imgSrc: thumbnail1,
      deadline: 'Oct 31, 2024',
      description: 'Generative AI, a subset of artificial intelligence, has rapidly gained traction across industries.',
      viewDetailsLink: '/details/1',
    },
    {
      imgSrc: thumbnail2,
      deadline: 'Nov 15, 2024',
      description: 'A deeper dive into AI trends transforming the tech landscape.',
      viewDetailsLink: '/details/2',
    },
    {
      imgSrc: thumbnail3,
      deadline: 'Dec 1, 2024',
      description: 'Understanding key components of digital transformation for businesses.',
      viewDetailsLink: '/details/3',
    },
    {
      imgSrc: thumbnail4,
      deadline: 'Oct 31, 2024',
      description: 'Generative AI, a subset of artificial intelligence, has rapidly gained traction across industries.',
      viewDetailsLink: '/details/1',
    },
    {
      imgSrc: thumbnail5,
      deadline: 'Nov 15, 2024',
      description: 'A deeper dive into AI trends transforming the tech landscape.',
      viewDetailsLink: '/details/2',
    },
    {
      imgSrc: thumbnail6,
      deadline: 'Dec 1, 2024',
      description: 'Understanding key components of digital transformation for businesses.',
      viewDetailsLink: '/details/3',
    },
  ];

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    afterChange: (current) => onSlideChange(current),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    onTotalSlidesUpdate(remindersData.length);
  }, [remindersData.length, onTotalSlidesUpdate]);

  return (
    <Slider
      ref={ref}
      {...settings}
      className="reminders-slider"
    >
      {remindersData.map((reminder, index) => (
        <div key={index}>
          <Reminders
            imgSrc={reminder.imgSrc}
            deadline={reminder.deadline}
            description={reminder.description}
            viewDetailsLink={reminder.viewDetailsLink}
          />
        </div>
      ))}
    </Slider>
  );
});

export default RemindersSlider;
