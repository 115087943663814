import Toast from 'react-myoneui/lib/Toast';
import ToastContainer from 'react-myoneui/lib/ToastContainer';
import ToastInfo from 'react-myoneui/lib/ToastInfo';
import { useSelector, useDispatch } from 'react-redux';
import { setToastBoxStatus } from '../redux/ToastBoxSlice';
const ToastBox = () => {
  const description = useSelector((state) => state.toastBox.description);
  const dispatch = useDispatch();
  const status = useSelector((state) => state.toastBox.status);
  return (
    <ToastContainer
      position="bottom-center"
      className="p-3 position-fixed"
    >
      <Toast
        variant="secondary"
        onClose={() => dispatch(setToastBoxStatus(false))}
        show={status}
        delay={3000}
        autohide
      >
        <Toast.Body>
          <ToastInfo toastDescription={description} />
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default ToastBox;
