import { Route } from 'react-router-dom';
import { lazyWithRetry } from 'components/lazyWithRetry';
import FormBuilderInformation from 'modules/admin/formBuilder/FormBuilderInformation';
import FormBuilderLayout from 'layouts/FormBuilderLayout';
import AddingNewForm from 'modules/admin/formBuilder/AddNewForm';
import PreviewFormBuilder from 'modules/admin/formBuilder/PreviewFormBuilder';
const FormBuilder = lazyWithRetry(() => import('modules/admin/formBuilder'));

export default (
  <>
    <Route
      path="form-builder"
      element={<FormBuilderLayout />}
    >
      <Route
        path=""
        element={<FormBuilder />}
      />
      <Route
        path=":formId/details"
        element={<AddingNewForm />}
      />
      <Route
        path=":formId/preview"
        element={<PreviewFormBuilder />}
      />
      <Route
        path=":formId"
        element={<FormBuilderInformation />}
      />
    </Route>
  </>
);
