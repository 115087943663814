import { useRef, useEffect, memo } from 'react';

function DropElementsSection(props) {
  const { children, highlight } = props;
  const ref = useRef(null);
  const { onClickOutside } = props;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);
  return (
    <div
      className={`drop-elements-section ${props?.className}`}
      ref={ref}
    >
      {children}
    </div>
  );
}
export default memo(DropElementsSection);
