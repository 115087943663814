import { useState, forwardRef, Children, useEffect } from 'react';
import Dropdown from 'react-myoneui/lib/Dropdown';
import Form from 'react-myoneui/lib/Form';
import FloatingLabel from 'react-myoneui/lib/FloatingLabel';
import Log from '../lib/Log';
// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = forwardRef(({ children, onClick }, ref) => (
  <div
    role="button"
    tabIndex={0}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = forwardRef(({ children, style, className, value, 'aria-labelledby': labeledBy }, ref) => {
  // const [value, setValue] = useState('');

  return (
    <div
      ref={ref}
      style={style}
      className={className}
      aria-labelledby={labeledBy}
    >
      <ul className="list-unstyled mb-0">{Children.toArray(children).filter((child) => !value || child.props.children.toString().toLowerCase().includes(value))}</ul>
    </div>
  );
});

function DropdownCheckboxMultiSelect({ label, className, items = [], selectedItems = [], onChange = () => {}, disabled = false }) {
  const [value, setValue] = useState([]);
  /*
  useEffect(() => {
    setValue(selectedItems);
  }, [selectedItems]);
*/
  useEffect(() => {
    setValue(selectedItems);
  }, [selectedItems]);
  const onSelect = (index) => {
    const selected = items[index];
    onChange(selected);
  };

  return (
    <Dropdown
      className={`dropdown-select ${className || ''}`}
      onSelect={onSelect}
      autoClose="outside"
      disabled={disabled}
    >
      <Dropdown.Toggle
        as={CustomToggle}
        id="dropdown-custom-components"
      >
        <FloatingLabel
          controlId="floatingSelection"
          label={label ? label : 'Choose Option'}
          className="w-100"
        >
          <Form.Control
            className="pointer-event-none"
            autoComplete="off"
            type="text"
            placeholder="roleSelection"
            value={selectedItems.join(', ')}
            onChange={() => {}}
          />
          <div className="caret-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="6.119"
              height="11.658"
              viewBox="0 0 6.119 11.658"
            >
              <path
                id="Path_21616"
                data-name="Path 21616"
                d="M0,0,4.42,3.71,8.84,0"
                transform="translate(1.409 10.249) rotate(-90)"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </svg>
          </div>
        </FloatingLabel>
      </Dropdown.Toggle>
      <Dropdown.Menu
        as={CustomMenu}
        align={'end'}
      >
        {items.map((item, i) => (
          <Dropdown.Item
            key={i}
            eventKey={i}
            active={selectedItems.indexOf(item) > -1}
          >
            <Form.Check
              inline
              label={item}
              checked={selectedItems.indexOf(item) > -1}
              value=""
              onChange={() => Log.error('...')}
              name="Monday"
              type="checkbox"
              className="align-items-center"
            />
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DropdownCheckboxMultiSelect;
