import { formatUTCDate } from './formatTime';

export const upCommingTargets = (targets) => {
  if (targets.length > 0) {
    const upcommingTargets = targets.filter((target) => new Date(target.date).getTime() > new Date().getTime());
    if (upcommingTargets.length > 0) {
      const nearestTargetDate = new Date(Math.min(...upcommingTargets.map((target) => new Date(target.date).getTime())));
      const nearestTarget = targets.filter((target) => new Date(target.date).getTime() === new Date(nearestTargetDate).getTime());
      return { nearestTargetDate: formatUTCDate(nearestTarget[0].date), nearestTargetProgress: nearestTarget[0].progress, totalUpcommingTargets: upcommingTargets.length - 1 };
    } else {
      return;
    }
  } else {
    return;
  }
};
