// Images
import { helpIcon } from '../constants/icons';

function MobileSidebarItems() {
  return (
    <div className="mobile-sidebar-item">
      <li className="divider"></li>
      <li>
        <a
          href="#top"
          className="is-active"
        >
          <div className="icon-wrap">
            {helpIcon}
            <span className="sidebar-hidden-item text-truncate">Help</span>
          </div>
        </a>
      </li>
      <li className="divider"></li>
      <li>
        <a
          href="#top"
          className="is-active"
        >
          <div className="icon-wrap circle-user-profile-gap">
            <div className="circle-wrap circle-wrap-md bg-circle-twilight text-white circle-user-profile">A</div>
            <span className="sidebar-hidden-item text-truncate">Anna Sthesia</span>
          </div>
        </a>
      </li>
      <li>
        <a
          href="#top"
          className="is-active sidebar-hidden-item"
        >
          <div className="icon-wrap user-pl">
            <span className="sidebar-hidden-item text-truncate">Profile</span>
          </div>
        </a>
      </li>
      <li>
        <a
          href="#top"
          className="is-active sidebar-hidden-item"
        >
          <div className="icon-wrap user-pl">
            <span className="sidebar-hidden-item text-truncate">Logout</span>
          </div>
        </a>
      </li>
    </div>
  );
}

export default MobileSidebarItems;
