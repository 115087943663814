import { useEffect, useState } from 'react';
import Nav from 'react-myoneui/lib/Nav';
import { useNavigate, Link } from 'react-router-dom';

function HorizontalNavLinkList({ className = '', navKey = '' }) {
  const navigate = useNavigate();

  const linkHandler = (to) => {
    navigate(to);
  };
  useEffect(() => {}, []);
  return (
    <></>
    // <div className={`${className || ''} student-dashboard-wrapper px-4 py-3`}>
    //   <Nav activeKey={navKey}>
    //     <Nav.Item>
    //       <Link
    //         to="/learner/program"
    //         className={`nav-link ${navKey === 'link-1' ? 'active' : ''}`}
    //       >
    //         Dashboard
    //       </Link>
    //     </Nav.Item>
    //     <Nav.Item>
    //       <Link
    //         to="/learner/program"
    //         className={`nav-link ${navKey === 'link-2' ? 'active' : ''}`}
    //       >
    //         Programs
    //       </Link>
    //     </Nav.Item>
    //     <Nav.Item>
    //       <Link
    //         to="/learner/contacts"
    //         className={`nav-link ${navKey === 'link-3' ? 'active' : ''}`}
    //       >
    //         Contacts
    //       </Link>
    //     </Nav.Item>

    //   </Nav>
    // </div>
  );
}

export default HorizontalNavLinkList;
