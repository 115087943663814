import { certificateIcon, deadlineIcon, liveIcon, menuBookIcon, submittedIcon } from 'constants/icons';

const activities = [
  {
    icon: certificateIcon,
    bgClass: 'bg-completed',
    title: 'Completed',
    description: 'Chapter 1: Introduction to AI',
    date: 'Sep 26, 2024',
    time: '1:00 PM',
  },
  {
    icon: liveIcon,
    bgClass: 'bg-live',
    title: 'Attended Webinar:',
    description: 'B2B Commerce Breakthrough: Path to Profitability',
    date: 'Sep 26, 2024',
    time: '1:00 PM',
  },
  {
    icon: submittedIcon,
    bgClass: 'bg-submitted',
    title: 'Submitted assignment of',
    description: 'Chapter 1: Introduction to AI',
    date: 'Sep 26, 2024',
    time: '1:00 PM',
  },
  {
    icon: menuBookIcon,
    bgClass: 'bg-started',
    title: 'Started course',
    description: 'Digital Transformation Summit: Revenue Revolution',
    date: 'Sep 26, 2024',
    time: '1:00 PM',
  },
  {
    icon: liveIcon,
    bgClass: 'bg-live',
    title: 'Attended Webinar:',
    description: 'B2B Commerce Breakthrough: Path to Profitability',
    date: 'Sep 26, 2024',
    time: '1:00 PM',
  },
  {
    icon: submittedIcon,
    bgClass: 'bg-submitted',
    title: 'Submitted assignment of',
    description: 'Chapter 1: Introduction to AI',
    date: 'Sep 26, 2024',
    time: '1:00 PM',
  },
  {
    icon: menuBookIcon,
    bgClass: 'bg-started',
    title: 'Started course',
    description: 'Digital Transformation Summit: Revenue Revolution',
    date: 'Sep 26, 2024',
    time: '1:00 PM',
  },
  {
    icon: liveIcon,
    bgClass: 'bg-live',
    title: 'Attended Webinar:',
    description: 'B2B Commerce Breakthrough: Path to Profitability',
    date: 'Sep 26, 2024',
    time: '1:00 PM',
  },
];

export default function MyActivities() {
  return (
    <div className="activities-content">
      <ul className="activity-content-list">
        {activities.map((activity, index) => (
          <li key={index}>
            <div className={`icon-wrap ${activity.bgClass}`}>{activity.icon}</div>
            <div className="info-wrap">
              <h4>
                {activity.title} <span>{activity.description}</span>
              </h4>
              <div className="date-wrap">
                {deadlineIcon}
                <span>{activity.date}</span>
                <span className="dot"></span>
                <span>{activity.time}</span>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
