import React from 'react';
import { Form, Modal, Button, Spinner, FloatingLabel, Row, Col } from 'react-myoneui';
import Log from 'lib/Log';
export const InputPopUpBox = (props) => {
  const { show, onChange, value, okText, cancelText, onOk, onHide, loading, variantBtn, title, inputField, inputLabel, inputPlaceholder, disabled, list, activeListIndex, onListClick } = props;

  const okHandler = async (data) => {
    try {
      onOk(data);
    } catch (err) {
      Log.error(err);
    }
  };

  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      contentClassName={list ? 'border rounded' : 'bg-transparent'}
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={okHandler}>
        <Modal.Body>
          {inputField && (
            <Row>
              <Col>
                <FloatingLabel
                  controlId="text"
                  label={inputLabel}
                >
                  <Form.Control
                    type="text"
                    name="text"
                    value={value}
                    onChange={onChange}
                    placeholder={inputPlaceholder}
                  />
                </FloatingLabel>
              </Col>
            </Row>
          )}

          {list && list?.length > 0
            ? list.map((element, index) => (
                <div
                  className="d-flex overflow-hidden  position-relative my-2 py-2 px-1 bg-gray rounded btn"
                  key={index}
                  onClick={() => onListClick(element?.id, index)}
                >
                  <p className="fs-4">
                    <span className="me-2">{index + 1}.</span>
                    {element?.name}
                    {index === activeListIndex && (
                      <Spinner
                        animation="border"
                        size="sm"
                        className="ms-2"
                      />
                    )}
                  </p>
                </div>
              ))
            : ''}
        </Modal.Body>
        <Modal.Footer>
          <>
            {onHide && (
              <Button
                variant="glitter"
                onClick={onHide}
              >
                {cancelText ? cancelText : ' No, Cancel'}
              </Button>
            )}
            {onOk && (
              <Button
                variant={variantBtn}
                onClick={okHandler}
                disabled={disabled}
                type="submit"
              >
                <div className="d-flex">
                  {loading && (
                    <span>
                      <Spinner
                        animation="border"
                        size="sm"
                      />
                      &nbsp; &nbsp;
                    </span>
                  )}

                  {okText ? okText : ' Yes, Confirm'}
                </div>
              </Button>
            )}
          </>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
