import { Route } from 'react-router-dom';
import { lazyWithRetry } from 'components/lazyWithRetry';
import ComponentDetail from 'modules/learner/program/ComponentDetail';
const ProgramFieldManagement = lazyWithRetry(() => import('modules/admin/program/FieldManagement'));
const EmailTemplate = lazyWithRetry(() => import('modules/admin/program/EmailTemplate/EmailTemplate'));
const AllComponent = lazyWithRetry(() => import('modules/admin/program/allComponent/index'));
const MemberProgramList = lazyWithRetry(() => import('modules/admin/program/MemberProgramList'));
const TutorProgramList = lazyWithRetry(() => import('modules/admin/program/TutorProgramList'));
const ProgramTab = lazyWithRetry(() => import('modules/learner/program/ProgramTab'));
const LectureList = lazyWithRetry(() => import('modules/learner/program/LectureList'));
const MemberAssessors = lazyWithRetry(() => import('modules/learner/program/assessors'));
const ActivityDetail = lazyWithRetry(() => import('modules/learner/program/ActivityDetail'));
const Program = lazyWithRetry(() => import('modules/admin/program'));
const EnrollmentLink = lazyWithRetry(() => import('modules/admin/program/EnrollmentLink'));
const RatingReview = lazyWithRetry(() => import('modules/admin/program/ratingReview'));
const Reports = lazyWithRetry(() => import('modules/admin/program/Reports'));

export default (
  <>
    <Route
      path="program/*"
      element={<Program />}
    />

    <Route
      path="program/:programId/email-template"
      exact
      element={<EmailTemplate />}
    />
    <Route
      path="program/:programId/field-management"
      exact
      element={<ProgramFieldManagement />}
    />
    <Route
      path="program/:programId/all-component"
      exact
      element={<AllComponent />}
    />
    <Route
      path="program/:programId/enrollment-link"
      exact
      element={<EnrollmentLink />}
    />
    <Route
      path="program/:programId/rating-review"
      exact
      element={<RatingReview />}
    />
    <Route
      path="program/:programId/reports"
      exact
      element={<Reports />}
    />
    <Route
      path="program/:programId/learner-list"
      exact
      element={<MemberProgramList />}
    />
    <Route
      path="program/:programId/tutor-list/:memId/leaner-list"
      exact
      element={<MemberProgramList />}
    />
    <Route
      path="program/:programId/learner-list/:id"
      exact
      element={<LectureList />}
    />
    <Route
      path="program/:programId/learner-list/:id/assessors"
      exact
      element={<MemberAssessors />}
    />
    <Route
      path="program/:programId/learner-list/:id/:programTab"
      exact
      element={<ProgramTab />}
    />
    <Route
      path="program/:programId/learner-list/:id/lecture-list/:sectionid/"
      element={<ComponentDetail backBtnStatus={true} />}
    />
    <Route
      path="program/:programId/learner-list/:id/lecture-list/:lectureDetail/activitityDetail"
      element={<ActivityDetail />}
    />
    <Route
      path="program/:programId/learner-list/:id/lecture-list/:lectureDetail/:otj_id/activitityDetail/:activityId/:memberSectionId"
      element={<ActivityDetail />}
    />

    <Route
      path="program/:programId/tutor-list"
      exact
      element={<TutorProgramList />}
    />
    <Route
      path="program/:programId/:backpage/:id"
      exact
      element={<LectureList />}
    />
  </>
);
