import React, { forwardRef, useEffect, useState } from 'react';
import { Form, OverlayTrigger } from 'react-myoneui';
import Dropdown from 'react-myoneui/lib/Dropdown';
import Button from 'react-myoneui/lib/Button';
import SubSectionRow from './SubSectionRow';
import AddDropdownList from '../addDropdown';
import { editSectionWeightage, reOrderSection, reOrderSectionBankSection } from 'api';
import { useNavigate, useParams } from 'react-router-dom';
import QuickEditDropdown from './QuickEditDropdown';
import ComponentRow from './ComponentRow';
import { useSelector, useDispatch } from 'react-redux';
import { translate, immutableMove, rearrange } from 'lib';
import DragItem from 'components/dragdrop/DragItem';
import { setToastBoxStatus, setToastBoxDescription, setStatusList } from '../../../../redux';
import {
  blackArrowDownIcon,
  folderIcon,
  collapseSectionIcon,
  assignmentDarkIcon,
  builderPencilIcon,
  coursePlayIcon,
  formBuilderIcon,
  offthejobIcon,
  resourceIcon,
  explainNoteIcon,
  reviewIcon,
  quizIcon,
} from 'constants/icons';
import Log from 'lib/Log';
import ls from 'localstorage-slim';
import Popover from 'react-myoneui/lib/Popover';
import { MessageBox } from 'components/MessageBox';
import { capitalizeWord } from 'lib/common';

ls.config.encrypt = true;
const SectionRow = (props) => {
  const { orgId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const organisationId = orgId || ls.get('orgId');
  const statusList = useSelector((state) => state.program.statusList);
  const [sections, setSections] = useState([]);
  const showChild = props?.isFromSectionBank ? true : statusList.includes(props.id);
  const [messageBoxStatus, setMessageBoxStatus] = useState(false);
  const {
    isFromMainSectionBank,
    isFromSectionBank,
    data,
    headding,
    subHeadding,
    title,
    sub_title,
    description,
    weightage,
    id,
    sectionType,
    isPublished,
    onAddSectionStatus,
    childsections,
    itemindex,
    mainweightage,
    onEditSection,
    onEditSectionBank,
    onDeleteSection,
    weightageStatus,
    onWeightage,
    onHasComponentStatus,
    activeParentID,
    onActiveParentID,
    onReOrder,
    parentId,
    isValidRole,
    onViewSection,
    name,
    isMandatory,
    programInfo,
  } = props;
  const { is_from_section_bank } = { ...data };
  const [ownWeight, setOwnWeight] = useState(weightage);
  const [secondLevelWeightageStatus, setSecondLevelWeightageStatus] = useState(false);
  const taxonomy = useSelector((state) => state?.taxonomy?.data);
  const [selectedSubSection, setSelectedSubSection] = useState(null);

  const iconMap = {
    CourseComponent: coursePlayIcon,
    UnitComponent: assignmentDarkIcon,
    ResourceComponent: resourceIcon,
    OTJComponent: offthejobIcon,
    FormComponent: formBuilderIcon,
    FormBuilder: builderPencilIcon,
    ReviewComponent: reviewIcon,
    QuizComponent: quizIcon,
  };

  const computeIcon = (sectionType) => {
    return iconMap[sectionType];
  };

  const taxonomyMatch = {
    CourseComponent: 'course',
    UnitComponent: 'assignment',
    ResourceComponent: 'resources',
    OTJComponent: 'otj',
    FormComponent: 'forms',
    ReviewComponent: 'review',
    QuizComponent: 'quizBank',
  };

  useEffect(() => {
    if (isFromSectionBank) {
      // setShowChild(true);
      sectionHandler();
    }
  }, [isFromSectionBank]);

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = forwardRef(({ children, onClick }, ref) => (
    <Button
      role="button"
      tabIndex={0}
      ref={ref}
      variant="leading-icon"
      className="leading-icon"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Button>
  ));

  const displayComponentCount = (component_count) => {
    try {
      let zero = component_count < 10 && component_count > 0 ? '0' : '';
      let s = component_count > 1 ? 's' : '';
      return zero + component_count + ` ${translate(taxonomy)('component')}` + s;
    } catch (error) {
      Log.error(error);
    }
  };
  const weightageHandler = (e) => {
    try {
      if (e.target.value <= 100 && isValidRole) {
        setOwnWeight(e.target.value);

        editSectionWeightage({
          id: id,
          weightage: e.target.value,
          orgId: organisationId,
        })
          .then((result) => {
            Log.info(result.data.data.weightage);
            onWeightage(itemindex, e.target.value);
          })
          .catch((error) => {
            Log.error(error);
            setOwnWeight(weightage);
          });
      }
    } catch (error) {
      Log.error(error);
    }
  };
  const calculateWeightage = (data) => {
    return data.reduce((a, b) => parseInt(a) + parseInt(b), 0) === 100;
  };
  const subSectionWeightageHandler = (id, data) => {
    try {
      if (isValidRole) {
        let cloneSections = [...sections];
        let obj = cloneSections.find((item) => item.id === id);
        let index = cloneSections.indexOf(obj);
        obj.weightage = data;
        cloneSections[index] = obj;
        setSections(cloneSections);
        let weightages = cloneSections.map((item) => (item.weightage ? parseInt(item.weightage) : 0));
        setSecondLevelWeightageStatus(calculateWeightage(weightages));
      }
    } catch (error) {
      Log.error(error);
    }
  };

  const sectionTypeHandler = (sectionType) => {
    return translate(taxonomy)(taxonomyMatch[sectionType]);
  };

  const onOffClass = () => {
    try {
      if (activeParentID) {
        if (activeParentID === '100') {
          return 'on';
        } else {
          return 'off';
        }
      } else {
        return '';
      }
    } catch (error) {
      Log.info(error);
    }
  };

  const deleteSectionHandler = async (id) => {
    try {
      onDeleteSection(id);
    } catch (error) {
      Log.error(error);
    }
  };

  const sectionHandler = () => {
    let status = !showChild;
    let cloneStatusList = [...statusList];
    if (status) {
      cloneStatusList.push(id);
    } else {
      cloneStatusList = cloneStatusList.filter((item) => item !== id);
    }
    dispatch(setStatusList(cloneStatusList));
    // setShowChild((toggle) => !toggle);
  };

  const reOrder = async (obj) => {
    try {
      Log.info('reOrder');
      let payload = {
        destination_index: sections[parseInt(obj.end)].index,
        section_id: sections[parseInt(obj.start)].id,
      };
      let result = !isFromSectionBank ? await reOrderSection(payload) : await reOrderSectionBankSection(payload);
      if (result) {
        let startIndex = parseInt(obj.start);
        let endIndex = parseInt(obj.end);
        let startObjIndex = sections[startIndex].index;
        let endObjIndex = sections[endIndex].index;
        dispatch(setToastBoxStatus(true));
        dispatch(setToastBoxDescription(result.data.message));
        let sectionList = immutableMove([...sections], startIndex, endIndex);
        setSections(rearrange([...sectionList], startObjIndex, endObjIndex));
      }
    } catch (error) {
      Log.error(error);
    }
  };
  const showComponentName = (sectionType) => {
    let componentName = '';

    if (taxonomy && sectionType) {
      // eslint-disable-next-line default-case
      switch (sectionType) {
        case 'CourseComponent':
          componentName = translate(taxonomy)('course');
          break;
        case 'UnitComponent':
          componentName = translate(taxonomy)('assignment');
          break;
        case 'ResourceComponent':
          componentName = translate(taxonomy)('resources');
          break;
        case 'FormComponent':
          componentName = translate(taxonomy)('forms');
          break;
        case 'OTJComponent':
          componentName = translate(taxonomy)('otj');
          break;
        case 'ReviewComponent':
          componentName = translate(taxonomy)('review');
          break;
        case 'QuizComponent':
          componentName = translate(taxonomy)('quizBank');
          break;
        default:
          componentName = sectionType;
          break;
      }
    } else {
      componentName = translate(taxonomy)('section');
    }
    return componentName;
  };
  useEffect(() => {
    setSections(childsections);
  }, []);

  useEffect(() => {
    Log.info(sectionType);
    if (sectionType !== null) {
      onHasComponentStatus(true);
    }
  }, [sectionType]);
  const editSectionHandler = () => {
    try {
      if (!is_from_section_bank) {
        onEditSection({ ...data });
      } else {
        onEditSectionBank({ ...data });
        // alert('Please update from unit bank');
      }
    } catch (err) {
      Log.error(err);
    }
  };

  /* Check for 2nd Level weightage and check for 100% completed */
  useEffect(() => {
    if (childsections?.length > 0) {
      let weightageList = props.childsections.map((item) => item.weightage);
      setSecondLevelWeightageStatus(calculateWeightage(weightageList));
    }
  }, [childsections]);

  const popover = (
    <Popover className="useful-note-tooltip popover-block">
      <Popover.Header>
        <div className="custom-tooltip-icon">{explainNoteIcon}</div>
        <span className="useful-note-title">Useful Note</span>
      </Popover.Header>
      <Popover.Body>
        <p>{showComponentName(sectionType)}</p>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      {!isFromSectionBank && (
        <>
          <MessageBox
            title={`Please update from ${translate(taxonomy)('section')} bank`}
            show={messageBoxStatus}
            onClose={() => setMessageBoxStatus(false)}
          />
          <DragItem
            parentid={parentId}
            key={id}
            id={id}
            data={data}
            index={itemindex}
            isDraggable={isValidRole && !isFromMainSectionBank ? true : false}
            onChange={onReOrder}
          >
            <div
              className={`section-grid section-grid-row-header section-grid-border-line ${activeParentID === id ? 'on' : ''}`}
              onClick={() => onActiveParentID(id)}
            >
              <div className="section-grid-left">
                {/* <Form.Check>
            <Form.Check.Input className="form-check-input" type="checkbox" id="checkbox2" />
            <Form.Check.Label className="form-check-label" htmlFor="checkbox2"></Form.Check.Label>
          </Form.Check> */}
                <div className="section-grid-info pe-3">
                  <div className="grid-file-icon">
                    <OverlayTrigger
                      placement="auto"
                      overlay={popover}
                    >
                      {sectionType ? computeIcon(sectionType) : folderIcon}
                    </OverlayTrigger>
                  </div>
                </div>
                <div className="section-grid-size">
                  {/* <div className="section-grid-headding">{headding}</div> */}
                  <div className="grid-text-overflow">{title}</div>
                  <div className="section-grid-sub-headding">
                    {sub_title
                      ? sectionType
                        ? sub_title
                        : subHeadding?.split(' ')[0] + ' ' + sub_title
                      : sectionType !== 'CourseComponent'
                      ? subHeadding.split(' ')[0] > 0
                        ? sectionType
                          ? subHeadding.split(' ')[0] + ' ' + sectionTypeHandler(sectionType)
                          : subHeadding
                        : sectionType
                        ? sectionTypeHandler(sectionType)
                        : subHeadding
                      : subHeadding}
                    {!sectionType && name === 'program-option' && programInfo?.enable_mandatory_option && (
                      <>
                        <Button
                          variant="secondary"
                          className={`btn-round ${isMandatory}-btn`}
                        >
                          {capitalizeWord(translate(taxonomy)(isMandatory))}
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="section-grid-right">
                <div className="add-weightage-holder">
                  {mainweightage && (
                    <div className="total-weightage-wrap weightage-space-67">
                      <span className={`weightage-text ${weightageStatus ? '' : 'text-danger'}`}>{translate(taxonomy)('weightage')}</span>
                      <div className={`weightage-percentage ${!weightageStatus ? 'weightage-percentage-danger' : ''}`}>
                        <Form.Control
                          type="number"
                          readOnly={!isValidRole}
                          placeholder=""
                          value={ownWeight ? ownWeight : 0}
                          onChange={weightageHandler}
                        />
                        <span>%</span>
                      </div>
                    </div>
                  )}

                  {isValidRole && sectionType === null && !isFromMainSectionBank ? (
                    <div className="action-col">
                      <AddDropdownList
                        id={id}
                        onAddSectionStatus={(data) => onAddSectionStatus(data)}
                        sectionType="subSection"
                      >
                        <Dropdown.Toggle
                          as={CustomToggle}
                          id="dropdown-custom-components"
                        >
                          Add
                          {blackArrowDownIcon}
                        </Dropdown.Toggle>
                      </AddDropdownList>
                    </div>
                  ) : (
                    <div className="action-col"></div>
                  )}

                  {isValidRole && (
                    <QuickEditDropdown>
                      {isFromMainSectionBank && (
                        <Dropdown.Item
                          eventKey="0"
                          onClick={() => {
                            navigate(`section/${data.id}/view`);
                          }}
                        >
                          View
                        </Dropdown.Item>
                      )}
                      <Dropdown.Item
                        eventKey="1"
                        onClick={editSectionHandler}
                      >
                        Quick Edit
                      </Dropdown.Item>

                      <Dropdown.Item
                        eventKey="2"
                        onClick={() => deleteSectionHandler(data.id)}
                      >
                        Delete
                      </Dropdown.Item>
                    </QuickEditDropdown>
                  )}

                  {!isValidRole && (
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={() => onViewSection({ ...data })}
                    >
                      View
                    </Button>
                  )}

                  {!isFromMainSectionBank && (
                    <span
                      className={`collapse-section ${statusList.includes(id) ? 'active' : ''}`}
                      onClick={sectionHandler}
                    >
                      {sections?.length > 0 && collapseSectionIcon}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </DragItem>
        </>
      )}
      <div style={{ display: showChild ? 'block' : 'none' }}>
        {sections &&
          sections.length > 0 &&
          sections.map((item, index) =>
            item?.parent_section === id && item?.component !== null ? (
              <ComponentRow
                isFromSectionBank={isFromSectionBank}
                show={showChild}
                objIndex={item?.index}
                sectionId={id}
                parentId={id}
                grandParent={id}
                weightageStatus={secondLevelWeightageStatus}
                mainweightage={mainweightage}
                key={item.id}
                weightage={item?.weightage}
                mandatory={item.is_mandatory}
                id={item?.id}
                title={item?.title}
                itemindex={index}
                description={item?.description}
                section_type={item?.section_type}
                value="1"
                level="2"
                asterisk="*"
                onWeightage={subSectionWeightageHandler}
                is_published={item.is_published}
                onDeleteComponent={deleteSectionHandler}
                onHasComponentStatus={onHasComponentStatus}
                activeParentID={activeParentID}
                onActiveParentID={(id) => onActiveParentID(id)}
                isValidRole={isValidRole}
                onReOrder={reOrder}
                data={item}
                programInfo={programInfo}
                selectedSection={selectedSubSection}
                onClickSection={(id) => setSelectedSubSection(id)}
              />
            ) : (
              <SubSectionRow
                isFromSectionBank={isFromSectionBank}
                objIndex={item?.index}
                parentId={id}
                key={item.id}
                show={showChild}
                itemindex={index}
                data={item}
                mainweightage={mainweightage}
                totalComponent={item.component_count}
                id={item.id}
                onAddSectionStatus={(data) => onAddSectionStatus(data, id)}
                component={item.component}
                childSection={item.child_sections}
                sectionType={item.section_type}
                className="space-row"
                headding={`${translate(taxonomy)('subSection')} ${itemindex + 1}.${index + 1}`}
                subHeadding={displayComponentCount(item.component_count)}
                mandatory={item.is_mandatory}
                title={item.title}
                sub_title={item?.sub_title}
                weightage={item.weightage}
                secondLevelWeightageStatus={secondLevelWeightageStatus}
                onEditSection={(data) => onEditSection(data)}
                onDeleteSection={deleteSectionHandler}
                onWeightage={subSectionWeightageHandler}
                onHasComponentStatus={onHasComponentStatus}
                value="1"
                activeParentID={activeParentID}
                onActiveParentID={(id) => onActiveParentID(id)}
                onReOrder={reOrder}
                isValidRole={isValidRole}
                onViewSection={(data) => onViewSection({ ...data })}
                programInfo={programInfo}
                selectedSection={selectedSubSection}
                onClickSection={(id) => setSelectedSubSection(id)}
              />
            ),
          )}
      </div>
    </>
  );
};

export default SectionRow;
