import React from 'react';
import { SortableElement, SortableHandle } from '../drag-and-drop';

const SortableItem = ({ children, columnId, className }, ref) => (
  <div
    ref={ref}
    className={className}
    data-column-id={columnId}
  >
    {children}
  </div>
);

const SortableElementItem = SortableElement(React.forwardRef(SortableItem));

const DragHandleContainer = ({ children }, ref) => <span ref={ref}>{children}</span>;

const SortableDragHandle = SortableHandle(React.forwardRef(DragHandleContainer));

const HeaderCellContainer = ({ index, column, tableManager }) => {
  let {
    config: {
      isHeaderSticky,
      components: { DragHandle },
      additionalProps: { headerCellContainer: additionalProps = {} },
      icons: { sortAscending: sortAscendingIcon, sortDescending: sortDescendingIcon },
    },
    sortApi: { sort, toggleSort },
    columnsApi: { visibleColumns },
    config: { enableColumnsReorder },
    columnsResizeApi: { useResizeRef },
    rowSelectionApi: { selectAll: selectionProps },
  } = tableManager;

  let resizeHandleRef = useResizeRef(column);

  const getClassNames = () => {
    let classNames;

    switch (column.id) {
      case 'virtual':
        classNames = `rgt-cell-header rgt-cell-header-virtual-col${isHeaderSticky ? ' rgt-cell-header-sticky' : ''}`.trim();
        break;
      default:
        classNames = `rgt-border-line rgt-cell-header rgt-cell-header-${column.id === 'checkbox' ? 'checkbox' : column.field}${
          column.sortable !== false && column.id !== 'checkbox' && column.id !== 'virtual' ? ' rgt-clickable' : ''
        }${column.sortable !== false && column.id !== 'checkbox' ? ' rgt-cell-header-sortable' : ' rgt-cell-header-not-sortable'}${
          isHeaderSticky ? ' rgt-cell-header-sticky' : ' rgt-cell-header-not-sticky'
        }${column.resizable !== false ? ' rgt-cell-header-resizable' : ' rgt-cell-header-not-resizable'}${
          column.searchable !== false && column.id !== 'checkbox' ? ' rgt-cell-header-searchable' : ' rgt-cell-header-not-searchable'
        }${isPinnedLeft ? ' rgt-cell-header-pinned rgt-cell-header-pinned-left' : ''}${isPinnedLeftSecondCol ? ' rgt-cell-header-pinned rgt-cell-header-pinned-left-2nd' : ''}${
          isPinnedRight ? ' rgt-cell-header-pinned rgt-cell-header-pinned-right' : ''
        } ${column.className}`.trim();
    }

    return (classNames.trim() + ' ' + (additionalProps.className || '')).trim();
  };

  const getAdditionalProps = () => {
    let mergedProps = {
      ...additionalProps,
    };
    if (column.sortable) {
      let onClick = additionalProps.onClick;
      mergedProps.onClick = (e) => {
        toggleSort(column.id);
        onClick?.(e);
      };
    }

    return mergedProps;
  };

  let isPinnedRight = column.pinned && index === visibleColumns.length - 1;
  let isPinnedLeft = column.pinned && index === 0;
  let isPinnedLeftSecondCol = column.pinned && index === 1;
  let classNames = getClassNames();
  let innerCellClassNames = `rgt-cell-header-inner${column.id === 'checkbox' ? ' rgt-cell-header-inner-checkbox' : ''}${!isPinnedRight ? ' rgt-cell-header-inner-not-pinned-right' : ''}`;
  additionalProps = getAdditionalProps();

  let headerCellProps = { tableManager, column };

  // const [optionsToggle, setOptionsToggle] = useState(false);
  // const onOptionsClick = (e) => {
  //   e.stopPropagation();
  //   setOptionsToggle((options) => !options);
  // };

  // const onPinLeftChange = (column, e) => {
  //   let columns = [...tableManager.columnsApi.columns];
  //   for (let col of columns) {
  //     if (col.id !== 'checkbox') {
  //       col.pinned = false;
  //     }
  //     if (col.id === column.id) {
  //       if (e.target.checked) {
  //         col.pinned = true;
  //       }
  //     }
  //   }
  //   columns.sort((a, b) => {
  //     if (a.id === 'checkbox') {
  //       return 0;
  //     }
  //     if (a.pinned < b.pinned) {
  //       return 1;
  //     }
  //     if (a.pinned > b.pinned) {
  //       return -1;
  //     }
  //     return 0;
  //   });
  //   tableManager.columnsApi.setColumns(columns);
  //   setOptionsToggle((options) => !options);
  // };

  // const onPinRightChange = (column, e) => {
  //   let columns = [...tableManager.columnsApi.columns];
  //   for (let col of columns) {
  //     if (col.id !== 'checkbox') {
  //       col.pinned = false;
  //     }
  //     if (col.id === column.id) {
  //       if (e.target.checked) {
  //         col.pinned = true;
  //       }
  //     }
  //   }
  //   columns.sort((a, b) => {
  //     if (a.id === 'checkbox') {
  //       return 0;
  //     }
  //     if (a.pinned < b.pinned) {
  //       return -1;
  //     }
  //     if (a.pinned > b.pinned) {
  //       return 1;
  //     }
  //     return 0;
  //   });
  //   tableManager.columnsApi.setColumns(columns);
  //   setOptionsToggle((options) => !options);
  // };

  return (
    <div
      data-column-id={column.id.toString()}
      {...additionalProps}
      className={classNames}
    >
      {column.id === 'virtual' ? null : (
        <React.Fragment>
          <SortableElementItem
            className={innerCellClassNames}
            index={index}
            disabled={!enableColumnsReorder || isPinnedLeft || isPinnedLeftSecondCol || isPinnedRight}
            columnId={column.id.toString()}
            collection={isPinnedLeft || isPinnedLeftSecondCol || isPinnedRight ? 0 : 1}
          >
            {DragHandle && !isPinnedLeft && !isPinnedLeftSecondCol && !isPinnedRight ? <SortableDragHandle index={index}>{<DragHandle />}</SortableDragHandle> : null}
            {column.id === 'checkbox'
              ? column.headerCellRenderer({
                  ...headerCellProps,
                  ...selectionProps,
                })
              : column.headerCellRenderer(headerCellProps)}
            {sort.colId !== column.id ? null : sort.isAsc ? (
              <span className="rgt-sort-icon rgt-sort-icon-ascending">{sortAscendingIcon}</span>
            ) : (
              <span className="rgt-sort-icon rgt-sort-icon-descending">{sortDescendingIcon}</span>
            )}
          </SortableElementItem>
          {column.resizable ? (
            <span
              ref={resizeHandleRef}
              className="rgt-resize-handle"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
            ></span>
          ) : null}
        </React.Fragment>
      )}
      {/*column.id !== 'checkbox' && column.id !== 'virtual' && (
        <>
          <span className="rgt-cell-header-popup-handle" onClick={onOptionsClick}>
            &#9776;
          </span>
          <div onClick={(e) => e.stopPropagation()} className="rgt-cell-header-popup" style={{ display: optionsToggle ? 'block' : 'none' }}>
            <div>
              <label>
                <input type="checkbox" checked={!isPinnedRight && column.pinned ? true : false} onChange={onPinLeftChange.bind(null, column)} /> Pin Left
              </label>
            </div>
            <div>
              <label>
                <input type="checkbox" checked={isPinnedRight ? true : false} onChange={onPinRightChange.bind(null, column)} /> Pin Right
              </label>
            </div>
          </div>
        </>
      )*/}
    </div>
  );
};

export default HeaderCellContainer;
