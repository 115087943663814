import React from 'react';
import { Badge, ProgressWrap, ProgressValue } from 'react-myoneui';
import ProgressBar from 'react-myoneui/lib/ProgressBar';
import AssignedProgramDropdown from '../AssignedProgramDropdown';
import { fixInteger } from '../../../../../lib/common';

const CourseActionTemplate = ({ value, data, tableManager }) => {
  const {
    config: {
      additionalProps: { cell: additionalProps = {} },
    },
  } = tableManager;

  const classNames = ('rgt-cell-inner rgt-text-truncate justify-content-end assign-program-drop-progress' + (additionalProps.className || '')).trim();

  const progressPercent = data.progress_calculated_on == 'completion' ? data.completed_progress : data.submitted_progress;

  return (
    <div
      {...additionalProps}
      className={classNames}
    >
      <AssignedProgramDropdown memberProgram={data} />
    </div>
  );
};

export default CourseActionTemplate;
