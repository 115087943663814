import axios from 'axios';
import ls from 'localstorage-slim';

// enable global encryption
ls.config.encrypt = true;
// Authorization: `Bearer ${ls.get('token') || payload?.token}`,
axios.interceptors.request.use(
  (config) => {
    config.headers['activerole'] = ls.get('activeRole') ? JSON.parse(ls.get('activeRole')).id : '';

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
export { login } from './users';
export {
  orgList,
  apprenticeshipClientList,
  createApprenticeshipClient,
  getOrgRoles,
  restoreOrgRoles,
  getOrgTaxonomy,
  getOrgTaxonomySuperAdmin,
  updateOrgSetting,
  getOrgSetting,
  getSubDomainInfo,
  updateTaxonomy,
  updateTaxonomySuperAdmin,
  updateAdminRoles,
  updateSuperAdminRoles,
  getUserDetail,
} from './organization';
export { changePassword } from './changePassword';
export { rolesPrivilegeLanguage, organisationSettingLanguage, loginLanguage, mainSideBarLanguage, addSectionLanguage } from './lang';
export {
  createProgram,
  getProgramList,
  createSection,
  editSection,
  getSectionList,
  getSectionById,
  editProgram,
  getProgramDetail,
  editSectionWeightage,
  publishProgram,
  assignProgramTo,
  getTaxonomy,
  getCoursestasklist,
  getMemberSection,
  getSectionChilds,
  createFeedback,
  getAllFeedBacks,
  getCategories,
  reOrderSection,
  getSectionBankList,
  createSectionBank,
  getTopTwoSections,
} from './program';
export { fileUploader } from './fileUploader';
export { createCourseComponent, getCoursesList, getComponentList, updateCourseComponent, findCategoryByCourseId } from './component/course';
export { createProgramResourceComponent, updateProgramResourceComponent } from './component/resource';
export { addUser, editUser, deleteUsers, listUsers, getUser, deleteApprentices } from './members';
export { addEvidences, saveObservation, deleteEvidences, deleteMemberActivitiesEvidences, updateLastAssedAt } from './learner/section';
export { getLearnerPrograms, viewProgramDetails, updateProgramScore, getTokenForDMS, viewMemberProgram, getFirstColSection, getChildSections, getSectionTotalComponents } from './learner/program';
export { createProfileCustomField, getProfileCustomField, createEnrollmentCustomField, getEnrollmentCustomField, editCustomFields, editProgramCustomFields } from './customField';
export { getRoles } from './roles';
export { createProgramOTJComponent, restoreHour, updateProgramOTJComponent, getActivities, createNewActivity, fetchMemberActivities, addHours, approveMemberActivity, deleteHour } from './offTheJob';
export { getAssessorPrograms, listAssessors, assignAssessorsProgram, unAassignAssessorsProgram, removeAssessor } from './assessor';
export { getAssessorApprentices, getAssessorProgramApprentices, getAssessorLearnerPrograms } from './assessor/learner';
export {
  listSections,
  getSectionBankSectionById,
  getSectionBundleById,
  createUnitBankSection,
  deleteSection,
  editUnitBankSection,
  createUnitBankCourseComponent,
  createUnitBankUnitComponent,
  createUnitBankResourceComponent,
  createUnitBankOTJComponent,
  updateUnitBankCourseComponent,
  updateUnitBankResourceComponent,
  updateUnitBankUnitComponent,
  updateUnitBankOTJComponent,
  removeSectionBankSection,
  reOrderSectionBankSection,
  getSectionBankTopTwoSections,
} from './unitBank';

export {
  listForms,
  getFormComponent,
  getFormComponentDetails,
  createFormComponent,
  removeFormComponent,
  editFormComponent,
  editFormSection,
  editFormSubSection,
  editFormField,
  removeFormField,
  createFormSection,
  createFormSubSection,
  createFormField,
} from './formBuilder';
export { getIQVs, removeIQVs, getIQVAssignAssessors, getNonIQVAssessors, assignIQVAssessors, unAssignIQVAssessors, getIQVLearnerPrograms, getIQVProgramLearner } from './iqv';
export { getEQVAssignIqvs, getNotAssignedEQVIqvs, assignEQVIqvs, unAssignEQVIqvs, getUserEQVIQVAssessors } from './eqv';
