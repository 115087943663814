import React, { useEffect, useState } from 'react';
import Pagination from 'react-myoneui/lib/Pagination';
import RowPerPageDropdown from './RowPerPageDropdown';
import { Form, PaginationWrapper, PageCount } from 'react-myoneui';

const PaginationTemplate = ({ tableManager }) => {
  const {
    config: { pageSizes },
    paginationApi: { totalPages, page, setPage, pageSize, setPageSize, pageRows },
  } = tableManager;

  const [newPage, setNewPage] = useState();

  const handlePageChange = (page) => {
    if (page <= totalPages) {
      setNewPage(page);
    }
  };

  useEffect(() => {
    const currentTimeOut = setTimeout(() => {
      setPage(newPage);
    }, 1000);
    return () => clearTimeout(currentTimeOut);
  }, [newPage]);

  let backButtonDisabled = page - 1 < 1;
  let nextButtonDisabled = page + 1 > totalPages;

  return (
    <div className="page-footer">
      <div className="row-page-wrap">
        <span>Rows per page</span>

        <RowPerPageDropdown
          items={pageSizes}
          value={pageSize}
          onChange={setPageSize}
        />
      </div>
      <PaginationWrapper>
        <Pagination>
          <Pagination.Prev
            disabled={backButtonDisabled}
            onClick={() => setPage(page - 1)}
          />
          <Pagination.Item active>
            {/* <Form.Control type="text" className="page-link" onClick={(event) => event.target.select()} value={totalPages ? page : 0} onChange={(event) => setPage(event.target.value * 1)} /> */}
            <Form.Control
              type="text"
              className="page-link"
              onClick={(event) => event.target.select()}
              value={newPage ?? page}
              onChange={(event) => handlePageChange(event.target.value)}
            />
          </Pagination.Item>
          <Pagination.Next
            disabled={nextButtonDisabled}
            onClick={() => setPage(page + 1)}
          />
        </Pagination>
        <PageCount pageTotal={totalPages} />
      </PaginationWrapper>
    </div>
  );
};

export default PaginationTemplate;
