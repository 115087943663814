import * as React from 'react';
import Offcanvas from 'react-myoneui/lib/Offcanvas';
import Card from 'react-myoneui/lib/Card';
import Button from 'react-myoneui/lib/Button';
import Form from 'react-myoneui/lib/Form';
import FloatingLabel from 'react-myoneui/lib/FloatingLabel';
import DropdownCollection from '../../../components/DropdownCollection';
import { clearFormIcon, removeIcon, addIcon } from '../../../constants/icons';
import { useState, useEffect } from 'react';
import { memberProgramReview } from 'api/review';
import DateTimePicker from 'react-datetime-picker';
import { formatToUTCDate } from '../../../utils/formatTime';

import { useSelector, useDispatch } from 'react-redux';
import { translate } from '../../../lib';

import Spinner from 'react-myoneui/lib/Spinner';
import { useParams } from 'react-router-dom';
import ls from 'localstorage-slim';
import { decryptData } from '../../../lib';
import Log from '../../../lib/Log';
import AddResourseInfoBlock from 'modules/admin/program/library/components/Resources/AddResourseInfoBlock';
import { setConfirmBoxStatus } from 'redux/confirmBox';
import UploadFileOffCanvas from 'modules/admin/program/library/components/Resources/UploadFileOffCanvas';
import { ConfirmBox } from 'components/ConfirmBox';
import TextEditor from 'components/TextEditor';
import { setToastBoxStatus, setToastBoxDescription } from 'redux/ToastBoxSlice';

ls.config.encrypt = true;
function AddNewReviewOffCanvas({ member_component, member_program, onClose, show, onSave, updateStatus, isValidRole, member_section_id, onUpdateSectionOnly }) {
  const selectedComponent = useSelector((state) => state.sections.active_component);
  const handleClose = (clear) => {
    try {
      setLoad(false);
      setBasicInfo({
        title: '',
        description: '',
      });
      setUploadedFiles([]);
      if (clear !== true) {
        onClose();
      }
    } catch (error) {
      Log.error(error);
    }
  };
  const organisationId = ls.get('orgId');

  const offCanvasKeyDown = (e) => e.stopPropagation();
  const [activeClass, setActiveClass] = useState('');
  const [date, setDate] = useState();
  const [location, setLocation] = useState('');
  const taxonomy = useSelector((state) => state.taxonomy.data);
  const lang = useSelector((state) => state?.lang?.data);

  const memberProgram = useSelector((state) => state.memberProgram);
  const { id } = useParams();
  const member = isValidRole ? decryptData(id) : ls.get('userid');
  const [load, setLoad] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [basicInfo, setBasicInfo] = useState({
    title: '',
    description: '',
  });

  const [addTitle, setAddTitle] = useState(false);
  const [title, setTitle] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileshow, setFileshow] = useState(false);
  const [validResourceLink, setValidResourceLink] = useState('');
  const [resourceToRemove, setResourceToRemove] = useState(null);
  const [fileToRemove, setFileToRemove] = useState(null);
  const dispatch = useDispatch();
  const [confirmBoxStatus, setConfirmBoxStatus] = useState(false);
  const [resource, setResource] = useState({ title: '', link: '', isValid: true });

  const [resourceInputField, setResourceInputField] = useState([]);

  const handleShow = () => setFileshow(true);
  const [addLoading, setAddLoading] = useState(false);

  // const createActivity = async () => {
  //   try {
  //     setLoad(true);
  //     const payload = { member_section_id, member, otj: otj_id, date: new Date(formatToUTCDate(date)), member_program: memberProgram._id, location, title, activity: selectedActivity.id };
  //     const res = await createNewActivity(payload);

  //     if (res) {
  //       setLoad(false);
  //       if (res?.data?.data?.memberSection && Object.entries(res.data.data.memberSection).length > 0) {
  //         updateStatus(res.data.data.memberSection);
  //       }
  //       if (res?.data?.data?.memberActivity?.activity) {
  //         const activity = activities.filter((activity) => activity.id === res.data.data.memberActivity.activity)[0];
  //         onSave({ ...res.data.data.memberActivity, activity: activity.name });
  //       } else {
  //         onSave(res.data.data.memberActivity);
  //       }
  //       handleClose();

  //       if (Object.entries(res.data.data.memberProgram).length > 0) {
  //         updateStatus(res.data.data.memberProgram);
  //       }
  //     }
  //   } catch (err) {
  //     Log.error('Error >> ', err);
  //   }
  // };

  const creatReview = async () => {
    try {
      const payload = {
        member_program: member_program,
        member_component: member_component,
        member_section_id: member_section_id,
        subject: basicInfo.title,
        description: basicInfo?.description,
        uploaded_file: uploadedFiles,
        orgId: organisationId,
        // programId: id,
      };

      setAddLoading(true);
      setLoad(true);
      const result = await memberProgramReview(payload);
      setAddLoading(false);
      setLoad(false);
      dispatch(setToastBoxStatus(true));
      dispatch(setToastBoxDescription(`${translate(taxonomy)('review')} has beeen created Successfully`));
      handleClose();
      onUpdateSectionOnly({ ...result?.data?.data?.membersection });
    } catch (error) {
      setAddLoading(false);
      console.error(error);
      dispatch(setToastBoxStatus(true));
      dispatch(setToastBoxDescription(error?.data?.message ? error?.data?.message : `Something went wrong while creating review`));
    }
  };

  const removeResource = (index) => {
    try {
      const resources = [...resourceInputField];
      resources.splice(index, 1);
      setResourceInputField(resources);
      setResourceInputField(resources);
      setConfirmBoxStatus(false);
      setResourceToRemove(null);
    } catch (error) {
      Log.error('Error:AddResources/removeResource', error);
    }
  };

  const removeFile = (index) => {
    try {
      const resources = [...uploadedFiles];
      resources.splice(index, 1);
      setUploadedFiles(resources);
      setUploadedFiles(resources);
      setConfirmBoxStatus(false);
      setFileToRemove(null);
    } catch (error) {
      Log.error('Error:AddResources/removeFile', error);
    }
  };
  const saveFiles = (res) => {
    try {
      setUploadedFiles([
        ...uploadedFiles,
        {
          url: res.link,
          title: res.name,
        },
      ]);
      setFileshow(false);
    } catch (error) {
      Log.error('Error:AddResources/saveFiles', error);
    }
  };
  useEffect(() => {
    if (basicInfo.title.length > 0 && basicInfo.description.length > 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [basicInfo.title, basicInfo.description]);

  return (
    <>
      {fileshow && (
        <UploadFileOffCanvas
          show={fileshow}
          onClose={() => setFileshow(false)}
          onSave={saveFiles}
        />
      )}
      {setConfirmBoxStatus && (
        <ConfirmBox
          show={confirmBoxStatus}
          question={`Are you sure you want to remove this ${fileToRemove ? 'File' : 'Resource'}?`}
          okText="Yes"
          cancelText="No"
          onHide={() => {
            setConfirmBoxStatus(false);
          }}
          onOk={() => (fileToRemove ? removeFile(Number(fileToRemove) - 1) : removeResource(Number(resourceToRemove) - 1))}
        />
      )}
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        onKeyDown={offCanvasKeyDown}
      >
        <Offcanvas.Header
          closeButton
          backButton
          className="pb-0"
        >
          <Offcanvas.Title>
            Add <br /> {`New ${translate(taxonomy)('review')}`}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="mb-4">
            <FloatingLabel
              controlId="floatingInputTitle"
              label="Sample Title"
              className="mb-3"
            >
              <Form.Control
                tabIndex="1"
                type="text"
                placeholder="Sample Title"
                value={basicInfo?.title}
                onChange={(e) => setBasicInfo({ ...basicInfo, title: e.target.value })}
              />
            </FloatingLabel>
            <Card className="react-quill-editor">
              <TextEditor
                placeholder="Detailed Description"
                value={basicInfo?.description}
                onChange={(obj) => setBasicInfo({ ...basicInfo, description: obj })}
              />
            </Card>
          </div>
          <span className="card-divider my-4" />
          <h4 className="upload-title-text">Upload {translate(taxonomy)('review')} Documents </h4>
          <div className="information-header mb-4">
            {uploadedFiles.length > 0 && <p>{uploadedFiles.length} files uploaded</p>}
            <Button
              variant="outline"
              className="dark"
              onClick={handleShow}
            >
              Upload File
            </Button>
          </div>
          {uploadedFiles?.length > 0 &&
            uploadedFiles.map((file, index) => {
              return (
                <div key={index}>
                  <AddResourseInfoBlock
                    className="mb-4"
                    type={file.url.substr(file.url.lastIndexOf('.') + 1)}
                    cirleImage
                    name={file.title}
                  >
                    <Button
                      variant="information-white-bg"
                      className="btn information-white-bg btn-sm btn-round"
                      onClick={() => {
                        setResourceToRemove(null);
                        setFileToRemove(index + 1);
                        setConfirmBoxStatus(true);
                      }}
                    >
                      {removeIcon}
                      Remove
                    </Button>
                  </AddResourseInfoBlock>
                </div>
              );
            })}

          {/* <div className="clear-form">
            <Button
              variant="default"
              onClick={() => handleClose(true)}
            >
              {clearFormIcon} Clear Form
            </Button>
          </div> */}
          {/* <FloatingLabel className="mb-4" controlId="activititySupervisor" label="Supervisor">
            <Form.Control type="number" placeholder="Supervisor" />
          </FloatingLabel> */}
        </Offcanvas.Body>
        <Offcanvas.Footer className="gap-1">
          <div className="d-flex flex-wrap justify-content-end gap-3">
            <Button
              variant="default"
              onClick={() => handleClose(false)}
              className="p-0"
            >
              Cancel
            </Button>
            {/* <Button variant="glitter" onClick={() => handleClose(true)}>
              Reset
            </Button> */}
            <Button
              variant="primary"
              onClick={creatReview}
              disabled={load || !isValid}
            >
              {load ? (
                <>
                  <div className="spinner-align-center">
                    <Spinner
                      animation="border"
                      size="sm"
                    />
                    {`Add ${translate(taxonomy)('review')}`}
                  </div>
                </>
              ) : (
                `Add ${translate(taxonomy)('review')}`
              )}
            </Button>
          </div>
        </Offcanvas.Footer>
      </Offcanvas>
    </>
  );
}

export default AddNewReviewOffCanvas;
