import ProfileDropdownItem from './ProfileDropdownItem';
import { envelopeIcon, memberIcon } from '../../../../constants/icons';
import CircleImg from '../../../../components/CircleImg';
import ls from 'localstorage-slim';
import { getUserIconFromRoleIdentifier, decryptData, getUserIconFromRoles } from '../../../../lib';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import Log from 'lib/Log';

// enable global encryption
ls.config.encrypt = true;
function ProfileMember(props) {
  const memberDetails = JSON.parse(ls.get('memberDetails'));
  const { userTab, id } = useParams();

  const { user, idStatus } = props;
  const { profile_image, first_name, last_name, decrypted_email, roles } = user;
  const allRoles = JSON.parse(ls.get('roles'));
  const userRoles = roles?.map((userRole) => allRoles.filter((role) => userRole.identifier === role.identifier)).flat();
  const myrole = JSON.parse(ls.get('activeRole')).identifier;
  const userIdentifier = userTab ? userTab.slice(0, -1) : '';
  const activeRole = JSON.parse(ls.get('activeRole'));

  useEffect(() => {
    Log.info('My Props :', props.user.roles);
  }, []);
  return (
    <div
      className={`member-card ${props?.className || ''}`}
      id="my-profile"
    >
      <div className="member-card-header">
        {/* If id is match with memberDetail id then it is login user */}
        {decryptData(id) === memberDetails.id ? (
          <>
            <CircleImg
              defaultIcon={getUserIconFromRoleIdentifier(userTab ? userIdentifier : activeRole.identifier)}
              src={profile_image}
              title={first_name ? first_name : ''}
            />
          </>
        ) : !idStatus && profile_image ? (
          <>
            <CircleImg
              defaultIcon={userTab ? getUserIconFromRoleIdentifier(userIdentifier) : getUserIconFromRoles(props.user.roles)}
              src={profile_image}
              title={memberDetails?.first_name}
            />
          </>
        ) : (
          <>
            <CircleImg
              defaultIcon={userTab ? getUserIconFromRoleIdentifier(userIdentifier) : getUserIconFromRoles(props.user.roles)}
              src={profile_image}
              title={first_name ? first_name : ''}
            />
          </>
        )}
        {/* <div className="member-button-wrap">
          <ProfileDropdownItem />
        </div> */}
      </div>
      <div className="member-card-footer">
        <div className="member-info">
          <h3>{idStatus ? memberDetails.first_name + ' ' + memberDetails.last_name : first_name + ' ' + last_name}</h3>
          <>
            {envelopeIcon} &nbsp;
            {idStatus ? memberDetails.email : decrypted_email}
          </>
        </div>
        {userRoles && userRoles.length > 0 && (
          <div className="member-card-roles">
            {userRoles.map(({ name, id }) => (
              <span
                className="member-link"
                key={id}
              >
                {memberIcon}
                {name}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default ProfileMember;
