import axios from 'axios';
import { API_URL } from '../../constants/config';
import ls from 'localstorage-slim';

export const getAssessorApprentices = async ({ orgId, ...payload }) => {
  const { assessor, role, ...params } = payload;
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };
  try {
    return await axios.post(`${API_URL}/assessor/list/apprentices`, { assessor, role }, { headers, params });
  } catch (err) {
    throw new Error(err);
  }
};

// body :{member:string, programId:string}
export const getAssessorProgramApprentices = async (payload) => {
  const { member, programId, ...params } = payload;
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };
  try {
    return await axios.post(`${API_URL}/assessor/list/program/apprentices`, { member, programId }, { headers, params });
  } catch (err) {
    throw new Error(err);
  }
};
// body:{memberProgram:string}
export const getMemberProgramAssessors = async (payload) => {
  const { memberProgram, ...params } = payload;
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };
  try {
    return await axios.post(`${API_URL}/assessor/program/list/assessors`, { memberProgram }, { headers, params });
  } catch (err) {
    throw new Error(err);
  }
};

export const getAssessorLearnerPrograms = async (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    },
  };
  try {
    return await axios.post(`${API_URL}/assessor/list/apprentice/programs`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const getAssessorReport = async (payload) => {
  const { roleName, search, startDate, endDate, role, ...params } = payload;
  try {
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };

    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}/${roleName}/reporting/get-assessor-report`, { search, startDate, endDate, role }, { headers, params })
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (err) {
    throw new Error(err);
  }
};

export const exportAssessorReport = async (payload) => {
  const { roleName, search, startDate, endDate, role, clientTimeOffset, ...params } = payload;
  try {
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };

    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}/${roleName}/reporting/export-assessor-report`, { search, startDate, endDate, role, clientTimeOffset }, { headers, params })
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (err) {
    throw new Error(err);
  }
};
