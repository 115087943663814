import { React, useState } from 'react';
import { forwardRef, Children } from 'react';
import Dropdown from 'react-myoneui/lib/Dropdown';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu

const CustomToggle = forwardRef(({ children, onClick }, ref) => (
  <div
    role="button"
    tabIndex={0}
    ref={ref}
    className="btn btn-icon rounded-circle"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it

const CustomMenu = forwardRef(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
  const [value, setValue] = useState('');

  return (
    <div
      ref={ref}
      style={style}
      className={className}
      aria-labelledby={labeledBy}
    >
      <ul className="list-unstyled mb-0">{Children.toArray(children).filter((child) => !value || child.props.children.toLowerCase().startsWith(value))}</ul>
    </div>
  );
});

function QuickEditDropdown(props) {
  const { children } = props;
  return (
    <Dropdown className="dropdown-select dropdown-no-border">
      <Dropdown.Toggle
        as={CustomToggle}
        id="dropdown-custom-components"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-three-dots"
          viewBox="0 0 16 16"
        >
          <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
        </svg>
      </Dropdown.Toggle>
      <Dropdown.Menu
        as={CustomMenu}
        className="dropdown-menu mw-dropdown-default"
      >
        {children}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default QuickEditDropdown;
