import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import Offcanvas from 'react-myoneui/lib/Offcanvas';
import Button from 'react-myoneui/lib/Button';
import DropdownCommon from './DropdownCommon';
import Form from 'react-myoneui/lib/Form';
import FloatingLabel from 'react-myoneui/lib/FloatingLabel';
import DropdownCheckboxMultiSelect from './DropdownCheckboxMultiSelect';
import DateTimePicker from 'react-datetime-picker';
import SnackBar from './SnackBar';
import { Row, Col } from 'react-myoneui';
import { useFetcher, useParams } from 'react-router-dom';
import { translate } from '../lib';
import { getTaxonomy } from '../api';
import { setTaxonomy } from '../redux';
import { LANG } from '../constants/config';
import { getRoles } from '../api/roles';
import { useSelector, useDispatch } from 'react-redux';
import { setUserRole, storeRoles } from '../redux';
import { getCategories, gettopLevelSections, getsecondLevelSections, getProgramList } from '../api/program';
import Log from '../lib/Log';
import ls from 'localstorage-slim';

// Images
import { filterIcon, clearFormIcon } from '../constants/icons';

// enable global encryption
ls.config.encrypt = true;

const chooseMandatoryType = [
  { id: 1, name: 'True' },
  { id: 2, name: 'False' },
];

function ShowFilterOffCanvas(props) {
  const {
    triggerTitle,
    userFilterList,
    contactFilterList,
    apprenticesFilterList,
    unitComponentFilterList,
    applyFilter,
    resetFilter,
    updateResetFilter,
    allcomponentFilterList,
    dataStatus,
    resubmissionFilter,
    quickAccessFilter,
    selectedCategories,
    smartTaegetFilter,
    TaskListFilter,
    formCleared = () => {},
    checkFilterList,
    sectionBankFilterList,
    activeApprenticeFilterList,
    activeProgramFilterList,
    emailFilterList,
  } = props;

  const orgId = ls.get('orgId');
  const organisationId = orgId || ls.get('orgId');
  const lang = useSelector((state) => state?.lang?.data);

  const language = ls.get('lang') || LANG;

  const [show, setShow] = useState(false);
  const [completeStatus, setCompleteStatus] = useState('');
  const [activeClass, setActiveClass] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectedPeriodItemsList, setSelectedPeriodItemsList] = useState([]);
  const [status, setstatus] = useState('');
  const [isMandatoryStatus, setIsMandatoryStatus] = useState('');
  const [mandatoryChoice, setMandatoryChoice] = useState(chooseMandatoryType);
  const [componentTypeChoice, setComponentTypeChoice] = useState(null);
  const [componentType, setComponentType] = useState('');
  const [progressCalculatedOn, setProgressCalculatedOn] = useState('');
  const [role, setRole] = useState([]);
  const [contactRole, setContactRole] = useState([]);
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [loadCategoryField, setLoadCategoryField] = useState(false);
  const [programs, setPrograms] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState({});
  const [loadProgramField, setLoadProgramField] = useState(false);
  const [topLevelSections, setTopLevelSections] = useState([]);
  const [selectedTopLevelSection, setSelectedTopLevelSection] = useState('');
  const [secondLevelSections, setSecondLevelSections] = useState([]);
  const [selectedSecondLevelSection, setSelectedSecondLevelSection] = useState('');
  const [targetName, setTargetName] = useState('');
  const { programId } = useParams();
  const selectRef = useRef(null);

  const setTaxonomyData = async () => {
    try {
      let result = await getTaxonomy({
        orgId: organisationId,
        lang: language,
      });
      dispatch(setTaxonomy(result.data));
    } catch (error) {
      Log.error(error);
    }
  };
  // const handleClose = () => setShow(false);
  const [datafetchStatus, setDatafetchStatus] = useState(false);
  const handleShow = () => {
    setShow(true);
    setDatafetchStatus(true);
  };
  const offCanvasKeyDown = (e) => e.stopPropagation();
  const roles = useSelector((state) => state.roles);

  const taxonomy = useSelector((state) => state.taxonomy.data);

  const [completeStatusChoice, setCompleteStatusChoice] = useState([
    { id: 1, name: 'Not Started' },
    { id: 2, name: 'In Progress' },
    { id: 3, name: 'Submitted' },
    { id: 4, name: 'Completed' },
    { id: 5, name: 'ReSubmission' },
  ]);
  const [progressCalculation, setProgressCalculation] = useState([
    { id: 1, name: 'Completion' },
    { id: 2, name: 'Submission' },
  ]);
  const [chooseStatus, setChooseStatus] = useState([
    { id: 1, name: 'Published' },
    { id: 2, name: 'Draft' },
  ]);

  const handleClose = () => {
    let categoriesClone = [...categories];
    let toplevelClone = [...topLevelSections];
    let secondlevelClone = [...secondLevelSections];
    let componenttypeClone = [...componentTypeChoice];
    let mandatoryClone = [...mandatoryChoice];
    let completStatusClone = [...completeStatusChoice];
    let chooseStatusClone = [...chooseStatus];
    let progressCalculetionClone = [...progressCalculation];
    let programsClone = [...programs];

    setCategories([]);
    setSelectedCategory('');
    setStartDate();
    setEndDate();
    setTopLevelSections([]);
    setSelectedTopLevelSection([]);
    setSecondLevelSections([]);
    setSelectedSecondLevelSection([]);
    setComponentTypeChoice([]);
    setComponentType('');
    setMandatoryChoice([]);
    setIsMandatoryStatus('');
    setCompleteStatus('');
    setCompleteStatusChoice('');
    setstatus('');
    setChooseStatus([]);
    setProgressCalculation([]);
    setProgressCalculatedOn('');
    setPrograms([]);
    setSelectedProgram({});
    setLoadProgramField(true);
    setLoadCategoryField(true);
    formCleared();

    setTimeout(() => {
      setCategories(categoriesClone);
      setTopLevelSections(toplevelClone);
      setSecondLevelSections(secondlevelClone);
      setComponentTypeChoice(componenttypeClone);
      setMandatoryChoice(mandatoryClone);
      setCompleteStatusChoice(completStatusClone);
      setChooseStatus(chooseStatusClone);
      setProgressCalculation(progressCalculetionClone);
      setPrograms(programsClone);
      setLoadProgramField(false);
      setLoadCategoryField(false);
    }, 100);
  };

  const closeOffcanvas = () => {
    setShow(false);
    setDatafetchStatus(false);
    handleClose();
  };

  useEffect(() => {
    if (resetFilter) {
      setStartDate();
      setEndDate();
      setstatus('');
      setCompleteStatus('');
      setComponentType('');
      setIsMandatoryStatus('');
      setProgressCalculatedOn('');
      setTopLevelSections([]);
      setSelectedTopLevelSection([]);
      setSecondLevelSections([]);
      setSelectedSecondLevelSection([]);
      setSelectedPeriodItemsList([]);
      setSelectedCategory([]);
      setCompleteStatus('');
      //applyFilter();
      updateResetFilter();
      setPrograms([]);
      setSelectedProgram({});
    }
  }, [resetFilter]);

  useEffect(() => {
    setTaxonomyData();
  }, []);

  useEffect(() => {
    setComponentTypeChoice([
      { id: 1, name: translate(taxonomy)('course'), staticName: 'CourseComponent' },
      { id: 2, name: translate(taxonomy)('assignment'), staticName: 'UnitComponent' },
      { id: 3, name: translate(taxonomy)('resources'), staticName: 'ResourceComponent' },
      { id: 4, name: translate(taxonomy)('otj'), staticName: 'OTJComponent' },
      { id: 5, name: translate(taxonomy)('review'), staticName: 'ReviewComponent' },
      { id: 6, name: translate(taxonomy)('forms'), staticName: 'FormComponent' },
    ]);
  }, [lang, taxonomy]);

  useEffect(() => {
    if (show && unitComponentFilterList && selectedProgram) {
      setTopLevelSections([]);
      setSelectedTopLevelSection([]);
      setSecondLevelSections([]);
      setSelectedSecondLevelSection([]);
    }
  }, [selectedProgram]);

  const onPeriodItemsChange = (item) => {
    setSelectedPeriodItemsList((selectedPeriodItemsList) => {
      const prevItems = [...selectedPeriodItemsList];
      const index = prevItems.indexOf(item);
      if (index === -1) {
        return [...prevItems, item];
      } else {
        prevItems.splice(index, 1);
        return prevItems;
      }
    });
  };

  useEffect(() => {
    async function fetchPrograms() {
      const programs = (await getProgramList({ perPage: 1000, currentPage: 1 })).data.data.programs.map((program) => {
        return { id: program._id, name: program.name };
      });
      setPrograms(programs);
    }
    if (unitComponentFilterList && show && !programs.length) {
      fetchPrograms();
    }
  }, [unitComponentFilterList, show]);

  useEffect(() => {
    async function fetchRoles() {
      const roles = await getRoles(orgId);
      dispatch(storeRoles(roles.data));
      ls.set('roles', JSON.stringify(roles.data.data));
    }
    if (Object.entries(roles.list).length < 1) fetchRoles();
  }, [userFilterList, contactFilterList, apprenticesFilterList]);

  useEffect(() => {
    const selectedRole = roles.list.filter((role) => selectedPeriodItemsList.includes(role.name)).map((role) => role.id);
    setRole(selectedRole);
  }, [selectedPeriodItemsList]);

  const theRoles = roles.list.filter((rol) => rol.identifier === 'assessor' || rol.identifier === 'tutor');

  async function fetchAllCategories(of) {
    const { data } = await getCategories(of);
    setCategories(data.data);
  }
  async function fetchtopLevelSections() {
    const { data } = await gettopLevelSections({ program: programId ?? selectedProgram.id });
    setTopLevelSections(data.data);
  }

  useEffect(() => {
    if (datafetchStatus) {
      if (unitComponentFilterList || sectionBankFilterList) {
        fetchAllCategories(unitComponentFilterList ? 'component' : 'section');
      }
      if (allcomponentFilterList) {
        fetchAllCategories();
        fetchtopLevelSections();
      }
    }
  }, [datafetchStatus]);

  useEffect(() => {
    if (Object.entries(selectedProgram).length) {
      fetchtopLevelSections();
    }
  }, [selectedProgram]);

  useEffect(() => {
    async function fetchsecondLevelSections() {
      if (selectedTopLevelSection.length) {
        const { data } = await getsecondLevelSections({ sectionId: selectedTopLevelSection[0]?.id });
        setSecondLevelSections(data.data);
      }
    }
    fetchsecondLevelSections();
  }, [selectedTopLevelSection]);
  return (
    <>
      <a
        onClick={handleShow}
        href="#"
        className="filter-icon"
        type="button"
      >
        {filterIcon}
        <span>{triggerTitle}</span>
      </a>
      <Offcanvas
        show={show}
        onHide={closeOffcanvas}
        placement="end"
        onKeyDown={offCanvasKeyDown}
      >
        <Offcanvas.Header
          closeButton
          backButton
        >
          <Offcanvas.Title>
            Apply Filter
            <p className="help-text w-75">Enter the name and description of the favourite report that all users will be able to access</p>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {checkFilterList === 'AdminProgramFilterList' && (
            <>
              {chooseStatus && chooseStatus.length > 0 && (
                <DropdownCommon
                  className="mb-4"
                  name="status"
                  defaultValue={status}
                  items={chooseStatus}
                  label={'Status'}
                  onChange={(data) => setstatus(data)}
                />
              )}
            </>
          )}
          {checkFilterList && (
            <>
              {progressCalculation && progressCalculation.length > 0 && (
                <DropdownCommon
                  className="mb-4"
                  name="progressCalculation"
                  defaultValue={progressCalculatedOn}
                  items={progressCalculation}
                  label={'Progress Calculation'}
                  onChange={(data) => setProgressCalculatedOn(data)}
                />
              )}
            </>
          )}
          {allcomponentFilterList && (
            <>
              {componentTypeChoice && componentTypeChoice.length > 0 && (
                <DropdownCommon
                  className="mb-4"
                  name="componentType"
                  defaultValue={componentType}
                  items={componentTypeChoice}
                  label={`${translate(taxonomy)('component')} Type`}
                  onChange={(data) => setComponentType(data)}
                />
              )}
              {mandatoryChoice && mandatoryChoice.length > 0 && (
                <DropdownCommon
                  className="mb-4"
                  name="Mandatory Type"
                  defaultValue={isMandatoryStatus}
                  items={mandatoryChoice}
                  label={'Mandatory Type'}
                  onChange={(data) => setIsMandatoryStatus(data)}
                />
              )}
              <>
                <Row>
                  <Col lg={6}>
                    <FloatingLabel
                      className="mb-4 target-end-date-label"
                      controlId="targetSartEndDate"
                      label="Created From"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Date"
                      />
                      <DateTimePicker
                        className={`remove-time-wrap ${activeClass}`}
                        clearIcon={null}
                        placeholder="Date"
                        value={startDate}
                        onChange={(value) => setStartDate(value)}
                        onBlur={() => {
                          setActiveClass('');
                        }}
                        onFocus={() => {
                          setActiveClass('target-date-focus');
                        }}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col lg={6}>
                    <FloatingLabel
                      className="mb-4 target-end-date-label"
                      controlId="targetSartEndDate"
                      label="Created To"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Date"
                      />
                      <DateTimePicker
                        className={`remove-time-wrap ${activeClass}`}
                        clearIcon={null}
                        placeholder="Date"
                        value={endDate}
                        onChange={(value) => setEndDate(value)}
                        onBlur={() => {
                          setActiveClass('');
                        }}
                        onFocus={() => {
                          setActiveClass('target-date-focus');
                        }}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
              </>

              {categories && categories.length > 0 && !loadCategoryField && (
                <>
                  <DropdownCommon
                    className="mb-4"
                    name="Categories"
                    defaultValue={selectedCategory}
                    items={categories}
                    label="Category"
                    onChange={(data) => setSelectedCategory([data])}
                  />
                </>
              )}
            </>
          )}
          {unitComponentFilterList && !loadProgramField && (
            <DropdownCommon
              className="mb-4"
              name="Programs"
              defaultValue={selectedProgram}
              items={programs}
              label="Program"
              onChange={(data) => setSelectedProgram(data)}
            />
          )}
          {(allcomponentFilterList || unitComponentFilterList) && (
            <>
              {topLevelSections && topLevelSections.length > 0 && (
                <DropdownCommon
                  className="mb-4"
                  name="selectedTopLevelSection"
                  defaultValue={selectedTopLevelSection[0]}
                  items={topLevelSections}
                  label="Top Level Section"
                  onChange={(data) => {
                    setSelectedTopLevelSection([data]);
                    setSecondLevelSections([]);
                    setSelectedSecondLevelSection([]);
                  }}
                />
              )}
              {selectedTopLevelSection.length > 0 && secondLevelSections && secondLevelSections.length > 0 && (
                <DropdownCommon
                  className="mb-4"
                  name="selectedSecondLevelSection"
                  defaultValue={selectedSecondLevelSection[0]}
                  items={secondLevelSections}
                  label="Second Level Section"
                  onChange={(data) => setSelectedSecondLevelSection([data])}
                />
              )}
            </>
          )}
          {quickAccessFilter && (
            <>
              {completeStatusChoice && completeStatusChoice.length > 0 && (
                <DropdownCommon
                  className="mb-4"
                  name="completeStatus"
                  defaultValue={completeStatus}
                  items={completeStatusChoice}
                  label={'Status'}
                  onChange={(data) => setCompleteStatus(data)}
                />
              )}
            </>
          )}
          {TaskListFilter && (
            <>
              <>
                {completeStatusChoice && completeStatusChoice.length > 0 && (
                  <DropdownCommon
                    className="mb-4"
                    name="completeStatus"
                    defaultValue={completeStatus}
                    items={completeStatusChoice?.filter((choice) => choice.name !== 'Completed')}
                    label={'Status'}
                    onChange={(data) => setCompleteStatus(data)}
                  />
                )}
              </>
              {componentTypeChoice && componentTypeChoice.length > 0 && (
                <DropdownCommon
                  className="mb-4"
                  name="componentType"
                  defaultValue={componentType}
                  items={componentTypeChoice}
                  label={`${translate(taxonomy)('component')} Type`}
                  onChange={(data) => setComponentType(data)}
                />
              )}

              <>
                <Row>
                  <Col lg={6}>
                    <FloatingLabel
                      className="mb-4 target-end-date-label"
                      controlId="targetSartEndDate"
                      label="Deadline Start Date"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Date"
                      />
                      <DateTimePicker
                        className={`remove-time-wrap ${activeClass}`}
                        clearIcon={null}
                        placeholder="Date"
                        value={startDate}
                        onChange={(value) => setStartDate(value)}
                        onBlur={() => {
                          setActiveClass('');
                        }}
                        onFocus={() => {
                          setActiveClass('target-date-focus');
                        }}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col lg={6}>
                    <FloatingLabel
                      className="mb-4 target-end-date-label"
                      controlId="targetSartEndDate"
                      label="Deadline End Date"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Date"
                      />
                      <DateTimePicker
                        className={`remove-time-wrap ${activeClass}`}
                        clearIcon={null}
                        placeholder="Date"
                        value={endDate}
                        onChange={(value) => setEndDate(value)}
                        onBlur={() => {
                          setActiveClass('');
                        }}
                        onFocus={() => {
                          setActiveClass('target-date-focus');
                        }}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
              </>
            </>
          )}
          {smartTaegetFilter && (
            <>
              {/* <FloatingLabel controlId="floatingInputTitle" label="Target Name" className="mb-3">
                <Form.Control type="text" defaultValue={targetName} placeholder="Target Name" value={targetName} onChange={(e) => setTargetName(e.target.value)} />
              </FloatingLabel> */}
              <Row>
                <Col lg={6}>
                  <FloatingLabel
                    className="mb-4 target-end-date-label"
                    controlId="targetSartEndDate"
                    label="Start Date"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Date"
                    />
                    <DateTimePicker
                      className={`remove-time-wrap ${activeClass}`}
                      clearIcon={null}
                      placeholder="Date"
                      value={startDate}
                      onChange={(value) => setStartDate(value)}
                      onBlur={() => {
                        setActiveClass('');
                      }}
                      onFocus={() => {
                        setActiveClass('target-date-focus');
                      }}
                    />
                  </FloatingLabel>
                </Col>
                <Col lg={6}>
                  <FloatingLabel
                    className="mb-4 target-end-date-label"
                    controlId="targetSartEndDate"
                    label="End Date"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Date"
                    />
                    <DateTimePicker
                      className={`remove-time-wrap ${activeClass}`}
                      clearIcon={null}
                      placeholder="Date"
                      value={endDate}
                      onChange={(value) => setEndDate(value)}
                      onBlur={() => {
                        setActiveClass('');
                      }}
                      onFocus={() => {
                        setActiveClass('target-date-focus');
                      }}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
            </>
          )}
          {resubmissionFilter && (
            <>
              {componentTypeChoice && componentTypeChoice.length > 0 && (
                <DropdownCommon
                  className="mb-4"
                  name="componentType"
                  defaultValue={componentType}
                  items={componentTypeChoice}
                  label={`${translate(taxonomy)('component')} Type`}
                  onChange={(data) => setComponentType(data)}
                />
              )}
              <Row>
                <Col lg={6}>
                  <FloatingLabel
                    className="mb-4 target-end-date-label"
                    controlId="targetSartEndDate"
                    label="Start Date"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Date"
                    />
                    <DateTimePicker
                      className={`remove-time-wrap ${activeClass}`}
                      clearIcon={null}
                      placeholder="Date"
                      value={startDate}
                      onChange={(value) => setStartDate(value)}
                      onBlur={() => {
                        setActiveClass('');
                      }}
                      onFocus={() => {
                        setActiveClass('target-date-focus');
                      }}
                    />
                  </FloatingLabel>
                </Col>
                <Col lg={6}>
                  <FloatingLabel
                    className="mb-4 target-end-date-label"
                    controlId="targetSartEndDate"
                    label="End Date"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Date"
                    />
                    <DateTimePicker
                      className={`remove-time-wrap ${activeClass}`}
                      clearIcon={null}
                      placeholder="Date"
                      value={endDate}
                      onChange={(value) => setEndDate(value)}
                      onBlur={() => {
                        setActiveClass('');
                      }}
                      onFocus={() => {
                        setActiveClass('target-date-focus');
                      }}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
            </>
          )}
          {userFilterList && (
            <>
              <DropdownCheckboxMultiSelect
                className="mb-4 user-max-content-dropdown"
                label="Role Selection"
                autoComplete="off"
                items={roles.list.map((role) => role.name)}
                selectedItems={selectedPeriodItemsList}
                onChange={onPeriodItemsChange}
              />
              <Row>
                <Col lg={6}>
                  <FloatingLabel
                    className="mb-4 target-end-date-label"
                    controlId="targetSartEndDate"
                    label="Start Date"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Date"
                    />
                    <DateTimePicker
                      className={`remove-time-wrap ${activeClass}`}
                      clearIcon={null}
                      placeholder="Date"
                      value={startDate}
                      onChange={(value) => setStartDate(value)}
                      onBlur={() => {
                        setActiveClass('');
                      }}
                      onFocus={() => {
                        setActiveClass('target-date-focus');
                      }}
                    />
                  </FloatingLabel>
                </Col>
                <Col lg={6}>
                  <FloatingLabel
                    className="mb-4 target-end-date-label"
                    controlId="targetSartEndDate"
                    label="End Date"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Date"
                    />
                    <DateTimePicker
                      className={`remove-time-wrap ${activeClass}`}
                      clearIcon={null}
                      placeholder="Date"
                      value={endDate}
                      onChange={(value) => setEndDate(value)}
                      onBlur={() => {
                        setActiveClass('');
                      }}
                      onFocus={() => {
                        setActiveClass('target-date-focus');
                      }}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
            </>
          )}
          {contactFilterList && (
            <>
              <DropdownCheckboxMultiSelect
                className="mb-4 user-max-content-dropdown"
                label="Role Selection"
                autoComplete="off"
                items={theRoles.map((role) => role.name)}
                selectedItems={selectedPeriodItemsList}
                onChange={onPeriodItemsChange}
              />
            </>
          )}
          {apprenticesFilterList && (
            <>
              <Row>
                <Col lg={6}>
                  <FloatingLabel
                    className="mb-4 target-end-date-label"
                    controlId="targetSartEndDate"
                    label="Start Date"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Date"
                    />
                    <DateTimePicker
                      className={`remove-time-wrap ${activeClass}`}
                      clearIcon={null}
                      placeholder="Date"
                      value={startDate}
                      onChange={(value) => setStartDate(value)}
                      onBlur={() => {
                        setActiveClass('');
                      }}
                      onFocus={() => {
                        setActiveClass('target-date-focus');
                      }}
                    />
                  </FloatingLabel>
                </Col>
                <Col lg={6}>
                  <FloatingLabel
                    className="mb-4 target-end-date-label"
                    controlId="targetSartEndDate"
                    label="End Date"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Date"
                    />
                    <DateTimePicker
                      className={`remove-time-wrap ${activeClass}`}
                      clearIcon={null}
                      placeholder="Date"
                      value={endDate}
                      onChange={(value) => setEndDate(value)}
                      onBlur={() => {
                        setActiveClass('');
                      }}
                      onFocus={() => {
                        setActiveClass('target-date-focus');
                      }}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
            </>
          )}
          {(unitComponentFilterList || sectionBankFilterList) && !loadCategoryField && (
            <>
              <DropdownCommon
                className="mb-4"
                name="Categories"
                defaultValue={selectedCategory[0]}
                items={categories}
                label="Category"
                onChange={(data) => setSelectedCategory([data])}
              />
            </>
          )}
          {(activeApprenticeFilterList || activeProgramFilterList) && (
            <>
              {/* <DropdownCheckboxMultiSelect
                className="mb-4 user-max-content-dropdown"
                label="Role Selection"
                autoComplete="off"
                items={roles.list.map((role) => role.name)}
                selectedItems={selectedPeriodItemsList}
                onChange={onPeriodItemsChange}
              /> */}
              <Row>
                <Col lg={6}>
                  <FloatingLabel
                    className="mb-4 target-end-date-label"
                    controlId="targetSartEndDate"
                    label="Start Date"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Date"
                    />
                    <DateTimePicker
                      className={`remove-time-wrap ${activeClass}`}
                      clearIcon={null}
                      placeholder="Date"
                      value={startDate}
                      onChange={(value) => {
                        console.log(value);
                        setStartDate(value);
                      }}
                      onBlur={() => {
                        setActiveClass('');
                      }}
                      onFocus={() => {
                        setActiveClass('target-date-focus');
                      }}
                    />
                  </FloatingLabel>
                </Col>
                <Col lg={6}>
                  <FloatingLabel
                    className="mb-4 target-end-date-label"
                    controlId="targetSartEndDate"
                    label="End Date"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Date"
                    />
                    <DateTimePicker
                      className={`remove-time-wrap ${activeClass}`}
                      clearIcon={null}
                      placeholder="Date"
                      value={endDate}
                      onChange={(value) => setEndDate(value)}
                      onBlur={() => {
                        setActiveClass('');
                      }}
                      onFocus={() => {
                        setActiveClass('target-date-focus');
                      }}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
            </>
          )}
          <div className="clear-form">
            <Button
              variant="default"
              onClick={() => handleClose()}
            >
              {clearFormIcon} Clear Form
            </Button>
          </div>
        </Offcanvas.Body>
        <Offcanvas.Footer>
          <Button
            variant="default"
            onClick={closeOffcanvas}
          >
            Cancel
          </Button>
          <SnackBar
            applyFilter={() => {
              setShow(false);
              setDatafetchStatus(false);
              return applyFilter({
                role,
                startDate,
                endDate,
                progressCalculatedOn,
                status,
                categories: selectedCategory[0],
                componentType,
                isMandatoryStatus,
                completeStatus,
                topLevelSections: selectedTopLevelSection[0],
                secondLevelSections: selectedSecondLevelSection[0],
                targetName,
                program: selectedProgram.id,
              });
            }}
          />
        </Offcanvas.Footer>
      </Offcanvas>
    </>
  );
}

export default ShowFilterOffCanvas;
