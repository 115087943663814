import Modal from 'react-myoneui/lib/Modal';
import Button from 'react-myoneui/lib/Button';
import Spinner from 'react-myoneui/lib/Spinner';
function MarkAsCompleteModal(props) {
  const { onClose, onMarkComplete, loading } = props;
  return (
    <Modal
      show={props.show}
      onHide={() => onClose()}
      contentClassName="bg-transparent mark-as-completed-modal"
    >
      <Modal.Header className="p-0">
        <Modal.Title>Are you Sure you want to Mark as complete ?</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button
          variant="glitter"
          onClick={() => onClose()}
        >
          No, Cancel
        </Button>
        <Button
          variant="primary"
          onClick={onMarkComplete}
        >
          {loading === true && (
            <span>
              <Spinner
                animation="border"
                size="sm"
              />{' '}
              &nbsp;
            </span>
          )}
          Yes, Confirm
        </Button>
        {/* <Button variant="primary" onClick={markAsCompletedClose}>
          Submit
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
}

export default MarkAsCompleteModal;
