import { useEffect, useState } from 'react';
const Rating = ({ value = 0, readOnly = false, onChange, ...props }) => {
  const randomNum = Math.random() * (9999 - 1) + 1;
  const [inputName, setInputName] = useState('');
  const [inputValue, setInputValue] = useState(0);
  useEffect(() => {
    setInputName(randomNum);
  }, []);
  useEffect(() => {
    setInputValue(+value);
  }, [value]);
  const rateHandler = (e) => {
    if (!readOnly) {
      setInputValue(+e.target.value);
      onChange && onChange(+e.target.value);
    }
  };
  return (
    <fieldset className={`review-star ${readOnly ? 'readonly' : ''} ${props?.className || ''}`}>
      <input
        type="radio"
        id={`rating10${inputName}`}
        name={inputName}
        value="5"
        checked={inputValue === 5}
        onChange={rateHandler}
        readOnly={readOnly}
      />
      <label
        htmlFor={`rating10${inputName}`}
        title="5 stars"
      ></label>
      <input
        type="radio"
        id={`rating9${inputName}`}
        name={inputName}
        value="4.5"
        checked={inputValue === 4.5}
        onChange={rateHandler}
        readOnly={readOnly}
      />
      <label
        className="half"
        htmlFor={`rating9${inputName}`}
        title="4 1/2 stars"
      ></label>
      <input
        type="radio"
        id={`rating8${inputName}`}
        name={inputName}
        value="4"
        checked={inputValue === 4}
        onChange={rateHandler}
        readOnly={readOnly}
      />
      <label
        htmlFor={`rating8${inputName}`}
        title="4 stars"
      ></label>
      <input
        type="radio"
        id={`rating7${inputName}`}
        name={inputName}
        value="3.5"
        checked={inputValue === 3.5}
        onChange={rateHandler}
        readOnly={readOnly}
      />
      <label
        className="half"
        htmlFor={`rating7${inputName}`}
        title="3 1/2 stars"
      ></label>
      <input
        type="radio"
        id={`rating6${inputName}`}
        name={inputName}
        value="3"
        checked={inputValue === 3}
        onChange={rateHandler}
        readOnly={readOnly}
      />
      <label
        htmlFor={`rating6${inputName}`}
        title="3 stars"
      ></label>
      <input
        type="radio"
        id={`rating5${inputName}`}
        name={inputName}
        value="2.5"
        checked={inputValue === 2.5}
        onChange={rateHandler}
        readOnly={readOnly}
      />
      <label
        className="half"
        htmlFor={`rating5${inputName}`}
        title="2 1/2 stars"
      ></label>
      <input
        type="radio"
        id={`rating4${inputName}`}
        name={inputName}
        value="2"
        checked={inputValue === 2}
        onChange={rateHandler}
        readOnly={readOnly}
      />
      <label
        htmlFor={`rating4${inputName}`}
        title="2 stars"
      ></label>

      <input
        type="radio"
        id={`rating3${inputName}`}
        onChange={rateHandler}
        name={inputName}
        value="1.5"
        checked={inputValue === 1.5}
        readOnly={readOnly}
      />
      <label
        className="half"
        htmlFor={`rating3${inputName}`}
        title="1 1/2 stars"
      ></label>
      <input
        type="radio"
        id={`rating2${inputName}`}
        name={inputName}
        value="1"
        checked={inputValue === 1}
        onChange={rateHandler}
        readOnly={readOnly}
      />
      <label
        htmlFor={`rating2${inputName}`}
        title="1 star"
      ></label>
      <input
        type="radio"
        id={`rating1${inputName}`}
        name={inputName}
        value="0.5"
        checked={inputValue === 0.5}
        onChange={rateHandler}
        readOnly={readOnly}
      />
      <label
        className="half"
        htmlFor={`rating1${inputName}`}
        title="1/2 star"
      ></label>
    </fieldset>
  );
};
export default Rating;
