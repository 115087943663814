import { rightArrowIcon } from 'constants/icons';
import { Link } from 'react-router-dom';

export default function Reminders({ imgSrc, deadline, description, viewDetailsLink }) {
  return (
    <div className="reminders-content">
      <div className="img-wrap">
        <img
          loading="lazy"
          src={imgSrc}
          alt="Resume learning"
        />
      </div>
      <div className="info-wrap">
        <span>Deadline: {deadline}</span>
        <p>{description}</p>
        <Link
          className="view-details"
          to={viewDetailsLink}
        >
          View details {rightArrowIcon}
        </Link>
      </div>
    </div>
  );
}
