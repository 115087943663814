import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
};

export const TaxonomySlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    setTaxonomy: (state, action) => {
      return {
        ...state,
        data: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setTaxonomy } = TaxonomySlice.actions;

export default TaxonomySlice.reducer;
