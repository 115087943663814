import { SET_TOKEN, SET_EMAIL, SET_ORG_ID, SET_USER_DETAIL, SET_USER_DOMAIN, SET_USER_ROLE, SET_LOG_OUT_URL, SET_ACTIVE_MEMBERPROGRAM_ID } from './Types';

const initialState = {
  token: null,
  isAuthenticated: true,
  email: '',
  orgId: '',
  detail: {},
  role: '',
  logoutUrl: '',
  domain: '',
  activeMemberProgramId: '',
};

const infoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case SET_ORG_ID:
      return {
        ...state,
        orgId: action.payload,
      };
    case SET_USER_DETAIL:
      return {
        ...state,
        detail: action.payload,
      };
    case SET_USER_DOMAIN:
      return {
        ...state,
        domain: action.payload,
      };
    case SET_USER_ROLE:
      return {
        ...state,
        role: action.payload,
      };
    case SET_LOG_OUT_URL:
      return {
        ...state,
        logoutUrl: action.payload,
      };
    case SET_ACTIVE_MEMBERPROGRAM_ID:
      return {
        ...state,
        activeMemberProgramId: action.payload,
      };
    default:
      return state;
  }
};

export default infoReducer;
