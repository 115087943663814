import { combineReducers } from 'redux';

import infoReducer from './info/Reducer';
import sidebarSlice from './sidebarSlice';
import confirmBoxSlice from './confirmBox';
import OrganizationSlice from './OrganizationSlice';
import ApprenticeshipSlice from './ApprenticeshipSlice';
import CreateProgramSlice from './CreateProgramSlice';
import ToastBoxSlice from './ToastBoxSlice';
import LangSlice from './LangSlice';
import TaxonomySlice from './TaxonomySlice';
import RolesSlice from './RolesSlice';
import ActiveRoleSlice from './ActiveRoleSlice';
import EditUserSlice from './EditUserSlice';
import Sections from './SectionSlice';
import MemberProgramSlice from './MemberProgramSlice';
import FeedbacksSlice from './FeedbacksSlice';
import ProgramSlice from './ProgramSlice';
import FormBuilderSlice from './FormBuilderSlice';
import adminSlice from 'reducers/adminSlice';
import configSlice from 'reducers/configSlice';
import FormDataSlice from './FormDataSlice';
import QuizDataSlice from './QuizDataSlice';
import AlertNotificationSlice from './AlertNotificationSlice';
import APISlice from './APISlice';

export default combineReducers({
  api: APISlice,
  info: infoReducer,
  sidebar: sidebarSlice,
  confirmBox: confirmBoxSlice,
  organizations: OrganizationSlice,
  apprenticeship: ApprenticeshipSlice,
  createProgram: CreateProgramSlice,
  toastBox: ToastBoxSlice,
  lang: LangSlice,
  taxonomy: TaxonomySlice,
  roles: RolesSlice,
  activeRole: ActiveRoleSlice,
  editUser: EditUserSlice,
  sections: Sections,
  memberProgram: MemberProgramSlice,
  feedbacks: FeedbacksSlice,
  program: ProgramSlice,
  formbuilder: FormBuilderSlice,
  admin: adminSlice,
  config: configSlice,
  formData: FormDataSlice,
  quizData: QuizDataSlice,
  alertNotification: AlertNotificationSlice,
});
