import { useState, useRef, useEffect } from 'react';

import SecondaryHeader from 'react-myoneui/lib/SecondaryHeader';
import SecondaryHeaderSearchField from 'react-myoneui/lib/SecondaryHeaderSearchField';
import SecondaryHeaderInfo from 'react-myoneui/lib/SecondaryHeaderInfo';

import GridTable from 'utils/react-grid-table';
// import Tabs from 'react-myoneui/lib/Tabs';
// import Tab from 'react-myoneui/lib/Tab';

import { clearBtn } from 'constants/icons';
import { Card } from 'react-myoneui';

import ContextMenuTemplate from './grid-table/ContextMenuTemplate';
import NameTemplate from './grid-table/NameTemplate';
import EmailTemplate from './grid-table/EmailTemplate';
import TutorsLastActiveTemplate from './grid-table/TutorsLastActiveTemplate';
import TotalProgramCol from './grid-table/TotalProgramCol';
import DataNotFound from 'components/DataNotFound';

import PaginationTemplate from 'components/grid-table/PaginationTemplate';
import Spinner from 'react-myoneui/lib/Spinner';
import ToastBox from 'components/ToastBox';
import { useNavigate, useParams } from 'react-router-dom';
// import CreateNewBatchOffCanvas from '../batches/CreateNewBatchOffCanvas';

import axios from 'axios';
import { setLang, setToastBoxDescription, setToastBoxStatus } from '../../../redux';
import { LANG } from 'constants/config';
import { translate, encryptData } from 'lib';
import ShowFilterOffCanvas from 'components/ShowFilterOffCanvas';

//redux
import { useSelector, useDispatch } from 'react-redux';
import { formatToUTC, formatToUTCDate } from 'utils/formatTime';
import TotalLearnerCol from './grid-table/TotalLearnerCol';
import { getUserIQVAssessors } from 'api/iqv';
import { getMemberProgramAssessors } from 'api/assessor/learner';
import { ConfirmBox } from 'components/ConfirmBox';
import ls from 'localstorage-slim';
import Log from 'lib/Log';
ls.config.encrypt = true;

const formatMember = (member) => {
  Log.info(member);
  return {
    id: member?.id,
    full_name: member?.first_name + ' ' + member?.last_name,
    email: member?.decrypted_email,
    lastActive: member?.lastActive,
    profile_image: member?.profile_image,
    total_members: member?.total_members,
    phone_country_code: member?.phone_country_code,
    country: member?.country,
    phone_no: member?.phone_no,
    roles: member?.roles,
    total_programs: member?.total_programs,
    custom_fields: member?.custom_fields ? JSON.parse(member.custom_fields) : [],
  };
};

function IQVAssessors(props) {
  Log.info(props);

  const { from = 'iqv' } = props;
  const [editStatus, setEditStatus] = useState(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [deleteModalStatus, setDeleteModalStatus] = useState(false);
  const [ids, setIds] = useState([]);
  const language = ls.get('lang') || LANG;
  const lang = useSelector((state) => state?.lang?.data);
  const [resetFilter, setResetFilter] = useState(false);
  const [displayResetFilter, setDisplayResetFilter] = useState(false);
  const dispatch = useDispatch();
  const roles = JSON.parse(ls.get('roles'));
  const assessorName = roles.filter((role) => role.identifier === 'assessor')[0]?.name;
  const learnerName = roles.filter((role) => role.identifier === 'apprentice')[0]?.name;

  const roleId = roles.filter((role) => role.identifier === 'assessor')[0]?.id;
  const [assignedViewPrivileges, setAssignedViewPrivileges] = useState({});
  const { id, programId } = useParams();
  const [confirmBoxLoading, setConfirmBoxLoading] = useState(false);

  const setProgramLanguage = async () => {
    try {
      let result = await axios.get(`/lang/modules/common/program/${language}.json`);
      dispatch(setLang(result.data));
    } catch (error) {
      Log.error(error);
    }
  };
  const columnData = [
    {
      id: 'checkbox',
      pinned: true,
      className: 'selectAll',
      width: '55px',
      minResizeWidth: 0,
      maxResizeWidth: null,
      resizable: false,
      visible: true,
    },
    {
      id: 1,
      field: 'full_name',
      visible: true,
      label: `${assessorName} Name`,
      width: '1fr',
      sortable: true,
      cellRenderer: NameTemplate,
    },
    {
      id: 2,
      field: 'email',
      visible: true,
      label: 'Email ID',
      width: '1fr',
      sortable: false,
      cellRenderer: EmailTemplate,
    },
    {
      id: 3,
      field: 'lastActive',
      visible: true,
      label: 'Last Active',
      width: '1fr',
      sortable: true,
      cellRenderer: TutorsLastActiveTemplate,
    },
    {
      id: 4,
      field: 'total_programs',
      visible: true,
      label: 'Total Programs',
      width: '1fr',
      sortable: false,
      cellRenderer: TotalProgramCol,
    },
    {
      id: 5,
      field: 'total_members',
      visible: true,
      label: `Total Members`,
      width: '1fr',
      sortable: false,
      cellRenderer: TotalLearnerCol,
    },
  ];

  const tableManager = useRef(null);
  const timerRef = useRef(null);
  const [search, setSearch] = useState('');
  const [searchText, setSearchText] = useState('');
  const [filter] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeCol, setActiveCol] = useState(columnData[4]);
  const [sort, setSort] = useState('desc');
  const [sortBy, setSortBy] = useState('createdAt');
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [organisationId] = useState(ls.get('orgId'));
  const [loading, setLoading] = useState(false);
  const [menu, setMenu] = useState({ display: true, apprentice: [], members: [], user: {} });

  const setTableManager = (tm) => (tableManager.current = tm);

  useEffect(() => {
    if (columnData) {
      onRowsRequest();
    }
  }, [perPage, currentPage, sort, sortBy]);

  const reLoadData = async (filter = {}) => {
    try {
      setData(null);
      setTotalData(null);
      setLoading(true);
      if (organisationId && loading === false) {
        var result;

        result = await getUserIQVAssessors({
          iqv: from === 'eqv' ? id : ls.get('userid'),
          perPage: perPage,
          currentPage: currentPage,
          search: searchText,
          sort: sort,
          sortBy: sortBy,
          ...filter,
        });

        if (result) {
          setLoading(false);
          const members = result?.data?.data?.members.map(formatMember);
          setAssignedViewPrivileges(result?.data?.data?.assignedViewPrivileges);
          setData(members);
          setTotalData(result?.data?.data?.meta?.totalMembers);
        }
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const onRowsRequest = async () => {
    try {
      reLoadData();
    } catch (e) {
      Log.error('Error on onRowsRequest', e);
      setLoading(false);
      setData([]);
      setTotalData(0);
    }
  };

  const rowChangeHandler = (id) => {
    try {
      Log.info('rowChangeHandler');
      Log.info('data : ' + data);
      Log.info('id : ' + id);
      setEditStatus(false);
      const members = data.filter((member) => id.includes(member?.id));
      if (members.length > 1) {
        setMenu({ display: false, members });
      } else {
        setMenu({ display: true, members });
      }
    } catch (error) {
      Log.error(error);
    }
  };

  // const removeHandler = async (payload) => {
  //   try {
  //     setConfirmBoxLoading(true);
  //     const result = await removeAssessor(payload);

  //     if (result) {
  //       Log.info(result);
  //       setDeleteModalStatus(false);
  //       setConfirmBoxLoading(false);

  //       reLoadData();
  //       dispatch(setToastBoxStatus(true));
  //       dispatch(setToastBoxDescription(result?.data?.message));
  //     }
  //   } catch (error) {
  //     dispatch(setToastBoxStatus(true));
  //     dispatch(setToastBoxDescription(error?.data?.message));
  //   }
  // };

  const onSearchChange = async (e) => {
    try {
      const value = e.target.value;
      setSearch(value);
      setSearchText(value);
      setData(null);
      setTotalData(null);
      setLoading(true);
      clearTimeout(timerRef.current);

      timerRef.current = setTimeout(async () => {
        let result = await getUserIQVAssessors({
          memberProgram: programId,
          iqv: from === 'eqv' ? id : ls.get('userid'),
          perPage: perPage,
          currentPage: currentPage,
          search: value,
          sort: sort,
          sortBy: sortBy,
          ...filter,
        });
        if (result) {
          setLoading(false);
          const members = result?.data?.data?.members?.map(formatMember);
          setAssignedViewPrivileges(result?.data?.data?.assignedViewPrivileges);
          setData(members);
          setTotalData(result?.data?.data?.meta?.totalMembers);
        }
      }, 500);
    } catch (err) {
      Log.error(err);
    }
  };
  const applyFilter = (data) => {
    try {
      let filter = {};
      if (data?.startDate) {
        filter.startDate = formatToUTC(data.startDate);
      }
      if (data?.endDate) {
        filter.endDate = formatToUTCDate(data.endDate);
      }
      if (Object.entries(filter).length > 0) {
        setDisplayResetFilter(true);
      } else {
        setDisplayResetFilter(false);
      }
      reLoadData(filter);
    } catch (err) {
      Log.error(err);
    }
  };
  const sortHandler = (sortData) => {
    try {
      setSort(sortData.isAsc ? 'asc' : 'desc');
      if (sortData.colId) {
        const sortCol = columnData.find((colData) => colData.id == sortData.colId);
        setActiveCol(sortCol);
        setSortBy(sortCol.field);
      } else {
        setActiveCol(columnData[4]);
        setSortBy('createdAt');
        setSort('desc');
      }
      setPerPage(10);
      setCurrentPage(1);
    } catch (err) {
      Log.error(err);
    }
  };

  const onClearSearch = async (e) => {
    try {
      setSearch('');
      setSearchText('');
      try {
        setData(null);
        setTotalData(null);
        setLoading(true);
        if (organisationId && loading === false) {
          var result;

          result = await getUserIQVAssessors({
            iqv: from === 'eqv' ? id : ls.get('userid'),
            perPage: perPage,
            currentPage: currentPage,
            search: '',
            sort: sort,
            sortBy: sortBy,
            ...filter,
          });

          if (result) {
            setLoading(false);
            const members = result?.data?.data?.members.map(formatMember);
            setAssignedViewPrivileges(result?.data?.data?.assignedViewPrivileges);
            setData(members);
            setTotalData(result?.data?.data?.meta?.totalMembers);
          }
        }
      } catch (err) {
      } finally {
        setLoading(false);
      }
    } catch (err) {
      Log.error(err);
    }
  };
  /*
  const editStatus = (info) => {
    try {
      if (info === false) {
        reLoadData();
      } else {
        // Display OffsetCanvas
        setEditStatus(info);
      }
    } catch (err) {
      Log.error(err);
    }
  };
  */
  useEffect(() => {
    setProgramLanguage();
  }, []);

  return (
    <div className="content-wrapper">
      {/* {show && (
        <CreateNewBatchOffCanvas
          show={show}
          onClose={() => setShow(false)}
        />
      )} */}
      <SecondaryHeader>
        <div className="secondary-header-wrap">
          <SecondaryHeaderInfo
            SecondaryHeaderTitle={`${assessorName}s`}
            SecondaryHeaderText=""
            SecondaryText
          />
        </div>
        <SecondaryHeaderSearchField
          placeholder={`Search ${assessorName}s...`}
          value={search}
          onChange={onSearchChange}
          onClear={onClearSearch}
        />
      </SecondaryHeader>
      <div className="filter-block">
        <ShowFilterOffCanvas
          apprenticesFilterList
          triggerTitle={translate(lang)('show_filters')}
          applyFilter={(data) => applyFilter(data)}
          updateResetFilter={() => {
            setResetFilter(false);
          }}
          resetFilter={resetFilter}
        />
        {displayResetFilter && (
          <span
            className="clear-filter"
            onClick={() => {
              setResetFilter(true);
              setDisplayResetFilter(false);
            }}
          >
            {clearBtn}
            Clear Filters
          </span>
        )}
      </div>
      <div className="content-block bg-transparent p-0">
        <div className="two-column-grid">
          <Card className="border-0 pt-3 flex-auto">
            {/* <Tabs
              defaultActiveKey="assessors"
              id="uncontrolled-tab-example"
              className="mb-3 grid-nav-tabs"
            >
              <Tab
                eventKey="assessors"
                title={`${assessorName}s`}
              > */}
            {loading && (
              <div className="spinner-wrapper">
                <Spinner animation="border" />
              </div>
            )}
            {data && data.length > 0 && totalData && totalData > 0 ? (
              <GridTable
                className="program-grid rgt-border-line-none"
                searchText={searchText}
                highlightSearch={false}
                columns={columnData}
                onSortChange={(sortData) => sortHandler(sortData)}
                sort={{ colId: activeCol.id, isAsc: sort == 'asc' ? true : false }}
                onRowsRequest={() => {
                  return {
                    rows: data,
                    totalRows: totalData,
                  };
                }}
                onSelectedRowsChange={(id) => rowChangeHandler(id)}
                onRowClick={({ data }) => navigate(`view-profile/${encryptData(data.id)}`)}
                filter={filter}
                onLoad={setTableManager}
                components={{
                  Footer: PaginationTemplate,
                  ContextMenu: ContextMenuTemplate({
                    setDeleteUser: (members) => {
                      setDeleteModalStatus(true);
                      setIds(members.map((member) => member.id));
                    },
                    editStatus,
                    onReLoad: () => reLoadData(),
                    setEditStatus: setEditStatus,
                    assessorName,
                    learnerName,
                    assignedViewPrivileges,
                    ...menu,
                  }),
                }}
                page={currentPage}
                pageSize={perPage}
                onPageChange={(page) => setCurrentPage(page)}
                onPageSizeChange={(data) => setPerPage(data)}
              />
            ) : (
              !loading && <DataNotFound title={assessorName} />
            )}
            {/* </Tab>
            </Tabs> */}
          </Card>
        </div>
      </div>

      {ConfirmBox && (
        <ConfirmBox
          show={deleteModalStatus}
          question={`Are you sure you want to delete ${menu.members[0]?.full_name} as ${assessorName}?`}
          bodyData={menu.members[0]}
          tutorRemoveBody={true}
          loading={confirmBoxLoading}
          onHide={() => {
            setDeleteModalStatus(false);
          }}
          onOk={(email) => {
            //removeHandler({ id: ids[0], email: email, roleId: roleId });
            Log.info('ok');
          }}
        />
      )}

      <ToastBox />
    </div>
  );
}

export default IQVAssessors;
