import { useParams, Link, useLocation } from 'react-router-dom';
import LinkWrapper from '../../components/LinkWrapper';
import { leftArrow, rightArrow, downArrow, feedbackIcon } from '../../constants/icons';
import { getTaxonomy } from '../../api';
import { Children, useState, memo } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ls from 'localstorage-slim';
import Log from '../../lib/Log';
import { useNavigate } from 'react-router-dom';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';
import { decryptData } from 'lib';
// enable global encryption
ls.config.encrypt = true;
export const delegateFilterSidebarList = async (activePath, program) => {
  const activeRole = JSON.parse(ls.get('activeRole'));

  let result = await getTaxonomy();
  if (result) {
    return [
      {
        text: 'Lecture List',
        name: 'lecture-list',
        type: 'item',
        link: `${activePath}/lecture-list`,
        isReact: true,
        isEnable: true,
      },
      {
        text: 'Task List',
        name: 'task-list',
        type: 'item',
        link: `${activePath}/task-list`,
        isReact: true,
        isEnable: program?.enable_mandatory_option,
      },
      {
        text: result?.data?.target,
        type: 'item',
        name: 'target',
        link: `${activePath}/target`,
        isReact: true,
        isEnable: activeRole.privileges?.can_view_smart_target,
      },
      // {
      //   text: 'Tutors',
      //   type: 'item',
      //   link: '/learners/tutors/',
      //   isReact: true,
      // },
      // {
      //   text: 'Document Bank',
      //   type: 'item',
      //   link: '/learners/document-bank/',
      //   isReact: true,
      // },
      {
        text: 'Quick Access',
        type: 'item',
        name: 'quick-links',
        link: `${activePath}/quick-links`,
        isReact: true,
        isEnable: true,
        className: 'feedback-sidebar-wrap',
      },
      {
        text: 'Resubmission',
        type: 'item',
        name: 'resubmission',
        link: `${activePath}/resubmission`,
        isReact: true,
        isEnable: true,
        count: '21',
        className: 'feedback-sidebar-wrap',
      },

      {
        text: 'Reports',
        type: 'item',
        name: 'reports',
        link: `${activePath}/reports`,
        isReact: true,
        isEnable: true,
        className: 'feedback-sidebar-wrap',
      },
    ];
  }
};

function DelegateRolesFilterSidebar({ backpage }) {
  const [delegateRolesFilterSidebar, setDelegateRolesFilterSidebar] = useState([]);
  const programId = useSelector((state) => state?.memberProgram?.program?.id);
  const { id } = useParams();
  const { pathname } = useLocation();
  const role = ls.get('role');
  // for back to learner-list
  const pathSplit = pathname.split('/');
  pathSplit.splice(pathSplit.indexOf('program') + 1, 1, programId);
  const back = pathSplit.slice(0, pathSplit.length - 2).join('/');

  const member_program_id = ls.get('activeMemberProgramId');
  const roles = JSON.parse(ls.get('roles'));
  const assessorTaxonomyName = roles.find((item) => item?.identifier === 'assessor')?.name;
  const feedbacks = useSelector((state) => state?.feedbacks?.data);
  const sections = useSelector((state) => state.sections.sections);
  const program = useSelector((state) => state?.memberProgram?.program);

  // const learnerListCheck = 'learner-list' === pathname.split('/')[4];
  const activePath = `${pathname.split('program')[0]}program/${member_program_id}/learner-list/${id}`;

  const activeRole = JSON.parse(ls.get('activeRole'));
  const displayAssessor = activeRole.privileges.can_view_assessor;

  const getTax = async () => {
    let data = await delegateFilterSidebarList(activePath, program);
    if (data) {
      data = data.filter((sidebar) => sidebar.isEnable);
      setDelegateRolesFilterSidebar(data);
    }
  };
  useEffect(() => {
    if (program) getTax();
  }, [program]);
  const onLinkClick9 = (e) => {
    const hrefSplit = e.target.href.split('#');
    const id = hrefSplit[hrefSplit.length - 1];
    const item = document.getElementById(id);
    if (item) {
      item.scrollIntoView({ block: 'center' });
    }
  };
  const location = useLocation();
  const goToBack = () => {
    let url = location.pathname.split('program');
    return url[0] + 'program/' + program?.id + '/learner-list';
  };
  const listSidebarItems = delegateRolesFilterSidebar.map((child, i) => {
    // const isActive = location.pathname === child.link;
    const pathSplit = location.pathname.split('/');
    const pathName = pathSplit[pathSplit.length - 1];
    const linkSplit = child.link.split('/');
    const linkName = linkSplit[linkSplit.length - 1];
    const pageReg = new RegExp(child.name);
    const isActive = pageReg.test(location.pathname);
    if (child.type === 'divider') {
      return (
        <li
          key={i}
          className="divider"
        />
      );
    }
    return (
      <li
        key={i}
        className={isActive ? 'active' : ''}
      >
        <Link
          isReact={child.isReact}
          to={child.link}
          className={child.className}
          // onClick={onLinkClick9}
        >
          <span className="text-truncate">{child.text}</span>
          {child.text === 'Quick Access' && (
            <span
              className="feedback-badge"
              title={feedbacks?.totalNewFeedbacksCount}
            >
              {feedbacks?.totalNewFeedbacksCount > 0 && (
                <>
                  <span className="feedback-icon">
                    {feedbackIcon} <span className="feedback-dot"></span>
                  </span>
                  <span className="feedback-sidebar-count">{feedbacks?.totalNewFeedbacksCount}</span>
                </>
              )}
            </span>
          )}
          {child.text === 'Resubmission' && child.count && (
            <span
              className="feedback-badge"
              title={sections?.totalReSubmission}
            >
              {feedbacks?.totalReSubmission > 0 && (
                <>
                  <span className="feedback-icon">
                    {feedbackIcon} <span className="feedback-dot"></span>
                  </span>
                  <span className="feedback-sidebar-count">{feedbacks?.totalReSubmissions}</span>
                </>
              )}
            </span>
          )}
        </Link>
      </li>
    );
  });

  return (
    <div className="dynamic-inner-sidebar">
      <div className="fixed-sidebar-back">
        {/* <Link className="sidebar-back-nav" to={`${back}/`}> */}
        <Link
          className="sidebar-back-nav"
          to={goToBack()}
        >
          {leftArrow}
          {/* {roles.filter((role) => role.identifier === 'apprentice')[0].name}s List */}
          <span className="text-truncate">Back</span>
        </Link>
        <span className="divider" />
      </div>

      <ul className="sidebar-list">
        {listSidebarItems}
        {['admin', 'tutor'].includes(role) && displayAssessor && assessorTaxonomyName && (
          <li>
            <Link to={`/${role}/program/` + member_program_id + '/learner-list/' + id + '/assessors'}>{assessorTaxonomyName}s</Link>
          </li>
        )}
      </ul>
    </div>
  );
}

export default memo(DelegateRolesFilterSidebar);
