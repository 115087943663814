import axios from 'axios';
import { API_URL } from '../../constants/config';
import ls from 'localstorage-slim';
import Log from '../../lib/Log';
// enable global encryption
ls.config.encrypt = true;
export const getAssessorPrograms = async (payload) => {
  const { search, progress_calculated_on, ...rest } = payload;
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };

  try {
    return await axios.post(`${API_URL}/assessor/list/programs`, rest, { headers, params: { search, progress_calculated_on } });
  } catch (err) {
    throw new Error(err);
  }
};

export const listAssessors = async (payload) => {
  const { memberProgram, ...params } = payload;
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };

  try {
    return await axios.post(`${API_URL}/admin/list/assessor`, { memberProgram }, { headers, params });
  } catch (err) {
    throw new Error(err);
  }
};

export const assignAssessorsProgram = async (payload) => {
  /* 
    payload
    {
      memberPrograms:[string], assessors:[string]
    }
  */
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    },
  };

  try {
    return await axios.post(`${API_URL}/admin/assessor/assign`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const unAassignAssessorsProgram = async (payload) => {
  /* 
    payload
    {
      memberPrograms:[string], assessors:[string]
    }
  */
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    },
  };

  try {
    return await axios.post(`${API_URL}/admin/assessor/unAssign`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};
/*
payload: { id:string, email:string }
*/
export const removeAssessor = async (payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };
    const { roleId, id, email } = payload;

    return new Promise((resolve, reject) => {
      return axios
        .post(
          `${API_URL}/admin/remove/${roleId}/assessor`,
          {
            email,
            id,
          },
          { headers },
        )
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    Log.error(error);
  }
};

export const getAssessorMemberPrograms = async (payload) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };

  try {
    return await axios.get(`${API_URL}/assessor/list/member-program`, { headers, params: payload });
  } catch (err) {
    throw new Error(err);
  }
};
