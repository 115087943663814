import React, { forwardRef, useState, useEffect } from 'react';
import Form from 'react-myoneui/lib/Form';
import Button from 'react-myoneui/lib/Button';
import Dropdown from 'react-myoneui/lib/Dropdown';
import { editSectionWeightage, reOrderSection, reOrderSectionBankSection } from '../../../../api';
import AddDropdownList from '../addDropdown';
import AddDropDownSectionBank from '../AddDropDownSectionBank';
import { blackArrowDownIcon, collapseSectionIcon, subSectionIcon, explainNoteIcon, reviewIcon } from '../../../../constants/icons';
import ComponentRow from './ComponentRow';
import { useParams } from 'react-router-dom';
import QuickEditDropdown from './QuickEditDropdown';
import { translate, immutableMove, rearrange } from '../../../../lib';
import { useSelector, useDispatch } from 'react-redux';
import { setStatusList, setToastBoxDescription, setToastBoxStatus } from '../../../../redux';
import DragItem from '../../../../components/dragdrop/DragItem';
import ls from 'localstorage-slim';
import { OverlayTrigger } from 'react-myoneui';
import Popover from 'react-myoneui/lib/Popover';
import Log from '../../../../lib/Log';
import { MessageBox } from 'components/MessageBox';
import AddScoreOffCanvas from 'modules/learner/program/AddScoreOffCanvas';

ls.config.encrypt = true;
const CustomToggle = forwardRef(({ children, onClick }, ref) => (
  <Button
    role="button"
    tabIndex={0}
    ref={ref}
    variant="leading-icon"
    className="leading-icon"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </Button>
));

const SubSectionRow = (props) => {
  const { orgId } = useParams();
  const dispatch = useDispatch();

  const statusList = useSelector((state) => state.program.statusList);
  const showChild = statusList.includes(props.id);
  const {
    isFromSectionBank,
    onEditSection,
    data,
    mainweightage,
    headding,
    subHeadding,
    title,
    sub_title,
    value,
    component,
    sectionType,
    onAddSectionStatus,
    id,
    parentId,
    childSection,
    weightage,
    totalComponent,
    onDeleteSection,
    mandatory,
    onWeightage,
    secondLevelWeightageStatus,
    isPublished,
    onHasComponentStatus,
    activeParentID,
    onActiveParentID,
    itemindex,
    show,
    onReOrder,
    isValidRole,
    onViewSection,
    objIndex,
    programInfo,
    selectedSection,
    onClickSection,
  } = props;
  const { is_from_section_bank } = { ...data };
  const [ownWeight, setOwnWeight] = useState(weightage);
  const [organisationId, setOrganisationId] = useState(null);
  const [weightageStatus, setWeightageStatus] = useState(false);
  const [sections, setSections] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const taxonomy = useSelector((state) => state?.taxonomy?.data);
  const [messageBoxStatus, setMessageBoxStatus] = useState(false);
  useEffect(() => {
    setOrganisationId(ls.get('orgId') || orgId);
    calculateWeightage(childSection.map((item) => item.weightage));
    setSections(childSection);
  }, []);
  const reOrder = async (obj) => {
    try {
      let payload = {
        destination_index: sections[parseInt(obj.end)].index,
        section_id: sections[parseInt(obj.start)].id,
      };
      let result = !isFromSectionBank ? await reOrderSection(payload) : await reOrderSectionBankSection(payload);
      dispatch(setToastBoxDescription('Dragging ...'));
      dispatch(setToastBoxStatus(true));
      if (result) {
        let startIndex = parseInt(obj.start);
        let endIndex = parseInt(obj.end);
        let startObjIndex = sections[startIndex].index;
        let endObjIndex = sections[endIndex].index;
        dispatch(setToastBoxStatus(true));
        dispatch(setToastBoxDescription(result.data.message));

        let sectionList = immutableMove([...sections], startIndex, endIndex);
        setSections(rearrange([...sectionList], startObjIndex, endObjIndex));
      }
    } catch (error) {
      Log.error(error);
      dispatch(setToastBoxDescription(''));
      dispatch(setToastBoxStatus(false));
    }
  };
  const calculateWeightage = (data) => {
    try {
      Log.info(data.reduce((a, b) => parseInt(a) + parseInt(b), 0) === 100);
      setWeightageStatus(data.reduce((a, b) => parseInt(a) + parseInt(b), 0) === 100);
    } catch (err) {
      Log.error(err);
    }
  };
  const weightageHandler = (e) => {
    try {
      if (e.target.value <= 100) {
        setOwnWeight(e.target.value);

        editSectionWeightage({
          id: id,
          weightage: e.target.value,
          orgId: organisationId,
        })
          .then((result) => {
            onWeightage(id, e.target.value);
          })
          .catch((error) => {
            Log.error(error);
            setOwnWeight(weightage);
          });
      }
    } catch (err) {
      Log.error(err);
    }
  };

  const weightageStatusHandler = (id, value, label) => {
    try {
      if (label === '3' && isValidRole) {
        let cloneSections = [...sections];
        let obj = cloneSections.find((item) => item.id === id);
        let index = cloneSections.indexOf(obj);
        obj.weightage = value;
        cloneSections[index] = obj;
        setSections(cloneSections);
        calculateWeightage(cloneSections.map((item) => item.weightage));
      }
    } catch (err) {
      Log.error(err);
    }
  };
  const onOffClass = () => {
    try {
      if (activeParentID) {
        if (activeParentID === parentId) {
          return 'on';
        } else {
          return 'off';
        }
      } else {
        return '';
      }
    } catch (err) {
      Log.error(err);
    }
  };

  const sectionHandler = () => {
    try {
      let status = !showChild;
      let cloneStatusList = [...statusList];
      if (status) {
        cloneStatusList.push(id);
      } else {
        cloneStatusList = cloneStatusList.filter((item) => item !== id);
      }
      dispatch(setStatusList(cloneStatusList));
    } catch (error) {
      Log.error(error);
    }
  };

  const deleteSectionHandler = async (id) => {
    try {
      onDeleteSection(id);
    } catch (error) {
      Log.error(error);
    }
  };
  const showComponentName = (sectionType) => {
    let componentName = '';

    if (taxonomy && sectionType) {
      // eslint-disable-next-line default-case
      switch (sectionType) {
        case 'CourseComponent':
          componentName = translate(taxonomy)('course');
          break;
        case 'UnitComponent':
          componentName = translate(taxonomy)('assignment');
          break;
        case 'ResourceComponent':
          componentName = translate(taxonomy)('resources');
          break;
        case 'FormComponent':
          componentName = translate(taxonomy)('forms');
          break;
        case 'ReviewComponent':
          componentName = translate(taxonomy)('review');
          break;
        case 'OTJComponent':
          componentName = translate(taxonomy)('otj');
          break;
      }
    } else {
      componentName = translate(taxonomy)('subSection');
    }
    return componentName;
  };
  const editSectionHandler = () => {
    try {
      if (!is_from_section_bank) {
        onEditSection({ ...data });
      } else {
        setMessageBoxStatus(true);
        //alert('Please update from unit bank');
      }
    } catch (error) {
      Log.error(error);
    }
  };
  useEffect(() => {
    let total = sections.filter((item) => item.parent_section === id && item.component !== null).length;
    if (total > 0) {
      onHasComponentStatus(true);
    }
  }, [sections]);

  const popover = (
    <Popover className="useful-note-tooltip popover-block">
      <Popover.Header>
        <div className="custom-tooltip-icon">{explainNoteIcon}</div>
        <span className="useful-note-title">Useful Note</span>
      </Popover.Header>
      <Popover.Body>
        <p>{showComponentName(sectionType)}</p>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      {/*
    If subsection does not have component then it is sub section and if it it has component value then this is component
    */}
      <MessageBox
        title={`Please update from ${translate(taxonomy)('section')} bank`}
        show={messageBoxStatus}
        onClose={() => setMessageBoxStatus(false)}
      />

      {component === null && (
        <DragItem
          parentid={parentId}
          key={id}
          id={id}
          data={data}
          index={itemindex}
          isDraggable={isValidRole ? true : false}
          onChange={onReOrder}
        >
          <div
            className={`section-grid section-grid-row-item-header me-0 ${selectedSection === id && parentId === activeParentID ? 'on' : ''} ${show ? 'd-flex' : 'd-none'}`}
            onClick={() => {
              onActiveParentID(parentId);
              onClickSection(id);
            }}
          >
            <div className="section-grid-left">
              <div className="section-grid-info pe-3">
                <div className="grid-file-icon">
                  <OverlayTrigger
                    placement="auto"
                    overlay={popover}
                  >
                    {subSectionIcon}
                  </OverlayTrigger>
                </div>
              </div>
              <div className="section-grid-size">
                {/* <div className="section-grid-headding"> {headding}</div> */}
                <div className="grid-text-overflow">{title}</div>
                {/* <div className="section-grid-sub-headding">{subHeadding}</div> */}
                <div className="section-grid-sub-headding">{sub_title ? `${subHeadding.split(' ')[0]} ${sub_title}` : subHeadding}</div>
              </div>
            </div>
            {/* 
            <div className="section-grid-middle">
              <div className="section-grid-size">
                <span className="grid-text-overflow" title={title}>
                  {title}
                </span>
              </div>
            </div> */}

            <div className="section-grid-right">
              {/* {isPublished === true ? <Badge className="badge">Published</Badge> : <Badge className="badge dark">Drafted</Badge>} */}
              <div className="add-weightage-holder">
                {mainweightage && (
                  <div className="total-weightage-wrap weightage-space-47">
                    <span className={`weightage-text ${secondLevelWeightageStatus === false ? 'text-danger' : ''}`}>{translate(taxonomy)('weightage')}</span>
                    <div className={`weightage-percentage ${!secondLevelWeightageStatus ? 'weightage-percentage-danger' : ''}`}>
                      <Form.Control
                        type="number"
                        placeholder=""
                        readOnly={!isValidRole}
                        value={ownWeight ? ownWeight : 0}
                        onChange={weightageHandler}
                      />
                      <span>%</span>
                    </div>
                  </div>
                )}
                <div className="action-col">
                  {isValidRole &&
                    sectionType === null &&
                    (!isFromSectionBank ? (
                      <AddDropdownList
                        id={id}
                        parentId={parentId}
                        sectionStatus={false}
                        onAddSectionStatus={(data) => onAddSectionStatus(data)}
                      >
                        <Dropdown.Toggle
                          as={CustomToggle}
                          id="dropdown-custom-components"
                        >
                          Add {blackArrowDownIcon}
                        </Dropdown.Toggle>
                      </AddDropdownList>
                    ) : (
                      <AddDropDownSectionBank
                        id={id}
                        parentId={parentId}
                        sectionStatus={false}
                        onAddSectionStatus={(data) => onAddSectionStatus(data)}
                      >
                        <Dropdown.Toggle
                          as={CustomToggle}
                          id="dropdown-custom-components"
                        >
                          Add {blackArrowDownIcon}
                        </Dropdown.Toggle>
                      </AddDropDownSectionBank>
                    ))}
                </div>

                {isValidRole && (
                  <QuickEditDropdown>
                    <Dropdown.Item
                      eventKey="1"
                      onClick={editSectionHandler}
                    >
                      Quick Edit
                    </Dropdown.Item>

                    <Dropdown.Item
                      eventKey="2"
                      onClick={() => deleteSectionHandler(data.id)}
                    >
                      Delete
                    </Dropdown.Item>
                  </QuickEditDropdown>
                )}

                {!isValidRole && (
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={() => onViewSection({ ...data })}
                  >
                    View
                  </Button>
                )}

                <span
                  className={`collapse-section ${statusList.includes(id) ? 'active' : ''}`}
                  onClick={sectionHandler}
                >
                  {sections?.length > 0 && collapseSectionIcon}
                </span>
              </div>
            </div>
          </div>
        </DragItem>
      )}
      {/* Get List component from client sections */}

      {sections &&
        sections.length > 0 &&
        sections
          .filter((item) => item.parent_section === id && item.component !== null)
          .map((item, index) => (
            <ComponentRow
              isFromSectionBank={isFromSectionBank}
              show={showChild && show}
              objIndex={item.index}
              className="section-grid-row-components"
              label="3"
              parentId={id}
              itemindex={index}
              weightageStatus={weightageStatus}
              sectionId={id}
              mainweightage={mainweightage}
              key={item.id}
              weightage={item?.weightage}
              mandatory={item.is_mandatory}
              id={item?.id}
              title={item?.title}
              description={item?.description}
              section_type={item?.section_type}
              value="1"
              asterisk="*"
              onWeightage={weightageStatusHandler}
              is_published={item.is_published}
              onDeleteComponent={deleteSectionHandler}
              grandParent={parentId}
              onHasComponentStatus={(data) => onHasComponentStatus(data)}
              activeParentID={selectedSection}
              onActiveParentID={() => {
                onActiveParentID(parentId);
                onClickSection(id);
              }}
              onReOrder={reOrder}
              isValidRole={isValidRole}
              data={item}
              programInfo={programInfo}
              selectedSection={selectedComponent}
              onClickSection={(id) => setSelectedComponent(id)}
            />
          ))}
    </>
  );
};

export default SubSectionRow;
