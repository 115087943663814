import { Route } from 'react-router-dom';
import { lazyWithRetry } from 'components/lazyWithRetry';
const UnsubmittedWorkflow = lazyWithRetry(() => import('modules/admin/workflow'));
const PendingWorkflow = lazyWithRetry(() => import('modules/admin/workflow/PendingWorkflow'));
const ResubmissionWorkflow = lazyWithRetry(() => import('modules/admin/workflow/ResubmissionWorkflow'));
const CompletedWorkflow = lazyWithRetry(() => import('modules/admin/workflow/CompletedWorkflow'));

export default (
  <>
    <Route
      path="workflow/"
      exact
      element={<UnsubmittedWorkflow />}
    />
    <Route
      path="workflow/unsubmitted"
      exact
      element={<UnsubmittedWorkflow />}
    />
    <Route
      path="workflow/pending"
      exact
      element={<PendingWorkflow />}
    />
    <Route
      path="workflow/resubmission"
      exact
      element={<ResubmissionWorkflow />}
    />
    <Route
      path="workflow/completed"
      exact
      element={<CompletedWorkflow />}
    />
  </>
);
