import axios from 'axios';
import ls from 'localstorage-slim';
import { API_URL } from '../constants/config';
import Log from '../lib/Log';
const activeRole = JSON.parse(ls.get('activeRole'));
export const getCount = async ({ roleName }) => {
  try {
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };

    return new Promise((resolve, reject) => {
      return axios
        .get(`${API_URL}/${roleName}/dashboard/get-count`, { headers })
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    Log.error(error);
  }
};

export const getPrograms = async ({ roleName }) => {
  try {
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };

    return new Promise((resolve, reject) => {
      return axios
        .get(`${API_URL}/${roleName}/dashboard/get-programs`, { headers })
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    Log.error(error);
  }
};

export const getNewSignupCounts = async ({ roleName, months }) => {
  try {
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };

    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}/${roleName}/dashboard/get-new-signup-count`, { months }, { headers })
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    Log.error(error);
  }
};

export const getLastActiveCounts = async ({ roleName, roleId, months }) => {
  try {
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };

    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}/${roleName}/dashboard/get-last-active-count`, { roleId, months }, { headers })
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    Log.error(error);
  }
};

export const getLastActiveProgram = async ({ roleName, months }) => {
  try {
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };
    const query = {
      months: months,
    };

    return new Promise((resolve, reject) => {
      return axios
        .get(`${API_URL}/${roleName}/dashboard/get-program-progress`, { params: query, headers })
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    Log.error(error);
  }
};

export const getActiveProgramCount = async ({ roleName }) => {
  try {
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };

    return new Promise((resolve, reject) => {
      return axios
        .get(`${API_URL}/${roleName}/dashboard/get-active-program-count`, { headers })
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    Log.error(error);
  }
};
export const getActiveLearnerCount = async ({ roleName }) => {
  try {
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };

    return new Promise((resolve, reject) => {
      return axios
        .get(`${API_URL}/${roleName}/dashboard/get-active-learner-count`, { headers })
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    Log.error(error);
  }
};

export const getTutorLastActiveLearner = async ({ roleName, search, endDate, startDate, currentPage, perPage }) => {
  try {
    console.log('getTutorLastActiveLearner');
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };
    const query = {
      limit: perPage,
      page: currentPage,
    };

    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}/user/reporting/view-login-details`, { role: activeRole.id, search, endDate, startDate }, { headers, params: query })
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    Log.error(error);
  }
};
export const getLastActiveLearner = async ({ roleName, search, endDate, startDate, currentPage, perPage }) => {
  try {
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };
    const query = {
      limit: perPage,
      page: currentPage,
    };

    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}/${roleName}/reporting/view-login-details`, { search, endDate, startDate }, { headers, params: query })
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    Log.error(error);
  }
};

export const getTutorLastActiveProgramList = async ({ roleName, search, endDate, startDate, currentPage, perPage, showAll }, roleid) => {
  try {
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };
    const query = {
      perPage: perPage,
      currentPage: currentPage,
      search,
      showAll,
      startDate,
      endDate,
    };

    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}/user/reporting/get-active-program-detail`, { role: roleid }, { headers, params: query })
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    Log.error(error);
  }
};

export const exportLastActiveLearner = async ({ roleName, search, endDate, startDate, currentPage, perPage }) => {
  try {
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };
    const query = {
      limit: perPage,
      page: currentPage,
    };

    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}/${roleName}/reporting/export-login-details`, { search, endDate, startDate, role: activeRole.id }, { headers, params: query })
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    Log.error(error);
  }
};

export const getLastActiveProgramList = async ({ roleName, search, endDate, startDate, currentPage, perPage, showAll }) => {
  try {
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };
    const query = {
      perPage: perPage,
      currentPage: currentPage,
      search,
      showAll,
      startDate,
      endDate,
    };

    return new Promise((resolve, reject) => {
      return axios
        .get(`${API_URL}/${roleName}/reporting/get-active-program-detail`, { headers, params: query })
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    Log.error(error);
  }
};

export const exportLastActiveProgramList = async ({ roleName, search, endDate, startDate, showAll }) => {
  try {
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };
    const query = {
      startDate,
      endDate,
    };

    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}/${roleName}/reporting/export-active-program-detail`, { search, showAll, role: activeRole.id }, { headers, params: query })
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    Log.error(error);
  }
};
export const getTutorLastActiveLearnerDetail = async ({ roleName, member }) => {
  try {
    console.log('getTutorLastActiveLearnerDetail');
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };

    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}/user/reporting/view-login-member-details`, { member: member, role: activeRole.id }, { headers })
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    Log.error(error);
  }
};
export const getLastActiveLearnerDetail = async ({ roleName, member }) => {
  try {
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };

    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}/${roleName}/reporting/view-login-member-details`, { member: member }, { headers })
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    Log.error(error);
  }
};
export const getTutorLastActiveLearnerActionLog = async ({ roleName, member, login_id }) => {
  try {
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };

    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}/user/reporting/learner/action-logs?perPage=99999,currentPage=1`, { role: activeRole.id, member_id: member, login_id: login_id }, { headers })
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    Log.error(error);
  }
};
export const getLastActiveLearnerActionLog = async ({ roleName, member, login_id }) => {
  try {
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };

    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}/${roleName}/reporting/learner/action-logs?perPage=99999,currentPage=1`, { member_id: member, login_id: login_id }, { headers })
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    Log.error(error);
  }
};

export const exportLastActiveLearnerActionLog = async ({ roleName, member, login_id }) => {
  try {
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };

    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}/${roleName}/reporting/learner/export-action-logs`, { member_id: member, login_id: login_id, role: activeRole.id }, { headers })
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    Log.error(error);
  }
};

export const getAssessorReport = async ({ roleName }) => {
  try {
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };

    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}/${roleName}/reporting/get-assessor-report`, { headers })
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    Log.error(error);
  }
};
