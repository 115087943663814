import { Route } from 'react-router-dom';
import { lazyWithRetry } from 'components/lazyWithRetry';

const MemberAssessors = lazyWithRetry(() => import('modules/learner/program/assessors'));
const AllComponent = lazyWithRetry(() => import('modules/admin/program/allComponent'));
const ProgramFieldManagement = lazyWithRetry(() => import('modules/admin/program/FieldManagement'));
const Program = lazyWithRetry(() => import('modules/admin/program'));
const MemberProgramList = lazyWithRetry(() => import('modules/admin/program/MemberProgramList'));
const ComponentDetail = lazyWithRetry(() => import('modules/learner/program/ComponentDetail'));
const Resubmission = lazyWithRetry(() => import('modules/learner/program/Resubmission'));
const LectureList = lazyWithRetry(() => import('modules/learner/program/LectureList'));
const ActivityDetail = lazyWithRetry(() => import('modules/learner/program/ActivityDetail'));
const Target = lazyWithRetry(() => import('modules/learner/program/Target/'));
const TaskList = lazyWithRetry(() => import('modules/learner/program/TaskList'));
const QuickLinks = lazyWithRetry(() => import('modules/learner/program/QuickLinks/index'));
const EnrollmentLink = lazyWithRetry(() => import('modules/admin/program/EnrollmentLink'));

export default (
  <>
    <Route
      path="program/*"
      element={<Program />}
    />
    <Route
      path="program/:programId/field-management"
      exact
      element={<ProgramFieldManagement />}
    />
    <Route
      path="program/:programId/enrollment-link"
      exact
      element={<EnrollmentLink />}
    />
    <Route
      path="program/:programId/all-component"
      exact
      element={<AllComponent />}
    />
    <Route
      path="program/:programId/learner-list"
      exact
      element={<MemberProgramList />}
    />
    <Route
      path="program/:programId/learner-list/:id/lecture-list"
      exact
      element={<LectureList />}
    />
    <Route
      path="program/:programId/learner-list/:id/target"
      exact
      element={<Target />}
    />
    <Route
      path="program/:programId/learner-list/:id/quick-links"
      exact
      element={<QuickLinks />}
    />
    <Route
      path="program/:programId/learner-list/:id/task-list"
      exact
      element={<TaskList />}
    />
    <Route
      path="program/:programId/learner-list/:id/resubmission"
      exact
      element={<Resubmission />}
    />
    <Route
      path="program/:programId/learner-list/:id/assessors"
      exact
      element={<MemberAssessors />}
    />
    <Route
      path="program/:programId/learner-list/:id/resubmission/:lectureDetail/activitityDetail/:activityId/:memberSectionId"
      exact
      element={<ActivityDetail />}
    />
    <Route
      path="program/:programId/learner-list/:id/lecture-list/:sectionid/"
      element={<ComponentDetail backBtnStatus={true} />}
    />
    <Route
      path="program/:programId/learner-list/:id/lecture-list/:lectureDetail/activitityDetail"
      element={<ActivityDetail />}
    />
    <Route
      path="program/:programId/learner-list/:id/lecture-list/:lectureDetail/:otj_id/activitityDetail/:activityId/:memberSectionId"
      element={<ActivityDetail />}
    />

    <Route
      path="program/:programId/learner-list/:id/lecture-list/:lectureDetail/activitityDetail/:activityId/:memberSectionId"
      element={<ActivityDetail />}
    />
    <Route
      path="program/:programId/learner-list/:id/task-list/:lectureDetail/activitityDetail"
      element={<ActivityDetail />}
    />
    <Route
      path="program/:programId/learner-list/:id/task-list/:lectureDetail/activitityDetail/:activityId/:memberSectionId"
      element={<ActivityDetail />}
    />
    <Route
      path="program/:programId/learner-list/:id/quick-links/:lectureDetail/activitityDetail"
      element={<ActivityDetail />}
    />
    <Route
      path="program/:programId/learner-list/:id/quick-links/:lectureDetail/activitityDetail/:activityId/:memberSectionId"
      element={<ActivityDetail />}
    />
    <Route
      path="program/:programId/:backpage/:id"
      exact
      element={<LectureList />}
    />
  </>
);
