import axios from 'axios';
import ls from 'localstorage-slim';
import Log from 'lib/Log';
import { API_URL } from 'constants/config';
// enable global encryption
ls.config.encrypt = true;
export const saveObservation = async (payload) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token') || payload?.token}`,
        organisationid: ls.get('orgId'),
      },
    };
    return await axios.post(`${API_URL}/ls.get`, { ...payload }, headers);
  } catch (err) {
    Log.error(err);
  }
};

export const setStatusComplete = async ({ orgId, payload }) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: orgId,
    },
  };
  try {
    return await axios.post(`${API_URL}/admin/update/markAsComplete`, { ...payload }, headers);
  } catch (err) {
    Log.error(err);
  }
};

export const updateLastAssedAt = async ({ orgId, payload }) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: orgId,
    },
  };
  try {
    return await axios.post(`${API_URL}/apprentice/update/lastAssessedAt`, { ...payload }, headers);
  } catch (err) {
    Log.error(err);
  }
};

export const addEvidences = async (payload) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token') || payload?.token}`,
        organisationid: ls.get('orgId'),
      },
    };
    return await axios.post(`${API_URL}/apprentice/add/evidences`, payload, headers);
  } catch (err) {
    Log.error(err);
  }
};

export const addMemberActivityEvidences = async (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: ls.get('orgId'),
    },
  };
  try {
    return await axios.post(`${API_URL}/apprentice/OTJ/activity/addEvidence`, payload, headers);
  } catch (err) {
    Log.error(err);
  }
};

export const deleteEvidences = async (payload) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token') || payload?.token}`,

    organisationid: ls.get('orgId'),
  };
  try {
    return await axios.delete(`${API_URL}/apprentice/delete/evidence`, { headers, data: payload });
  } catch (err) {
    Log.error(err);
  }
};

export const addMemberActivityVideoEvidences = async (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: ls.get('orgId'),
    },
  };
  try {
    return await axios.post(`${API_URL}/apprentice/OTJ/activity/addVideoEvidence`, payload, headers);
  } catch (err) {
    Log.error(err);
  }
};

export const deleteMemberActivitiesVideoEvidence = async (payload) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token') || payload?.token}`,
    organisationid: ls.get('orgId'),
  };
  try {
    return await axios.delete(`${API_URL}/apprentice/OTJ/activity/removeVideoEvidence`, { headers, data: payload });
  } catch (err) {
    Log.error(err);
  }
};

export const deleteMemberActivitiesEvidences = async (payload) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token') || payload?.token}`,
    organisationid: ls.get('orgId'),
  };
  try {
    return await axios.delete(`${API_URL}/apprentice/OTJ/activity/removeEvidence`, { headers, data: payload });
  } catch (err) {
    Log.error(err);
  }
};

export const updateStatus = async ({ orgId, payload }) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: orgId,
    },
  };
  try {
    return await axios.post(`${API_URL}/apprentice/update/status`, { ...payload }, headers);
  } catch (err) {
    Log.error(err);
  }
};
