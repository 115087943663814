import { useState, useEffect } from 'react';
import { leftArrow, rightArrow, downArrow, feedbackIcon } from '../constants/icons';
import { learnerSidebarList } from '../constants/learnerSidebarList';
import LinkWrapper from './LinkWrapper';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import MobileSidebarItems from './MobileSidebarItems';
import { useSelector } from 'react-redux';
function LearnerMainSidebar() {
  const { t } = useTranslation();
  const location = useLocation();
  const totalDir = location.pathname.split('/').length;
  const lastDirName = location.pathname.split('/')[totalDir - 1];

  const [sidebarItems, setSidebarItems] = useState([]);
  const feedbacks = useSelector((state) => state?.feedbacks?.data);
  const [sidebarActive, setSidebarActive] = useState(false);
  const [moreOptionsActive, setMoreOptionsActive] = useState(false);
  const sections = useSelector((state) => state.sections.sections);
  const program = useSelector((state) => state?.memberProgram?.program);

  const onItemClick = (index, hasChildren, e) => {
    if (hasChildren) {
      e.preventDefault();
      let sbActive = false;
      const newItems = sidebarItems.map((item, i) => {
        if (i === index) {
          item.isActive = true;
          sbActive = true;
        } else {
          item.isActive = false;
        }
        return item;
      });
      setSidebarItems(newItems);
      setSidebarActive(sbActive);
    }
  };

  const getLang = async () => {
    let data = await learnerSidebarList(program);

    if (data) {
      data = data.filter((sidebar) => sidebar.isEnable);
      setSidebarItems(data);
    }
  };
  const onItemHideClick = (e) => {
    e.preventDefault();
    const newItems = sidebarItems.map((item, i) => {
      item.isActive = false;
      return item;
    });
    setSidebarItems(newItems);
    setSidebarActive(false);
  };

  const onMoreOptionsClick = (e) => {
    e.preventDefault();
    setMoreOptionsActive((active) => !active);
  };

  const childMapper = (child, j) => {
    if (child.type === 'divider') {
      return (
        <li
          key={j}
          className="divider"
        />
      );
    }

    const isActivePath = location.pathname === child.link || location.pathname.includes(child.link);

    return (
      <li
        key={j}
        className={isActivePath ? 'active' : ''}
      >
        <LinkWrapper
          isReact={child.isReact}
          href={child.link}
        >
          {child.text}
          {child.children && rightArrow}
        </LinkWrapper>
      </li>
    );
  };

  const listSidebarItems = sidebarItems.map((item, i) => {
    let childItems = null;
    let itemHasChildren = false;
    const isActivePath = location.pathname === item.link || location.pathname.includes(`${item.link}/`);

    if (item.children) {
      itemHasChildren = true;
      const moreOptions = item.children.filter((n) => n.isMoreOptions);
      const children = item.children.filter((n) => !n.isMoreOptions);
      childItems = children.map(childMapper);
      if (moreOptions.length) {
        const moreOptionsList = moreOptions.map(childMapper);
        childItems.push(
          <li
            key={childItems.length}
            className={moreOptionsActive ? 'dropdown-option dropdown-option-active' : 'dropdown-option'}
          >
            <a
              onClick={onMoreOptionsClick}
              href="#top"
            >
              More Options
              {downArrow}
            </a>
            <ul>{moreOptionsList}</ul>
          </li>,
        );
      }
    }

    if (item.type === 'divider') {
      return (
        <li
          key={i}
          className="divider"
        />
      );
    }

    return (
      <li
        key={i}
        className={item.isActive || isActivePath ? 'active' : ''}
      >
        <LinkWrapper
          href={item.link}
          isReact={item.isReact}
          onClick={onItemClick.bind(null, i, itemHasChildren)}
          className="is-active"
        >
          <div className="icon-wrap">
            {item.icon}
            <span className="sidebar-hidden-item text-truncate">{t(item.text)}</span>
          </div>
          <div className="sidebar-hidden-item">{item.rightIcon ? item.rightIcon : item.children ? rightArrow : null}</div>

          {item.text === 'Quick Access' && (
            <div className="feedback-sidebar-wrap feedback-sidebar-learner">
              <span
                className="feedback-badge"
                title={feedbacks?.totalNewFeedbacksCount}
              >
                {feedbacks?.totalNewFeedbacksCount > 0 && (
                  <>
                    <span className="feedback-icon">
                      {feedbackIcon} <span className="feedback-dot"></span>
                    </span>
                    <span className="feedback-sidebar-count">{feedbacks?.totalNewFeedbacksCount}</span>
                  </>
                )}
              </span>
            </div>
          )}
          {item.text === 'Resubmission' && (
            <div className="feedback-sidebar-wrap feedback-sidebar-learner">
              <span
                className="feedback-badge"
                title={feedbacks?.totalReSubmissions}
              >
                {feedbacks?.totalReSubmissions > 0 && (
                  <>
                    <span className="feedback-icon">
                      {feedbackIcon} <span className="feedback-dot"></span>
                    </span>
                    <span className="feedback-sidebar-count">{feedbacks?.totalReSubmissions}</span>
                  </>
                )}
              </span>
            </div>
          )}
        </LinkWrapper>
        {item.children && (
          <div className="inner-sidebar">
            <div className="fixed-sidebar-back">
              <a
                className="sidebar-back-nav"
                onClick={onItemHideClick}
                href="#top"
              >
                {leftArrow}
                <span
                  className="text-truncate"
                  title={t(item.text)}
                >
                  {t(item.text)}
                </span>
              </a>
              <span className="divider" />
            </div>
            <ul className="inner-sidebar-list">{childItems}</ul>
          </div>
        )}
      </li>
    );
  });
  useEffect(() => {
    // setSidebarItems

    if (program) getLang();
  }, [program]);
  return (
    <>
      <div className={sidebarActive ? 'sidebar learner-sidebar sidebar-active' : 'sidebar learner-sidebar'}>
        <ul className="sidebar-list">{listSidebarItems}</ul>
      </div>
    </>
  );
}

export default LearnerMainSidebar;
