import { memo } from 'react';
import { Form, Col } from 'react-myoneui';
function InputCheckbox({ data, mode, question_number }) {
  return (
    <>
      <Col
        className="py-2"
        // md={state?.col_width}
        name="formElement"
      >
        <Form.Label
          htmlFor={data?.title}
          className="form-builder-label"
        >
          {question_number}. {data?.title}
        </Form.Label>
        <ol
          type="a"
          className="ol-style"
        >
          {data?.options?.length > 0
            ? data.options.map((item, index) => {
                return (
                  <li
                    key={index}
                    className="align-items-center pt-2 "
                  >
                    <Form.Check
                      value=""
                      label={item.label}
                      name="checkbox"
                      type="checkbox"
                      className="ms-2"
                    />
                  </li>
                );
              })
            : ''}
        </ol>
      </Col>
    </>
  );
}
export default memo(InputCheckbox);
