import { useRef } from 'react';

const useFilter = (props, tableManager) => {
  const filterApi = useRef({}).current;

  filterApi.filter = props.filter;

  return filterApi;
};

export default useFilter;
