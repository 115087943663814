import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
};

export const apiSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setAPI: (state, action) => {
      return action.payload;
    },
    addAPI: (state, action) => {
      console.log(action.payload);
      return {
        ...state,
        data: [...state.data, action.payload],
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAPI, addAPI } = apiSlice.actions;

export default apiSlice.reducer;
