import * as React from 'react';
import { useState } from 'react';
import Offcanvas from 'react-myoneui/lib/Offcanvas';
import Card from 'react-myoneui/lib/Card';
import Button from 'react-myoneui/lib/Button';
import Form from 'react-myoneui/lib/Form';
import FloatingLabel from 'react-myoneui/lib/FloatingLabel';
import { Row, Col } from 'react-myoneui';
import 'react-quill/dist/quill.snow.css';
import TextEditor from '../../../../components/TextEditor';
import DateTimePicker from 'react-datetime-picker';
import { useSelector } from 'react-redux';
import { addTarget } from '../../../../api/target';
import { ConfirmBox } from '../../../../components/ConfirmBox';
import { setConfirmBoxStatus } from '../../../../redux/confirmBox';
import { useDispatch } from 'react-redux';
import { storeSections, setSelectedProgramComponent } from '../../../../redux';
import Spinner from 'react-myoneui/lib/Spinner';
import { useParams } from 'react-router-dom';
import { translate } from '../../../../lib';
import { formatToUTCDate } from '../../../../utils/formatTime';
import { setToastBoxStatus, setToastBoxDescription } from '../../../../redux/ToastBoxSlice';
import Log from '../../../../lib/Log';
import ls from 'localstorage-slim';
function AddTargetSelectedOffCanvas(props) {
  const taxonomy = useSelector((state) => state.taxonomy.data);
  const roles = JSON.parse(ls.get('roles'));
  const learnerName = roles.filter((role) => role.identifier === 'apprentice')[0].name.toLowerCase();
  const { programId: member_program_id } = useParams();
  const [load, setLoad] = useState(false);
  const [activeClass, setActiveClass] = useState('');
  const [validationError, setValidationError] = useState('');
  const [targetExist, setTargetExist] = useState(false);
  const [targetExistIndex, setTargetExistIndex] = useState(null);
  const sections = useSelector((state) => state.sections);
  const [progressEditable, setProgressEditable] = useState(true);
  const { active_first, active_second, active_third, active_column } = sections;
  const confirmBoxStatus = useSelector((state) => state?.confirmBox?.status);
  const dispatch = useDispatch();

  let active_member_section = useSelector((state) => state.sections.active_component);
  const id = useSelector((state) => state.sections.active_component.id);
  const targets = useSelector((state) => state?.sections?.active_component?.targets);
  let sectionClone = {};
  if (sections.sections.length > 0) {
    sectionClone = JSON.parse(JSON.stringify(sections.sections));
    // if (active_column === 3) {
    //   active_member_section = sectionClone[active_first].child_sections[active_second].child_sections[active_third].member_section;
    // } else if (active_column === 2) {
    //   active_member_section = sectionClone[active_first].child_sections[active_second].member_section;
    // } else if (active_column === 1) {
    //   active_member_section = sectionClone[active_first].member_section;
    // }
  }
  Log.info('active_member_section', active_member_section);

  const { show, onClose, component_type } = props;

  React.useEffect(() => {
    if (show) {
      if (component_type === 'ResourceComponent' || component_type === 'UnitComponent') {
        setTarget({ ...target, progress: '100' });
        setProgressEditable(false);
      } else {
        setProgressEditable(true);
      }
    } else {
      setTarget({
        date: '',
        progress: '',
        description: '',
      });
    }
  }, [component_type, show]);

  const offCanvasKeyDown = (e) => e.stopPropagation();

  const [target, setTarget] = useState({
    date: '',
    progress: '',
    description: '',
  });

  const [error, setError] = useState({
    target: {
      error: false,
      msg: '',
    },
  });

  const [clicked, setClicked] = useState({
    progress: false,
    description: false,
    date: false,
  });

  const invalidNumbers = ['e', 'E', '.', '+', '-'];

  const { date, progress, description } = target;
  const isFormValid = date !== '' && progress !== '' && !error.target.error && description.length > 8;

  const handleClose = () => {
    setTargetExist(false);
    setTargetExistIndex(null);

    // clear state
    setTarget({
      date: '',
      progress: '',
      description: '',
    });
    setValidationError('');
    setClicked({ progress: false, description: false, date: false });
    setLoad(false);
    onClose();
  };

  const updateTarget = async () => {
    dispatch(setConfirmBoxStatus(false));
    const finalTarget = { member_program_id, date: new Date(formatToUTCDate(target.date)), progress: target.progress, description: target.description };
    const payload = { member_section_id: id, ...finalTarget };
    try {
      Log.info(payload);
      const res = await addTarget(payload);
      if (res.data.error) {
        setValidationError(res.data.message);
      } else {
        let resultData = { ...res.data.data };
        let existingTargets = JSON.parse(JSON.stringify(targets));

        if (targetExist) {
          const existingTargetIndex = existingTargets.indexOf(existingTargets.filter((target) => target._id === resultData._id)[0]);
          existingTargets.splice(existingTargetIndex, 1, resultData);
        } else {
          existingTargets.push(resultData);
        }
        let selectedComponentClone = JSON.parse(JSON.stringify(active_member_section));
        selectedComponentClone.targets = existingTargets;
        dispatch(setSelectedProgramComponent(selectedComponentClone));
        setTargetExist(false);
        setTargetExistIndex(null);
        dispatch(setToastBoxStatus(true));
        dispatch(setToastBoxDescription(error?.data?.message ? error?.data?.message : `Successfully added ${translate(taxonomy)('target')}`));
        onClose();
        // clear state
        setTarget({
          date: '',
          progress: '',
          description: '',
        });
        setValidationError('');
        setClicked({ progress: false, description: false, date: false });
      }
    } catch (err) {
      Log.info('Error >> ', err);
      setValidationError('Something happens wrong');
    } finally {
      setLoad(false);
    }
  };

  const addNewTarget = async () => {
    setValidationError('');
    setLoad(true);
    if (targetExist) {
      // popup box
      dispatch(setConfirmBoxStatus(true));
    } else {
      updateTarget();
    }
  };

  const checkTargetDate = (value) => {
    try {
      setTargetExist(false);
      setTarget({ ...target, date: value });

      if (targets && targets.length > 0) {
        const dates = targets.map((target) => new Date(target.date).getTime());
        const currentDate = new Date(formatToUTCDate(value)).getTime();
        if (dates.includes(currentDate)) {
          setTargetExist(true);
          setTargetExistIndex(dates.indexOf(currentDate));
        }
      }
    } catch (error) {
      Log.info(error);
    }
  };

  const checkProgress = (value) => {
    Log.info('Value >> ', value === '');
    setTarget({ ...target, progress: value });
    if (value !== '' && value < 1) {
      setError({ ...error, target: { error: true, msg: "Progress can't be less than 1%" } });
    } else if (value > 100) {
      setError({ ...error, target: { error: true, msg: "Progress can't be greater than 100%" } });
    } else {
      setError({ ...error, target: { error: false, msg: '' } });
    }
  };

  const checkDescription = (value) => {
    setTarget({ ...target, description: value?.trim() });
  };

  const handleChange = (component, value) => {
    switch (component) {
      case 'date':
        checkTargetDate(value);
        break;
      case 'progress':
        checkProgress(value);
        break;
      case 'description':
        checkDescription(value);
        break;
      default:
    }
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        onKeyDown={offCanvasKeyDown}
      >
        {confirmBoxStatus && (
          <ConfirmBox
            show={confirmBoxStatus}
            question={`Are you sure you want to update this ${translate(taxonomy)('target')}`}
            okText="Yes"
            cancelText="No"
            onHide={() => {
              setLoad(false);
              dispatch(setConfirmBoxStatus(false));
            }}
            onOk={updateTarget}
          />
        )}
        <Offcanvas.Header
          closeButton
          backButton
          className="pb-0"
        >
          <Offcanvas.Title>
            Add <br />
            {`${translate(taxonomy)('target')}`}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <p className="help-text fs-12 mt-0">
            {`${translate(taxonomy)('target')}s are Milestones for the ${learnerName} in ${
              active_member_section?.section?.title
            } . Please considere these as progresses ${learnerName} should achieve by certain dates.`}{' '}
          </p>
          <Row>
            <Col
              lg={6}
              className="floating-icon-wrap"
            >
              <FloatingLabel
                className="mb-4 target-end-date-label"
                controlId="targetSartEndDate"
                label={`${translate(taxonomy)('target')} End date`}
              >
                <Form.Control
                  type="text"
                  placeholder="Target start &amp; End date"
                />
                <DateTimePicker
                  className={`remove-time-wrap ${activeClass}`}
                  clearIcon={null}
                  placeholder="Target start &amp; End date"
                  value={target.date}
                  minDate={new Date()}
                  onChange={(value) => handleChange('date', value)}
                  onBlur={() => {
                    setActiveClass('');
                    setClicked({ ...clicked, date: true });
                  }}
                  onFocus={() => {
                    setActiveClass('target-date-focus');
                    setClicked({ ...clicked, date: false });
                  }}
                />
              </FloatingLabel>
              {clicked.date && target.date === '' && (
                <div>
                  <span
                    className="invalid-feedback mb-4"
                    style={{ display: 'block' }}
                  >
                    {` ${translate(taxonomy)('target')} Date is required`}
                  </span>
                </div>
              )}
              {targetExist && (
                <div>
                  <span
                    className="valid-feedback mb-4"
                    style={{ display: 'block' }}
                  >
                    {`Current ${translate(taxonomy)('target')} date has been already exist`}
                  </span>
                </div>
              )}
            </Col>
            <Col lg={6}>
              <FloatingLabel
                className="mb-4"
                controlId="targetPercentage"
                label={`${translate(taxonomy)('target')} Percentage %`}
              >
                <Form.Control
                  type="number"
                  max="100"
                  min="1"
                  readOnly={!progressEditable}
                  placeholder="Target Percentage %"
                  value={target.progress}
                  onKeyDown={(e) => progressEditable && invalidNumbers.includes(e.key) && e.preventDefault()}
                  onChange={(e) => progressEditable && handleChange('progress', e.target.value)}
                  onBlur={() => {
                    progressEditable && setClicked({ ...clicked, progress: true });
                  }}
                  onFocus={() => {
                    progressEditable && setClicked({ ...clicked, progress: false });
                  }}
                />
              </FloatingLabel>
              {error.target.error && (
                <div>
                  <span
                    className="invalid-feedback mb-4"
                    style={{ display: 'block' }}
                  >
                    {error.target.msg}
                  </span>
                </div>
              )}
              {clicked.progress && target.progress.trim() === '' && (
                <div>
                  <span
                    className="invalid-feedback mb-4"
                    style={{ display: 'block' }}
                  >
                    {`${translate(taxonomy)('target')} is required`}
                  </span>
                </div>
              )}
            </Col>
          </Row>

          <Card className="react-quill-editor">
            <TextEditor
              placeholder={`${translate(taxonomy)('target')} Description`}
              className="myeditor"
              theme="snow"
              value={target.description?.trim()}
              onChange={(value) => handleChange('description', value)}
              onFocus={() => setClicked({ ...clicked, description: false })}
              onBlur={() => {
                setClicked({ ...clicked, description: true });
              }}
            />
          </Card>
          {clicked.description && target.description.length === 8 && (
            <div>
              <span
                className="invalid-feedback mb-4"
                style={{ display: 'block' }}
              >
                <h3>Description is required</h3>
              </span>
            </div>
          )}
          {validationError.trim().length > 1 && (
            <div>
              <span
                className="invalid-feedback mb-4"
                style={{ display: 'block' }}
              >
                <h3>{validationError}</h3>
              </span>
            </div>
          )}
        </Offcanvas.Body>
        <Offcanvas.Footer>
          <Button
            variant="default"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            disabled={load || !isFormValid}
            variant="primary"
            onClick={addNewTarget}
          >
            {load ? (
              <>
                <div className="spinner-align-center">
                  <Spinner
                    animation="border"
                    size="sm"
                  />{' '}
                  {`Add ${translate(taxonomy)('target')}`}
                </div>
              </>
            ) : (
              `Add ${translate(taxonomy)('target')}`
            )}
          </Button>
        </Offcanvas.Footer>
      </Offcanvas>
    </>
  );
}

export default AddTargetSelectedOffCanvas;
