import { React, useState, useEffect } from 'react';
import { forwardRef, Children } from 'react';
import Dropdown from 'react-myoneui/lib/Dropdown';
import { userIcon, binIcon, dotsIcon, externalLinkIcon, downloadFileIcon } from '../../../../constants/icons';
import { setConfirmBoxStatus, setConfirmBoxActiveId } from '../../../../redux/confirmBox';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { decryptData } from '../../../../lib';

import { setActiveMemberProgramId, setToastBoxDescription, setToastBoxStatus } from '../../../../redux';
import ls from 'localstorage-slim';
import { translate } from '../../../../lib';
import { getOrgSetting } from 'api';
import Log from 'lib/Log';
import { releaseCertificateToMemberProgram } from 'api/program';
import { downloadProgram } from 'api/learner/program';
import { Toast } from 'react-myoneui';
ls.config.encrypt = true;
// The forwardRef is important!!
// Dropdown needs accimport AssignedProgram from './AssignedProgram';

const CustomToggle = forwardRef(({ children, onClick }, ref) => (
  <div
    role="button"
    tabIndex={0}
    ref={ref}
    className="btn btn-icon rounded-circle"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it

const CustomMenu = forwardRef(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
  const [value, setValue] = useState('');

  return (
    <div
      ref={ref}
      style={style}
      className={className}
      aria-labelledby={labeledBy}
    >
      <ul className="list-unstyled mb-0">{Children.toArray(children).filter((child) => !value || child.props.children.toLowerCase().startsWith(value))}</ul>
    </div>
  );
});

function AssignedProgramDropdown({ memberProgram }) {
  const { memberProgramId, member_name, member_email, wage_meter, refecth } = memberProgram;
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const activeRole = JSON.parse(ls.get('activeRole'));
  const { id } = useParams();
  const dispatch = useDispatch();
  const role = ls.get('role');
  const token = ls.get('token');
  const pathRole = pathname.split('/')[1];
  const isAssessor = role === 'assessor' && pathRole === 'assessor' && activeRole.identifier === 'assessor';
  const isTutor = role === 'tutor' && pathRole === 'tutor' && activeRole.identifier === 'tutor';
  const isAdmin = role === 'admin' && pathRole === 'admin' && activeRole.identifier === 'admin';

  const taxonomy = useSelector((state) => state.taxonomy.data);
  const [enable_journey_worker_rate, setEnable_journey_worker_rate] = useState();
  const orgSetting = (payload) => {
    getOrgSetting(payload)
      .then((result) => {
        if (result?.data?.data) {
          setEnable_journey_worker_rate(result?.data?.data.enable_journey_worker_rate);
          console.log('organization data', result?.data?.data);
        }
      })
      .catch((error) => Log.error(error));
  };
  console.log(ls.get('orgId'));
  useEffect(() => {
    orgSetting({ orgId: ls.get('orgId'), token });
  }, []);

  // const activeMemberProgramId = useSelector((state) => state?.confirmBox?.activeId);

  const deleteHandler = () => {
    dispatch(setConfirmBoxActiveId(memberProgramId));
    dispatch(setConfirmBoxStatus(true));
  };

  const viewProgramDetail = () => {
    navigate(`program/${memberProgramId}/lecture-list`, { state: { member_name, member_email } });
  };
  const addAssessorLink = () => {
    if (['iqv', 'eqv'].includes(activeRole?.identifier)) {
      navigate(`/${role}/apprentices/view-profile/${id}/program/${memberProgramId}/assessors`);
      return;
    }
    navigate(`/${role}/users/apprentices/view-profile/${id}/program/${memberProgramId}/assessors`);
  };

  const downloadCertificate = (url) => {
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadProgramFolder = async (memberProgramId) => {
    try {
      const response = await downloadProgram(memberProgramId);
      if (response?.status === 200) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        const blob = new Blob([response.data]);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = `${memberProgram?.name}-${memberProgram?.member_name}.zip`;
        a.click();
        window.URL.revokeObjectURL(url);
        dispatch(setToastBoxStatus(true));
        dispatch(setToastBoxDescription('Program Downloaded'));
      }
    } catch (error) {
      if (error?.data) {
        const message = await error?.data?.text();

        dispatch(setToastBoxStatus(true));
        dispatch(setToastBoxDescription(message?.message));
      }
    }
  };

  const releaseCertificate = async () => {
    try {
      const result = await releaseCertificateToMemberProgram(memberProgramId);
      if (result) {
        dispatch(setToastBoxDescription(result?.data?.message));
        dispatch(setToastBoxStatus(true));
        refecth();
      }
    } catch (error) {
      Log.error('Error', error);
    } finally {
    }
  };

  const roles = JSON.parse(ls.get('roles'));
  const existingIdentifiers = roles && roles.length > 0 && roles.map((role) => role.identifier);
  const checkIdentifier = existingIdentifiers?.includes('assessor') ? true : false;

  const canReleaseCertificate =
    (isAdmin || isAssessor || isTutor) &&
    activeRole?.privileges?.can_manage_apprentices &&
    memberProgram?.status === 'Completed' &&
    !memberProgram?.certificate_url &&
    memberProgram?.has_certificate &&
    memberProgram?.release_type === 'manual';

  return (
    <>
      <Dropdown
        className="dropdown-select position-static assigned-drop-alignment assigned-dropdown-list"
        align="end"
      >
        <Dropdown.Toggle
          as={CustomToggle}
          id="dropdown-custom-components"
        >
          {dotsIcon}
        </Dropdown.Toggle>
        <Dropdown.Menu
          as={CustomMenu}
          className="dropdown-menu mw-dropdown-default"
        >
          <Dropdown.Item
            eventKey="1"
            onClick={() => viewProgramDetail()}
          >
            {externalLinkIcon} <span title="View">View</span>
          </Dropdown.Item>
          {role === 'admin' && (
            <Dropdown.Item
              eventKey="2"
              onClick={deleteHandler}
            >
              {binIcon} <span title="Delete">Delete</span>
            </Dropdown.Item>
          )}
          {checkIdentifier === true && activeRole?.privileges?.can_view_assessor === true && (
            <Dropdown.Item
              eventKey="2"
              onClick={addAssessorLink}
            >
              {userIcon} <span title={roles.filter((role) => role.identifier === 'assessor')[0]?.name + 's' ?? ''}>{roles.filter((role) => role.identifier === 'assessor')[0]?.name + 's' ?? ''}</span>
            </Dropdown.Item>
          )}
          {enable_journey_worker_rate && wage_meter?.length > 0 && (
            <Dropdown.Item onClick={() => navigate(`/${role}/wage/${memberProgramId}`, { state: { memberProgram: JSON.stringify(memberProgram) } })}>
              {userIcon} <span>Wage Meter</span>
            </Dropdown.Item>
          )}
          {canReleaseCertificate && (
            <Dropdown.Item
              onClick={() => {
                releaseCertificate();
              }}
            >
              {userIcon} <span>Release Certificate</span>
            </Dropdown.Item>
          )}
          {memberProgram?.certificate_url && (
            <Dropdown.Item onClick={() => downloadCertificate(memberProgram?.certificate_url)}>
              {downloadFileIcon} <span> Download Certificate</span>
            </Dropdown.Item>
          )}

          {/* {memberProgram?.status === 'Completed' && (
            <Dropdown.Item onClick={() => downloadProgramFolder(memberProgramId)}>
              {downloadFileIcon} <span> Download Program</span>
            </Dropdown.Item>
          )} */}
        </Dropdown.Menu>
      </Dropdown>
      <Toast />
    </>
  );
}

export default AssignedProgramDropdown;
