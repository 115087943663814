import * as React from 'react';
import Offcanvas from 'react-myoneui/lib/Offcanvas';
import Card from 'react-myoneui/lib/Card';
import Button from 'react-myoneui/lib/Button';
import Form from 'react-myoneui/lib/Form';
import FloatingLabel from 'react-myoneui/lib/FloatingLabel';
import DropdownCollection from '../../../components/DropdownCollection';
import { addIcon } from '../../../constants/icons';
import { useState } from 'react';
import { createNewActivity } from '../../../api/offTheJob';
import DateTimePicker from 'react-datetime-picker';
import { formatToUTCDate } from '../../../utils/formatTime';

import { useSelector } from 'react-redux';
import { translate } from '../../../lib';
import { clearFormIcon } from '../../../constants/icons';

import Spinner from 'react-myoneui/lib/Spinner';
import { useParams } from 'react-router-dom';
import ls from 'localstorage-slim';
import { decryptData } from '../../../lib';
import Log from '../../../lib/Log';
ls.config.encrypt = true;
function AddNewActivityOffCanvas({ otj_id, member_section_id, onClose, show, activities, onSave, updateStatus, canCreateNewActivity, displayAdminActivity, isValidRole }) {
  const handleClose = (clear) => {
    try {
      setLoad(false);
      setSelectedActivity({});
      setLocation('');
      setDate();
      setTitle('');
      setAddTitle(false);
      if (clear !== true) {
        onClose();
      }
    } catch (error) {
      Log.error(error);
    }
  };
  const offCanvasKeyDown = (e) => e.stopPropagation();
  const [selectedActivity, setSelectedActivity] = useState({});
  const [activeClass, setActiveClass] = useState('');
  const [date, setDate] = useState();
  const [location, setLocation] = useState('');
  const taxonomy = useSelector((state) => state.taxonomy.data);
  const memberProgram = useSelector((state) => state.memberProgram);
  const { id } = useParams();
  const member = isValidRole ? decryptData(id) : ls.get('userid');
  const [load, setLoad] = useState(false);
  const [addTitle, setAddTitle] = useState(false);
  const [title, setTitle] = useState('');
  const createActivity = async () => {
    try {
      setLoad(true);
      const payload = { member_section_id, member, otj: otj_id, date: new Date(formatToUTCDate(date)), member_program: memberProgram._id, location, title, activity: selectedActivity.id };
      const res = await createNewActivity(payload);

      if (res) {
        setLoad(false);
        if (res?.data?.data?.memberSection && Object.entries(res.data.data.memberSection).length > 0) {
          updateStatus(res.data.data.memberSection);
        }
        if (res?.data?.data?.memberActivity?.activity) {
          const activity = activities.filter((activity) => activity.id === res.data.data.memberActivity.activity)[0];
          onSave({ ...res.data.data.memberActivity, activity: activity.name });
        } else {
          onSave(res.data.data.memberActivity);
        }
        handleClose();

        if (Object.entries(res.data.data.memberProgram).length > 0) {
          updateStatus(res.data.data.memberProgram);
        }
      }
    } catch (err) {
      Log.error('Error >> ', err);
    }
  };

  const isValid = () => {
    return load || (Object.entries(selectedActivity).length === 0 && title.trim() === '') || location.trim() === '' || date === undefined;
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        onKeyDown={offCanvasKeyDown}
      >
        <Offcanvas.Header
          closeButton
          backButton
          className="pb-0"
        >
          <Offcanvas.Title>
            Add <br /> New {translate(taxonomy)('activity')}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <p className="help-text w-75 fw-bold fs-12 mt-0">Duis9 aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
          <div className="mb-3">
            {displayAdminActivity && (
              <DropdownCollection
                disable={title.trim() !== '' || activities.length < 1}
                defaultValue={selectedActivity}
                items={activities}
                label={'Choose Activity'}
                onChange={(obj) => setSelectedActivity(obj)}
              />
            )}

            {canCreateNewActivity && (
              <div className="card-link-wrap mt-3">
                <Card.Link
                  onClick={() => setAddTitle(true)}
                  disabled={Object.entries(selectedActivity).length > 1}
                  className="text-primary cardLinkCursor"
                >
                  {addIcon}
                  Create New {translate(taxonomy)('activity')}
                </Card.Link>
              </div>
            )}
          </div>

          {addTitle && Object.entries(selectedActivity).length === 0 && (
            <FloatingLabel
              className="mb-4"
              controlId="activitityTitle"
              label="Title"
            >
              <Form.Control
                type="text"
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </FloatingLabel>
          )}
          <FloatingLabel
            className="mb-4 target-end-date-label"
            controlId="targetSartEndDate"
            label="Date"
          >
            <Form.Control
              type="text"
              placeholder="Date"
            />
            <DateTimePicker
              className={`remove-time-wrap ${activeClass}`}
              clearIcon={null}
              placeholder="Date"
              value={date}
              onChange={(value) => setDate(value)}
              onBlur={() => {
                setActiveClass('');
              }}
              onFocus={() => {
                setActiveClass('target-date-focus');
              }}
            />
          </FloatingLabel>
          <FloatingLabel
            className="mb-4"
            controlId="activitityLocation"
            label="Location"
          >
            <Form.Control
              type="text"
              placeholder="Location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
          </FloatingLabel>
          <div className="clear-form">
            <Button
              variant="default"
              onClick={() => handleClose(true)}
            >
              {clearFormIcon} Clear Form
            </Button>
          </div>
          {/* <FloatingLabel className="mb-4" controlId="activititySupervisor" label="Supervisor">
            <Form.Control type="number" placeholder="Supervisor" />
          </FloatingLabel> */}
        </Offcanvas.Body>
        <Offcanvas.Footer className="gap-1">
          <div className="d-flex flex-wrap justify-content-end gap-3">
            <Button
              variant="default"
              onClick={handleClose}
              className="p-0"
            >
              Cancel
            </Button>
            {/* <Button variant="glitter" onClick={() => handleClose(true)}>
              Reset
            </Button> */}
            <Button
              disabled={isValid()}
              variant="primary"
              onClick={createActivity}
            >
              {load ? (
                <>
                  <div className="spinner-align-center">
                    <Spinner
                      animation="border"
                      size="sm"
                    />
                    Add {translate(taxonomy)('activity')}
                  </div>
                </>
              ) : (
                `Add ${translate(taxonomy)('activity')}`
              )}
            </Button>
          </div>
        </Offcanvas.Footer>
      </Offcanvas>
    </>
  );
}

export default AddNewActivityOffCanvas;
