import React, { useEffect, useState } from 'react';

import Offcanvas from 'react-myoneui/lib/Offcanvas';
import Button from 'react-myoneui/lib/Button';
import { useSelector } from 'react-redux';

import { translate } from '../../lib';
import SectionBlock from './SectionBlock';
import MultipleComponentList from './MultipleComponentList';
import { getSectionChilds } from '../../api';
import { parse } from 'date-fns';
import { stringify } from 'postcss';
import { data } from 'autoprefixer';
import Spinner from 'react-myoneui/lib/Spinner';
import Log from '../../lib/Log';
function MultipleComponentOffCanvas(props) {
  const taxonomy = useSelector((state) => state.taxonomy.data);

  const { show, onClose, memberProgramId, memberSectionId, onEvidence } = props;
  const [state, setState] = useState({
    load: false,
    childs: [],
    validate: false,
    all: [],
  });
  const { childs, all } = state;
  const offCanvasKeyDown = (e) => e.stopPropagation();

  const handleClose = () => {
    setState({ ...state, load: false });
    onClose();
  };
  const fetchSectionChilds = async () => {
    try {
      setState({
        ...state,
        load: 'loading',
        childs: [],
      });

      let payload = { member_program: memberProgramId, member_section_id: memberSectionId };
      let response = await getSectionChilds(payload);
      if (response) {
        setState({
          ...state,
          childs: response.data.data,
          load: true,
        });
      }
    } catch (error) {
      setState({
        ...state,
        childs: [],
        load: true,
      });
      Log.error(error);
    }
  };
  const ownComponentHandler = (e) => {
    try {
      /* This is handle First Level Components only */
      let cloneChilds = JSON.parse(JSON.stringify(state.childs));
      let cloneComponents = cloneChilds.components;
      let obj = cloneComponents.find((item) => item.memberSectionId === e.target.value);
      let index = cloneComponents.indexOf(obj);
      obj.status = e.target.checked;
      cloneComponents[index] = obj;
      cloneChilds.components = cloneComponents;
      setState({
        ...state,
        childs: cloneChilds,
      });
    } catch (error) {
      Log.error(error);
    }
  };
  const toggleSelectAll = (e, data) => {
    /*
    This is will update status accordig to the checkbox status and 
    update on 1st level components only
    */
    let label = e.currentTarget.dataset.label;
    if (label === '1') {
      let components = JSON.parse(JSON.stringify(state.childs.components));
      components = components.map((item) => {
        return { ...item, status: e.target.checked };
      });
      let cloneChilds = JSON.parse(JSON.stringify(state.childs));
      cloneChilds.components = components;
      setState({ ...state, childs: cloneChilds });
    } else if (label === '2') {
      let childs = { ...state.childs };
      let section = childs.subSections.find((item) => item.memberSectionId === data.memberSectionId);
      let index = childs.subSections.indexOf(section);
      childs.subSections[index] = data;
      setState({ ...state, childs: childs });
      // setState({ ...state, childs: data });
    }
  };
  const sectionChange = (e, data) => {
    let cloneChilds = JSON.parse(JSON.stringify(state.childs));
    let subSections = cloneChilds.subSections;
    let section = subSections.find((item) => item.memberSectionId === e.currentTarget.dataset.id);
    let index = subSections.indexOf(section);

    subSections[index] = data;
    setState({ ...state, childs: cloneChilds });
  };
  const sectionComponentChange = (e, data) => {
    let cloneChilds = JSON.parse(JSON.stringify(state.childs));
    let subSections = cloneChilds.subSections;

    let section = subSections.find((item) => item.memberSectionId === data.memberSectionId);
    let sectionIndex = subSections.indexOf(section);
    subSections[sectionIndex] = data;
    setState({ ...state, childs: cloneChilds });

    // memberSectionId
  };
  const subSectionHandler = ({ data, id }) => {
    try {
      let cloneData = JSON.parse(JSON.stringify(data));
      cloneData.components = [
        ...cloneData.components.map((item) => {
          let obj = { ...item };
          obj.status = cloneData.status;
          return obj;
        }),
      ];
      let cloneChilds = JSON.parse(JSON.stringify(state.childs));
      let subSections = JSON.parse(JSON.stringify(state.childs.subSections));
      let section = subSections.find((item) => item.memberSectionId === id);
      let index = subSections.indexOf(section);
      subSections[index] = cloneData;
      cloneChilds.subSections = subSections;
      setState({ ...state, childs: cloneChilds });
    } catch (error) {
      Log.error(error);
    }
  };

  const sendEvidence = () => {
    // () => onEvidence(state.all)
    if (state.all.length > 0) {
      onEvidence(state.all);
    } else {
      onEvidence([memberSectionId]);
    }
  };
  const updateFirstLevelComponents = (e, obj) => {
    let childs = { ...state.childs };
    childs.components = obj;
    setState({ ...state, childs: childs });
  };
  const singleChangeHandler = (e, obj) => {
    let label = e.currentTarget.dataset.label;

    if (label === '1') {
      updateFirstLevelComponents(e, obj);
    }
  };
  useEffect(() => {
    // Checking atleast one selected eighter section or component to attach evidence
    // Components
    let status = [];
    state.childs.components &&
      state.childs.components.length > 0 &&
      state.childs.components.forEach((item) => {
        status.push({ id: item.memberSectionId, status: item?.status ? item?.status : false });
      });
    // Sections
    state.childs.subSections &&
      state.childs.subSections.length > 0 &&
      state.childs.subSections.forEach((item) => {
        status.push({ id: item.memberSectionId, status: item?.status ? item?.status : false });
      });
    // Section > components
    state.childs.subSections &&
      state.childs.subSections.length > 0 &&
      state.childs.subSections.forEach((section) => {
        section.components &&
          section.components.length > 0 &&
          section.components.forEach((item) => {
            status.push({ id: item.memberSectionId, status: item?.status ? item?.status : false });
          });
      });
    let selectedOnly = status.filter((item) => item.status === true);
    let hasSelected = status.filter((item) => item.status === true).length > 0;
    let all = selectedOnly.map((item) => {
      return item.id;
    });
    setState({ ...state, validate: hasSelected, all: all });
  }, [childs]);
  useEffect(() => {
    fetchSectionChilds();
  }, [memberProgramId, memberSectionId]);
  return (
    <>
      <Offcanvas
        className="multiple-component-offcanvas"
        show={show}
        onHide={handleClose}
        placement="end"
        onKeyDown={offCanvasKeyDown}
      >
        <Offcanvas.Header
          closeButton
          backButton
          className="pb-0"
        >
          <Offcanvas.Title>Add Evidence :</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <p className="help-text fs-12 lh-base mt-0">
            Users can upload evidence to every {translate(taxonomy)('subSection')} and {translate(taxonomy)('component')}s listed below. If no any {translate(taxonomy)('subSection')} and{' '}
            {translate(taxonomy)('component')}s are selected then the evidence will be attached to parent {translate(taxonomy)('section')} itself.
          </p>
          {state?.load === true &&
            state?.childs?.subSections &&
            state?.childs?.subSections.length > 0 &&
            state?.childs?.subSections.map((item) => (
              <SectionBlock
                label={1}
                key={item.memberSectionId}
                multiComponentTitle={item?.title}
                noSingleChange={singleChangeHandler}
                onChange={(data) => subSectionHandler({ data: data, id: item.memberSectionId })}
                data={item}
                onSelectAll={toggleSelectAll}
                onSelectionChange={sectionChange}
                onComponentChange={sectionComponentChange}
              />
            ))}
          {state?.load === true && state?.childs?.subSections.length === 0 && state?.childs?.components?.length === 0 && <div className="data-not-found"> No Data Found</div>}
          {state?.load === 'loading' && (
            <div className="spinner-wrapper">
              <Spinner animation="border" />
            </div>
          )}

          {state.load === true && state.childs.components && state.childs.components.length > 0 && (
            <MultipleComponentList
              label={1}
              noSingleChange={(e, data) => singleChangeHandler(e, data)}
              onChange={ownComponentHandler}
              onSelectAll={toggleSelectAll}
              list={state.childs.components}
            />
          )}
        </Offcanvas.Body>
        <Offcanvas.Footer>
          <div className="program-button-wrap">
            <Button
              variant="default"
              onClick={handleClose}
              className="p-0"
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="dark"
              onClick={sendEvidence}
            >
              Attach Evidence
            </Button>
          </div>
        </Offcanvas.Footer>
      </Offcanvas>
    </>
  );
}
/**
  <SectionBlock
                  label={1}
                  key={item.memberSectionId}
                  multiComponentTitle="Knowledge"
                  noSingleChange={singleChangeHandler}
                  onChange={(data) => subSectionHandler({ data: data, id: item.memberSectionId })}
                  data={item}
                />
*/
export default MultipleComponentOffCanvas;
