import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  total: 0,
};

export const OrganizationSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    setOranizationsData: (state, action) => {
      return {
        ...state,
        list: action.payload,
      };
    },
    setTotalOranizationsData: (state, action) => {
      return {
        ...state,
        total: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setOranizationsData, setTotalOranizationsData } = OrganizationSlice.actions;

export default OrganizationSlice.reducer;
