import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setTaxonomy } from '../redux';
import { getTaxonomy } from '../api';
import { LANG } from '../constants/config';
import ls from 'localstorage-slim';
import Log from '../lib/Log';
ls.config.encrypt = true;
const TaxonomyState = () => {
  const { orgId } = useParams();
  const organisationId = orgId || ls.get('orgId');
  const dispatch = useDispatch();
  const language = ls.get('lang') || LANG;
  const taxonomy = useSelector((state) => state.taxonomy.data);
  const setTaxonomyData = async () => {
    try {
      getTaxonomy({
        orgId: organisationId,
        lang: language,
      }).then((result) => {
        dispatch(setTaxonomy(result.data));
      });
    } catch (error) {
      Log.error(error);
    }
  };
  useEffect(() => {
    if (Object.keys(taxonomy).length < 1) {
      setTaxonomyData();
    }
  });

  return { ...taxonomy };
};
export default TaxonomyState;
