import { ProgressBar, ProgressValue, ProgressWrap } from 'react-myoneui';
import { deadlineIcon, playBtnIcon } from 'constants/icons';

export default function IntroCard(props) {
  const { title, imgSrc, deadlineText, subTitle, className } = props;
  return (
    <div className={`intro-card-wrapper ${className || ''}`}>
      {title && <h2>{title}</h2>}
      <div className="intro-card-holder">
        <div className="intro-card">
          <img
            loading="lazy"
            src={imgSrc}
            alt="Resume learning"
          />
          <img
            className="image-shadow"
            loading="lazy"
            src={imgSrc}
            alt="Resume learning"
          />
          <span
            role="button"
            className="play-icon"
          >
            {playBtnIcon}
          </span>
        </div>
        <div className="intro-card-info">
          <span className="intro-card-date">
            {deadlineIcon} {deadlineText}
          </span>
          {subTitle && <h4 title={subTitle}>{subTitle}</h4>}
          <ProgressWrap>
            <ProgressBar
              variant="success"
              now={80}
            />
            <ProgressValue ProgressPercentage={`${80}%`} />
          </ProgressWrap>
        </div>
      </div>
    </div>
  );
}
