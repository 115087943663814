import axios from 'axios';
import { API_URL } from '../constants/config';
import ls from 'localstorage-slim';
import Log from 'lib/Log';

const FORM_BUILDER_BASE_API = `${API_URL}/admin/formComponent`;

export const listForms = async (payload) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        organisationid: ls.get('orgId'),
      },
    };

    return await axios.get(`${FORM_BUILDER_BASE_API}/list/form-components`, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const getFormComponent = async (id) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        organisationid: ls.get('orgId'),
      },
    };

    return await axios.get(`${FORM_BUILDER_BASE_API}/form/${id}`, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const getFormComponentDetails = async (id) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        organisationid: ls.get('orgId'),
      },
    };

    return await axios.get(`${FORM_BUILDER_BASE_API}/form-details/${id}`, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const editFormComponent = async (payload) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        organisationid: ls.get('orgId'),
      },
    };

    return await axios.patch(`${FORM_BUILDER_BASE_API}/update/form-component`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

// save form as  template
export const saveFormComponentTemplate = async (payload) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        organisationid: ls.get('orgId'),
      },
    };

    return await axios.post(`${FORM_BUILDER_BASE_API}/create-form-component-template`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

// get all form template
export const getAllSavedTemplate = async () => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        organisationid: ls.get('orgId'),
      },
    };

    return await axios.get(`${FORM_BUILDER_BASE_API}/list/form-component-templates`, headers);
  } catch (err) {
    throw new Error(err);
  }
};

// clone save template at new form
export const cloneSaveTemplate = async (payload) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        organisationid: ls.get('orgId'),
      },
    };

    return await axios.post(`${FORM_BUILDER_BASE_API}/clone-from-template`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const editFormSection = async (payload) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        organisationid: ls.get('orgId'),
      },
    };
    /*
    {

      "_id":"64b8c52ccc7917986f39297e",
      "heading":"section heading updatesssss",
      "sub_heading":"updated sub heading",
      "name":"section",
      "title":"Section",
      "parent_id":"64b79747af67f12ba21ff778"
    }
    */
    return await axios.patch(`${FORM_BUILDER_BASE_API}/update/form-section`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const editFormSubSection = async (payload) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        organisationid: ls.get('orgId'),
      },
    };
    /*
    {

      "_id":"64b8c52ccc7917986f39297e",
      "heading":"section heading updatesssss",
      "sub_heading":"updated sub heading",
      "name":"section",
      "title":"Section",
      "parent_id":"64b79747af67f12ba21ff778"
    }
    */
    return await axios.patch(`${FORM_BUILDER_BASE_API}/update/form-sub-section`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const editFormField = async (payload) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        organisationid: ls.get('orgId'),
      },
    };

    return await axios.patch(`${FORM_BUILDER_BASE_API}/update/form-field`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const createFormComponent = async (payload) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        organisationid: ls.get('orgId'),
      },
    };

    return await axios.post(`${FORM_BUILDER_BASE_API}/create`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const createFormSection = async (payload) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        organisationid: ls.get('orgId'),
      },
    };
    /*
    payload: {
    "heading":"section heading 24",
    "sub_heading":"sub heading 34",
    "parent_id":"64b79747af67f12ba21ff778",
    "name":"section",
    "title":"Section"
    }
    */
    return await axios.post(`${FORM_BUILDER_BASE_API}/create/form-section`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

// api to clone form section sub-section component
export const cloneFormSection = async (payload) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        organisationid: ls.get('orgId'),
      },
    };

    return await axios.post(`${FORM_BUILDER_BASE_API}/duplicate/form-section`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const cloneFormSubSection = async (payload) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        organisationid: ls.get('orgId'),
      },
    };

    return await axios.post(`${FORM_BUILDER_BASE_API}/duplicate/form-sub-sub-section`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const cloneFormField = async (payload) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        organisationid: ls.get('orgId'),
      },
    };

    return await axios.post(`${FORM_BUILDER_BASE_API}/duplicate/form-field`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const createFormSubSection = async (payload) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        organisationid: ls.get('orgId'),
      },
    };
    /*
    payload:{
    "heading":" sub section heading 12345",
    "sub_heading":" sub section sub heading 1",
    "parent_id":"64ae7bae22183a84f337121c",
    "name":"sub section",
    "title":"Sub Section"
    }
    */
    return await axios.post(`${FORM_BUILDER_BASE_API}/create/form-sub-section`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const createFormField = async (payload) => {
  try {
    /*
    payload: {
    "label":"label",
    "help_text":"this is a help text",
    "field_type":"text",
    "editable_by":[],
    "col_width":12,
    "parent_id":"64b8d302cc7917986f392e4b",
    "name":"form-element",
    "title":"Textarea Title",
    "description":"des",
   "placeholder:"placeholder"

}
    */
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        organisationid: ls.get('orgId'),
      },
    };

    return await axios.post(`${FORM_BUILDER_BASE_API}/create/form-field`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const removeFormComponent = async (payload) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };
  try {
    return await axios.delete(`${FORM_BUILDER_BASE_API}/remove/form`, { headers, data: payload });
  } catch (err) {
    Log.error(err);
  }
};
export const removeSubSection = async (payload) => {
  // payload:{sub_section_id:""}
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };
  try {
    return await axios.delete(`${FORM_BUILDER_BASE_API}/remove/form-sub-section`, { headers, data: payload });
  } catch (err) {
    Log.error(err);
  }
};
export const removeSection = async (payload) => {
  // payload:{section_id:""}
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };
  try {
    return await axios.delete(`${FORM_BUILDER_BASE_API}/remove/form-section`, { headers, data: payload });
  } catch (err) {
    Log.error(err);
  }
};
export const removeFormField = async (payload) => {
  // payload:{field_id:""}
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };
  try {
    return await axios.delete(`${FORM_BUILDER_BASE_API}/remove/form-field`, { headers, data: payload });
  } catch (err) {
    Log.error(err);
  }
};
export const getFormElements = async (payload) => {
  const headers = {
    headers: {
      organisationid: ls.get('orgId'),
      Authorization: `Bearer ${ls.get('token')}`,
    },
  };
  try {
    return await axios.get(`${FORM_BUILDER_BASE_API}/list/${payload.subSectionId}/form-field`, headers);
  } catch (err) {
    Log.error(err);
  }
};
export const getSections = async (payload) => {
  const headers = {
    headers: {
      organisationid: ls.get('orgId'),
      Authorization: `Bearer ${ls.get('token')}`,
    },
  };
  try {
    return await axios.get(`${FORM_BUILDER_BASE_API}/list/${payload.formId}/form-section`, headers);
  } catch (err) {
    Log.error(err);
  }
};
export const getSubSections = async (payload) => {
  const headers = {
    headers: {
      organisationid: ls.get('orgId'),
      Authorization: `Bearer ${ls.get('token')}`,
    },
  };
  try {
    return await axios.get(`${FORM_BUILDER_BASE_API}/list/${payload.sectionId}/form-sub-section`, headers);
  } catch (err) {
    Log.error(err);
  }
};

export const insertSection = async (payload) => {
  try {
    /*
   payload: {

    "destination_index":12,
    "section_id":"64c0c49b6b1e92fa9e6e90de",
    "parent_id":"64b79747af67f12ba21ff778"  

}
}
    */
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        organisationid: ls.get('orgId'),
      },
    };

    return await axios.post(`${FORM_BUILDER_BASE_API}/order/form-section`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const insertSubSection = async (payload) => {
  try {
    /*
   payload: {

    "destination_index":12,
    "sub_section_id":"64c0c49b6b1e92fa9e6e90de",
    "parent_id":"64b79747af67f12ba21ff778"  

}
}
    */
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        organisationid: ls.get('orgId'),
      },
    };

    return await axios.post(`${FORM_BUILDER_BASE_API}/order/form-sub-section`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};
export const insertFormField = async (payload) => {
  try {
    /*
   payload: {

    "destination_index":12,
    "sub_section_id":"64c0c49b6b1e92fa9e6e90de",
    "parent_id":"64b79747af67f12ba21ff778"  

}
}
    */
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        organisationid: ls.get('orgId'),
      },
    };

    return await axios.post(`${FORM_BUILDER_BASE_API}/order/form-field`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};
