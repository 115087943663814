import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const MemberProgramSlice = createSlice({
  name: 'memberProgram',
  initialState,
  reducers: {
    storeMemberProgram: (state, action) => {
      return {
        ...action.payload,
      };
    },
  },
});

export const memberProgram = (state) => state;
export const { storeMemberProgram } = MemberProgramSlice.actions;
export default MemberProgramSlice.reducer;
