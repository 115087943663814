import { useState, useRef, Children } from 'react';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { Card, Col, Row, Spinner } from 'react-myoneui';
import HorizontalNavLinkList from '../../components/HorizontalNavLinkList';
import LearnerMainSidebar from 'components/LearnerMainSidebar';
import SecondaryHeader from 'react-myoneui/lib/SecondaryHeader';
import Button from 'react-myoneui/lib/Button';
import GridTable from 'utils/react-grid-table';
import { getTaxonomy, unAassignAssessorsProgram, getUser, getAllFeedBacks } from 'api';
import { getProgramDetail } from 'api/program';
import SecondaryHeaderSearchField from 'react-myoneui/lib/SecondaryHeaderSearchField';
import FullNameTemplate from './assignAssessors/grid-table/FullNameTemplate';
import EmailTemplate from './assignAssessors/grid-table/EmailTemplate';
import LastAccessTemplate from './assignAssessors/grid-table/LastAccessTemplate';
import ContextMenuTemplate from './assignAssessors/grid-table/ContextMenuTemplate';
import AssignAssessorsOffCanvas from './assignAssessors/AssignAssessorOffCanvas';
import { useDispatch, useSelector } from 'react-redux';
import PaginationTemplate from 'components/grid-table/PaginationTemplate';
import LearnerRolesFilterSidebar from '../../../admin/roles/LearnerRolesFilterSidebar';
import { useEffect } from 'react';
import { setSidebarData } from '../../../../reducers/sidebarSlice';
import { setTaxonomy, storeMemberProgram, setFeedbacks } from '../../../../redux';
import { getMemberProgramAssessors } from 'api/assessor/learner';
import DelegateFilterSidebar from 'components/filterSidebar/DelegateFilterSidebar';
import DataNotFound from 'components/DataNotFound';
import UserInfo from 'components/program/UserInfo';
import ls from 'localstorage-slim';
import { decryptData, encryptData } from 'lib';
import Log from 'lib/Log';
import { viewMemberProgram } from 'api/learner/program';
// enable global encryption
ls.config.encrypt = true;
function MemberAssessors() {
  const navigate = useNavigate();
  const taxonomy = useSelector((state) => state.taxonomy.data);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [isExpand, setIsExpand] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [assignStatus, setAssignStatus] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [userDetail, setUserDetail] = useState({});
  const roles = JSON.parse(ls.get('roles'));
  const assessorName = roles.filter((role) => role.identifier === 'assessor')[0]?.name + 's' ?? '';

  // for viewing from admin side
  const { pathname } = useLocation();
  const pathRole = pathname.split('/')[1];
  const role = ls.get('role');
  const activeRole = JSON.parse(ls.get('activeRole'));
  const isLearner = role === 'learner' && pathRole === 'learner' && activeRole.identifier === 'apprentice';
  const isAdmin = role === 'admin' && pathRole === 'admin' && activeRole.identifier === 'admin';

  const columnData = [
    {
      id: 'checkbox',
      pinned: true,
      className: 'selectAll',
      width: '55px',
      minResizeWidth: 0,
      maxResizeWidth: null,
      resizable: false,
      visible: isAdmin ? true : false,
    },
    {
      id: 1,
      field: 'full_name',
      visible: true,
      label: `${assessorName} Name`,
      width: '1fr',
      sortable: true,
      cellRenderer: FullNameTemplate,
    },
    {
      id: 2,
      field: 'email',
      visible: true,
      label: `Email ID`,
      width: '2fr',
      sortable: true,
      cellRenderer: EmailTemplate,
    },
    {
      id: 3,
      field: 'lastActive',
      visible: true,
      label: `Last Access`,
      width: '3fr',
      sortable: true,
      cellRenderer: LastAccessTemplate,
    },
  ];

  const learnerListCheck = 'learner-list' === pathname.split('/')[4];

  const { programId, id } = useParams();
  const tableManager = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [filter] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const memberProgram = useSelector((state) => state.memberProgram);

  // Sidebar Trigger Components Start
  const dispatch = useDispatch();

  const enableActiveWrapper = useSelector((state) => state.sidebar.enableActiveWrapper);

  const setTaxonomyData = async () => {
    try {
      let result = await getTaxonomy();
      dispatch(setTaxonomy({ ...taxonomy, ...result.data }));
    } catch (error) {
      Log.error(error);
    }
  };

  const setTableManager = (tm) => (tableManager.current = tm);

  const fetchAssessors = async (payloadData) => {
    try {
      const payload = {
        memberProgram: programId,
        ...payloadData,
      };

      setData([]);
      setTotalData(0);
      setLoading('loading');
      const { data } = await getMemberProgramAssessors(payload);

      if (data) {
        //dispatch(storeMemberProgram(data.data));

        setLoading(true);
        const members = data?.data?.members.map((member) => {
          return {
            id: member?.id,
            full_name: member?.first_name + ' ' + member?.last_name,
            email: member?.decrypted_email,
            lastActive: member?.lastActive,
            profile_image: member.profile_image,
            total_members: member.total_members,
            phone_country_code: member.phone_country_code,
            country: member.country,
            phone_no: member.phone_no,
            roles: member.roles.map((item) => roles.find((role) => role.id === item)),
            total_programs: member.total_programs,
            custom_fields: member.custom_fields ? JSON.parse(member.custom_fields) : [],
          };
        });
        setData(members);
        setTotalData(data.data.meta.totalMembers);
      }
    } catch (err) {
      setLoading(true);
      Log.error('Error >> ', err);
    }
  };
  const fetchMemberProgramInfo = async () => {
    try {
      let result = await viewMemberProgram({ memberProgramId: programId });
      dispatch(storeMemberProgram({ ...result?.data?.data }));
    } catch (error) {
      Log.error(error);
    }
  };

  useEffect(() => {
    setTaxonomyData();
    if (!isLearner) {
      if (!enableActiveWrapper) {
        setTimeout(() => {
          dispatch(setSidebarData({ enableActiveWrapper: true }));
        }, 1);
      }
    }
  }, [enableActiveWrapper]);

  useEffect(() => {
    if (!isLearner) {
      dispatch(setSidebarData({ enableActiveWrapper: true }));

      return () => {
        dispatch(setSidebarData({ enableActiveWrapper: false }));
      };
    }
  }, [dispatch, !isLearner]);

  useEffect(() => {
    dispatch(storeMemberProgram(null));
    fetchAssessors();
    fetchUserDetail();
    fetchAllFeedbacks();
    fetchMemberProgramInfo();
  }, [programId]);

  const fetchUserDetail = async () => {
    let result = await getUser(decryptData(id));
    Log.info(result);
    setUserDetail(result.data.member);
  };
  const showPage = (page) => {
    try {
      setCurrentPage(page);

      fetchAssessors({
        currentPage: page,
        perPage: perPage,
      });
    } catch (err) {
      Log.error(err);
    }
  };
  const fetchAllFeedbacks = async () => {
    try {
      let result = await getAllFeedBacks({ member_program: programId });

      dispatch(setFeedbacks({ ...result.data.data }));
    } catch (error) {
      Log.error(error);
    }
  };
  const showPageSize = (pageSize) => {
    try {
      setCurrentPage(1);
      setPageSize(pageSize);
      let payload = {
        pageSize: pageSize,
        currentPage: 1,
      };
      fetchAssessors(payload);
    } catch (err) {
      Log.error(err);
    }
  };
  const applyFilter = (data) => {
    try {
      Log.info('appyFilter ....', data);
    } catch (error) {
      Log.error(error);
    }
  };
  const assignedHandler = () => {
    try {
      setAssignStatus(false);
      Log.info('fetchAssessors');
      fetchAssessors();
    } catch (error) {
      Log.error(error);
    }
  };
  const unAssignUsers = async () => {
    try {
      Log.info('UnassignUsers');
      Log.info(selectedRows);

      let payload = {
        memberProgram: [programId],
        assessors: [...selectedRows],
      };
      setLoading('loading');
      setData([]);
      setTotalData(0);
      let result = await unAassignAssessorsProgram(payload);
      if (result) fetchAssessors();
      // unAassignAssessorsProgram();
    } catch (error) {
      Log.error(error);
      setLoading(true);
    }
  };
  const searchAssessors = (e) => {
    try {
      setSearchText(e.target.value);
      fetchAssessors({
        search: e.target.value,
      });
    } catch (err) {
      Log.error(err);
    }
  };
  const clearSearch = () => {
    try {
      setSearchText('');
      fetchAssessors();
    } catch (err) {
      Log.error(err);
    }
  };
  return (
    <>
      {isLearner && (
        <>
          <HorizontalNavLinkList navKey="link-2" />
          <SecondaryHeader>
            <div className="program-title-ellipsis d-flex justify-content-end w-100">
              <div className="secondary-header-wrap">
                <div className="info-wrap">
                  <h3 title={memberProgram?.program?.name}>{memberProgram?.program?.name}</h3>
                </div>
              </div>
            </div>
          </SecondaryHeader>
          <LearnerMainSidebar />
        </>
      )}

      <div className="content-wrapper">
        {!isLearner && (
          <>
            <>
              <SecondaryHeader>
                {userDetail?.id && <UserInfo data={userDetail} />}
                <SecondaryHeaderSearchField
                  placeholder={`Search ${roles.filter((role) => role.identifier === 'assessor')[0]?.name + 's' ?? ''}.....`}
                  value={searchText}
                  onChange={searchAssessors}
                  onClear={clearSearch}
                />

                {isAdmin && (
                  <div className="button-wrap">
                    <Button
                      variant="secondary"
                      onClick={() => setAssignStatus(true)}
                    >
                      {`Assign ${roles.filter((role) => role.identifier === 'assessor')[0]?.name + 's' ?? ''}`}
                    </Button>
                  </div>
                )}
              </SecondaryHeader>
              {learnerListCheck ? <DelegateFilterSidebar /> : <LearnerRolesFilterSidebar />}
            </>
          </>
        )}

        <div className="content-block bg-transparent p-0">
          <div className={`assign-tutor-block ${isLearner ? 'justify-content-end' : ''}`}>
            <div className="program-title-ellipsis d-flex justify-content-end w-100">
              {!isLearner && (
                <h2
                  className="program-list-title"
                  title={memberProgram?.program?.name}
                >
                  {memberProgram?.program?.name}
                </h2>
              )}
            </div>
          </div>
          <Row>
            <Col lg={isExpand ? 4 : 12}>
              {loading === 'loading' && (
                <div className="spinner-wrapper">
                  <Spinner animation="border" />
                </div>
              )}

              <Card className="border-0">
                {data && data.length > 0 && totalData > 0 ? (
                  <GridTable
                    className="program-grid rgt-border-line-none ellipsis-text-grid"
                    searchText={searchText}
                    highlightSearch={false}
                    columns={columnData}
                    onRowsRequest={() => {
                      return {
                        rows: data,
                        totalRows: totalData,
                      };
                    }}
                    page={currentPage}
                    pageSize={pageSize}
                    onSelectedRowsChange={(a) => setSelectedRows(a)}
                    onPageChange={(page) => showPage(page)}
                    onPageSizeChange={(size) => showPageSize(size)}
                    // onRowClick={(obj) => rowClickHandler(obj)}
                    filter={filter}
                    onLoad={setTableManager}
                    components={{
                      Footer: PaginationTemplate,
                      ContextMenu: ContextMenuTemplate({
                        unAssignUsers: unAssignUsers,
                        assessorName: assessorName,
                      }),
                    }}
                  />
                ) : (
                  loading === true && totalData === 0 && <DataNotFound title={roles.filter((role) => role.identifier === 'assessor')[0]?.name + 's' ?? ''} />
                )}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      {assignStatus && (
        <AssignAssessorsOffCanvas
          show={assignStatus}
          name="Assessors"
          program={programId}
          onClose={(data) => {
            setAssignStatus(false);
            data && fetchAssessors();
          }}
          onAssigned={assignedHandler}
        />
      )}
    </>
  );
}
export default MemberAssessors;
