import axios from 'axios';
import { API_URL } from '../constants/config';
import ls from 'localstorage-slim';

// enable global encryption
ls.config.encrypt = true;

export const createProgramReviewComponent = ({ orgId, ...payload }) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: orgId,
    },
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(`${API_URL}/admin/program/review/component/create`, payload, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const updateProgramReviewComponent = ({ orgId, ...payload }) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: orgId,
    },
  };
  return new Promise((resolve, reject) => {
    return axios
      .patch(`${API_URL}/admin/program/review/component/update`, payload, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const memberProgramReview = ({ orgId, ...payload }) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: orgId,
    },
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(`${API_URL}/apprentice/add/memberProgramReview`, payload, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const fetchMemberReview = async (payload) => {
  try {
    const { member_component, member_program, ...params } = { ...payload };
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };

    return await axios.post(`${API_URL}/apprentice/list/memberProgramReviews`, { member_component, member_program }, { headers, params });
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteMemberReview = async (data) => {
  const { orgId, id } = data;
  const headers = {
    headers: {
      organisationid: orgId,
      Authorization: `Bearer ${ls.get('token')}`,
    },
  };
  try {
    return await axios.delete(`${API_URL}/apprentice/remove/memberProgramReview/${id}`, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const editReview = ({ id, orgId, ...payload }) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: orgId,
    },
  };
  return new Promise((resolve, reject) => {
    return axios
      .patch(`${API_URL}/apprentice/update/memberProgramReview/${id}`, payload, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const createUnitBankReviewComponent = async (data) => {
  const { orgId, ...payload } = data;
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: orgId,
    },
  };
  try {
    return await axios.post(`${API_URL}/admin/sectionBank/create/reviewComponent`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const updateUnitBankReviewComponent = async (data) => {
  try {
    const { orgId, ...payload } = data;
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token') || payload?.token}`,
        organisationid: orgId,
      },
    };

    return await axios.patch(`${API_URL}/admin/sectionBank/update/reviewComponent`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};
