import Button from 'react-myoneui/lib/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import { Badge } from 'react-myoneui';
import { binIcon, editIcon } from 'constants/icons';
import ls from 'localstorage-slim';
import { addLog } from 'api/log';
import Log from '../../lib/Log';

function ReviewDetailList(props) {
  const navigate = useNavigate();

  const { reviewId, title, location, name, schedule, viewButton, handleClick, onDeleteReview, onEditReview, onViewReview, onHandleMesgBox, reviewedId, status, sectionId } = props;
  const { pathname } = useLocation();
  const pathRole = pathname.split('/')[1];

  const role = ls.get('role');
  const activeRole = JSON.parse(ls.get('activeRole'));

  const isLearner = role === 'learner' && pathRole === 'learner' && activeRole.identifier === 'apprentice';
  const isIQV = role === 'iqv' && pathRole === 'iqv' && activeRole.identifier === 'iqv';
  const isEQV = role === 'eqv' && pathRole === 'eqv' && activeRole.identifier === 'eqv';
  const isAdmin = role === 'admin' && pathRole === 'admin' && activeRole.identifier === 'admin';
  const isAssessor = role === 'assessor' && pathRole === 'assessor' && activeRole.identifier === 'assessor';
  const isTutor = role === 'tutor' && pathRole === 'tutor' && activeRole.identifier === 'tutor';
  const { can_manage_apprentices } = activeRole?.privileges;

  const userId = ls.get('userid');
  const ownId = userId === reviewedId;

  const handleDelete = () => {
    onDeleteReview();
    if (onHandleMesgBox) {
      onHandleMesgBox();
    }
  };

  const handleEdit = () => {
    if (onHandleMesgBox) {
      onHandleMesgBox();
    }
    onEditReview();
  };
  async function handleView() {
    if (onHandleMesgBox) {
      onHandleMesgBox();
    }
    try {
      await addLog({
        model: sectionId,
        model_name: 'MemberSection',
        from: 'single_review',
      });
    } catch (err) {
      Log.error(err);
    }
    onViewReview();
  }

  return (
    <div
      className={`activities-detail-list ${props?.className || ''}`}
      onClick={() => handleClick && handleClick(reviewId)}
    >
      <>
        <div className="activities-detail-info activities-detail-info-width">
          {title && <h4>{title}</h4>}
          {location && <span>{location}</span>}
          {name && <span>{name}</span>}
        </div>
        <div className="activities-detail-info">
          <h4></h4>
          {schedule && <span>Date: {schedule}</span>}
        </div>
        {/* <div className="activities-detail-info">
          <h4>Total Hours</h4>
          {<span>dfnjk</span>}
        </div> */}
      </>

      {/* {(status === true || status === false) && (
        <div className="activities-detail-info">
          <Badge className={status === true ? 'badge' : 'badge disapproved-btn'}>{status === true ? 'Approved' : 'Disapproved'}</Badge>
        </div>
      )} */}
      {/* {status === null && <div className="empty-badge"></div>} */}
      <div className="d-flex align-items-center gap-3">
        <Button
          variant="information-white-bg"
          className="information-white-bg btn-sm btn-round"
          onClick={() => handleView()}
        >
          {viewButton}
        </Button>

        {ownId && !isLearner && !isIQV && !isEQV && (isTutor || isAssessor || isAdmin) && can_manage_apprentices === true && status !== 'Completed' && (
          <>
            <Button
              className="hour-remove-btn"
              title="Remove"
              onClick={() => {
                handleDelete();
              }}
            >
              {binIcon}
            </Button>
            <Button
              className="edit-hour-wrap edit-btn"
              title="Edit"
              onClick={() => {
                handleEdit();
              }}
            >
              {editIcon}
            </Button>
          </>
        )}
      </div>
    </div>
  );
}
export default ReviewDetailList;
