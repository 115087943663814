import { memo, forwardRef, useEffect, useState } from 'react';
import SecondaryHeader from 'react-myoneui/lib/SecondaryHeader';
import SecondaryHeaderInfo from 'react-myoneui/lib/SecondaryHeaderInfo';
import { Spinner } from 'react-myoneui';
import PreviewContent from 'components/FormBuilder/previewContent';
import Button from 'react-myoneui/lib/Button';
import { saveFormComponentTemplate } from 'api/formBuilder';
import Log from 'lib/Log';

// Icons
import { previewContentIcon } from 'constants/icons';
import { setSidebarData } from 'reducers/sidebarSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { setToastBoxStatus, setToastBoxDescription } from 'redux/ToastBoxSlice';
import ToastBox from 'components/ToastBox';
import { InputPopUpBox } from 'components/InputPopUpBox';
function PreviewFormBuilder() {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const dispatch = useDispatch();
  const { formId } = useParams();
  const navigate = useNavigate();
  const formName = useSelector((state) => state?.formbuilder?.form?.name);

  // save as draft api
  const saveAsTemplate = async (e) => {
    try {
      e.preventDefault();
      let payload = {
        form_component_id: formId,
        name: templateName,
      };
      setLoading(true);
      const result = await saveFormComponentTemplate(payload);
      Log.info(result);
      if (result?.data?.message === 'Form component template successfully Created') {
        dispatch(setToastBoxStatus(true));
        dispatch(setToastBoxDescription('Successfully Updated'));
        setTemplateName('');
        setShow(false);
        // setLoading(false);
      }
    } catch (error) {
      Log.error('Error on edit Form Component Handler', error);
      // setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = forwardRef(({ children, onClick }, ref) => (
    <Button
      role="button"
      tabIndex={0}
      ref={ref}
      variant="secondary"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Button>
  ));

  return (
    <>
      <ToastBox />
      <InputPopUpBox
        show={show}
        loading={loading}
        title={'Template Name'}
        inputField={true}
        inputLabel={'Template Name'}
        inputPlaceholder={''}
        value={templateName}
        disabled={!templateName}
        onChange={(e) => setTemplateName(e.target.value)}
        onOk={saveAsTemplate}
        okText={'Save'}
        onHide={() => setShow(false)}
        cancelText={'Close'}
      />
      <SecondaryHeader>
        <div className="secondary-header-wrap">
          <SecondaryHeaderInfo
            SecondaryHeaderTitle={formName ? formName : ''}
            SecondaryHeaderText=""
            SecondaryText
          />
        </div>
        <div className="header-close-wrap res-close-wrap">
          <div className="button-wrap">
            <Button
              variant="outline"
              className="dark"
              onClick={() => setShow(true)}
            >
              Save As Template
            </Button>
            <Button
              variant="text-icon"
              onClick={() => navigate(`/admin/form-builder/${formId}/details`)}
              className="text-icon"
            >
              Close Preview Content
              {previewContentIcon}
            </Button>
          </div>
        </div>
      </SecondaryHeader>
      <div className="form-builder-wrapper">
        <div className="form-builder-content">
          <div className=" personal-details-form">
            <div className="section-grid-wrapper">
              <div className="section-grid-holder">
                <PreviewContent />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default memo(PreviewFormBuilder);
