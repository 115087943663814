// export const API_URL = APP_URL ? APP_URL : 'https://api.sandbox.portfolio.mykademy.com';
import ls from 'localstorage-slim';
ls.config.encrypt = true;
export const API_URL = process.env.REACT_APP_API_URL;
ls.set('lang', 'en');
export const LANG = process.env.REACT_APP_API_LANG ? process.env.REACT_APP_API_LANG : 'en';
export const secretkey = process.env.REACT_APP_SECRETKEY;
export const redirectKey = process.env.REACT_APP_REDIRECT_KEY;
export const notification_url = process.env.REACT_APP_NOTIFICATION_SIGNALR_URL;
export const bucket_name = process.env.REACT_APP_BUCKET_NAME;
export const dms_base_url = process.env.REACT_APP_DMS_BASE_URL;
export const availableLanguages = [
  { label: 'EN', fullLabel: 'English', value: 'en' },
  { label: 'FR', fullLabel: 'French', value: 'fr' },
];
