import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
};

export const FeedbackSlice = createSlice({
  name: 'feedbacks',
  initialState,
  reducers: {
    setFeedbacks: (state, action) => {
      return {
        ...state,
        data: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setFeedbacks } = FeedbackSlice.actions;

export default FeedbackSlice.reducer;
