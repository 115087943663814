import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import Offcanvas from 'react-myoneui/lib/Offcanvas';
import Button from 'react-myoneui/lib/Button';
import Form from 'react-myoneui/lib/Form';
import { Row, Col, OffcanvasFooter } from 'react-myoneui';
import FloatingLabel from 'react-myoneui/lib/FloatingLabel';
import Log from 'lib/Log';
import DropdownCommon from 'components/DropdownCommon';
import { editIcon, addIcon, removeIcon } from 'constants/icons';
import { convertTimeToSecs, covertSecsToTimeData } from 'lib/common';
import { setMinutes } from 'date-fns';
function VideoTagOffCanvas(props) {
  const { onSave, onClose, allTags, oldTags } = props;
  const [mode, setMode] = useState('add');
  const offCanvasKeyDown = (e) => e.stopPropagation();
  const generateId = () => {
    return new Date().getTime();
  };
  const [showHrs, setShowHrs] = useState(false);
  const [showMins, setShowMins] = useState(false);
  const [showSecs, setShowSecs] = useState(false);
  const [defaultHrs, setDefaultHrs] = useState(null);
  const [defaultMins, setDefaultMins] = useState(null);
  const [defaultSecs, setDefaultSecs] = useState(null);
  const [validate, setValidate] = useState(false);
  const [tags, setTags] = useState([]);
  const [msg, setMsg] = useState({
    status: false,
    text: '',
  });
  const [tag, setTag] = useState({
    tag: '',
    hrs: '00',
    mins: '00',
    secs: '00',
    id: null,
  });
  const hrs = () => {
    let i = 0;
    let data = [];
    while (i < 24) {
      let txt = i < 10 ? '0' + i : i.toString();
      data.push({ id: i + 1, name: txt });
      i++;
    }
    return data;
  };
  const mins = () => {
    let i = 0;
    let data = [];
    while (i < 60) {
      let txt = i < 10 ? '0' + i : i.toString();
      data.push({ id: i + 1, name: txt });
      i++;
    }
    return data;
  };
  const secs = () => {
    let i = 0;
    let data = [];
    while (i < 60) {
      let txt = i < 10 ? '0' + i : i.toString();
      data.push({ id: i + 1, name: txt });
      i++;
    }
    return data;
  };
  const changeHrs = (data) => {
    setTag({
      ...tag,
      hrs: data.name,
    });
  };

  const changeMins = (data) => {
    setTag({
      ...tag,
      mins: data.name,
    });
  };
  const changeSecs = (data) => {
    setTag({
      ...tag,
      secs: data.name,
    });
  };
  const updateTag = () => {
    try {
      if (!isDuplicate(tag)) {
        let tagsClone = JSON.parse(JSON.stringify(tags));
        let obj = tagsClone.find((item) => item.id === tag.id);
        let index = tagsClone.indexOf(obj);
        tagsClone[index] = tag;
        setTags(tagsClone);
        setMode('add');
        defaultForm();
        setMsg({
          status: false,
          text: '',
        });
      } else {
        setMsg({
          status: true,
          text: 'Sorry, duplicate time is mentioned',
        });
      }
    } catch (error) {
      Log.error('Error VideoTagOffCanvas/updateTag:', error);
    }
  };
  const implementTag = () => {
    if (mode === 'add') {
      addTag();
    } else {
      updateTag();
    }
  };
  const defaultForm = () => {
    setTag({ tag: '', hrs: '00', mins: '00', secs: '00', id: generateId() });
    setShowHrs(false);
    setShowMins(false);
    setShowSecs(false);
    setDefaultHrs(hrs()[0]);
    setDefaultMins(mins()[0]);
    setDefaultSecs(secs()[0]);
    setTimeout(() => {
      setShowHrs(true);
      setShowMins(true);
      setShowSecs(true);
    }, 500);
  };
  const isDuplicate = (data) => {
    var time = convertTimeToSecs(data.hrs, data.mins, data.secs);
    var thisTimes = tags.map((item) => {
      return { tag: item.tag, time: convertTimeToSecs(item.hrs, item.mins, item.secs) };
    });
    var combineTags = [...oldTags, ...thisTimes];
    return combineTags.filter((item) => item.time === time).length > 0;

    // convertTimeToSecs
  };
  const addTag = () => {
    if (!isDuplicate(tag)) {
      setTags([...tags, { ...JSON.parse(JSON.stringify(tag)), id: generateId() }]);
      defaultForm();
      setMsg({
        status: false,
        text: '',
      });
    } else {
      setMsg({
        status: true,
        text: 'Sorry, duplicate time is mentioned',
      });
    }
  };
  const convertTime = (tags) => {
    try {
      let data = tags.map((item) => {
        return { tag: item.tag, time: convertTimeToSecs(item.hrs, item.mins, item.secs), id: item.id };
      });
      return data;
    } catch (error) {
      Log.error('Error on convertTime');
    }
  };
  const submit = () => {
    try {
      let totaltime = convertTimeToSecs(tag.hrs, tag.mins, tag.secs);
      let floatTag = { ...JSON.parse(JSON.stringify(tag)) };
      if (tag?.tag?.length > 0 && totaltime > 0) {
        if (isDuplicate(floatTag)) {
          setMsg({
            status: true,
            text: 'Sorry, duplicate time is mentioned',
          });
        } else {
          onSave(convertTime([...tags, { ...floatTag }]));
          onClose();
          setMsg({
            status: false,
            text: '',
          });
        }
      } else {
        onSave(convertTime([...tags]));
        onClose();
        setMsg({
          status: false,
          text: '',
        });
      }
    } catch (error) {
      Log.error('Error on convertTime');
    }
  };
  const convertTimeToData = (allTags) => {
    try {
      let uniqueId = generateId();
      let i = 0;
      let convertedTags = [];
      while (i < allTags?.length) {
        let timesData = covertSecsToTimeData(allTags[i].time);
        let obj = { tag: allTags[i].tag, ...timesData, id: uniqueId };
        convertedTags.push(obj);
        uniqueId++;

        i++;
      }
      setTags(convertedTags);
    } catch (error) {
      Log.error('Error on convertTimeToData :', convertTimeToData);
    }
  };
  const removeTag = (id) => {
    setTags(tags.filter((item) => item.id !== id));
    setMode('add');
  };
  const editTag = (data) => {
    try {
      setTag({ ...tag, tag: data.tag, id: data.id });
      let hrObj = hrs().find((item) => item.name === data.hrs.toString());
      setShowHrs(false);
      setDefaultHrs(hrObj);
      let minObj = mins().find((item) => item.name === data.mins.toString());
      setDefaultMins(minObj);
      setShowMins(false);
      let secObj = secs().find((item) => item.name === data.secs.toString());
      setDefaultSecs(secObj);
      setShowSecs(false);
      setMode('edit');
      setTimeout(() => {
        setShowHrs(true);
        setShowMins(true);
        setShowSecs(true);
      }, 500);
    } catch (error) {
      Log.error('Error on editTag :', error);
    }
  };

  /*
  changeEachHrs,changeEachMins,changeEachSecs
  */
  useEffect(() => {
    convertTimeToData(allTags);
  }, [allTags]);
  useEffect(() => {
    setShowHrs(true);
    setShowMins(true);
    setShowSecs(true);
    setDefaultHrs(hrs()[0]);
    setDefaultMins(mins()[0]);
    setDefaultSecs(secs()[0]);
    setTag({ ...tag, id: generateId() });
  }, []);
  useEffect(() => {
    if (tag?.tag?.length > 0 && convertTimeToSecs(tag.hrs, tag.mins, tag.secs) > 0) {
      console.log('Yes');
      setValidate(true);
    } else {
      console.log('Nupe');
      setValidate(false);
    }
  }, [tag]);
  return (
    <Offcanvas
      show={props.show}
      onHide={onClose}
      placement="end"
      onKeyDown={offCanvasKeyDown}
    >
      <Offcanvas.Header
        closeButton
        backButton
      >
        <Offcanvas.Title>
          Add Video Tag
          <p className="help-text w-75 fs-12">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <>
          <Row>
            <Col lg={12}>
              <FloatingLabel
                controlId="sampleTitle"
                label="Name"
                className="mb-3"
              >
                <Form.Control
                  name="name"
                  type="text"
                  value={tag?.tag}
                  placeholder="Put Tag Name"
                  onChange={(e) => setTag({ ...tag, tag: e.target.value })}
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col
              lg={4}
              className="mt-2"
            >
              {showHrs && (
                <DropdownCommon
                  autoComplete={false}
                  label="hrs"
                  items={hrs()}
                  defaultValue={defaultHrs}
                  onChange={changeHrs}
                />
              )}
            </Col>
            <Col
              lg={4}
              className="mt-2"
            >
              {showMins && (
                <DropdownCommon
                  defaultValue={defaultMins}
                  items={mins()}
                  label={'mins'}
                  onChange={changeMins}
                />
              )}
            </Col>
            <Col
              lg={4}
              className="mt-2"
            >
              {showSecs && (
                <DropdownCommon
                  label="secs"
                  className=""
                  items={secs()}
                  defaultValue={defaultSecs}
                  onChange={changeSecs}
                />
              )}
            </Col>
          </Row>
          {msg.status && (
            <Row>
              <Col className="p-3 text-danger">{msg.text}</Col>
            </Row>
          )}

          <div className="add-tag-btn">
            <Button
              disabled={validate === false}
              onClick={implementTag}
              variant="default"
            >
              {mode === 'add' ? addIcon : editIcon}
              {mode === 'add' ? 'Add' : 'Update'} Tag
            </Button>
          </div>
          {tags &&
            tags?.length > 0 &&
            tags.map((item) => (
              <ul
                key={item.id}
                className="video-tag-list"
              >
                <li>
                  {parseInt(item.hrs) > 0 && item.hrs + ':'}
                  {item.mins}:{item.secs} - {item.tag}
                  <div className="video-btn-wrap">
                    <Button
                      onClick={() => editTag(item)}
                      variant="outline-secondary"
                    >
                      {editIcon}
                    </Button>
                    <Button
                      onClick={() => removeTag(item.id)}
                      variant="outline-secondary"
                    >
                      {removeIcon}
                    </Button>
                  </div>
                </li>
              </ul>
            ))}
        </>
      </Offcanvas.Body>
      <OffcanvasFooter>
        <Button
          className="m-2"
          variant="secondary"
          onClick={submit}
        >
          Submit
        </Button>
      </OffcanvasFooter>
    </Offcanvas>
  );
}

export default React.memo(VideoTagOffCanvas);
