import React, { useEffect, useState } from 'react';
import DropdownCollection from '../DropdownCollection';
import DateTimePicker from 'react-datetime-picker';
import { Form } from 'react-myoneui';
import { useDispatch, useSelector } from 'react-redux';
import { displayFormatedDate } from '../../lib';
import { getComponentList, getTaxonomy } from '../../api';
import { setTaxonomy } from '../../redux';
import { LANG } from '../../constants/config';
import { translate } from '../../lib';
import Log from '../../lib/Log';
import { useParams } from 'react-router-dom';
import ls from 'localstorage-slim';

// enable global encryption
ls.config.encrypt = true;
function DeadLinesType(props) {
  const dispatch = useDispatch();
  const taxonomy = useSelector((state) => state.taxonomy.data);

  const { onChange, tabIndex, phase, mode, isFromSectionBank } = props;
  const { programId, orgId } = useParams();

  const deadlineDurationOptions = [
    { id: 1, value: 'enrolled_at', name: 'Enrolled At' },
    { id: 2, value: 'started_at', name: 'Started At' },
  ];

  const deadLineAfter = !isFromSectionBank
    ? [...deadlineDurationOptions, { id: 3, value: 'after_completed_component', name: `After Completed ${translate(taxonomy)('component')}` }]
    : deadlineDurationOptions;

  const organisationId = ls.get('orgId') || orgId;
  const deadlineTypeList = [
    { id: 1, name: 'No Deadline', value: 'no_deadline' },
    { id: 2, name: 'Interval', value: 'interval' },
    { id: 3, name: 'Duration', value: 'duration' },
  ];
  const [counter, setCounter] = useState(0);
  const [deadlineType, setDeadLineType] = useState(deadlineTypeList[0]);
  const [start_date, setStartDate] = useState(null);
  const [end_date, setEndDate] = useState(null);
  const [errorMsg, setErrorMsg] = useState('');

  /* Duration */
  const [duration, setDuration] = useState(null);
  const [previous_section, setPreviousSection] = useState(null);
  const [lock_after_duration, setLockAfterDuration] = useState(null);
  const [validate, setValidate] = useState(true);
  const [set_deadline_after, setDeadLineAfter] = useState(null);
  const [componentList, setComponentList] = useState(null);
  const sendData = { start_date, end_date, duration, set_deadline_after, previous_section, lock_after_duration, validate, deadline_type: deadlineType };
  const language = ls.get('lang') || LANG;

  const deadlineTypeHandler = (obj) => {
    try {
      setDeadLineType(obj);

      if (obj?.value === 'no_deadline') {
        setStartDate(null);
        setEndDate(null);
        setDuration(null);
        setPreviousSection(null);
        setLockAfterDuration(null);
        setDeadLineAfter(null);
        setComponentList(null);
        setErrorMsg('');
        setValidate(true);
        onChange({ ...sendData, deadline_type: obj?.value });
      }

      if (obj?.value === 'interval') {
        setStartDate(new Date());
        setEndDate(new Date());
        setDuration(null);
        setPreviousSection(null);
        setLockAfterDuration(null);
        setDeadLineAfter(null);
        setComponentList(null);
        setErrorMsg('');
        setValidate(true);
      }

      if (obj?.value === 'duration') {
        setStartDate(null);
        setEndDate(null);
        setDuration(1);
        setPreviousSection(null);
        setLockAfterDuration(false);
        setDeadLineAfter(deadLineAfter[0]);
        setComponentList(null);
        setErrorMsg('');
        setValidate(true);
      }
    } catch (error) {
      Log.error(error);
    }
    //onChange({ start_date:new Date(), end_date:new Date(), duration:, set_deadline_after, previous_section, lock_after_duration, validate, deadline_type: obj.value });
  };

  const setTaxonomyData = async () => {
    try {
      let result = await getTaxonomy({
        orgId: organisationId,
        lang: language,
      });
      dispatch(setTaxonomy(result.data));
    } catch (error) {
      Log.error(error);
    }
  };
  const startDateHandler = (val) => {
    try {
      setStartDate(new Date(val));
    } catch (error) {
      Log.error(error);
    }
  };
  const compareDate = (startDate, endDate, txt1, txt2) => {
    try {
      if (!startDate || !end_date) {
        return false;
      }
      /* Setting Second and Milliseconds zero */
      endDate.setSeconds(0);
      startDate.setSeconds(0);
      endDate.setMilliseconds(0);
      startDate.setMilliseconds(0);

      let isValid = new Date(startDate) <= new Date(endDate);
      if (!isValid) {
        setErrorMsg(`${txt2} must be greater than ${txt1}`);
      }
      return isValid;
    } catch (error) {
      Log.error(error);
    }
  };

  const intervalValidation = () => {
    try {
      let compareStartAndEndDate = compareDate(start_date, end_date, 'Start Date', 'End Date');
      let compareCurrentAndEndDate = compareDate(new Date(), end_date, 'Current Date', 'End Date');
      let compareCurrentAndStartDate = compareDate(new Date(), start_date, 'Current Date', 'Start Date');
      if (compareStartAndEndDate === false) {
        setValidate(false);
      } else if (compareCurrentAndEndDate === false) {
        // No need to compare on edit mode
        setValidate(props.mode === true ? true : false);
      } else if (compareCurrentAndStartDate === false) {
        setValidate(props.mode === true ? true : false);
      } else {
        setValidate(true);
        setErrorMsg('');
      }
    } catch (error) {
      Log.error(error);
    }
  };
  const durationValidation = () => {
    //  Log.error('durationValidation');
  };
  const endDateHandler = (val) => {
    try {
      setEndDate(new Date(val));
    } catch (error) {
      Log.error(error);
    }
  };
  const deadLineAfterHandler = (obj) => {
    try {
      setDeadLineAfter(obj);
      if (obj.value === 'after_completed_component') {
        loadComponents();
      } else {
        setComponentList([]);
      }
    } catch (error) {
      throw Log.error(error);
    }
  };
  const loadComponents = () => {
    try {
      getComponentList({
        programId,
        orgId: organisationId,
      })
        .then((result) => {
          let resultData = [...result?.data?.data];
          let componentData = resultData.map((item) => {
            return {
              id: item._id,
              name: item.title,
            };
          });
          // In Edition Section no need to show own component on the list
          if (props.id) {
            componentData = componentData.filter((item) => item.id !== props.id);
          }

          setComponentList(componentData);
        })
        .catch((error) => Log.error(error));
    } catch (error) {
      Log.error(error);
    }
  };
  const lockAfterDurationHandler = (e) => {
    setLockAfterDuration(e.target.checked);
  };
  const componentHandler = (obj) => {
    setPreviousSection(obj);
  };
  const durationHandler = (e) => {
    setDuration(e.target.value);
  };
  const displayDurationMsg = () => {
    try {
      let msg = '';
      if (duration > 0) {
        msg = `The  ${phase} duration will be of ${duration} ${duration > 1 ? 'days' : 'day'} .`;
      }
      if (duration > 0 && set_deadline_after && set_deadline_after.value === 'enrolled_at') {
        msg = `The  ${phase} can be accessed within ${duration} ${duration > 1 ? 'days' : 'day'} after learner is enrolled for the  ${phase}. The ${phase} will be accessible even after the deadline.`;
      }
      if (duration > 0 && set_deadline_after && set_deadline_after.value === 'started_at') {
        msg = `The  ${phase} can be accessed within ${duration} ${duration > 1 ? 'days' : 'day'}  after learner has started the  ${phase}.  The ${phase} will be accessible even after the deadline.`;
      }
      if (duration > 0 && set_deadline_after && set_deadline_after.value === 'after_completed_component') {
        msg = `The  ${phase} can be accessed until  ${duration} ${duration > 1 ? 'days' : 'day'} after the chosen  ${translate(taxonomy)(
          'component',
        )} is completed. The ${phase} will be accessible even after the deadline.`;
      }
      if (duration > 0 && set_deadline_after && set_deadline_after.value === 'after_completed_component' && previous_section) {
        msg = `The  ${phase} can be accessed until ${duration} ${duration > 1 ? 'days' : 'day'} after ${previous_section.name} is completed. The ${phase} will be accessible even after the deadline.`;
      }
      return <div className="react-datetime-text-wrap">{msg}</div>;
    } catch (error) {
      Log.error(error);
    }
  };

  useEffect(() => {
    /* It mode is add and edit no need to send validate status true */
    let sendValidate = props.mode === 'add' ? validate : true;
    onChange({ start_date, end_date, duration, set_deadline_after, previous_section, lock_after_duration, validate: sendValidate, deadline_type: deadlineType });
  }, [start_date, end_date, duration, set_deadline_after, previous_section, lock_after_duration, validate, deadlineType]);

  useEffect(() => {
    let data = {
      start_date,
      end_date,
      duration,
      set_deadline_after,
      previous_section,
      lock_after_duration,
    };
    if (deadlineType.value === 'interval') {
      intervalValidation();
    } else if (deadlineType.value === 'duration') {
      durationValidation();
    } else {
      setValidate(true);
    }
    setCounter(counter + 1);
  }, [deadlineType, start_date, end_date, duration, set_deadline_after, previous_section, lock_after_duration]);
  useEffect(() => {
    setDeadLineType(deadlineTypeList[0]);
  }, []);
  useEffect(() => {
    props.deadLineType && setDeadLineType(deadlineTypeList.find((item) => item.value === props.deadLineType));
    props.startDate && setStartDate(new Date(props.startDate));
    props.endDate && setEndDate(new Date(props.endDate));
    props.duration && setDuration(props.duration);
    props.lockAfterDuration && setLockAfterDuration(props.lockAfterDuration);
    props.setDeadLineAfter && setDeadLineAfter(deadLineAfter.find((item) => item.value === props.setDeadLineAfter));

    if (props.previousSection) {
      let previousSection = { id: props.previousSection.id, name: props.previousSection.title };
      setPreviousSection(previousSection);
    }
    // props.previousSection && setPreviousSection(props.previousSection);
    if (props.previousSection) {
      loadComponents();
    }
  }, [props.deadlineType, props.startDate, props.endDate, props.duration, props.lockAfterDuration, props.setDeadLineAfter, props.previouseSection]);
  const defaultComponent = () => {
    return previous_section && componentList.find((item) => item.id === previous_section);
  };

  return (
    <>
      {deadlineType && (
        <DropdownCollection
          defaultValue={deadlineType}
          className="mb-4"
          autoComplete={false}
          items={deadlineTypeList}
          label={'Deadline Type'}
          onChange={deadlineTypeHandler}
        />
      )}
      {deadlineType?.value === 'no_deadline' && <>{validate === false && props.mode === 'add' ? <div>{errorMsg}</div> : <div>{phase} can be accessed any time.</div>} </>}
      {deadlineType?.value === 'interval' && (
        <>
          <div className="react-datetime-wrapper">
            <div className="react-datetime-label-wrap">
              <p>Start Date</p>

              <DateTimePicker
                clearIcon={null}
                onChange={startDateHandler}
                value={new Date(start_date)}
              />
            </div>
            <div className="react-datetime-label-wrap">
              <p>End Date</p>
              <DateTimePicker
                clearIcon={null}
                onChange={endDateHandler}
                value={new Date(end_date)}
              />
            </div>
          </div>
          {validate === false && props.mode === 'add' ? (
            <div className="react-datetime-text-wrap">{errorMsg}</div>
          ) : (
            <div className="react-datetime-text-wrap mb-0">
              The {phase} can only be accessed in between {displayFormatedDate(start_date, 'startDate')} and {displayFormatedDate(end_date, 'endDate')}.
            </div>
          )}
        </>
      )}
      {deadlineType?.value === 'duration' && (
        <>
          <h4 className="phase-duration-title">{phase} Duration</h4>
          <div>
            <Form.Floating className="mb-3">
              <Form.Control
                tabindex={tabIndex}
                id="floatingInputCustom"
                type="text"
                value={duration}
                onChange={durationHandler}
              />
              <label htmlFor="floatingInputCustom">Days</label>
            </Form.Floating>
          </div>
          <div>
            <DropdownCollection
              className="mb-4"
              defaultValue={set_deadline_after}
              items={deadLineAfter}
              label={'Set Deadline After'}
              onChange={deadLineAfterHandler}
            />
          </div>

          {componentList && componentList?.length > 0 && (
            <div>
              <DropdownCollection
                className="mb-4"
                defaultValue={previous_section}
                items={componentList}
                label={'Choose Phase'}
                onChange={componentHandler}
              />
            </div>
          )}
          <Form.Check className="mb-2">
            <Form.Check.Input
              type="checkbox"
              id="flexCheckPercentage"
              checked={lock_after_duration}
              onChange={lockAfterDurationHandler}
            />
            <Form.Check.Label htmlFor="flexCheckPercentage">Lock After {phase} Duration</Form.Check.Label>
          </Form.Check>

          {validate === false && props.mode === 'add' ? <div className="react-datetime-text-wrap">{errorMsg}</div> : displayDurationMsg()}
        </>
      )}
    </>
  );
}
export default DeadLinesType;
