import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: '',
  name: '',
  description: '',
  banner_image_url: '',
  program_image_url: '',
  advantages: [],
  requirements: '',
  weightage: 0,
  progress: 0,
  cap_mark_after_deadlines: 0,
  cap_mark_for_resubmission: 0,
};

export const CreateProgramSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    setProgramId: (state, action) => {
      return {
        ...state,
        id: action.payload,
      };
    },
    setName: (state, action) => {
      return {
        ...state,
        name: action.payload,
      };
    },
    setDescription: (state, action) => {
      return {
        ...state,
        description: action.payload,
      };
    },
    setBannerImageUrl: (state, action) => {
      return {
        ...state,
        banner_image_url: action.payload,
      };
    },
    setProgramImageUrl: (state, action) => {
      return {
        ...state,
        program_image_url: action.payload,
      };
    },
    setAdvantages: (state, action) => {
      return {
        ...state,
        advantages: action.payload,
      };
    },
    setRequirements: (state, action) => {
      return {
        ...state,
        requirements: action.payload,
      };
    },
    setWeightage: (state, action) => {
      return {
        ...state,
        weightage: action.payload,
      };
    },
    setProgress: (state, action) => {
      return {
        ...state,
        progress: action.payload,
      };
    },
    setCapMarkAfterDeadlines: (state, action) => {
      return {
        ...state,
        cap_mark_after_deadlines: action.payload,
      };
    },
    setCapMarkAfterResubmission: (state, action) => {
      return {
        ...state,
        cap_mark_for_resubmission: action.payload,
      };
    },
    resetCreateProgram: (state) => {
      return {
        ...state,
        name: '',
        description: '<p></p>',
        banner_image_url: '',
        program_image_url: '',
        advantages: '',
        requirements: '',
        weightage: 0,
        progress: 0,
        cap_mark_after_deadlines: 0,
        cap_mark_for_resubmission: 0,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setProgramId,
  setName,
  setDescription,
  setBannerImageUrl,
  setProgramImageUrl,
  setAdvantages,
  setRequirements,
  setWeightage,
  setProgress,
  setCapMarkAfterDeadlines,
  setCapMarkAfterResubmission,
  resetCreateProgram,
} = CreateProgramSlice.actions;

export default CreateProgramSlice.reducer;
