import axios from 'axios';
import { API_URL } from '../constants/config';
import ls from 'localstorage-slim';

// enable global encryption
ls.config.encrypt = true;
export const addTarget = (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: ls.get('orgId'),
    },
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(`${API_URL}/apprentice/add/target`, payload, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const removeTarget = (payload) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };
  return new Promise((resolve, reject) => {
    return axios
      .delete(`${API_URL}/apprentice/remove/target`, { headers, data: payload })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getTargets = ({ member_id, ...payload }) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token') || payload?.token}`,
    organisationid: ls.get('orgId'),
  };
  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/apprentice/fetch/targets/${member_id}`, { headers, params: { ...payload } })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const addTargetReview = (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: ls.get('orgId'),
    },
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(`${API_URL}/apprentice/add/targetReview`, payload, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const fetchTargetReview = (target_id) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    },
  };
  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/apprentice/fetchReviews/${target_id}`, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};
