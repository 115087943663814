import { useState, useRef } from 'react';
import { Card } from 'react-myoneui';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import GridTable from '../../../../utils/react-grid-table';
import { ConfirmBox } from '../../../../components/ConfirmBox';
import CourseNameTemplate from './grid-table/CourseNameTemplate';
import StartDateTemplate from './grid-table/StartDateTemplate';
import LastActivityTemplate from './grid-table/LastActivityTemplate';
import { setConfirmBoxActiveId, setConfirmBoxStatus } from '../../../../redux/confirmBox';
import ScoreTextTemplate from './grid-table/ScoreTextTemplate';
import { translate } from '../../../../lib';
import CourseProgressTemplate from './grid-table/CourseProgressTemplate';
import CourseActionTemplate from './grid-table/CourseActionTemplate';
import { deleteMemberProgram } from '../../../../api/learner/program';
import Spinner from 'react-myoneui/lib/Spinner';
import Log from '../../../../lib/Log';
import DataNotFound from '../../../../components/DataNotFound';

function AssignedProgram({ programs, user, onReload, loading }) {
  const navigate = useNavigate();
  const confirmBoxStatus = useSelector((state) => state?.confirmBox?.status);
  const taxonomy = useSelector((state) => state.taxonomy.data);
  const activeMemberProgramId = useSelector((state) => state?.confirmBox?.activeId);
  const dispatch = useDispatch();

  const columnData = [
    {
      id: 1,
      field: 'name',
      visible: true,
      label: 'Program Name',
      width: '1fr',
      sortable: true,
      cellRenderer: CourseNameTemplate,
    },
    {
      id: 2,
      visible: true,
      field: 'started_at',
      label: 'Start Date',
      width: '.6fr',
      sortable: true,
      cellRenderer: StartDateTemplate,
    },
    {
      id: 3,
      visible: true,
      field: 'last_assessed_at',
      label: 'Last Activity',
      width: '.6fr',
      sortable: true,
      cellRenderer: LastActivityTemplate,
    },
    {
      id: 4,
      visible: true,
      field: 'score_text',
      label: translate(taxonomy)('score'),
      width: '.4fr',
      sortable: true,
      cellRenderer: ScoreTextTemplate,
    },
    {
      id: 5,
      visible: true,
      field: 'progress',
      label: 'Program Progress',
      width: '.6fr',
      sortable: true,
      cellRenderer: CourseProgressTemplate,
    },
    {
      id: 6,
      visible: true,
      field: 'action',
      label: '',
      width: '.2fr',
      sortable: true,
      cellRenderer: CourseActionTemplate,
    },
  ];

  const timerRef = useRef(null);
  const tableManager = useRef(null);
  const searchText = '';
  const [filter] = useState(null);

  const setTableManager = (tm) => (tableManager.current = tm);

  const onRowsRequest = async (requestData, tableManager) => {
    try {
      const queryParams = {
        from: requestData.from,
        to: requestData.to,
      };
      if (requestData.sort && requestData.sort.colId) {
        const sortCol = columnData.find((c) => c.id === requestData.sort.colId);
        let sort = { field: sortCol.field, dir: requestData.sort.isAsc ? 'asc' : 'desc' };
        queryParams['sort'] = sort;
      }
      if (requestData.searchText && requestData.searchText.trim()) {
        queryParams['search'] = requestData.searchText;
      }
      if (requestData.filter) {
        queryParams['filter'] = requestData.filter;
      }

      const programInfo = programs.map((program) => {
        let scoreText = program.score ? `${program.obtained_score}/${program.score}` : 'N/A';
        return { ...program, member_name: `${user.first_name + ' ' + user.last_name}`, member_email: user.decrypted_email, member_id: user._id, score_text: scoreText };
      });
      return {
        rows: programInfo,
        totalRows: programs.length,
      };
    } catch (e) {
      Log.error(e);
      return {
        rows: [],
        totalRows: 0,
      };
    }
  };

  const deleteHandler = async () => {
    try {
      await deleteMemberProgram({ memberProgramId: activeMemberProgramId });
      dispatch(setConfirmBoxStatus(false));
      dispatch(setConfirmBoxActiveId(null));
      onReload();
    } catch (error) {
      Log.error(error);
      dispatch(setConfirmBoxStatus(false));
      dispatch(setConfirmBoxActiveId(null));
    }
  };

  const showProgramDetail = (data) => {
    try {
      data.event.preventDefault();
      if (data.column.field !== 'action') {
        navigate(`program/${data.data.memberProgramId}/lecture-list`);
      }
    } catch (error) {
      Log.error(error);
    }
  };

  return (
    <Card className="border-0 mb-4 custom-grid-height">
      <Card.Header className="about-activity-header">
        <strong>Assigned Program</strong>
        {/* <Button variant="leading-icon" className="leading-icon">
          {editPencilIcon}
          Edit
        </Button> */}
      </Card.Header>
      {loading && (
        <div className="spinner-wrapper">
          <Spinner animation="border" />
        </div>
      )}
      {confirmBoxStatus && (
        <ConfirmBox
          show={confirmBoxStatus}
          question="Are you sure you want delete this member program?"
          okText="Delete"
          cancelText="Cancel"
          onHide={() => dispatch(setConfirmBoxStatus(false))}
          onOk={deleteHandler}
        />
      )}
      {programs && programs.length > 0 ? (
        <GridTable
          className="program-grid rgt-border-line-none ellipsis-text-grid"
          searchText={searchText}
          pageSize={programs.length}
          highlightSearch={false}
          columns={columnData}
          onRowsRequest={onRowsRequest}
          onRowClick={(data) => showProgramDetail(data)}
          filter={filter}
          onLoad={setTableManager}
        />
      ) : (
        !loading && (
          <DataNotFound
            title="Assigned Program"
            className="assigned-program-not-found"
          />
        )
      )}
    </Card>
  );
}

export default AssignedProgram;
