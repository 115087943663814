import axios from 'axios';
import { API_URL } from '../constants/config';
import ls from 'localstorage-slim';

// enable global encryption
ls.config.encrypt = true;
export const createProgram = (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: payload?.orgId,
    },
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(
        `${API_URL}/admin/program/create/`,
        {
          name: payload?.name,
          description: payload?.description,
        },
        headers,
      )
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const editProgram = (payload) => {
  console.log(payload);
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: payload?.orgId,
    },
  };
  return new Promise((resolve, reject) => {
    let payloadData = {
      notes: payload?.notes,
      name: payload?.name,
      description: payload?.description,
      program_image_url: payload?.program_image_url ? payload.program_image_url : '',
      banner_image_url: payload?.banner_image_url ? payload.banner_image_url : '',
      teaching_languages: payload?.teaching_languages ? payload.teaching_languages : [],
      advantages: payload?.advantages ? payload.advantages : [],
      requirements: payload?.requirements ? payload.requirements : [],
      weightage: payload?.weightage ? payload.weightage : false,
      score: payload?.score ? payload.score : 0,
      component_score: payload?.component_score,
      score_type: payload?.score_type ? payload.score_type : 'automatic',
      status_type: payload?.status_type ? payload.status_type : 'automatic',
      progress_calculated_on: payload?.progress_calculated_on ? payload.progress_calculated_on : 'completion',
      cap_mark_after_deadlines: payload?.capMarkAfterDeadlines,
      cap_mark_for_resubmission: payload?.capMarkForResubmission,
      enable_mandatory_option: payload.enable_mandatory_option,
      has_certificate: payload.has_certificate,
      release_type: payload?.release_type,
      is_b2b_enable: payload?.is_b2b_enable,
      isAllowedRatingsAndReviews: payload?.isAllowedRatingsAndReviews,
      certificate_expiry: payload?.certificate_expiry,
    };

    return axios
      .post(`${API_URL}/admin/program/${payload.programId}/edit`, payloadData, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getProgramList = (payload) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token') || payload?.token}`,
    organisationid: `${ls.get('orgId') || payload?.orgId}`,
  };

  return new Promise((resolve, reject) => {
    //assign program to user threr we didn't use pagination thus it will get data what send from backend thus not placed any static value
    const { perPage, currentPage, search, is_published, sortBy, sort, progress_calculated_on, member, role } = payload;
    return axios
      .get(`${API_URL}/admin/programs`, { params: { is_published, perPage, currentPage, search, sortBy, sort, progress_calculated_on, member, role }, headers })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const editSection = (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: `${ls.get('orgId') || payload?.orgId}`,
    },
  };
  return new Promise((resolve, reject) => {
    let params = {
      section_id: payload.id,
      title: payload?.title,
      sub_title: payload?.sub_title,
      description: payload?.description,
      program: payload?.program,
      is_published: payload?.is_published,
      status: payload?.status,
      categories: payload.categories,
    };
    if (payload?.parentSectionId) {
      params['parent_section'] = payload.parentSectionId;
    }

    if (payload?.duration) {
      params['duration'] = payload.duration;
    }
    if (payload['start_date']) {
      params['start_date'] = payload['start_date'];
    }
    if (payload['end_date']) {
      params['end_date'] = payload['end_date'];
    }
    if (payload['lock_after_duration']) {
      params['lock_after_duration'] = payload['lock_after_duration'];
    }
    if (payload['previous_section']) {
      params['previous_section'] = payload['previous_section'];
    } else {
      params['previous_section'] = null;
    }
    if (payload['set_deadline_after']) {
      params['set_deadline_after'] = payload['set_deadline_after'].value;
    }
    if (payload['deadline_type']) {
      params['deadline_type'] = payload['deadline_type'].value;
    }
    return axios
      .post(`${API_URL}/admin/program/section/update/`, params, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const createSection = (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: `${ls.get('orgId') || payload?.orgId}`,
    },
  };
  return new Promise((resolve, reject) => {
    let params = {
      title: payload?.title,
      sub_title: payload?.sub_title,
      description: payload?.description,
      program: payload?.program,
      publish_to_section_bank: payload?.publish_to_section_bank,
      // is_mandatory: payload.is_mandatory,
      status: payload?.status,
      categories: payload.categories,
    };
    if (payload?.parentSectionId) {
      params['parent_section'] = payload.parentSectionId;
    }

    if (payload?.duration) {
      params['duration'] = payload.duration;
    }
    if (payload['start_date']) {
      params['start_date'] = payload['start_date'];
    }
    if (payload['end_date']) {
      params['end_date'] = payload['end_date'];
    }
    if (payload['lock_after_duration']) {
      params['lock_after_duration'] = payload['lock_after_duration'];
    }
    if (payload['previous_section']) {
      params['previous_section'] = payload['previous_section'];
    }
    if (payload['set_deadline_after']) {
      params['set_deadline_after'] = payload['set_deadline_after'].value;
    }
    if (payload['deadline_type']) {
      params['deadline_type'] = payload['deadline_type'].value;
    }
    return axios
      .post(`${API_URL}/admin/program/section/create/`, params, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getSectionList = (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: `${ls.get('orgId') || payload.orgId}`,
    },
  };
  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/admin/program/section/${payload.programId}`, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getTopTwoSections = (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: `${ls.get('orgId') || payload.orgId}`,
    },
  };
  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/admin/program/section-subSection/${payload.programId}`, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getSectionBankList = (payload) => {
  const { program, ...params } = payload;
  /*
  Payload : {
  currentPage: number
search_keyword: string
perPage: number}

  */
  const headers = {
    organisationid: ls.get('orgId'),
    Authorization: `Bearer ${ls.get('token') || payload?.token}`,
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(`${API_URL}/admin/sectionBank/list/top-level-sections/`, { program }, { headers, params })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};
export const createSectionBank = (payload) => {
  let payloadData = {
    section_ids: payload.section_id,
    program: payload.program,
    // is_mandatory: payload.is_mandatory,
    status: payload.status,
  };
  const headers = {
    headers: {
      organisationid: ls.get('orgId'),
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
    },
  };

  return new Promise((resolve, reject) => {
    return axios
      .post(`${API_URL}/admin/program/import/sectionBank-section`, payloadData, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getProgramDetail = (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: `${ls.get('orgId') || payload?.orgId}`,
    },
  };
  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/admin/program/${payload.programId}`, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const editSectionWeightage = (payload) => {
  const headers = {
    headers: {
      organisationid: `${ls.get('orgId') || payload.orgId}`,
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
    },
  };
  return new Promise((resolve, reject) => {
    let payloadData = {
      section_id: payload?.id,
      weightage: payload?.weightage,
    };
    return axios
      .post(`${API_URL}/admin/program/section/add/weightage`, payloadData, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const publishProgram = (payload) => {
  const headers = {
    headers: {
      organisationid: payload.orgId,
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
    },
  };
  return new Promise((resolve, reject) => {
    let payloadData = {
      program_id: payload?.programId,
      is_published: payload?.isPublished,
    };
    return axios
      .post(`${API_URL}/admin/program/publish`, payloadData, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getSectionById = async (payload) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token') || payload?.token}`,
        organisationid: payload.orgId,
      },
    };

    return await axios.get(`${API_URL}/admin/program/component/${payload.id}`, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const getTaxonomy = (payload) => {
  let lang = ls.get('lang');
  const headers = {
    headers: {
      organisationid: payload?.orgId || ls.get('orgId'),
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
    },
  };
  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/admin/get/taxonomy/${lang}`, headers)
      .then((result) => resolve(result.data))
      .catch((error) => reject(error.response));
  });
};

export const restoreTaxonomy = ({ email }) => {
  const headers = {
    headers: {
      organisationid: ls.get('orgId'),
      Authorization: `Bearer ${ls.get('token')}`,
    },
  };

  return new Promise((resolve, reject) => {
    return axios
      .post(`${API_URL}/admin/restore/taxonomy`, { email }, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const assignProgramTo = async (data) => {
  try {
    const { orgId, ...payload } = data;
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token') || payload?.token}`,
        organisationid: orgId,
      },
    };
    return await axios.post(`${API_URL}/admin/create/member-program`, payload, headers);
  } catch (err) {
    return err?.response;
  }
};

export const getCoursestasklist = (payload) => {
  return new Promise((resolve, reject) => {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token') || payload?.token}`,
        organisationid: payload?.orgId || ls.get('orgId'),
      },
    };

    return axios
      .post(`${API_URL}/apprentice/list/member-section-components`, payload, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const deleteProgram = (payload) => {
  return new Promise((resolve, reject) => {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token') || payload?.token}`,
        organisationid: payload?.orgId || ls.get('orgId'),
      },
    };

    return axios
      .post(`${API_URL}/admin/program/delete`, payload, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const deleteProgramSection = (payload) => {
  return new Promise((resolve, reject) => {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token') || payload?.token}`,
        organisationid: payload?.orgId || ls.get('orgId'),
      },
    };

    return axios
      .post(`${API_URL}/admin/program/section/delete`, payload, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getMemberProgramList = (payload) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token') || payload?.token}`,
    organisationid: `${ls.get('orgId') || payload.orgId}`,
  };
  return new Promise((resolve, reject) => {
    const { perPage, currentPage, search, sortBy, sort, startDate, endDate } = payload;

    return axios
      .get(`${API_URL}/admin/program/${payload.programId}/list/member-program`, { params: { perPage, currentPage, search, sortBy, sort, startDate, endDate }, headers })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const lockCompleteMemberProgram = async (memberProgramId) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        organisationid: ls.get('orgId'),
      },
    };
    return await axios.post(`${API_URL}/admin/lock/member-program`, { memberProgramId }, headers);
  } catch (err) {
    return err?.response;
  }
};

export const releaseCertificateToMemberProgram = async (memberProgramId) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        organisationid: ls.get('orgId'),
      },
    };
    return await axios.post(`${API_URL}/admin/update/generate-member-program-certificate`, { memberProgramId }, headers);
  } catch (err) {
    return err?.response;
  }
};

export const getMemberSection = (id) => {
  return new Promise((resolve, reject) => {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        organisationid: ls.get('orgId'),
      },
    };

    return axios
      .get(`${API_URL}/apprentice/member-section/${id}/details`, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getCategories = async (of = 'component') => {
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };
  try {
    return await axios.post(`${API_URL}/admin/list/categories`, { of }, { headers });
  } catch (err) {
    throw new Error(err);
  }
};

export const getParentCategories = async ({ sectionId, isFromSectionBank }) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };
  try {
    return await axios.post(`${API_URL}/admin/list/parent/categories`, { sectionId, isFromSectionBank }, { headers });
  } catch (err) {
    throw new Error(err);
  }
};

export const gettopLevelSections = async (payload) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };
  try {
    return await axios.post(`${API_URL}/admin/list/topLevelSections`, payload, { headers });
  } catch (err) {
    throw new Error(err);
  }
};
export const getsecondLevelSections = async (payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };
    return await axios.post(`${API_URL}/admin/list/secondLevelSections`, payload, { headers });
  } catch (err) {
    throw new Error(err);
  }
};
export const getAllComponents = async (payload) => {
  const { program, ...query } = payload;
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };
  try {
    return await axios.post(`${API_URL}/admin/list/components`, { program }, { params: query, headers });
  } catch (err) {
    throw new Error(err);
  }
};

export const getSectionChilds = async (payload) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };
  try {
    return await axios.post(`${API_URL}/admin/list/memberSection/components`, payload, { headers });
  } catch (err) {
    throw new Error(err);
  }
};
export const createFeedback = async (payload) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };
  try {
    return await axios.post(`${API_URL}/apprentice/add/feedback`, payload, { headers });
  } catch (err) {
    throw new Error(err);
  }
};

export const getAllFeedBacks = async (payload) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };
  try {
    return await axios.post(`${API_URL}/apprentice/newFeedback/count`, payload, { headers });
  } catch (err) {
    throw new Error(err);
  }
};

export const reOrderSection = async (payload) => {
  /*
  payload {"destination_index": string,"section_id": string}
  */
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };
  try {
    return await axios.post(`${API_URL}/admin/program/section/ordering`, payload, { headers });
  } catch (err) {
    throw new Error(err);
  }
};

export const createDefaultProgramLogo = async (default_logo_url) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };
  try {
    return await axios.post(`${API_URL}/admin/program/set-default-logo`, { default_logo_url }, { headers });
  } catch (err) {
    throw new Error(err);
  }
};

export const fetchDefaultProgramLogo = async () => {
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };
  try {
    return await axios.get(`${API_URL}/admin/program/logo/get-default`, { headers });
  } catch (err) {
    throw new Error(err);
  }
};

export const getProgramProgress = async ({ payload, role }) => {
  try {
    let activeRole = JSON.parse(ls.get('activeRole'));
    let roleid = activeRole?.id;
    let roleName = activeRole.identifier;
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };

    return await axios.post(`${API_URL}/${roleName === 'tutor' ? 'user' : role}/reporting/view-program-progress-details`, { ...payload, role: roleid }, { headers });
  } catch (err) {
    throw new Error(err);
  }
};
export const exportProgramProgress = async ({ payload, role }) => {
  try {
    let activeRole = JSON.parse(ls.get('activeRole'));
    let roleid = activeRole?.id;
    let roleName = activeRole.identifier;
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };

    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}/${roleName === 'tutor' ? 'user' : role}/reporting/export-program-progress-details`, { ...payload, role: roleid }, { headers })
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (err) {
    throw new Error(err);
  }
};

export const getUserComponents = async ({ payload, role }) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };
  /*
  payload = {
  search: '',
  perPage: 10,
  currentPage: 1,
  member_program_id: '6540bea81e471da9ab885876',
  startedStartDate: '',
  startedEndDate: '',
  submittedStartDate: '',
  submittedEndDate: '',
  completedStartDate: '',
  completedEndDate: '',
  resubmittedStartDate: '',
  resubmittedEndDate: '',
};
  */
  try {
    let activeRole = JSON.parse(ls.get('activeRole'));
    let roleid = activeRole?.id;
    let roleName = activeRole.identifier;
    return await axios.post(`${API_URL}/${roleName === 'tutor' ? 'user' : role}/reporting/view-program-components`, { ...payload, role: roleid }, { headers });
  } catch (err) {
    throw new Error(err);
  }
};

export const exportUserComponents = async ({ payload, role }) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };

  try {
    let activeRole = JSON.parse(ls.get('activeRole'));
    let roleid = activeRole?.id;
    let roleName = activeRole.identifier;
    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}/${roleName === 'tutor' ? 'user' : role}/reporting/export-program-components`, { ...payload, role: roleid }, { headers })
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (err) {
    throw new Error(err);
  }
};

export const getActiveApprenticeMemberPrograms = async (payload) => {
  try {
    /*
    payload:{ memberId, currentPage, perPage}
    */
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };
    let activeRole = JSON.parse(ls.get('activeRole'));
    let roleid = activeRole?.id;
    let roleName = activeRole.identifier === 'tutor' ? 'user' : activeRole.identifier;
    return await axios.post(`${API_URL}/${roleName}/reporting/apprentice/member-programs`, { ...payload, role: roleid }, { headers });
  } catch (err) {
    throw new Error(err);
  }
};

export const exportActiveApprenticeMemberPrograms = async (payload) => {
  try {
    /*
    payload:{ memberId, currentPage, perPage}
    */
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };
    let activeRole = JSON.parse(ls.get('activeRole'));
    let roleid = activeRole?.id;
    let roleName = activeRole.identifier === 'tutor' ? 'user' : activeRole.identifier;

    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}/${roleName}/reporting/apprentice/export-member-programs`, { ...payload, role: roleid }, { headers })
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (err) {
    throw new Error(err);
  }
};

export const generateProgramEnrollLInk = (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: `${ls.get('orgId') || payload?.orgId}`,
    },
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(`${API_URL}/admin/program/enroll-link/generate`, { programId: payload.programId }, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getProgramEmailTemplate = async (program) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };
  try {
    return await axios.post(`${API_URL}/admin/program/email-template/get`, { program }, { headers });
  } catch (err) {
    throw new Error(err);
  }
};

export const createProgramEmailTemplate = async (payload) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };
  try {
    return await axios.post(`${API_URL}/admin/program/email-template/create`, payload, { headers });
  } catch (err) {
    throw new Error(err);
  }
};

export const updateProgramEmailTemplate = async (payload) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };
  try {
    return await axios.patch(`${API_URL}/admin/program/email-template/update`, payload, { headers });
  } catch (err) {
    throw new Error(err);
  }
};
