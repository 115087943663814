import { memo } from 'react';
import { Form, Col } from 'react-myoneui';
function InputMultiple({ data, mode, question_number }) {
  return (
    <>
      <Col
        className="py-2"
        // md={state?.col_width}
        name="formElement"
      >
        <Form.Label
          htmlFor={data?.title}
          className="form-builder-label"
        >
          {question_number}. {data?.title}
        </Form.Label>
        <ol
          type="a"
          className="ol-style"
        >
          {data?.options?.length > 0
            ? data.options.map((item, index) => {
                return (
                  <li key={index}>
                    <Form.Control
                      type="text"
                      className="mb-1 ms-2"
                      id={item?.index}
                      aria-describedby="passwordHelpBlock"
                      placeholder={item?.placeholder}
                      value={item?.value}
                    />
                  </li>
                );
              })
            : ''}
        </ol>
      </Col>
    </>
  );
}
export default memo(InputMultiple);
