import axios from 'axios';
import { API_URL } from '../constants/config';
import ls from 'localstorage-slim';
import Log from 'lib/Log';

export const listSections = async (params) => {
  try {
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };

    return await axios.get(`${API_URL}/admin/sectionBank/list/sections`, { headers, params });
  } catch (err) {
    throw new Error(err);
  }
};

export const getSectionBankTopTwoSections = (sectionId) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: `${ls.get('orgId')}`,
    },
  };
  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/admin/sectionBank/list/section/${sectionId}/subSection`, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getSectionBankSectionById = async (payload) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token') || payload?.token}`,
        organisationid: payload.orgId,
      },
    };

    return await axios.get(`${API_URL}/admin/sectionBank/section/${payload.id}`, headers);
  } catch (err) {
    throw new Error(err);
  }
};
export const getSectionBundleById = async (sectionId, params) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        organisationid: ls.get('orgId'),
      },
      params,
    };

    return await axios.get(`${API_URL}/admin/sectionBank/sectionBundle/${sectionId}`, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const createUnitBankSection = (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: `${ls.get('orgId')}`,
    },
  };
  return new Promise((resolve, reject) => {
    let params = {
      title: payload?.title,
      sub_title: payload?.sub_title,
      description: payload?.description,
      program: payload?.program,
      categories: payload?.categories,
    };
    if (payload?.parentSectionId) {
      params['parent_section'] = payload.parentSectionId;
    }

    if (payload?.duration) {
      params['duration'] = payload.duration;
    }
    if (payload['start_date']) {
      params['start_date'] = payload['start_date'];
    }
    if (payload['end_date']) {
      params['end_date'] = payload['end_date'];
    }
    if (payload['lock_after_duration']) {
      params['lock_after_duration'] = payload['lock_after_duration'];
    }
    if (payload['previous_section']) {
      params['previous_section'] = payload['previous_section'];
    }
    if (payload['set_deadline_after']) {
      params['set_deadline_after'] = payload['set_deadline_after'].value;
    }
    if (payload['deadline_type']) {
      params['deadline_type'] = payload['deadline_type'].value;
    }
    return axios
      .post(`${API_URL}/admin/sectionBank/create/`, params, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const deleteSection = (payload) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };

    return axios
      .delete(`${API_URL}/admin/sectionBank/remove/section`, { headers, data: payload })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const editUnitBankSection = (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: `${ls.get('orgId') || payload?.orgId}`,
    },
  };
  return new Promise((resolve, reject) => {
    let params = {
      section_id: payload.id,
      title: payload?.title,
      sub_title: payload?.sub_title,
      description: payload?.description,
      categories: payload?.categories,
    };
    if (payload?.parentSectionId) {
      params['parent_section'] = payload.parentSectionId;
    }

    if (payload?.duration) {
      params['duration'] = payload.duration;
    }
    if (payload['start_date']) {
      params['start_date'] = payload['start_date'];
    }
    if (payload['end_date']) {
      params['end_date'] = payload['end_date'];
    }
    if (payload['lock_after_duration']) {
      params['lock_after_duration'] = payload['lock_after_duration'];
    }
    if (payload['previous_section']) {
      params['previous_section'] = payload['previous_section'];
    } else {
      params['previous_section'] = null;
    }
    if (payload['set_deadline_after']) {
      params['set_deadline_after'] = payload['set_deadline_after'].value;
    }
    if (payload['deadline_type']) {
      params['deadline_type'] = payload['deadline_type'].value;
    }
    return axios
      .patch(`${API_URL}/admin/sectionBank/update/section`, params, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const createUnitBankCourseComponent = (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: `${payload.orgId}`,
    },
  };

  return new Promise((resolve, reject) => {
    return axios
      .post(`${API_URL}/admin/sectionBank/create/courseComponent`, { ...payload }, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const createUnitBankUnitComponent = async (data) => {
  const { orgId, ...payload } = data;
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: orgId,
    },
  };
  try {
    return await axios.post(`${API_URL}/admin/sectionBank/create/unitComponent`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const createUnitBankResourceComponent = async (data) => {
  const { orgId, ...payload } = data;
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: orgId,
    },
  };
  try {
    return await axios.post(`${API_URL}/admin/sectionBank/create/resourceComponent`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const createUnitBankOTJComponent = ({ orgId, ...payload }) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: orgId,
    },
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(`${API_URL}/admin/sectionBank/create/OTJComponent`, payload, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const updateUnitBankCourseComponent = async (payload) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token') || payload?.token}`,
        organisationid: `${payload.orgId}`,
      },
    };
    return await axios.patch(`${API_URL}/admin/sectionBank/update/courseComponent`, payload, headers);
  } catch (err) {
    Log.error('Error >> ', err);
    throw new Error(err);
  }
};

export const updateUnitBankResourceComponent = async (data) => {
  try {
    const { orgId, ...payload } = data;
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token') || payload?.token}`,
        organisationid: orgId,
      },
    };

    return await axios.patch(`${API_URL}/admin/sectionBank/update/resourceComponent`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const updateUnitBankUnitComponent = async (payload) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token') || payload?.token}`,
        organisationid: `${payload.orgId}`,
      },
    };
    return await axios.patch(`${API_URL}/admin/sectionBank/update/unitComponent`, payload, headers);
  } catch (err) {
    Log.error('Error >> ', err);
    throw new Error(err);
  }
};

export const updateUnitBankOTJComponent = ({ orgId, ...payload }) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: orgId,
    },
  };
  return new Promise((resolve, reject) => {
    return axios
      .patch(`${API_URL}/admin/sectionBank/update/OTJComponent`, payload, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const removeSectionBankSection = async (payload) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };
  try {
    return await axios.delete(`${API_URL}/admin/sectionBank/remove/section`, { headers, data: payload });
  } catch (err) {
    Log.error(err);
  }
};

export const reOrderSectionBankSection = async (payload) => {
  /*
  payload {"destination_index": string,"section_id": string}
  */
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };
  try {
    return await axios.post(`${API_URL}/admin/sectionBank/order/section`, payload, { headers });
  } catch (err) {
    throw new Error(err);
  }
};
