import * as React from 'react';
import Offcanvas from 'react-myoneui/lib/Offcanvas';
import Button from 'react-myoneui/lib/Button';
import { Row, Col } from 'react-myoneui';
import PasswordTooltip from 'react-myoneui/lib/PasswordTooltip';
import Log from '../../../../lib/Log';
function ChangePasswordOffCanvas(props) {
  const handleClose = () => props.onClose();
  const offCanvasKeyDown = (e) => e.stopPropagation();
  return (
    <>
      <Offcanvas
        show={props.show}
        onHide={handleClose}
        placement="end"
        onKeyDown={offCanvasKeyDown}
      >
        <Offcanvas.Header
          closeButton
          backButton
          className="align-items-center"
        >
          <Offcanvas.Title>
            Change <br /> Password
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col
              lg={8}
              className="change-password-wrap"
            >
              <PasswordTooltip
                placeholder="Old Password"
                name="oldPassword"
                id="oldPassword"
                label="Old Password"
                defaultValue="12356"
                required
              />
            </Col>
            <Col
              lg={8}
              className="change-password-wrap"
            >
              <PasswordTooltip
                placeholder="New Password"
                name="newPassword"
                id="newPassword"
                label="New Password"
                defaultValue="12356"
                required
              />
            </Col>
            <Col
              lg={8}
              className="change-password-wrap"
            >
              <PasswordTooltip
                placeholder="Confirm Password"
                name="confirmPassword"
                id="confirmPassword"
                label="Confirm Password"
                defaultValue="12356"
                required
              />
            </Col>
          </Row>
        </Offcanvas.Body>
        <Offcanvas.Footer>
          <Button
            variant="default"
            onClick={handleClose}
          >
            Let’s do this later
          </Button>
          <Button
            variant="primary"
            onClick={handleClose}
          >
            Change Password
          </Button>
        </Offcanvas.Footer>
      </Offcanvas>
    </>
  );
}

export default ChangePasswordOffCanvas;
