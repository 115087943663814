import axios from 'axios';
import { API_URL } from '../constants/config';
import ls from 'localstorage-slim';

// enable global encryption
ls.config.encrypt = true;
// const API_URL = process.env.REACT_APP_API_URL;
// perPage,currentPage,search,sort,sordBy
export const apprenticeshipClientList = (payload) => {
  const headers = {
    headers: {
      organisationid: ls.get('orgId') || payload.orgId,
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
    },
  };

  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/apprenticeship-client/?perPage=${payload.perPage}&currentPage=${payload.page}&search=${payload.search}&sortBy=${payload.sortBy}&sort=${payload.sort}`, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getOrgTaxonomy = (payload) => {
  const headers = {
    headers: {
      organisationid: ls.get('orgId') || payload.orgId,
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
    },
  };
  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/admin/list/taxonomy`, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getOrgTaxonomySuperAdmin = (payload) => {
  const headers = {
    headers: {
      organisationid: ls.get('orgId') || payload.orgId,
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
    },
  };
  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/apprenticeship-client/list/taxonomy`, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const orgList = (payload) => {
  const headers = {
    headers: {
      organisationid: ls.get('orgId') || payload.orgId,
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
    },
  };

  return new Promise((resolve, reject) => {
    return axios
      .get(
        `${API_URL}/apprenticeship-client/mykademy-list?page_limit=${payload.page_limit}&page=${payload.page}&search_keyword=${payload.search_keyword}&status=${payload.status}&orderby_item=${
          payload.sortBy
        }&orderby_direction=${payload.sort}&account_id=${payload.account_id || ''}`,
        headers,
      )
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const createApprenticeshipClient = (payload) => {
  //   apprenticeship-client/create
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
    },
  };
  const body = {
    admin_email: payload.admin_email,
    admin_name: payload.admin_name,
    database: payload.database,
    domain: payload.domain,
    idp_id: parseInt(payload.idp_id),
    name: payload.name,
    backgroundUrl: payload?.backgroundUrl,
    logoUrl: payload?.logoUrl,
    secret_key: payload?.secret_key,
    tenent_domain: payload?.tenent_domain,
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(`${API_URL}/apprenticeship-client/create`, body, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getOrgRoles = (payload) => {
  const headers = {
    headers: {
      organisationid: ls.get('orgId') || payload.orgId,
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
    },
  };

  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/roles/organisation?id=${payload.orgId}`, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const restoreOrgRoles = (email) => {
  const headers = {
    headers: {
      organisationid: ls.get('orgId'),
      Authorization: `Bearer ${ls.get('token')}`,
    },
  };

  return new Promise((resolve, reject) => {
    return axios
      .post(`${API_URL}/roles/restore`, { email }, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const updateSuperAdminRoles = (payload) => {
  const headers = {
    headers: {
      organisationid: ls.get('orgId') || payload.orgId,
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
    },
  };

  return new Promise((resolve, reject) => {
    return axios
      .post(`${API_URL}/roles/update`, payload.data, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};
export const updateAdminRoles = (payload) => {
  const headers = {
    headers: {
      organisationid: ls.get('orgId') || payload.orgId,
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
    },
  };

  return new Promise((resolve, reject) => {
    return axios
      .post(`${API_URL}/roles/update`, payload.data, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const updateTaxonomy = (payload) => {
  const headers = {
    headers: {
      organisationid: ls.get('orgId') || payload.orgId,
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
    },
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(`${API_URL}/admin/create-or-update/taxonomy`, payload, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const updateTaxonomySuperAdmin = (payload) => {
  const headers = {
    headers: {
      organisationid: ls.get('orgId') || payload.orgId,
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
    },
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(`${API_URL}/apprenticeship-client/create-or-update/taxonomy`, payload, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const updateOrgSetting = (payload) => {
  const headers = {
    headers: {
      organisationid: ls.get('orgId') || payload.orgId,
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
    },
  };

  return new Promise((resolve, reject) => {
    return axios
      .post(
        `${API_URL}/apprenticeship-client/update`,
        {
          id: payload?.orgId,
          enable_journey_worker_rate: payload.enable_journey_worker_rate,
          enable_sharing_program: payload?.enable_sharing_program,
          lang: payload?.lang ? payload?.lang : 'en',
          enable_reporting_learning_outcome: payload?.enable_reporting_learning_outcome,
          enable_product: payload?.enable_product,
        },
        headers,
      )
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getOrgSetting = (payload) => {
  const headers = {
    headers: {
      organisationid: ls.get('orgId') || payload.orgId,
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
    },
  };

  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/apprenticeship-client/${payload.orgId}`, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};
export const getSubDomainInfo = (payload) => {
  const headers = {
    headers: {
      organisationid: ls.get('orgId') || payload.orgId,
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
    },
  };

  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/domain-detail/${payload.subdomain}`, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getUserDetail = (payload) => {
  const headers = {
    headers: {
      organisationid: ls.get('orgId') || payload.orgId,
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
    },
  };

  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/member/details/`, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};
export const getServiceProviders = (payload) => {
  const headers = {
    headers: {
      organisationid: ls.get('orgId') || payload.orgId,
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
    },
  };
  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/admin/list/serviceProviders`, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const syncApprenticeshipClient = (client_id) => {
  const headers = {
    headers: {
      organisationid: ls.get('orgId'),
      Authorization: `Bearer ${ls.get('token')}`,
    },
  };

  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/apprenticeship-client/sync?client_id=${client_id}`, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getEmailLogs = (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
    },
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(`${API_URL}/apprenticeship-client/email-log-list`, payload, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getProgramImportLogs = (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
    },
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(`${API_URL}/apprenticeship-client/program-import-log-list`, payload, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getOrgPrograms = (payload) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token') || payload?.token}`,
    organisationid: payload?.orgId,
  };
  const { perPage, currentPage, search, is_published, sortBy, sort, progress_calculated_on, member, role } = payload;

  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/apprenticeship-client/list/program`, { headers, params: { perPage, currentPage, search, is_published, sortBy, sort, progress_calculated_on, member, role } })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};
