import { object } from 'prop-types';
import * as React from 'react';
import { useState } from 'react';
import Form from 'react-myoneui/lib/Form';
import { collapseSectionIcon } from '../../constants/icons';
import MultipleComponentList from './MultipleComponentList';
import { translate } from '../../lib';
import { useSelector } from 'react-redux';

function SectionBlock(props) {
  const { label, multiComponentTitle, children, data, onChange, onSelectAll, onComponentChange, onSelectionChange } = props;
  const [showChild, setShowChild] = useState(false);
  const [activeClass, setActiveClass] = useState();
  const [isParentChecked, setIsParentChecked] = useState(false);
  const [showPopBox, setShowPopBox] = useState(false);
  const mycheckbox = React.useRef(null);
  const taxonomy = useSelector((state) => state.taxonomy.data);

  const collapseHandler = () => {
    setShowChild((toggle) => !toggle);
    setActiveClass((toggle) => !toggle);
  };
  const toggleSelectAll = (e) => {
    let components = JSON.parse(JSON.stringify(data.components));
    components = components.map((item) => {
      return { ...item, status: e.target.checked };
    });
    let cloneData = { ...data };
    cloneData.components = components;
    onSelectAll(e, cloneData);
    // onChange(cloneData);
  };

  const toggleEachComponent = (e) => {
    let cloneData = { ...data };
    let component = cloneData.components.find((item) => item.memberSectionId === e.target.value);
    let index = cloneData.components.indexOf(component);
    component.status = e.target.checked;
    cloneData.components[index] = component;
    // onChange(cloneData);
    onChange(e);
  };
  const subSectionToggle = (e) => {
    setIsParentChecked(e.currentTarget.checked);
    if (e.currentTarget.checked === true) {
      let cloneData = { ...data };
      let components = [...data.components];
      cloneData.components = components.map((item) => {
        return { ...item, status: true };
      });
      cloneData.status = true;
      onSelectionChange(e, cloneData);
      //setShowPopBox(true);
    } else {
      let cloneData = { ...data };
      cloneData.status = e.currentTarget.checked;
      onSelectionChange(e, cloneData);
    }
  };
  const singleChange = (e) => {
    //noSingleChange(e);
    let cloneData = { ...data };
    let components = [...data.components];
    let component = components.find((item) => item.memberSectionId === e.currentTarget.dataset.id);
    let index = components.indexOf(component);
    component.status = e.currentTarget.checked;
    components[index] = component;
    cloneData.components = components;
    onComponentChange(e, cloneData);
  };
  return (
    <>
      <div className="multiple-component-block">
        <div
          className={`multiple-component-header pop-box-holder ${activeClass ? 'active' : ''}`}
          onMouseLeave={() => setShowPopBox(false)}
        >
          <>
            {showPopBox && (
              <div className="pop-box">
                By selecting {translate(taxonomy)('subSection')}, all the&nbsp;{translate(taxonomy)('component')}s&nbsp; inside this&nbsp;{translate(taxonomy)('subSection')}&nbsp;will be selected by
                default. If any&nbsp;{translate(taxonomy)('component')}s&nbsp;are not required to upload evidence, uncheck those&nbsp;{translate(taxonomy)('component')}s&nbsp;.
              </div>
            )}
            <Form.Check>
              <Form.Check.Input
                data-type="section"
                data-label={label}
                ref={mycheckbox}
                data-id={data?.memberSectionId}
                type="checkbox"
                id={data?.memberSectionId}
                onChange={subSectionToggle}
                value={data?.memberSectionId}
                onMouseEnter={() => setShowPopBox(true)}
              />
              <Form.Check.Label
                htmlFor={data?.memberSectionId}
                title={multiComponentTitle}
              >
                {data.title}
              </Form.Check.Label>
            </Form.Check>
          </>
          <span
            className={`collapse-section ${showChild ? 'active' : ''}`}
            onClick={collapseHandler}
          >
            {collapseSectionIcon}
          </span>
        </div>
        {showChild === true && data.components.length === 0 && (
          <ul className="multiple-component-list">
            <li className="dnf-item">Data Not Found</li>
          </ul>
        )}

        {data.components && data.components.length > 0 && (
          <MultipleComponentList
            label={label + 1}
            noSingleChange={singleChange}
            parentId={data?.memberSectionId}
            status={isParentChecked}
            onChange={toggleEachComponent}
            onSelectAll={toggleSelectAll}
            list={data.components}
            showChild={showChild}
          />
        )}
      </div>
    </>
  );
}
export default SectionBlock;
