import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

export const changePassword = (payload) => {
  return new Promise((resolve, reject) => {
    return axios
      .post(`${API_URL}/changePassword`, payload)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};
