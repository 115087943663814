import { useEffect, memo } from 'react';
import { useDispatch } from 'react-redux';
import { flushFormData } from '../../../redux/FormDataSlice';
import { setSidebarData } from '../../../reducers/sidebarSlice';
// import Log from 'lib/Log';
import InputRadio from './InputRadio';
import InputCheckbox from './InputCheckbox';
import InputSingleLine from './InputSingleLine';
import InputMultiple from './InputMultiple';
import InputTextarea from './InputTextarea';

const QuizPreview = ({ selectedComponent }) => {
  const dispatch = useDispatch();

  dispatch(setSidebarData({ enableActiveWrapper: true }));
  useEffect(() => {
    dispatch(flushFormData());
  }, []);

  return selectedComponent?.questions && selectedComponent?.questions?.length > 0 ? (
    <>
      {selectedComponent.questions.map((item, index) => {
        switch (item.type) {
          case 'Radio':
            return (
              <InputRadio
                className="py-2"
                data={item}
                question_number={index + 1}
              />
            );
          case 'Checkbox':
            return (
              <InputCheckbox
                className="py-2"
                data={item}
                question_number={index + 1}
              />
            );
          case 'Single Input':
            return (
              <InputSingleLine
                className="py-2"
                data={item}
                question_number={index + 1}
              />
            );
          case 'Multiple Input':
            return (
              <InputMultiple
                className="py-2"
                data={item}
                question_number={index + 1}
              />
            );
          case 'Text Area':
            return (
              <InputTextarea
                className="py-2"
                data={item}
                question_number={index + 1}
              />
            );
        }
      })}
    </>
  ) : (
    ''
  );
};

export default memo(QuizPreview);
