import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  statusList: [],
  enrollLink: '',
  isAllowedRatingsAndReviews: '',
};

export const ProgramSlice = createSlice({
  name: 'program',
  initialState,
  reducers: {
    setStatusList: (state, action) => {
      return {
        ...state,
        statusList: action.payload,
      };
    },

    setEnrollLink: (state, action) => {
      return {
        ...state,
        enrollLink: action.payload,
      };
    },

    setIsAllowedRatingsAndReviews: (state, action) => {
      return {
        ...state,
        isAllowedRatingsAndReviews: action.payload,
      };
    },
  },
});

export const { setStatusList, setEnrollLink, setIsAllowedRatingsAndReviews } = ProgramSlice.actions;
export default ProgramSlice.reducer;
