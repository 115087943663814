import React from 'react';
import { Badge, ProgressWrap, ProgressValue } from 'react-myoneui';
import ProgressBar from 'react-myoneui/lib/ProgressBar';
import { fixInteger } from '../../../../../lib/common';
import { setPercentageVariant } from '../../../../../lib';
const CourseProgressTemplate = ({ value, data, tableManager }) => {
  const {
    config: {
      additionalProps: { cell: additionalProps = {} },
    },
  } = tableManager;

  const classNames = ('rgt-cell-inner rgt-text-truncate justify-content-end assign-program-drop-progress' + (additionalProps.className || '')).trim();

  const progressPercent = data.progress_calculated_on == 'completion' ? data.completed_progress : data.submitted_progress;

  return (
    <div
      {...additionalProps}
      className={classNames}
    >
      <div className="learner-button-wrap flex-auto">
        <ProgressWrap>
          <ProgressBar
            variant={setPercentageVariant(progressPercent)}
            now={progressPercent}
          />
          <ProgressValue ProgressPercentage={`${progressPercent}%`} />
        </ProgressWrap>
      </div>
    </div>
  );
};

export default CourseProgressTemplate;
