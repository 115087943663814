import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { lazyWithRetry } from 'components/lazyWithRetry';
import EQVIQVAssessors from 'modules/eqv/assessors';
import ProfileActivity from 'modules/admin/profile';
import Program from 'modules/admin/program';
import EQVIQV from 'modules/eqv/iqvs';
import IQVAssessors from 'modules/iqv/assessors';
import IQVAssessorMemberProgram from 'modules/iqv/learnerProgram';
import ComponentDetail from 'modules/learner/program/ComponentDetail';
import ApprenticeProgramRoute from '../common/apprenticeProgramRoute';
import ProgramRoute from 'routes/common/programRoute';
const ProgramDetail = lazyWithRetry(() => import('modules/admin/users/programs/programDetail'));
const ProgrammeProgress = lazyWithRetry(() => import('modules/admin/users/programs/programProgress'));
const ActivePrograms = lazyWithRetry(() => import('modules/admin/users/programs/activePrograms'));
const UserApprentices = lazyWithRetry(() => import('modules/admin/users/apprentices'));
const Profile = lazyWithRetry(() => import('modules/admin/profile/Profile'));
const AllComponent = lazyWithRetry(() => import('modules/admin/program/allComponent'));
const LectureList = lazyWithRetry(() => import('modules/learner/program/LectureList'));
const QuickLinks = lazyWithRetry(() => import('modules/learner/program/QuickLinks/index'));
const TaskList = lazyWithRetry(() => import('modules/learner/program/TaskList'));
const ActivityDetail = lazyWithRetry(() => import('modules/learner/program/ActivityDetail'));
const Resubmission = lazyWithRetry(() => import('modules/learner/program/Resubmission'));
const MemberProgramList = lazyWithRetry(() => import('modules/admin/program/MemberProgramList'));
const Target = lazyWithRetry(() => import('modules/learner/program/Target/'));
const ProgramFieldManagement = lazyWithRetry(() => import('modules/admin/program/FieldManagement'));
const Taxonomy = lazyWithRetry(() => import('../../modules/superAdmin/taxonomy'));
const NoMatch = lazyWithRetry(() => import('modules/NoMatch'));
const EQVLayout = lazyWithRetry(() => import('layouts/EQVLayout'));
const Dashboard = lazyWithRetry(() => import('modules/admin/dashboard'));
const Review = lazyWithRetry(() => import('modules/eqv/assessors/Review'));
const MemberAssessors = lazyWithRetry(() => import('modules/learner/program/assessors'));
const Notification = lazyWithRetry(() => import('components/notification'));
const AssessorReport = lazyWithRetry(() => import('modules/admin/users/assessors/activeApprentices'));
const ActiveApprentices = lazyWithRetry(() => import('modules/admin/users/apprentices/activeApprentices'));
const RatingReview = lazyWithRetry(() => import('modules/admin/program/ratingReview'));
const ExternalExamComponentList = lazyWithRetry(() => import('modules/admin/externalExam/componentList'));
const ExternalExamLearnerList = lazyWithRetry(() => import('modules/admin/externalExam/learnerList'));
const EventComponentList = lazyWithRetry(() => import('modules/admin/event/componentList'));
const EventLearnerList = lazyWithRetry(() => import('modules/admin/event/learnerList'));

export default (
  <Routes>
    <Route
      path="eqv/"
      element={<EQVLayout />}
    >
      {ApprenticeProgramRoute}
      <Route
        index
        element={<Dashboard />}
      />
      <Route
        path="dashboard/"
        element={<Dashboard />}
      />
      <Route
        path="notification"
        element={<Notification />}
      />
      <Route
        path="view-profile/:id"
        element={<ProfileActivity />}
      />
      <Route
        path="iqvs/"
        element={<EQVIQV />}
      />
      <Route
        path="iqvs/view-profile/:id"
        element={<ProfileActivity />}
      />
      <Route
        path="iqvs/:id/assessors"
        exact
        element={<IQVAssessors from="eqv" />}
      />
      <Route
        path="iqvs/:iqvId/assessors/view-profile/:id"
        element={<ProfileActivity />}
      />
      <Route
        path="iqvs/:iqvId/assessors/:id/review/"
        element={<Review />}
      />
      <Route
        path="iqvs/:iqvId/assessors/:id/learner-program/"
        element={<IQVAssessorMemberProgram />}
      />
      <Route
        path="assessors"
        element={<EQVIQVAssessors />}
      />
      <Route
        path="assessors/view-profile/:id"
        element={<ProfileActivity />}
      />
      <Route
        path="assessors/:id/review/"
        element={<Review />}
      />
      <Route
        path="assessors/:id/learner-program/"
        element={<IQVAssessorMemberProgram />}
      />
      {ProgramRoute}
      {/* <Route
        path="program/*"
        element={<Program />}
      />
      <Route
        path="program/*"
        element={<Program />}
      />
      <Route
        path="program/:programId/field-management"
        exact
        element={<ProgramFieldManagement />}
      />
      <Route
        path="program/:programId/all-component"
        exact
        element={<AllComponent />}
      /> */}
      <Route
        path="apprentices/"
        exact
        element={<UserApprentices />}
      />
      <Route
        path="field-management/apprentices"
        exact
        element={<ProgramFieldManagement />}
      />
      <Route
        path="taxonomy/*"
        element={<Taxonomy />}
      />
      <Route
        path="profile/edit-profile/:id"
        element={<Profile />}
      />
      <Route
        path="apprentices/view-profile/:id"
        exact
        element={<ProfileActivity name="apprentices" />}
      />
      <Route
        path="apprentices/view-profile/:id/program/:programId/"
        exact
        element={<LectureList />}
      />
      <Route
        path="apprentices/view-profile/:id/program/:programId/lecture-list"
        exact
        element={<LectureList />}
      />
      <Route
        path="apprentices/view-profile/:id/program/:programId/:lectureDetail/activitityDetail"
        element={<ActivityDetail />}
      />
      <Route
        path="apprentices/view-profile/:id/program/:programId/lecture-list/:lectureDetail/activitityDetail"
        element={<ActivityDetail />}
      />
      <Route
        path="apprentices/view-profile/:id/program/:programId/lecture-list/:lectureDetail/activitityDetail/:activityId/:memberSectionId"
        element={<ActivityDetail />}
      />
      <Route
        path="apprentices/view-profile/:id/program/:member_program_id/target"
        exact
        element={<Target />}
      />
      <Route
        path="apprentices/view-profile/:id/program/:programId/task-list"
        exact
        element={<TaskList />}
      />
      <Route
        path="apprentices/view-profile/:id/program/:programId/task-list/:lectureDetail/activitityDetail"
        exact
        element={<ActivityDetail />}
      />
      <Route
        path="apprentices/view-profile/:id/program/:programId/task-list/:lectureDetail/activitityDetail/:activityId/:memberSectionId"
        exact
        element={<ActivityDetail />}
      />
      <Route
        path="apprentices/view-profile/:id/program/:programId/quick-links"
        exact
        element={<QuickLinks />}
      />
      <Route
        path="apprentices/view-profile/:id/program/:programId/quick-links/:lectureDetail/activitityDetail"
        exact
        element={<ActivityDetail />}
      />
      <Route
        path="apprentices/view-profile/:id/program/:programId/quick-links/:lectureDetail/activitityDetail/:activityId/:memberSectionId"
        exact
        element={<ActivityDetail />}
      />
      <Route
        path="apprentices/view-profile/:id/program/:programId/resubmission"
        exact
        element={<Resubmission />}
      />
      <Route
        path="apprentices/view-profile/:id/program/:programId/resubmission/:lectureDetail/activitityDetail/:activityId/:memberSectionId"
        exact
        element={<ActivityDetail />}
      />
      <Route
        path="apprentices/view-profile/:id/program/:programId/assessors"
        exact
        element={<MemberAssessors />}
      />
      <Route
        path="Review/"
        element={<Review />}
      />
      {/* Progress Reports Route */}
      <Route
        path="users/programs/active"
        exact
        element={<ActivePrograms />}
      />
      <Route
        path="users/apprentices/active"
        exact
        element={<ActiveApprentices />}
      />
      <Route
        path="users/programs/progress/:programId"
        exact
        element={<ProgrammeProgress />}
      />
      <Route
        path="users/programs/active/progress/:programId/:memberProgramId"
        exact
        element={<ProgramDetail />}
      />
      <Route
        path="users/programs/active/progress/:memberProgramId"
        exact
        element={<ProgramDetail />}
      />
      <Route
        path="users/programs/active/progress/learner-program-detail"
        exact
        element={<ProgramDetail />}
      />
      <Route
        path="assessor/report"
        exact
        element={<AssessorReport />}
      />
      <Route
        path="users/apprentice/program/report"
        exact
        element={<ProgrammeProgress />}
      />
      <Route
        path="program/:programId/rating-review"
        exact
        element={<RatingReview />}
      />
      <Route
        path="external-exam"
        element={<ExternalExamComponentList />}
      />
      <Route
        path="external-exam/:examId"
        element={<ExternalExamLearnerList />}
      />
      <Route
        path="event"
        element={<EventComponentList />}
      />
      <Route
        path="event/:eventId"
        element={<EventLearnerList />}
      />
      <Route
        path="*"
        element={<NoMatch />}
      />
    </Route>
  </Routes>
);
