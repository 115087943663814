import { Modal, Button } from 'react-myoneui';

export const MessageBox = (props) => {
  const { show, onClose, title, description, messageImage } = props;

  const handleClose = () => {
    onClose();
  };
  return (
    <Modal
      size="xl"
      show={show}
      onHide={handleClose}
      centered
      contentClassName="bg-transparent message-modal-box"
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {messageImage && (
          <div className="resource-file-wrap">
            <img
              alt={messageImage}
              src={require('../assets/images/dms_preview.png')}
            />
          </div>
        )}
        <p className="fw-bold">{description}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="glitter"
          onClick={handleClose}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
