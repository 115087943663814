import axios from 'axios';
import { API_URL } from '../constants/config';
import ls from 'localstorage-slim';
import Log from '../lib/Log';
// enable global encryption
ls.config.encrypt = true;
export const createProfileCustomField = async (data) => {
  try {
    const { orgId, ...payload } = data;
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token') || payload?.token}`,
        organisationid: `${ls.get('orgId') || payload?.orgId}`,
      },
    };
    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}/admin/profile-custom-field/create`, payload, headers)
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    return { date: null, message: error };
  }
};

export const editProfileCustomField = async (data) => {
  try {
    const { orgId, ...payload } = data;
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token') || payload?.token}`,
        organisationid: `${ls.get('orgId') || payload?.orgId}`,
      },
    };
    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}/admin/profile-custom-field/update`, payload, headers)
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    return { date: null, message: error };
  }
};

export const getProfileCustomField = async (orgId) => {
  const headers = {
    headers: {
      organisationid: `${ls.get('orgId') || orgId}`,
      Authorization: `Bearer ${ls.get('token')}`,
    },
  };
  try {
    return await axios.get(`${API_URL}/admin/profile-custom-fields/get`, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const createEnrollmentCustomField = async (data) => {
  try {
    const { orgId, ...payload } = data;
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token') || payload?.token}`,
        organisationid: `${ls.get('orgId') || payload?.orgId}`,
      },
    };
    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}/admin/enrollment-custom-field/create`, payload, headers)
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    Log.error(error);
  }
};

export const editEnrollmentCustomField = async (data) => {
  try {
    const { orgId, ...payload } = data;
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token') || payload?.token}`,
        organisationid: `${ls.get('orgId') || payload?.orgId}`,
      },
    };
    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}/admin/enrollment-custom-field/update`, payload, headers)
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    return { date: null, message: error };
  }
};

export const getEnrollmentCustomField = async (programId) => {
  const headers = {
    headers: {
      organisationid: `${ls.get('orgId')}`,
      Authorization: `Bearer ${ls.get('token')}`,
    },
  };
  try {
    return await axios.get(`${API_URL}/admin/enrollment-custom-fields/${programId}/get`, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteProfileCustomField = async (data) => {
  const { orgId, id } = data;
  const headers = {
    headers: {
      organisationid: orgId,
      Authorization: `Bearer ${ls.get('token')}`,
    },
  };
  try {
    return await axios.delete(`${API_URL}/admin/profile-custom-field/${id}/delete`, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteEnrollmentCustomField = async (data) => {
  const { orgId, id } = data;
  const headers = {
    headers: {
      organisationid: orgId,
      Authorization: `Bearer ${ls.get('token')}`,
    },
  };
  try {
    return await axios.delete(`${API_URL}/admin/enrollment-custom-field/${id}/delete`, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const editCustomFields = async (custom_fields) => {
  try {
    const payload = {
      custom_fields,
      id: ls.get('userid'),
    };
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token') || payload?.token}`,
        organisationid: `${ls.get('orgId') || payload?.orgId}`,
      },
    };
    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}/member/update/profile-custom-field`, payload, headers)
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    return { date: null, message: error };
  }
};
export const editProgramCustomFields = async (payload) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token') || payload?.token}`,
        organisationid: `${ls.get('orgId') || payload?.orgId}`,
      },
    };
    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}/apprentice/program/update/custom-fields`, payload, headers)
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    return { date: null, message: error };
  }
};
