import { useParams, Link, useLocation } from 'react-router-dom';
import { leftArrow, feedbackIcon } from '../../../constants/icons';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ls from 'localstorage-slim';
import Log from 'lib/Log';

// enable global encryption
ls.config.encrypt = true;
export const learnerFilterSidebarList = async (activePath, preview, result, program) => {
  const activeRole = JSON.parse(ls.get('activeRole'));
  const roles = JSON.parse(ls.get('roles'));
  const existingIdentifiers = roles && roles.length > 0 && roles.map((role) => role.identifier);

  const computeActivePath = (path) => {
    const expectedPath = ['/lecture-list', '/task-list', '/target', '/quick-links', '/resubmission', '/assessors', '/reports'];
    const currentActiveExpectedPath = expectedPath.find((path) => activePath.includes(path));
    const splittedPath = activePath?.split(currentActiveExpectedPath);
    return splittedPath.length > 1 ? `${splittedPath[0]}${path}` : `${activePath}${path}`;
  };

  if (result) {
    return [
      {
        text: 'Lecture List',
        name: 'lecture-list',
        type: 'item',
        // link: !preview ? `${activePath}/lecture-list` : activePath,
        link: !preview ? computeActivePath('/lecture-list') : '#',

        isEnable: true,
      },
      {
        text: 'Task List',
        name: 'task-list',
        type: 'item',
        // link: `${activePath}/task-list`,
        link: computeActivePath(`/task-list`),

        isEnable: program?.enable_mandatory_option && !preview,
      },
      {
        text: result?.target,
        name: 'target',
        type: 'item',
        // link: `${activePath}/target`,
        link: computeActivePath(`/target`),

        isEnable: activeRole.privileges?.can_view_smart_target && !preview,
      },

      {
        text: 'Quick Access',
        type: 'item',
        name: 'quick-links',
        // link: `${activePath}/quick-links`,
        link: computeActivePath(`/quick-links`),

        isEnable: !preview,
        count: '21',
        className: 'feedback-sidebar-wrap',
      },
      {
        text: 'Resubmission',
        type: 'item',
        name: 'resubmission',
        // link: `${activePath}/resubmission`,
        link: computeActivePath('/resubmission'),

        isEnable: !preview,
        count: '21',
        className: 'feedback-sidebar-wrap',
      },
      {
        text: roles.filter((role) => role.identifier === 'assessor')[0]?.name + 's' ?? '',
        type: 'item',
        name: 'assessors',
        // link: `${activePath}/assessors`,
        link: computeActivePath('/assessors'),

        isEnable: activeRole.privileges?.can_view_assessor && existingIdentifiers.includes('assessor') && !preview ? true : false,
        count: '',
        className: 'feedback-sidebar-wrap',
      },
      {
        text: 'Custom Fields',
        type: 'item',
        name: 'custom-fields',
        link: computeActivePath('/custom-fields'),
        isEnable: !preview ? true : false,
        className: 'feedback-sidebar-wrap',
      },
      {
        text: 'Reports',
        type: 'item',
        name: 'reports',
        link: computeActivePath('/reports'),
        className: 'feedback-sidebar-wrap',
        isEnable: !preview,
      },
    ];
  }
};

function LearnerRolesFilterSidebar({ backpage, preview = false, profileId }) {
  const [learnerRolesFilterSidebar, setLearnerRolesFilterSidebar] = useState([]);
  const programId = useSelector((state) => state?.memberProgram?.program?.id);
  const program = useSelector((state) => state?.memberProgram?.program);
  const role = ls.get('role');
  const feedbacks = useSelector((state) => state?.feedbacks?.data);
  const sections = useSelector((state) => state.sections.sections);
  const { pathname } = useLocation();
  const roles = JSON.parse(ls.get('roles'));
  const taxonomy = useSelector((state) => state?.taxonomy?.data);

  const getTax = async () => {
    try {
      const pathSplit = pathname?.split('/');
      const originalPath = pathSplit?.splice(0, pathSplit.length - 1);
      const back = originalPath.join('/');
      const activePath = back;
      let data = await learnerFilterSidebarList(activePath, preview, taxonomy, program);
      if (data) {
        data = data.filter((sidebar) => sidebar.isEnable);
        setLearnerRolesFilterSidebar(data);
      }
    } catch (error) {
      Log.error(error);
    }
  };

  const onLinkClick5 = (e) => {
    try {
      const hrefSplit = e.target.href?.split('#');
      if (hrefSplit.length > 2) {
        const id = hrefSplit[hrefSplit.length - 1];
        const item = document.getElementById(id);
        if (item) {
          item.scrollIntoView({ block: 'center' });
        }
      }
    } catch (error) {
      Log.error(error);
    }
  };
  const location = useLocation();
  const listSidebarItems = learnerRolesFilterSidebar.map((child, i) => {
    const pageReg = new RegExp(child.name);
    const isActive = pageReg.test(location.pathname) || preview;
    if (child.type === 'divider') {
      return (
        <li
          key={i}
          className="divider"
        />
      );
    }
    return (
      <li
        key={i}
        className={isActive ? 'active' : ''}
      >
        <Link
          to={child.link}
          className={child.className}
          //onClick={onLinkClick}
        >
          <span className="text-truncate">{child.text} </span>
          {child.text === 'Quick Access' && child.count && (
            <span
              className="feedback-badge"
              title={feedbacks?.totalNewFeedbacksCount}
            >
              {feedbacks?.totalNewFeedbacksCount > 0 && (
                <>
                  <span className="feedback-icon">
                    {feedbackIcon} <span className="feedback-dot"></span>
                  </span>
                  <span className="feedback-sidebar-count">{feedbacks?.totalNewFeedbacksCount}</span>
                </>
              )}
            </span>
          )}
          {child.text === 'Resubmission' && child.count && (
            <span
              className="feedback-badge"
              title={sections?.totalReSubmissions}
            >
              {feedbacks?.totalReSubmissions > 0 && (
                <>
                  <span className="feedback-icon">
                    {feedbackIcon} <span className="feedback-dot"></span>
                  </span>
                  <span className="feedback-sidebar-count">{feedbacks?.totalReSubmissions}</span>
                </>
              )}
            </span>
          )}
        </Link>
      </li>
    );
  });
  const setApprenticesRoute = () => {
    try {
      if (role === 'admin') {
        return '/' + role + '/users/apprentices/view-profile/' + profileId;
      } else {
        return '/' + role + '/apprentices/view-profile/' + profileId;
      }
    } catch (error) {
      Log.error('Error on setApprenticesRoute :', error);
    }
  };
  useEffect(() => {
    if (taxonomy) {
      getTax();
    }
  }, [taxonomy, programId]);
  return (
    <div className="dynamic-inner-sidebar">
      <div className="fixed-sidebar-back">
        {backpage && backpage === 'learner-list' ? (
          <Link
            className="sidebar-back-nav"
            to={`/${role ? role : 'admin'}/program/${programId}/learner-list`}
          >
            {leftArrow}
            {roles.filter((role) => role.identifier === 'apprentice')[0].name}s List
          </Link>
        ) : backpage && backpage === 'program' ? (
          <Link
            className="sidebar-back-nav"
            to={`/${role ? role : 'admin'}/program/${programId}/learner-list`}
          >
            {leftArrow}
            {roles.filter((role) => role.identifier === 'apprentice')[0].name}s List
          </Link>
        ) : backpage && backpage === 'apprentices' ? (
          <Link
            className="sidebar-back-nav"
            to={setApprenticesRoute()}
          >
            {leftArrow}
            Profile
          </Link>
        ) : (
          <Link
            className="sidebar-back-nav"
            disabled={preview}
            to={pathname?.split('program')[0]}
          >
            {leftArrow}
            <span
              className="text-truncate"
              title="Back"
            >
              Back
            </span>
          </Link>
        )}

        <span className="divider" />
      </div>

      <ul className="sidebar-list">{listSidebarItems}</ul>
    </div>
  );
}

export default LearnerRolesFilterSidebar;
