import { Link, useLocation, useParams } from 'react-router-dom';

import { useSelector } from 'react-redux';
import ls from 'localstorage-slim';
import LinkWrapper from 'components/LinkWrapper';
import { translate } from 'lib';
import { leftArrow } from 'constants/icons';

// enable global encryption
ls.config.encrypt = true;
function ViewUnitSidebar() {
  const { sectionId } = useParams();
  const location = useLocation();
  const taxonomy = useSelector((state) => state.taxonomy.data);

  const viewUnitSidebarList = [
    {
      text: `${translate(taxonomy)('section')}`,
      type: 'item',
      link: `/admin/unit-bank/section/${sectionId}/view`,
      isReact: true,
      is_enable: true,
    },
  ];
  const enabledChild = viewUnitSidebarList && viewUnitSidebarList.length > 0 && viewUnitSidebarList.filter((child) => child.is_enable);
  const listSidebarItems = enabledChild.map((child, i) => {
    const isActive = location.pathname === child.link;
    if (child.type === 'divider') {
      return (
        <li
          key={i}
          className="divider"
        />
      );
    }
    return (
      <li
        key={i}
        className={isActive ? 'active' : ''}
      >
        <LinkWrapper
          isReact={child.isReact}
          href={child.link}
          className={child.className}
        >
          {child.text}
        </LinkWrapper>
      </li>
    );
  });

  return (
    <div className="dynamic-inner-sidebar">
      <div className="fixed-sidebar-back">
        <Link
          className="sidebar-back-nav"
          to={`/admin/unit-bank`}
        >
          {leftArrow}
          <span
            className="text-truncate"
            title={`${translate(taxonomy)('section')} Bank`}
          >{`${translate(taxonomy)('section')} Bank`}</span>
        </Link>
        <span className="divider" />
      </div>
      <ul className="sidebar-list">{listSidebarItems}</ul>
    </div>
  );
}

export default ViewUnitSidebar;
