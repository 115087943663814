import React from 'react';
import { useState, useRef } from 'react';
import Offcanvas from 'react-myoneui/lib/Offcanvas';
import Button from 'react-myoneui/lib/Button';
import Form from 'react-myoneui/lib/Form';
import FloatingLabel from 'react-myoneui/lib/FloatingLabel';
import Spinner from 'react-myoneui/lib/Spinner';
import { fileUploader } from '../../../../../../api';
import Log from '../../../../../../lib/Log';
function UploadFileOffCanvas(props) {
  const { onSave, onClose } = props;
  const offCanvasKeyDown = (e) => e.stopPropagation();
  const uploadFileInput = useRef(null);
  const [loading, setLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState({ name: '', link: '' });
  const uploadFileHandler = () => {
    uploadFileInput.current.click();
  };

  const uploadResourcesFile = async (e) => {
    setLoading(true);
    try {
      let result = await fileUploader(e.target.files[0]);
      if (result) {
        onSave({ ...uploadedFile, link: result?.data?.data[0]?.fileToSend?.Location });
      }
    } catch (error) {
      setLoading(false);
      Log.error(error);
    }
  };

  return (
    <Offcanvas
      show={props.show}
      onHide={onClose}
      placement="end"
      onKeyDown={offCanvasKeyDown}
    >
      <Offcanvas.Header
        closeButton
        backButton
      >
        <Offcanvas.Title>
          Upload File
          <p className="help-text w-75 fs-12">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {loading ? (
          <div className="spinner-middle">
            <Spinner animation="border" />
          </div>
        ) : (
          <div className="flex-content mb-4">
            <FloatingLabel
              controlId="sampleTitle"
              label="File Name"
            >
              <Form.Control
                type="text"
                placeholder="File Name"
                value={uploadedFile.name}
                onChange={(e) => {
                  setUploadedFile({ ...uploadedFile, name: e.target.value });
                }}
              />
            </FloatingLabel>
            <Button
              variant="outline"
              className="border border-primary text-primary browse-file-text"
              onClick={uploadFileHandler}
              disabled={!uploadedFile.name.trim()}
            >
              Browse File
            </Button>
            <input
              type="file"
              className="d-none"
              ref={uploadFileInput}
              onChange={(e) => uploadResourcesFile(e)}
            />
          </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default React.memo(UploadFileOffCanvas);
