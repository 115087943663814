import { Route } from 'react-router-dom';
import { lazyWithRetry } from 'components/lazyWithRetry';
const ProgramDetail = lazyWithRetry(() => import('modules/admin/users/programs/programDetail'));
const ProgrammeProgress = lazyWithRetry(() => import('modules/admin/users/programs/programProgress'));
const ActivePrograms = lazyWithRetry(() => import('modules/admin/users/programs/activePrograms'));
const AssessorReport = lazyWithRetry(() => import('modules/admin/users/assessors/activeApprentices'));
const ActiveApprentices = lazyWithRetry(() => import('modules/admin/users/apprentices/activeApprentices'));
const UserAssessors = lazyWithRetry(() => import('modules/admin/users/assessors'));
const UserTutors = lazyWithRetry(() => import('modules/admin/users/tutors'));
const UserApprentices = lazyWithRetry(() => import('modules/admin/users/apprentices'));
const ProfileActivity = lazyWithRetry(() => import('modules/admin/profile'));
const ProfileFieldManagement = lazyWithRetry(() => import('modules/admin/users/FieldManagement'));
const LectureList = lazyWithRetry(() => import('modules/learner/program/LectureList'));
const Resubmission = lazyWithRetry(() => import('modules/learner/program/Resubmission/'));
const MemberAssessors = lazyWithRetry(() => import('modules/learner/program/assessors/'));
const UserManagement = lazyWithRetry(() => import('modules/admin/users'));
const UserIQVs = lazyWithRetry(() => import('modules/admin/users/iqvs'));
const UserEQVs = lazyWithRetry(() => import('modules/admin/users/eqvs'));
const IQVAssessors = lazyWithRetry(() => import('modules/admin/users/iqvs/assessors'));
const EQVIqvs = lazyWithRetry(() => import('modules/admin/users/eqvs/iqvs'));
const Program = lazyWithRetry(() => import('modules/admin/program'));
const MemberProgram = lazyWithRetry(() => import('modules/iqv/learnerProgram'));

export default (
  <>
    <Route
      path="users/tutors/:tutorId/list-programs"
      element={<Program />}
    />
    {/* Users > IQVs  */}
    <Route
      path="users/iqvs/"
      exact
      element={<UserIQVs />}
    />
    <Route
      path="users/iqvs/:iqvid/assessors"
      exact
      element={<IQVAssessors />}
    />
    <Route
      path="users/iqvs/:iqvid/assessors/view-profile/:id"
      exact
      element={<ProfileActivity />}
    />
    {/* Users> EQVs */}
    <Route
      path="users/eqvs/"
      exact
      element={<UserEQVs />}
    />
    <Route
      path="users/eqvs/:eqvid/iqvs"
      exact
      element={<EQVIqvs />}
    />
    <Route
      path="users/eqvs/:eqvid/iqvs/view-profile/:id"
      exact
      element={<ProfileActivity />}
    />
    {/* Users > Assessors  */}
    <Route
      path="users/assessors/"
      exact
      element={<UserAssessors />}
    />

    <Route
      path="users/assessors/:id/learner-program/"
      element={<MemberProgram />}
    />
    {/* Users > Tutors start */}
    <Route
      path="users/tutors/"
      exact
      element={<UserTutors />}
    />
    {/* Users > Tutors end  */}
    <Route
      path="users/apprentices/"
      exact
      element={<UserApprentices />}
    />
    <Route
      path="users/apprentices/active"
      exact
      element={<ActiveApprentices />}
    />
    <Route
      path="users/programs/active"
      exact
      element={<ActivePrograms />}
    />

    <Route
      path="users/programs/progress/:programId"
      exact
      element={<ProgrammeProgress />}
    />
    <Route
      path="users/apprentice/program/report"
      exact
      element={<ProgrammeProgress />}
    />
    <Route
      path="users/programs/active/progress/:programId/:memberProgramId"
      exact
      element={<ProgramDetail />}
    />
    <Route
      path="users/programs/active/progress/:memberProgramId"
      exact
      element={<ProgramDetail />}
    />
    <Route
      path="users/programs/active/progress/learner-program-detail"
      exact
      element={<ProgramDetail />}
    />
    <Route
      path="users/tutors/:tutorId/list-learners"
      exact
      element={<UserApprentices name="tutorApprentices" />}
    />
    <Route
      path="users/users-management/view-profile/:id"
      exact
      element={<ProfileActivity />}
    />
    <Route
      path="assessor/report"
      exact
      element={<AssessorReport />}
    />

    {/* <Route
      path="users/users-management/view-profile/:id/program/:programId/lecture-list"
      exact
      element={<LectureList />}
    />
    <Route
      path="users/apprentices/view-profile/:id/program/:programId/resubmission"
      exact
      element={<Resubmission />}
    />
    <Route
      path="users/apprentices/view-profile/:id/program/:programId/assessors"
      exact
      element={<MemberAssessors />}
    /> */}
    <Route
      path="users/users-management"
      exact
      element={<UserManagement />}
    />
    <Route
      path="users/field-management"
      exact
      element={<ProfileFieldManagement />}
    />
  </>
);
