import React from 'react';

const HeaderSelectionCellTemplate = ({
  tableManager,
  ref = tableManager.rowSelectionApi.selectAll.ref,
  onChange = tableManager.rowSelectionApi.selectAll.onChange,
  checked = tableManager.rowSelectionApi.selectAll.checked,
  disabled = tableManager.rowSelectionApi.selectAll.disabled,
}) => {
  const {
    config: {
      additionalProps: { headerSelectionCell: additionalProps = {} },
    },
  } = tableManager;

  let classNames = (disabled ? 'rgt-disabled rgt-checkbox form-check-input' : 'rgt-clickable rgt-checkbox form-check-input ' + additionalProps.className || '').trim();

  return (
    <input
      {...additionalProps}
      className={classNames}
      type="checkbox"
      ref={ref}
      onChange={onChange}
      checked={checked}
      disabled={disabled}
    />
  );
};

export default HeaderSelectionCellTemplate;
