import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import Offcanvas from 'react-myoneui/lib/Offcanvas';
import Button from 'react-myoneui/lib/Button';
import Form from 'react-myoneui/lib/Form';
import { Row, Col, OffcanvasFooter } from 'react-myoneui';
import FloatingLabel from 'react-myoneui/lib/FloatingLabel';
import Log from 'lib/Log';
import DropdownCommon from 'components/DropdownCommon';
import { convertTimeToSecs, covertSecsToTimeData } from 'lib/common';

function EditVideoTagOffCanvas(props) {
  const { onSave, onClose, oldTags, data } = props;
  const offCanvasKeyDown = (e) => e.stopPropagation();
  const [showHrs, setShowHrs] = useState(false);
  const [showMins, setShowMins] = useState(false);
  const [showSecs, setShowSecs] = useState(false);
  const [defaultHrs, setDefaultHrs] = useState(null);
  const [defaultMins, setDefaultMins] = useState(null);
  const [defaultSecs, setDefaultSecs] = useState(null);
  const [tags, setTags] = useState([]);
  const [msg, setMsg] = useState({
    status: false,
    text: '',
  });
  const [tag, setTag] = useState({
    tag: '',
    hrs: '00',
    mins: '00',
    secs: '00',
  });
  const hrs = () => {
    let i = 0;
    let data = [];
    while (i < 24) {
      let txt = i < 10 ? '0' + i : i.toString();
      data.push({ id: i + 1, name: txt });
      i++;
    }
    return data;
  };
  const mins = () => {
    let i = 0;
    let data = [];
    while (i < 60) {
      let txt = i < 10 ? '0' + i : i.toString();
      data.push({ id: i + 1, name: txt });
      i++;
    }
    return data;
  };
  const secs = () => {
    let i = 0;
    let data = [];
    while (i < 60) {
      let txt = i < 10 ? '0' + i : i.toString();
      data.push({ id: i + 1, name: txt });
      i++;
    }
    return data;
  };
  const changeHrs = (data) => {
    setTag({
      ...tag,
      hrs: data.name,
    });
  };

  const changeMins = (data) => {
    setTag({
      ...tag,
      mins: data.name,
    });
  };
  const changeSecs = (data) => {
    setTag({
      ...tag,
      secs: data.name,
    });
  };

  const isDuplicate = (data) => {
    console.log(data);
    var time = convertTimeToSecs(data.hrs, data.mins, data.secs);
    console.log(time);
    var remainTags = oldTags.filter((item) => item.id !== data.id);
    console.log(remainTags);
    return remainTags.filter((item) => item.time === time)?.length > 0;

    // convertTimeToSecs
  };

  const submit = () => {
    if (!isDuplicate(tag)) {
      onSave({ tag: tag.tag, id: tag.id, time: convertTimeToSecs(tag.hrs, tag.mins, tag.secs) });
    } else {
      setMsg({
        status: true,
        text: 'Sorry, duplicate time is mentioned',
      });
    }
  };

  const editTag = (data) => {
    try {
      console.log(data);
      setTag({ ...tag, ...data });
      let hrObj = hrs().find((item) => item.name === data.hrs.toString());
      setShowHrs(false);
      setDefaultHrs(hrObj);
      console.log(mins());
      let minObj = mins().find((item) => item.name === data.mins.toString());
      console.log(minObj);
      setDefaultMins(minObj);
      setShowMins(false);
      console.log(secs());
      let secObj = secs().find((item) => item.name === data.secs.toString());
      console.log(secObj);
      setDefaultSecs(secObj);
      setShowSecs(false);

      setTimeout(() => {
        setShowHrs(true);
        setShowMins(true);
        setShowSecs(true);
      }, 500);
    } catch (error) {
      Log.error('Error on editTag :', error);
    }
  };

  useEffect(() => {
    console.log(data);

    editTag({ ...data });
  }, [data]);
  return (
    <Offcanvas
      show={props.show}
      onHide={onClose}
      placement="end"
      onKeyDown={offCanvasKeyDown}
    >
      <Offcanvas.Header
        closeButton
        backButton
      >
        <Offcanvas.Title>
          Edit Video Tag
          <p className="help-text w-75 fs-12">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <>
          <Row>
            <Col lg={12}>
              <FloatingLabel
                controlId="sampleTitle"
                label="Name"
                className="mb-3"
              >
                <Form.Control
                  name="name"
                  type="text"
                  value={tag?.tag}
                  placeholder="Put Tag Name"
                  onChange={(e) => setTag({ ...tag, tag: e.target.value })}
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col
              lg={4}
              className="mt-2"
            >
              {showHrs && (
                <DropdownCommon
                  autoComplete={false}
                  label="hrs"
                  items={hrs()}
                  defaultValue={defaultHrs}
                  onChange={changeHrs}
                />
              )}
            </Col>
            <Col
              lg={4}
              className="mt-2"
            >
              {showMins && (
                <DropdownCommon
                  defaultValue={defaultMins}
                  items={mins()}
                  label={'mins'}
                  onChange={changeMins}
                />
              )}
            </Col>
            <Col
              lg={4}
              className="mt-2"
            >
              {showSecs && (
                <DropdownCommon
                  label="secs"
                  className=""
                  items={secs()}
                  defaultValue={defaultSecs}
                  onChange={changeSecs}
                />
              )}
            </Col>
          </Row>

          {msg.status && (
            <Row>
              <Col className="p-3 text-danger">{msg.text}</Col>
            </Row>
          )}
        </>
      </Offcanvas.Body>
      <OffcanvasFooter>
        <Button
          className="m-2"
          variant="secondary"
          onClick={submit}
        >
          Submit
        </Button>
      </OffcanvasFooter>
    </Offcanvas>
  );
}

export default React.memo(EditVideoTagOffCanvas);
