import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: false,
  token: '',
  lang_src_url: '',
  tenant_domain: '',
  account_domain: '',
  account_id: '',
  is_custom_language: '',
  logo_url: '',
  header: {},
  profile: {},
  product: {},
  products: [],
  sidebar: [],
  us_role_id: '',
  us_timezone: '',
  us_uid: '',
  us_bms_role_id: '',
  us_dms_role_id: '',
  us_name: '',
  message_api_url: '',
  user_id: '',
  us_email_verified: '',
  secret: '',
  signature: '',
  features: {},
  thirdparty: {},
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setAdminData: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      for (let x in action.payload) {
        if (x in state) {
          state[x] = action.payload[x];
        }
      }
    },
    resetAdminData: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAdminData, resetAdminData } = adminSlice.actions;

export default adminSlice.reducer;
