import React, { useEffect, useState, useRef } from 'react';
import { Form, Modal, Button } from 'react-myoneui';
import FloatingLabel from 'react-myoneui/lib/FloatingLabel';
import { Spinner } from 'react-myoneui';
import Log from 'lib/Log';
export const ConfirmBox = (props) => {
  const {
    show,
    question,
    info,
    okText,
    tutorRemoveBody,
    editRequiredHourBody,
    restoreDefault,
    editRequiredHour,
    bodyData,
    cancelText,
    onOk,
    onHide,
    memberProgramCheckBox,
    deleteMemberProgram = false,
    onSetDeleteMemberProgram = () => {},
    loading,
  } = props;
  const checkedHandler = (e) => {
    onSetDeleteMemberProgram(e.target.checked);
    setDeletAllStatus(e.target.checked);
  };

  const [tutorEmail, setTutorEmail] = useState('');
  const [deletAllStatus, setDeletAllStatus] = useState(false);
  const [requiredHour, setRequiredHour] = useState(null);
  const invalidNumbers = ['e', 'E', '.', '+', '-'];

  const okHandler = () => {
    try {
      setDeletAllStatus(false);
      onOk(requiredHour);
    } catch (err) {
      Log.error(err);
    }
  };
  useEffect(() => {
    if (editRequiredHour && editRequiredHourBody) {
      setRequiredHour(null);
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      contentClassName="bg-transparent"
    >
      {question && (
        <Modal.Header>
          <Modal.Title>{question}</Modal.Title>
        </Modal.Header>
      )}
      {info && (
        <Modal.Body>
          <p>{info}</p>
        </Modal.Body>
      )}

      {memberProgramCheckBox && (
        <Modal.Body>
          <Form.Check className="text-start">
            <Form.Check.Input
              type="checkbox"
              checked={deletAllStatus}
              id="memberProgramChecked"
              onChange={checkedHandler}
            />
            <Form.Check.Label htmlFor="memberProgramChecked">Delete all member program as well</Form.Check.Label>
          </Form.Check>
        </Modal.Body>
      )}

      {((tutorRemoveBody && bodyData) || restoreDefault) && (
        <>
          <Modal.Body>
            <FloatingLabel
              controlId="floatingInput"
              label="Email address"
              className="mt-3 text-start"
            >
              <Form.Control
                type="email"
                placeholder="name@example.com"
                onChange={(e) => setTutorEmail(e.target.value)}
              />
            </FloatingLabel>

            {tutorRemoveBody && bodyData && (
              <FloatingLabel
                label="Phone Number"
                className="mt-3 text-start"
              >
                <Form.Control
                  type="text"
                  defaultValue={`${bodyData?.phone_country_code}  ${bodyData?.phone_no}`}
                  readOnly
                />
              </FloatingLabel>
            )}
          </Modal.Body>
        </>
      )}

      {editRequiredHourBody && (
        <>
          <Modal.Body>
            <FloatingLabel
              label="Original Required Hours"
              className="mt-3 text-start"
            >
              <Form.Control
                type="number"
                min={1}
                value={editRequiredHourBody}
                readOnly
              />
            </FloatingLabel>
          </Modal.Body>
          <Modal.Body>
            <FloatingLabel
              label="Required Hours"
              className="mt-3 text-start"
            >
              <Form.Control
                type="number"
                min={1}
                value={requiredHour}
                onKeyDown={(e) => invalidNumbers.includes(e.key) && e.preventDefault()}
                onChange={(e) => setRequiredHour(e.target.value)}
              />
            </FloatingLabel>
          </Modal.Body>
        </>
      )}

      <Modal.Footer>
        {!tutorRemoveBody && !editRequiredHour && (
          <>
            {' '}
            {onHide && (
              <Button
                variant="glitter"
                onClick={onHide}
              >
                {cancelText ? cancelText : ' No, Cancel'}
              </Button>
            )}
            {onOk && (
              <Button
                variant="primary"
                onClick={okHandler}
              >
                <div className="d-flex">
                  {loading && (
                    <span>
                      <Spinner
                        animation="border"
                        size="sm"
                      />{' '}
                      &nbsp; &nbsp;
                    </span>
                  )}

                  {okText ? okText : ' Yes, Confirm'}
                </div>
              </Button>
            )}
          </>
        )}

        {(tutorRemoveBody || restoreDefault) && (
          <>
            {' '}
            <Button
              variant="glitter"
              onClick={onHide}
            >
              {cancelText ? cancelText : ' No, Cancel'}
            </Button>
            <Button
              variant="primary"
              disabled={tutorEmail.length < 5}
              onClick={() => onOk(tutorEmail)}
            >
              <div className="d-flex">
                {loading && (
                  <span>
                    <Spinner
                      animation="border"
                      size="sm"
                    />{' '}
                    &nbsp; &nbsp;
                  </span>
                )}

                {okText ? okText : ' Yes, Confirm'}
              </div>
            </Button>
          </>
        )}

        {editRequiredHour && (
          <>
            {' '}
            <Button
              variant="glitter"
              onClick={onHide}
            >
              {cancelText ? cancelText : 'Cancel'}
            </Button>
            <Button
              variant="primary"
              disabled={requiredHour < 1}
              onClick={() => onOk(requiredHour)}
            >
              {okText ? okText : 'Save'}
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};
