import axios from 'axios';
import { API_URL } from '../../constants/config';
import ls from 'localstorage-slim';
import Log from '../../lib/Log';
// enable global encryption
ls.config.encrypt = true;

export const enrollSubscription = (payload) => {
  /*
  payload  :{
    "member": "656dabb1f4f2c6fa8bc4c64c",
    "component" : "656d96faf4f2c6fa8bc48df4"
  }
  */
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token') || payload?.token}`,
        organisationid: `${ls.get('orgId')}`,
      },
    };

    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}/apprentice/sync/course/enrollment/subscription`, { ...payload }, headers)
        .then((result) => resolve(result))
        .catch((error) => {
          reject(error);
          console.log('error');
        });
    });
  } catch (error) {
    return false;
    console.log('Error on enrollSubscription :', error);
  }
};
export const createCourseComponent = (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: `${payload.orgId}`,
    },
  };

  return new Promise((resolve, reject) => {
    return axios
      .post(`${API_URL}/admin/program/course/component/create`, { ...payload }, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};
export const getCoursesList = (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: payload.orgId,
    },
  };

  return new Promise((resolve, reject) => {
    return axios
      .get(
        `${API_URL}/admin/courses?page_limit=${payload.limit ? payload.limit : 10}&page=${payload.page ? payload.page : 1}&status=1&search_keyword=${
          payload.search_keyword ? payload.search_keyword : ''
        }`,
        headers,
      )
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};
export const getComponentList = (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: payload.orgId,
    },
  };
  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/admin/program/${payload.programId}/components`, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const updateCourseComponent = async (payload) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token') || payload?.token}`,
        organisationid: `${payload.orgId}`,
      },
    };
    return await axios.patch(`${API_URL}/admin/program/course/component/update`, payload, headers);
  } catch (err) {
    Log.error('Error >> ', err);
    throw new Error(err);
  }
};

export const findCategoryByCourseId = async (payload) => {
  /*
  Payload: 
  {course_id:Number}
  */
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token') || payload?.token}`,
        organisationid: ls.get('orgId'),
      },
    };
    return await axios.post(`${API_URL}/admin/program/course/categories`, payload, headers);
  } catch (err) {
    Log.error('Error >> ', err);
    throw new Error(err);
  }
};
