import { useState, useEffect } from 'react';
import SectionElement from './SectionElement';
import { getSections } from 'api/formBuilder';
import { Spinner } from 'react-myoneui';
import Log from 'lib/Log';
import { useDispatch, useSelector } from 'react-redux';
import { getSubSections, getFormElements } from 'api/formBuilder';
import { flushFormData, storeFormData } from '../../../redux/FormDataSlice';
import { getFormFields } from 'api/component/form';
export const ShowForm = ({ id = null, mode = 'preview', payloadData }) => {
  const elements = useSelector((state) => state.formData?.data?.filter((item) => item?.parent_id === id));
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [nodes, setNodes] = useState([]);
  const fetchFormData = async () => {
    try {
      let payload = { memberProgram: payloadData?.member_program, form: payloadData?.form };
      let result = await getFormFields(payload);
      return result.data.data.map((item) => {
        let obj = { ...item };
        obj.id = item.field;
        return obj;
      });
    } catch (error) {
      Log.error(error);
    }

    //onUpdate(result);
  };
  const removeDuplicate = (data) => {
    let coll = [];
    console.log(data);
  };
  const setUniqueOnly = (data) => {
    console.log(data);
    let collection = [];
    data.forEach((item) => {
      if (collection?.find((col) => col.id === item.id)) {
        console.log('no add');
      } else {
        console.log('add');
        collection.push(item);
      }
    });
    console.log(collection);
    return collection;
  };
  const fetchSections = async () => {
    try {
      setLoading('loading');

      let formFilledData = mode === 'preview' ? [] : await fetchFormData();

      var data = [];
      let sectionsResult = await getSections({ formId: id });

      let sectionData = sectionsResult.data.data;
      data = JSON.parse(JSON.stringify(sectionData));
      //dispatch(storeFormData(data));

      // Fetch all data of Sub Sections
      Promise.all(sectionData.map((item) => getSubSections({ sectionId: item.id }))).then((result) => {
        console.log(result);

        result.forEach((item) => {
          item.data.data.forEach((obj) => {
            data.push(obj);
          });
        });
        let allSubSections = data.filter((item) => item.title === 'Sub Section');
        // data = [...data, ...result.data.data];

        //data = [...data, ...allSubSections];
        // dispatch(storeFormData(data));

        // Fetch all form elements data
        Promise.all(allSubSections.map((allsub) => getFormElements({ subSectionId: allsub.id }))).then((elementResult) => {
          console.log(elementResult);
          elementResult.forEach((item) => {
            item.data.data.forEach((ele) => {
              console.log(ele);
              data.push(ele);
            });
          });

          // let allElements = [];
          // elementResult.map((elementsData) => {
          //   allElements = [...allElements, ...elementsData.data.data];
          // });
          /* If formElement have data need to implemented otherwise not */
          if (formFilledData.length > 0) {
            data = data.map((element) => {
              let obj = { ...element };
              if (formFilledData.length > 0) {
                let formFilledObj = formFilledData.find((ele) => ele.field === element.id);
                obj = {
                  ...obj,
                  ...formFilledObj,
                };
              }
              return obj;
            });
          }
          // data = [...data, ...allElements];
          // console.log(data);

          setTimeout(() => {
            dispatch(storeFormData(data));
          }, 1000);
        });
      });

      setLoading('loaded');
    } catch (error) {
      Log.error(error);
      setLoading('loaded');
    }
  };
  useEffect(() => {
    //fetchRoles();
    if (id) {
      fetchSections();
    }
  }, [id]);
  const updateNodes = (data) => {
    try {
      let cloneAllNodes = JSON.parse(JSON.stringify(nodes));
      data.array.forEach((element) => {
        cloneAllNodes.push(element);
      });
      setNodes(cloneAllNodes);
    } catch (error) {
      Log.error(error);
    }
  };

  useEffect(() => {
    dispatch(flushFormData());
  }, []);
  return loading === 'loading' ? (
    <div className="spinner-wrapper">
      <Spinner animation="border" />
    </div>
  ) : (
    <>
      {elements &&
        elements.length > 0 &&
        elements.map((ele) => (
          <SectionElement
            data={ele}
            key={ele.id}
            mode={mode}
            onUpdate={(data) => updateNodes(data)}
            payloadData={payloadData}
          />
        ))}
    </>
  );
};
