import { memo } from 'react';
import { Form, Col } from 'react-myoneui';
import { useDispatch, useSelector } from 'react-redux';
import { storeFormData } from '../../../redux/FormDataSlice';
import Log from 'lib/Log';
function InputText({ data, mode }) {
  const state = useSelector((state) => state.formData.data.find((item) => item.id === data.id));
  const formData = useSelector((state) => state.formData.data);
  const dispatch = useDispatch();

  const valueControl = (e) => {
    try {
      let cloneFormData = JSON.parse(JSON.stringify(formData));
      let obj = cloneFormData.find((item) => item.id === e.target.id);
      let index = cloneFormData.indexOf(obj);
      obj.value = e.target.value;
      cloneFormData[index] = obj;
      dispatch(storeFormData(cloneFormData));
    } catch (error) {
      Log.error(error);
    }
  };

  return (
    <>
      <Col
        className="py-2"
        md={state?.col_width}
        name="formElement"
      >
        <Form.Label
          htmlFor={state?.label + state?.field}
          className="form-builder-label"
        >
          {state?.label}
        </Form.Label>
        <Form.Control
          className={mode === 'preview' ? 'disabled' : ''}
          type={state?.field_type}
          id={state?.id}
          aria-describedby="passwordHelpBlock"
          placeholder={state?.placeholder}
          value={state?.value}
          readOnly={mode === 'readonly'}
          onChange={valueControl}
        />
      </Col>
    </>
  );
}
export default memo(InputText);
