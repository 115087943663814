import { memo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ls from 'localstorage-slim';
import Log from 'lib/Log';
import LinkWrapper from 'components/LinkWrapper';
import { leftArrow } from 'constants/icons';

// enable global encryption
ls.config.encrypt = true;
function FormBuilderSiedBar() {
  const { orgId, formId } = useParams();
  const activeRole = JSON.parse(ls.get('activeRole'));
  const roles = JSON.parse(ls.get('roles'));
  const location = useLocation();
  const allRoles = JSON.parse(ls.get('roles'));

  // for navigating we need role
  const pathRole = location.pathname.split('/')[1];
  const role = ls.get('role');
  const isTutor = role === 'tutor' && pathRole === 'tutor' && activeRole.identifier === 'tutor';
  const isIQV = role === 'iqv' && pathRole === 'iqv' && activeRole.identifier === 'iqv';
  const isAdmin = role === 'admin' && pathRole === 'admin' && activeRole.identifier === 'admin';
  const isAssessor = role == 'assessor' && pathRole === 'assessor' && activeRole.identifier === 'assessor';
  const currentActiveRole = isTutor ? 'tutor' : isAdmin ? 'admin' : isAssessor ? 'assessor' : isIQV ? 'iqv' : '';

  // const path = ls.get('domain') ? '/admin' : `/organization/${orgId}`;
  const path = ls.get('domain') ? `/${currentActiveRole}` : `/organization/${orgId}`;

  const programInformationRoute = () => {
    try {
      if (formId) {
        return `${path}/form-builder/${formId}`;
      }
    } catch (err) {
      Log.error(err);
    }
  };

  const FormBuilderFilterSidebarList = [
    {
      text: 'Basic Information',
      type: 'item',
      link: programInformationRoute(),
      isReact: true,
      is_enable: true,
    },

    {
      text: 'Form',
      type: 'item',
      link: `${path}/form-builder/${formId}/details`,
      isReact: true,
      is_enable: true,
    },
  ];
  const enabledChild = FormBuilderFilterSidebarList && FormBuilderFilterSidebarList.length > 0 && FormBuilderFilterSidebarList.filter((child) => child.is_enable);
  const listSidebarItems = enabledChild.map((child, i) => {
    const isActive = location.pathname === child.link;
    if (child.type === 'divider') {
      return (
        <li
          key={i}
          className="divider"
        />
      );
    }
    return (
      <li
        key={i}
        className={isActive ? 'active' : ''}
      >
        <LinkWrapper
          isReact={child.isReact}
          href={child.link}
          className={child.className}
        >
          {child.text}
        </LinkWrapper>
      </li>
    );
  });

  return (
    <div className="dynamic-inner-sidebar">
      <div className="fixed-sidebar-back">
        <Link
          className="sidebar-back-nav"
          to={`${path}/form-builder/`}
        >
          {leftArrow}
          <span
            className="text-truncate"
            title="Form Builder"
          >
            Form Builder
          </span>
        </Link>
        <span className="divider" />
      </div>
      <ul className="sidebar-list">{listSidebarItems}</ul>
    </div>
  );
}

export default memo(FormBuilderSiedBar);
