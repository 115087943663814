import { memo } from 'react';

function DropELementsDot() {
  return (
    <>
      <span className="drop-elements-dot top-dot"></span>
      <span className="drop-elements-dot right-dot"></span>
      <span className="drop-elements-dot bottom-dot"></span>
      <span className="drop-elements-dot left-dot"></span>
    </>
  );
}
export default memo(DropELementsDot);
