import CircleRadiusChart from './CircleRadiusChart';

export default function LearningProgress() {
  const activeProgram = [
    {
      name: 'Completed',
      value: 29,
    },
    {
      name: 'In progress',
      value: 18,
    },
    {
      name: 'Not Started',
      value: 14,
    },
  ];
  return (
    <div className="learning-progress-content">
      <CircleRadiusChart
        requestedMonths={6}
        data={activeProgram}
      />
    </div>
  );
}
