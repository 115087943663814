import axios from 'axios';
import ls from 'localstorage-slim';
import { API_URL } from '../constants/config';
import Log from '../lib/Log';

export const addLog = async (payload) => {
  /*
  payload: { model:string, model_name:string, from:string }
  */
  try {
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };
    return axios.post(`${API_URL}/admin/create/view-action-log`, payload, { headers });
  } catch (error) {
    Log.error(error);
  }
};
