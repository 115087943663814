import { deadlineIcon } from 'constants/icons';

export default function LearnerThumbnail(props) {
  const { className, imgSrc, timeText, title, children } = props;
  return (
    <div className={`learner-thumbnail ${className || ''}`}>
      <div className="img-wrap">
        <img
          loading="lazy"
          src={imgSrc}
          alt="Resume learning"
        />
      </div>
      <div className="info-wrap">
        {timeText && (
          <span>
            {deadlineIcon} {timeText}
          </span>
        )}
        {title && <h4 title={title}>{title}</h4>}
      </div>
      <>{children}</>
    </div>
  );
}
