import { Component } from 'react';
import PropTypes from 'prop-types';
import Log from '../lib/Log';
import { errorIcon } from 'constants/icons';
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    Log.error('Error', error);
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="content-wrapper">
          <div className="error-msg-block">
            {errorIcon}
            <h2>Something went wrong ...</h2>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default ErrorBoundary;
