import { useEffect, useState, memo } from 'react';
import Badge from 'react-myoneui/lib/Badge';
import { useSelector } from 'react-redux';
import { translate } from 'lib';
import Button from 'react-myoneui/lib/Button';
import { Link, useLocation } from 'react-router-dom';
import { getSectionTotalComponents } from 'api';
import Log from 'lib/Log';
// Images
import { addIcon, externalLinkIcon, ratingIcon } from '../../constants/icons';
import ls from 'localstorage-slim';
import { capitalizeWord, showTypeIcon } from 'lib/common';
import OffcanvasRating from './OffcanvasRating';

// enable global encryption
ls.config.encrypt = true;
const ProgramList = (props) => {
  const { pathname } = useLocation();
  const activeRole = JSON.parse(ls.get('activeRole'));
  const { can_create_smart_target, can_view_smart_target, can_manage_apprentices, can_give_ratings_and_reviews } = activeRole?.privileges;
  const role = ls.get('role');
  const pathRole = pathname.split('/')[1];
  const isLearner = role === 'learner' && pathRole === 'learner' && activeRole.identifier === 'apprentice';
  const isIQV = role === 'iqv' && pathRole === 'iqv' && activeRole.identifier === 'iqv';
  const isEQV = role === 'eqv' && pathRole === 'eqv' && activeRole.identifier === 'eqv';
  // const isAssessor = role === 'assessor' && pathRole === 'assessor' && activeRole.identifier === 'assessor';
  // const isTutor = role === 'tutor' && pathRole === 'tutor' && activeRole.identifier === 'tutor';
  // const isAdmin = role === 'admin' && pathRole === 'admin' && activeRole.identifier === 'admin';

  const taxonomy = useSelector((state) => state?.taxonomy?.data);
  const [totalComponent, setTotalComponent] = useState(0);
  const [loading, setLoading] = useState(false);
  let ProgressStatus = null;
  let {
    title,
    sub_title,
    dotCircle,
    weightagePercentage,
    progressValue,
    iconName,
    target,
    addTargetButton,
    onButtonClick,
    observationButton,
    onObservationClick,
    weightageStatus = false,
    programInfo,
    programName = '',
    component,
    column,
    id,
    type,
    preview = false,
    subSection,
    data,
    activeId,
  } = props;

  const fetchChildSections = async (member_section) => {
    try {
      setLoading(true);
      let result = await getSectionTotalComponents({ member_section });
      setLoading(false);
      setTotalComponent(result?.data.data.total_component);
    } catch (error) {
      setLoading(false);
      Log.error(error);
    }
  };
  const taxonomyMatch = {
    CourseComponent: 'course',
    UnitComponent: 'assignment',
    ResourceComponent: 'resources',
    OTJComponent: 'otj',
    FormComponent: 'forms',
    ReviewComponent: 'review',
    QuizComponent: 'quizBank',
  };
  switch (progressValue) {
    case 'Completed':
      ProgressStatus = <Badge className="badge">Completed</Badge>;
      break;
    case 'Submitted':
      ProgressStatus = <Badge className="badge warning">Submitted</Badge>;
      break;
    case 'In Progress':
      ProgressStatus = <Badge className="badge blue">In Progress</Badge>;
      break;
    case 'Not Started':
      ProgressStatus = <Badge className="badge dark">Not Started</Badge>;
      break;
    case 'ReSubmission':
      ProgressStatus = <Badge className="resubmission">ReSubmission</Badge>;
      break;
    default:
      ProgressStatus = <Badge>{progressValue}</Badge>;
  }

  useEffect(() => {
    /* If it is not component then it is section and have to fetch it's child components */
    if (!component && !preview) {
      fetchChildSections(id);
    }
  }, [id]);

  return (
    <>
      <div
        onClick={props.onClick}
        className={`program-list-block ${activeId === id ? 'active' : ''} ${props.className || ''}`}
      >
        <div className="program-list-header">
          <div className="program-list-header-wrap">
            {<div className="icon-wrap">{showTypeIcon(iconName)}</div>}
            {title && <h4>{title}</h4>}
          </div>
          {dotCircle && <span className="dot-circle"></span>}
        </div>
        <div className="program-list-body">
          <h5>
            {preview ? (
              <>{subSection}</>
            ) : (
              <>
                {loading && '...'}
                {!component && !loading && totalComponent}
                &nbsp;
                {sub_title ? sub_title : type ? translate(taxonomy)(taxonomyMatch[type]) : translate(taxonomy)('component')}
              </>
            )}
          </h5>

          {weightageStatus === true ? (
            <span>
              {`${translate(taxonomy)('weightage')} - `} <strong>{weightagePercentage ? weightagePercentage : 0}%</strong>
            </span>
          ) : (
            ''
          )}
        </div>
        <div className="program-list-footer">
          <div className="unit-bank-preview">
            {ProgressStatus}

            <>
              {!(data?.section?.section_type || component) && column === 1 && programInfo?.enable_mandatory_option && (
                <Button
                  variant="secondary"
                  className={`btn-round ${data?.section?.status ? data?.section?.status : 'mandatory'}-btn`}
                >
                  {capitalizeWord(data?.section?.status ? translate(taxonomy)(data?.section?.status) : translate(taxonomy)('mandatory'))}
                </Button>
              )}
            </>
          </div>
          <div className="program-list-target-info">
            {(activeRole.identifier === 'apprentice' || can_view_smart_target) && target && (
              <>
                <span>
                  {`${translate(taxonomy)('target')} - `} <strong>{`${target.nearestTargetDate} (${target.nearestTargetProgress}%)`}</strong>
                </span>

                {target.totalUpcommingTargets > 0 && (
                  <Link
                    to={`${pathname.split('lecture-list')[0]}target`}
                    state={{ from: 'LectureList', programName }}
                  >
                    +{target.totalUpcommingTargets} more
                  </Link>
                )}
              </>
            )}
            {data?.status === 'Completed' && data?.section?.parent_section === null && !data?.section?.component && can_give_ratings_and_reviews && programInfo?.isAllowedRatingsAndReviews ? (
              <OffcanvasRating
                triggerTitle={ratingIcon}
                data={data}
              />
            ) : (
              ''
            )}
          </div>
          {addTargetButton && observationButton && !component && (
            <div className="program-list-add-target justify-content-between">
              {(isLearner || can_manage_apprentices || isIQV || isEQV) && (
                <Button
                  onClick={() => {
                    onObservationClick();
                  }}
                  variant="leading-icon"
                  className="leading-icon add-target-btn"
                  type="button"
                >
                  {externalLinkIcon} {progressValue === 'Completed' ? 'View ' : isIQV || isEQV ? 'View ' : ''}
                  {observationButton}
                </Button>
              )}

              {(isLearner || can_create_smart_target) && (
                <div className="d-flex justify-content-end flex-1">
                  {progressValue !== 'Completed' && (
                    <Button
                      onClick={() => {
                        onButtonClick();
                      }}
                      variant="leading-icon"
                      className="leading-icon add-target-btn"
                      type="button"
                    >
                      {addIcon} {addTargetButton}
                    </Button>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default memo(ProgramList);
