import { useState, memo, useEffect } from 'react';
import SubSection from './SubSection';
import { blackArrowDownIcon, closeIcon } from 'constants/icons';
import { setActiveEle, setMode, setElements, addElements, addElement, setIsDraged } from '../../redux/FormBuilderSlice';
import { useDispatch, useSelector } from 'react-redux';
import { createFormSubSection, getSubSections, insertSection, cloneFormSection } from 'api/formBuilder';
import { Row, Col, Spinner } from 'react-myoneui';
import DropElementsSection from './DropElementsSection';
import DropELementsDot from './DropELementsDot';
import DropElementsDropdown from './DropElementsDropdown';
import { removeSection } from 'api/formBuilder';
import Log from 'lib/Log';
import { sortElements } from 'lib/common';
import { useParams } from 'react-router-dom';

function SectionElement({ dragItem = {}, id, onDrop, data, onDelete, onEdit, onSectionDrop, onDragItem, onLoadSections, dragStatus }) {
  const [dropOverStatus, setDropOverStatus] = useState(false);
  const [dragBox, setDragBox] = useState(dragItem);
  const [elements, setElements] = useState([]);
  const [state, setState] = useState({ ...data });
  const mode = useSelector((state) => state.formbuilder.mode);
  const activeEle = useSelector((state) => state.formbuilder.activeEle);
  const { formId } = useParams();
  const [showChild, setShowChild] = useState(true);
  const [dragOverId, setDragOverId] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const dragOver = (e) => {
    if ((dragBox?.name == 'sub-section' || dragBox?.name == 'section') && id !== dragItem.id) {
      setDropOverStatus(true);
      setDragOverId(e.target.dataset.id);
      e.dataTransfer.setData('text', dragBox);
      e.preventDefault();
    }
  };

  const subSectionDrop = async (e) => {
    let mydata = JSON.parse(e.dataTransfer.getData('text'));
    mydata.parent_id = e.currentTarget.dataset.id;
    let { heading, sub_heading, parent_id, name, title } = { ...dragBox };
    let payload = { heading, sub_heading, parent_id, name, title, parent_id: e.currentTarget.dataset.id };
    setLoading('loading');
    let result = await createFormSubSection(payload);
    setLoading(true);

    let cloneElements = [...elements];
    cloneElements.push(result.data.data);
    setElements([...elements, { ...result.data.data }]);

    dispatch(setActiveEle(result.data.data));
    dispatch(setMode('edit'));
    setDropOverStatus(false);

    e.preventDefault();
  };
  const dropSection = async (e) => {
    let item = JSON.parse(e.currentTarget.dataset.item);

    let dragElement = JSON.parse(e.dataTransfer.getData('text'));
    dragElement.parent_id = item.parent_id;
    dragElement.index = parseInt(e.currentTarget.dataset.index);
    onSectionDrop({ ...dragElement, id: item.id.toString(), parent_id: item.parent_id, index: parseInt(e.currentTarget.dataset.index) });
  };
  const drop = async (e) => {
    try {
      let dragEle = JSON.parse(e.dataTransfer.getData('text'));
      if ((dragBox?.name == 'sub-section' || dragBox?.name == 'section') && id !== dragItem.id) {
        // Either new section can be drop or existing section can be drop. In new section index will be null
        if (dragEle.name === 'section') {
          if (dragEle.index) {
            // Draging available Section to available Section
            let payload = {
              parent_id: dragEle.parent_id,
              section_id: dragEle.id,
              destination_index: parseInt(e.currentTarget.dataset.index),
            };
            let result = await insertSection(payload);
            onLoadSections(payload);
          } else {
            // Draging new Section into existing section
            dropSection(e);
          }
        } else if (dragEle.name === 'sub-section') {
          subSectionDrop(e);
        }
        dispatch(setIsDraged(false));
      }
    } catch (error) {
      Log.error(error);
    }
  };
  const dragLeave = (e) => {
    if (dragBox?.name == 'sub-section' || dragBox?.name == 'section') {
      setDropOverStatus(false);
    }
  };
  const editHandler = () => {
    dispatch(setActiveEle({ ...state }));
    dispatch(setMode('edit'));
  };
  const makeActive = () => {
    dispatch(setMode('active'));
    dispatch(setActiveEle({ ...state }));
  };
  const deleteHandler = async () => {
    try {
      setLoading('loading');
      await removeSection({ section_id: id });
      setLoading('loaded');
      setElements([]);
      onDelete({ ...state });

      dispatch(setMode('view'));
    } catch (error) {
      Log.error(error);
      setLoading('loaded');
    }
  };

  // duplicate form section start here
  const duplicateSection = async () => {
    try {
      const payload = { parent_id: formId, section_id: id };
      setLoading('loading');
      const response = await cloneFormSection(payload);
      if (response?.data?.message === 'form-sub-section-duplicate-success') {
        setLoading('loaded');
        // setElements([...elements, { ...response.data.data }]);
        onLoadSections();
      }
    } catch (error) {
      Log.error(error);
      setLoading('loaded');
    }
  };

  const deleteElement = (data) => {
    setElements([...elements].filter((item) => item.id !== data.id));
  };
  const fetchSubSections = async () => {
    try {
      // setLoading('loading');
      let result = await getSubSections({ sectionId: id });
      setLoading('loaded');
      if (result.data.data.length > 0) {
        let resultData = [...result.data.data];
        setElements(sortElements(resultData));
        let sendingData = resultData.filter((item) => {
          return elements.filter((element) => element.id === item.id).length === 0;
        });
      }
    } catch (error) {
      Log.error(error);
      setLoading('loaded');
    }
  };
  const editElement = (data) => {
    let cloneElements = [...elements];
    let obj = cloneElements.find((item) => item.id === data.id);
    let index = cloneElements.indexOf(obj);
    cloneElements[index] = { ...data };
    setElements(cloneElements);
  };
  const adjustSubSectionDrop = (payload) => {
    var foundElement = false;
    var colls = [];
    elements.forEach((item) => {
      var myelement = { ...item };
      if (foundElement) {
        myelement.index = myelement.index + 1;
        colls.push(myelement);
      } else if (item.index === payload.index) {
        let newElement = { ...payload };

        myelement.index = myelement.index + 1;
        colls = [...colls, newElement, myelement];
        foundElement = true;
      } else {
        colls.push(myelement);
      }
    });

    setElements(colls);
    dispatch(setMode('edit'));

    dispatch(setActiveEle(payload));
  };
  useEffect(() => {
    fetchSubSections();
  }, []);
  useEffect(() => {
    setDragBox(dragItem);
  }, [dragItem]);
  useEffect(() => {
    setState(data);
  }, [data]);
  useEffect(() => {
    if (activeEle?.id === state?.id) {
      onEdit(activeEle);
    }
  }, [activeEle]);
  const dragStart = (e) => {
    // setDragStartStatus(true);
    setDragBox(JSON.parse(e.target.dataset.item));
    e.dataTransfer.setData('text', e.target.dataset.item);
    onDragItem(JSON.parse(e.target.dataset.item));
    dispatch(setIsDraged(true));
  };
  return (
    <>
      {state && mode === 'active' && activeEle.id === state?.id ? (
        <Row className="form-collapse-wrap">
          <Col className="py-2">
            <DropElementsSection
              onClickOutside={() => dispatch(setMode('view'))}
              className="drop-elements-primary py-2 bg-transparent"
            >
              <DropELementsDot />
              <div className="form-builder-section">
                <h4>{state?.heading}</h4>
                <div className="desc"> {state?.sub_heading}</div>
              </div>
              <DropElementsDropdown
                onEdit={editHandler}
                onDelete={deleteHandler}
                onDuplicate={duplicateSection}
                title={state?.title}
              />
            </DropElementsSection>
          </Col>
          <button
            className={`form-collapse-btn  btn btn-icon rounded-circle ${showChild ? 'active' : ''}`}
            onClick={() => setShowChild(!showChild)}
          >
            {blackArrowDownIcon}
          </button>
        </Row>
      ) : (
        <div
          className={`${dropOverStatus ? 'dropOver' : !(dragBox?.name == 'sub-section' || dragBox?.name == 'section') && dragStatus ? 'dropOver-not-supported' : ''}`}
          data-id={state?.id}
          data-index={state?.index}
          data-item={JSON.stringify(state)}
          onDragOver={dragOver}
          onDragLeave={dragLeave}
          // onDragOver={dragBox?.name == 'sub-section' || dragBox?.name == 'section' ? dragOver : () => {}}
          // onDragLeave={dragBox?.name == 'sub-section' ? dragLeave : () => {}}
          // //  onDrop={dragBox?.name == 'sub-section' || dragBox?.name == 'section' ? drop : () => {}}
          onDrop={drop}
          draggable={true}
          onDragStart={dragStart}
        >
          <Row className="form-collapse-wrap sectionBlock ">
            <Col className="py-2">
              <div
                className="form-builder-section"
                onClick={makeActive}
              >
                <h4>{state?.heading}</h4>
                <div className="desc"> {state?.sub_heading}</div>
              </div>
            </Col>
            <button
              className={`form-collapse-btn btn btn-icon rounded-circle ${showChild ? 'active' : ''}`}
              onClick={() => setShowChild(!showChild)}
            >
              {!(dragBox?.name === 'sub-section' || dragBox?.name === 'section') && dragStatus ? closeIcon : blackArrowDownIcon}
            </button>
          </Row>
        </div>
      )}
      {loading === 'loading' ? (
        <Spinner animation="border" />
      ) : (
        elements &&
        elements.length > 0 &&
        elements.map((subsection) => (
          <SubSection
            key={subsection.id}
            id={subsection.id}
            parentId={state.id}
            onDrop={onDrop}
            data={subsection}
            dragItem={dragBox}
            show={showChild}
            onDelete={deleteElement}
            onEdit={editElement}
            // onDuplicate={() => duplicateSubSection(subsection?.id)}
            onSubSectionDrop={adjustSubSectionDrop}
            onDragItem={(data) => setDragBox(data)}
            onLoadSections={fetchSubSections}
          />
        ))
      )}
    </>
  );
}
export default memo(SectionElement);
