import { Route } from 'react-router-dom';
import { lazyWithRetry } from 'components/lazyWithRetry';

const UserApprentices = lazyWithRetry(() => import('modules/admin/users/apprentices'));
const ProgrammeProgress = lazyWithRetry(() => import('modules/admin/users/programs/programProgress'));
const ProgramDetail = lazyWithRetry(() => import('modules/admin/users/programs/programDetail'));
const ComponentDetail = lazyWithRetry(() => import('modules/learner/program/ComponentDetail'));
const MemberAssessors = lazyWithRetry(() => import('modules/learner/program/assessors/'));
const Resubmission = lazyWithRetry(() => import('modules/learner/program/Resubmission'));
const ProfileActivity = lazyWithRetry(() => import('modules/admin/profile'));
const LectureList = lazyWithRetry(() => import('modules/learner/program/LectureList'));
const ActivityDetail = lazyWithRetry(() => import('modules/learner/program/ActivityDetail'));
const Target = lazyWithRetry(() => import('modules/learner/program/Target/'));
const TaskList = lazyWithRetry(() => import('modules/learner/program/TaskList'));
const QuickLinks = lazyWithRetry(() => import('modules/learner/program/QuickLinks/index'));

export default (
  <>
    <Route
      path="apprentices/"
      exact
      element={<UserApprentices />}
    />
    <Route
      path="apprentices/view-profile/:id"
      exact
      element={<ProfileActivity name="apprentices" />}
    />
    <Route
      path="apprentices/view-profile/:id/program/:programId/"
      exact
      element={<LectureList />}
    />
    <Route
      path="apprentices/view-profile/:id/program/:programId/lecture-list"
      exact
      element={<LectureList />}
    />
    <Route
      path="apprentices/view-profile/:id/program/:programId/lecture-list/:sectionid"
      exact
      element={<ComponentDetail backBtnStatus={true} />}
    />
    <Route
      path="apprentices/view-profile/:id/program/:programId/lecture-list/:lectureDetail/activitityDetail"
      element={<ActivityDetail />}
    />
    <Route
      path="apprentices/view-profile/:id/program/:programId/lecture-list/:lectureDetail/:otj_id/activitityDetail/:activityId/:memberSectionId"
      element={<ActivityDetail />}
    />
    <Route
      path="apprentices/view-profile/:id/program/:programId/lecture-list/:lectureDetail/activitityDetail/:activityId/:memberSectionId"
      element={<ActivityDetail backBtnStatus={true} />}
    />
    <Route
      path="apprentices/view-profile/:id/program/:programId/:lectureDetail/activitityDetail"
      element={<ActivityDetail />}
    />
    <Route
      path="apprentices/view-profile/:id/program/:programId/task-list/:lectureDetail/activitityDetail"
      exact
      element={<ActivityDetail />}
    />
    {/* apprentices/view-profile/:id/program/:programId/task-list/:otj_id/activitityDetail/:activityId/:memberSectionId */}
    <Route
      path="apprentices/view-profile/:id/program/:programId/task-list/:otj_id/activitityDetail/:activityId/:memberSectionId"
      exact
      element={<ActivityDetail backBtnStatus={true} />}
    />
    <Route
      path="apprentices/view-profile/:id/program/:programId/quick-links/:lectureDetail/activitityDetail"
      exact
      element={<ActivityDetail />}
    />
    <Route
      path="apprentices/view-profile/:id/program/:programId/quick-links/:otj_id/activitityDetail/:activityId/:memberSectionId"
      exact
      element={<ActivityDetail backBtnStatus={true} />}
    />
    <Route
      path="apprentices/view-profile/:id/program/:member_program_id/target"
      exact
      element={<Target />}
    />
    <Route
      path="apprentices/view-profile/:id/program/:programId/task-list"
      exact
      element={<TaskList />}
    />
    <Route
      path="apprentices/view-profile/:id/program/:programId/quick-links"
      exact
      element={<QuickLinks />}
    />
    <Route
      path="apprentices/view-profile/:id/program/:programId/resubmission"
      exact
      element={<Resubmission />}
    />
    <Route
      path="apprentices/view-profile/:id/program/:programId/resubmission/:otj_id/activitityDetail/:activityId/:memberSectionId"
      element={<ActivityDetail backBtnStatus={true} />}
    />

    <Route
      path="apprentices/view-profile/:id/program/:programId/assessors"
      exact
      element={<MemberAssessors />}
    />
  </>
);
