import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useState, forwardRef, Children } from 'react';
import Offcanvas from 'react-myoneui/lib/Offcanvas';
import Button from 'react-myoneui/lib/Button';
import Card from 'react-myoneui/lib/Card';
import Form from 'react-myoneui/lib/Form';
import FloatingLabel from 'react-myoneui/lib/FloatingLabel';
// import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import { editSection, getCategories, editUnitBankSection } from 'api';
import { useParams } from 'react-router-dom';
import { setToastBoxStatus, setToastBoxDescription } from '../../redux/ToastBoxSlice';
import DeadLinesType from './DeadLinesType';
import Spinner from 'react-myoneui/lib/Spinner';
import { translate } from '../../lib';
import TextEditor from '../TextEditor';
import ls from 'localstorage-slim';
import Log from '../../lib/Log';
import TagContent from 'components/tag';
import DropdownCollection from 'components/DropdownCollection';

// enable global encryption
ls.config.encrypt = true;
function EditSection(props) {
  const { onClose, onEdit, parentSectionId, isPublished, data, viewMode, programInfo, isFromSectionBank } = props;
  console.log(data);
  const handleClose = () => onClose();
  const offCanvasKeyDown = (e) => e.stopPropagation();
  const [deadlines, setDeadlines] = useState({
    duration: null,
    end_date: null,
    lock_after_duration: null,
    previous_section: null,
    set_deadline_after: null,
    start_date: null,
    validate: true,
  });
  const { programId } = useParams();
  // Category List from API
  const [allCategoryList, setAllCategoryList] = useState([]);

  const orgId = ls.get('orgId');
  const [title, setTitle] = useState(data.title);
  const [subTitle, setSubTitle] = useState(data?.sub_title);
  const [published, setIsPublished] = useState(isPublished ? isPublished : false);
  const [description, setDescription] = useState(data.description);
  const [isValidated, setIsValidate] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [selectedStatus, setSelectedStatus] = useState([]);
  const taxonomy = useSelector((state) => state?.taxonomy?.data);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [statusList, setStatusList] = useState([]);

  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = forwardRef(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <ul className="list-unstyled mb-0">{Children.toArray(children).filter((child) => !value || child.props.children.toLowerCase().startsWith(value))}</ul>
      </div>
    );
  });

  useEffect(() => {
    if (taxonomy) {
      const list = [
        { id: 'mandatory', name: translate(taxonomy)('mandatory') },
        { id: 'optional', name: translate(taxonomy)('optional') },
        { id: 'additional', name: translate(taxonomy)('additional') },
      ];
      setStatusList(list);
      setSelectedStatus(list?.find((status) => status.id === data.status) || list[0]);
    }
  }, [taxonomy]);

  const titleHandler = (e) => {
    setTitle(e.target.value);
  };
  const descriptionHandler = (data) => {
    setDescription(data);

    //dispatch(setDescription(e.target.innerHTML));
  };
  const updateSection = async () => {
    try {
      let payload = {
        id: data.id,
        orgId,
        title,
        sub_title: subTitle,
        description,
        parentSectionId,
        duration: Number(deadlines?.duration),
        end_date: deadlines?.end_date,
        lock_after_duration: deadlines?.lock_after_duration,
        previous_section: deadlines?.deadline_type.value === 'duration' && deadlines?.set_deadline_after?.value === 'after_completed_component' ? deadlines?.previous_section?.id : null,
        set_deadline_after: deadlines?.set_deadline_after,
        start_date: deadlines?.start_date,
        deadline_type: deadlines?.deadline_type,
        status: selectedStatus.id,
      };
      let createdCategories = [];
      for (const cat of selectedCategory) {
        if (cat?.new) {
          createdCategories.push({ name: cat.name, of: 'section' });
        } else {
          createdCategories.push({ id: cat?.id });
        }
      }
      payload = { ...payload, categories: createdCategories };
      setLoading(true);
      let result = programId ? await editSection({ ...payload, is_published: published, program: programId }) : await editUnitBankSection(payload);
      if (result) {
        setLoading(false);
        if (onEdit) {
          onEdit(result);
        } else {
          onClose();
        }

        //onResponse(result?.data);
      }
    } catch (error) {
      setLoading(false);
      dispatch(setToastBoxStatus(true));
      dispatch(setToastBoxDescription(error?.data?.message));
      onClose();
      // onResponse(error?.data);
      Log.error('Error :EditSection/saveSection :', error);
    }
  };

  const deadlineHandler = (data) => {
    setDeadlines(data);
  };
  const getAllSectionCategory = async () => {
    try {
      const result = await getCategories(parentSectionId ? 'sub_section' : 'section');
      setAllCategoryList(result.data.data);
      let listCategoryObs = result.data.data.filter((item) => data.categories.includes(item.id));
      let filterCatList = listCategoryObs.map((item) => {
        return {
          id: item.id,
          name: item.name,
          cname: item.cname,
        };
      });
      setSelectedCategory(filterCatList);
    } catch (err) {
      Log.error('Error:EditSections/getAllSectionCategory', err);
    }
  };
  useEffect(() => {
    if (title.length === 0) {
      setIsValidate(false);
    } else {
      setIsValidate(true);
    }
  }, [title]);

  useEffect(() => {
    getAllSectionCategory();
  }, []);
  return (
    <>
      <Offcanvas
        show={props.show}
        onHide={handleClose}
        placement="end"
        onKeyDown={offCanvasKeyDown}
      >
        {loading === true ? (
          <Offcanvas.Body>
            <div className="spinner-center">
              {' '}
              <Spinner animation="border" />{' '}
            </div>{' '}
          </Offcanvas.Body>
        ) : (
          <>
            <Offcanvas.Header
              closeButton
              backButton
            >
              <Offcanvas.Title>
                {viewMode ? 'View' : 'Edit'} {data.parent_section ? translate(taxonomy)('subSection') : translate(taxonomy)('section')}
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Form>
                <FloatingLabel
                  label="Sample Title"
                  className="mb-4 custom-input-textarea"
                  title={title}
                >
                  <Form.Control
                    as="textarea"
                    disabled={viewMode}
                    type="text"
                    placeholder="Type Title"
                    tabIndex="1"
                    value={title}
                    onChange={titleHandler}
                  />
                </FloatingLabel>
                <FloatingLabel
                  label="Sample Sub Title"
                  className="mb-4"
                >
                  <Form.Control
                    disabled={viewMode}
                    type="text"
                    placeholder="Type Sub Title"
                    tabIndex="1"
                    value={subTitle}
                    onChange={(e) => setSubTitle(e.target.value)}
                  />
                </FloatingLabel>
                <Card
                  className="react-quill-editor mb-4"
                  disabled={viewMode}
                >
                  <TextEditor
                    tabIndex="2"
                    value={description}
                    onChange={descriptionHandler}
                  />
                </Card>
                {/* Only on Parent Section will have category and mandatory field will be show other wise no  */}
                {!data.parent_section && (
                  <>
                    {/* If Section is on view mode need to disable on form fileds */}
                    <div
                      className="mb-4 form-floating"
                      disabled={viewMode}
                    >
                      <TagContent
                        className="custom-tag-content mb-4"
                        tagTitle="Category *"
                        list={allCategoryList}
                        keywords={selectedCategory}
                        onTags={(data) => setSelectedCategory(data)}
                        placeholder="Add new category and press comma"
                        placeholderTitle="Add new category and press comma"
                      />
                    </div>
                    {/* If Section is on view mode need to disable on form fileds */}
                    {programInfo?.enable_mandatory_option && (
                      <div
                        className="mb-4 form-floating"
                        disabled={viewMode}
                      >
                        <DropdownCollection
                          autoComplete={false}
                          defaultValue={selectedStatus}
                          items={statusList}
                          label={`${translate(taxonomy)('section')} Status`}
                          onChange={(obj) => setSelectedStatus(obj)}
                          onClear={() => setSelectedStatus([])}
                        />
                      </div>
                    )}
                  </>
                )}
                {/* If Section is on view mode need to disable on form fileds */}
                <div disabled={viewMode}>
                  <DeadLinesType
                    mode="edit"
                    deadLineType={data?.deadline_type}
                    previousSection={data?.previous_section}
                    setDeadLineAfter={data?.set_deadline_after}
                    lockAfterDuration={data?.lock_after_duration}
                    duration={data?.duration}
                    endDate={data?.end_date}
                    startDate={data?.start_date}
                    onChange={(data) => deadlineHandler(data)}
                    id={data?.id}
                    tabIndex="3"
                    phase={data.parent_section ? translate(taxonomy)('subSection') : translate(taxonomy)('section')}
                    isFromSectionBank={isFromSectionBank}
                  />
                </div>
              </Form>
            </Offcanvas.Body>
            <Offcanvas.Footer>
              <Button
                variant="default"
                onClick={handleClose}
              >
                Cancel
              </Button>
              {!viewMode && (
                <Button
                  variant="primary"
                  onClick={updateSection}
                  disabled={!isValidated}
                >
                  Save
                </Button>
              )}
            </Offcanvas.Footer>
          </>
        )}
      </Offcanvas>
    </>
  );
}
EditSection.prototype = {
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
  parentSectionId: PropTypes.string,
};
export default React.memo(EditSection);
