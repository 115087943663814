import axios from 'axios';
import { API_URL, dms_base_url } from '../../constants/config';
import ls from 'localstorage-slim';
import Log from '../../lib/Log';
import { PageSize } from 'utils/react-grid-table';
// enable global encryption
ls.config.encrypt = true;

// apprentice/view/parent-child-sections
export const getParentData = async ({ ...payload }) => {
  /* payload:{ parent_section, member_section} */
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: ls.get('orgId'),
    },
  };
  try {
    return await axios.post(`${API_URL}/apprentice/view/parent-child-sections`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};
export const getChildSections = async ({ ...payload }) => {
  /* payload: {"member_section": "639c147ef57a573bfe75eae7"} */
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: ls.get('orgId'),
    },
  };
  try {
    return await axios.post(`${API_URL}/apprentice/view/child-sections`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};
/* url: https://api.sandbox.portfolio.mykademy.com/apprentice/view/component-count
method: GET
payload: {"member_section": "639c147ef57a573bfe75eae7"} */
export const getSectionTotalComponents = async (payload) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        organisationid: ls.get('orgId'),
      },
    };
    return await axios.post(`${API_URL}/apprentice/view/component-count`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};
export const getFirstColSection = async ({ orgId, ...payload }) => {
  /* payload: {"memberProgramId":"639c1462f57a573bfe75ea53"} */
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: ls.get('orgId') || orgId,
    },
  };
  try {
    return await axios.post(`${API_URL}/apprentice/view/first-level-sections`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const getLearnerPrograms = async ({ orgId, ...payload }) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: ls.get('orgId') || orgId,
    },
  };
  try {
    return await axios.post(`${API_URL}/apprentice/list/member-programs`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const viewProgramDetails = async ({ orgId, memberProgramId }) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        organisationid: orgId,
      },
    };
    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}/apprentice/view/member-program`, { memberProgramId }, headers)
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    Log.error(error);
  }
};

export const viewMemberProgram = async ({ orgId, memberProgramId }) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        organisationid: ls.get('orgId') || orgId,
      },
    };
    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}/apprentice/get/member-program`, { memberProgramId }, headers)
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    Log.error(error);
  }
};

export const deleteMemberProgram = async (payload) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token') || payload?.token}`,
        organisationid: payload?.orgId || ls.get('orgId'),
      },
    };

    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}/admin/apprentice/member-program/delete`, { member_program_id: payload.memberProgramId }, headers)
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    Log.error(error);
  }
};

export const updateProgramScore = async ({ orgId, memberProgramId, score }) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        organisationid: orgId,
      },
    };

    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}/admin/apprentice/member-program/update-score`, { memberProgramId, score }, headers)
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    Log.error(error);
  }
};

export const getTokenForDMS = async (id) => {
  try {
    return new Promise((resolve, reject) => {
      const uninterceptedAxiosInstance = new axios.create();
      return uninterceptedAxiosInstance
        .get(`${dms_base_url}/api/v6/get_user_token?account=${ls.get('account_id')}&user_id=${id}`)
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    Log.error(error);
  }
};

export const calculateMemberProgramProgress = async (payload) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token') || payload?.token}`,
        organisationid: payload?.orgId || ls.get('orgId'),
      },
    };

    return new Promise((resolve, reject) => {
      return axios
        .get(`${API_URL}/apprentice/program/${payload.memberProgramId}/calculate/progress`, headers)
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    Log.error(error);
  }
};

export const getMember = async ({ orgId, ...payload }) => {
  const { status, ...params } = payload;
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId') || orgId,
  };
  try {
    return await axios.post(`${API_URL}/workFlow/admin/get-members`, { status }, { headers, params });
  } catch (err) {
    throw new Error(err);
  }
};

export const getMemberData = async ({ orgId, ...payload }) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: ls.get('orgId') || orgId,
    },
  };
  try {
    return await axios.post(`${API_URL}/workFlow/admin/get-member-data`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const getMemberContatData = async ({ orgId, ...payload }) => {
  const { id, ...params } = payload;
  const headers = {
    Authorization: `Bearer ${ls.get('token') || payload?.token}`,
    organisationid: ls.get('orgId') || orgId,
  };
  try {
    return await axios.post(`${API_URL}/apprentice/get/contact`, { id }, { headers, params });
  } catch (err) {
    throw new Error(err);
  }
};

export const contactSendMessage = async ({ orgId, payload }) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token') || payload?.token}`,
    organisationid: ls.get('orgId') || orgId,
  };
  try {
    return await axios.post(`${API_URL}/apprentice/contact/send-message`, payload, { headers });
  } catch (err) {
    throw new Error(err);
  }
};

export const getMemberCount = async ({ orgId, ...payload }) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: ls.get('orgId') || orgId,
    },
  };
  try {
    return await axios.post(`${API_URL}/workFlow/admin/get-member-status`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const getTokenziedProgram = async (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    },
  };
  try {
    return await axios.post(`${API_URL}/apprentice/view/tokenized-program`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const selfEnrollToProgram = async (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    },
  };
  try {
    return await axios.post(`${API_URL}/apprentice/program/self-enroll`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const downloadProgram = async (memberProgramId) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    },
  };
  try {
    return new Promise((resolve, reject) => {
      return axios
        .get(`${API_URL}/apprentice/download/member-program/${memberProgramId}`, { ...headers, responseType: 'blob' })
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (err) {
    throw new Error(err);
  }
};

export const addFeedbackRating = async (payload) => {
  try {
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };
    return await axios.put(`${API_URL}/admin/create/member-section-rating-and-review`, payload, { headers });
  } catch (err) {
    Log.error(err);
  }
};

export const getFeedbackRatingById = async (orgId, payload) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId') || orgId,
  };
  try {
    return await axios.post(`${API_URL}/admin/get/member-section-rating-and-review`, payload, { headers });
  } catch (err) {
    Log.error(err);
  }
};

export const getProgramRatingAndReview = async (programId, orgId) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      Organisationid: ls.get('orgId') || orgId,
    },
  };
  try {
    return await axios.get(`${API_URL}/admin/list/program/section/rating-and-review/${programId}`, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const getProgramSectionRatingAndReview = async (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      Organisationid: ls.get('orgId') || payload?.organisationId,
    },
    params: {
      perPage: payload?.pageSize,
    },
  };
  try {
    return await axios.get(`${API_URL}/admin/list/program/section/all-rating-and-review/${payload?.sectionId}`, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const getLearnerProgramReports = async (payload) => {
  console.log('payload', payload);
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      Organisationid: ls.get('orgId') || payload?.organisationId,
    },
    params: {
      perPage: payload?.pageSize,
      currentPage: payload?.currentPage,
      sub_section: payload?.sub_section,
    },
  };
  try {
    return await axios.get(`${API_URL}/admin/program/${payload?.programId}/list/member-program-section`, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const getScore = async (programId, orgId) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      Organisationid: ls.get('orgId') || orgId,
    },
  };
  try {
    return await axios.get(`${API_URL}/apprentice/memberProgram/${programId}/sectionTotalScore`, headers);
  } catch (err) {
    throw new Error(err);
  }
};

// update componenent achieved score
export const updateAchievedComponentScore = async ({ orgId, memberSection, achieved_score }) => {
  console.log('payload', memberSection);
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        organisationid: orgId,
      },
    };

    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}/admin/update/memberSection/score`, { memberSection, achieved_score }, headers)
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    Log.error(error);
  }
};
