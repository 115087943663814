function AboutInfoControl(props) {
  const { name, description, type } = props;
  return (
    <div className={`about-info-control ${props?.className || ''}`}>
      {name && <span>{name}</span>}
      {type === 'image' ? (
        <img
          src={description}
          alt="attachments"
          className="w-50"
        />
      ) : (
        <div>{description ?? ''}</div>
      )}
    </div>
  );
}

export default AboutInfoControl;
