import { Route } from 'react-router-dom';
import { lazyWithRetry } from 'components/lazyWithRetry';

const JourneyWorkerRate = lazyWithRetry(() => import('modules/admin/journeyWorkerRate'));

export default (
  <>
    <Route
      path="journey-worker-rate"
      element={<JourneyWorkerRate />}
    />
  </>
);
