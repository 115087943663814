import axios from 'axios';
import { API_URL } from '../constants/config';
import ls from 'localstorage-slim';

// enable global encryption
ls.config.encrypt = true;
export const createProgramOTJComponent = ({ orgId, ...payload }) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: orgId,
    },
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(`${API_URL}/admin/program/OTJ/component/create`, payload, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const updateProgramOTJComponent = ({ orgId, ...payload }) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: orgId,
    },
  };
  return new Promise((resolve, reject) => {
    return axios
      .put(`${API_URL}/admin/program/OTJ/component/update`, payload, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getActivities = (otj_id) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };
  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/apprentice/OTJ/${otj_id}/activities`, { headers })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const updateRequiredHour = (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    },
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(`${API_URL}/admin/update/OTJ/required_hour`, payload, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const createNewActivity = (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    },
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(`${API_URL}/apprentice/create/OTJ/activity`, payload, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const fetchMemberActivities = (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    },
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(`${API_URL}/apprentice/fetch/OTJ/memberActivities`, payload, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const addHours = (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    },
  };
  return new Promise((resolve, reject) => {
    return axios
      .patch(`${API_URL}/apprentice/OTJ/activity/addHours`, payload, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const approveMemberActivity = (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    },
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(`${API_URL}/admin/approve/member-activity`, payload, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const deleteHour = (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    },
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(`${API_URL}/apprentice/OTJ/activity/delete/hour`, payload, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const restoreHour = (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    },
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(`${API_URL}/apprentice/OTJ/activity/restore/hour`, payload, headers)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};
