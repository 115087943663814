import React, { useEffect, useState } from 'react';
import Form from 'react-myoneui/lib/Form';
const SingleComponent = ({ label, item = {}, onChange = () => {}, status = false, parentId = 0 }) => {
  const [checked, setChecked] = useState(false);
  const [value, setValue] = useState('');
  const changeHandler = (e) => {
    onChange(e);
    setChecked(!checked);
  };
  useEffect(() => {
    setValue(item.memberSectionId);
  }, []);
  useEffect(() => {
    setChecked(status);
  }, [status]);
  return (
    <Form.Check>
      <Form.Check.Input
        data-parentid={parentId}
        type="checkbox"
        data-id={item.memberSectionId}
        checked={checked}
        id={`multiComponent_${item.memberSectionId}`}
        onChange={changeHandler}
        value={value}
        data-label={label}
      />
      <Form.Check.Label
        htmlFor={`multiComponent_${item.memberSectionId}`}
        title={item.sectionTitle}
      >
        {item.sectionTitle}
      </Form.Check.Label>
    </Form.Check>
  );
};
/*
     <Form.Check>
              <Form.Check.Input type="checkbox" checked={item?.status === true} id={`multiComponent_${item.memberSectionId}`} onChange={onChange} value={item.memberSectionId} />
              <Form.Check.Label htmlFor={`multiComponent_${item.memberSectionId}`} title={item.sectionTitle}>
                {item.sectionTitle}
              </Form.Check.Label>
            </Form.Check>
    */
export default SingleComponent;
