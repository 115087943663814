import { SET_TOKEN, SET_EMAIL, SET_ORG_ID, SET_USER_DETAIL, SET_USER_DOMAIN, SET_USER_ROLE, SET_LOG_OUT_URL, SET_ACTIVE_MEMBERPROGRAM_ID } from './Types';

export const setToken = (payload) => {
  return {
    type: SET_TOKEN,
    payload: payload,
  };
};
export const setUserEmail = (payload) => {
  return {
    type: SET_EMAIL,
    payload: payload,
  };
};

export const setOrgId = (payload) => {
  return {
    type: SET_ORG_ID,
    payload: payload,
  };
};

export const setUserDetail = (payload) => {
  return {
    type: SET_USER_DETAIL,
    payload: payload,
  };
};

export const setUserDomain = (payload) => {
  return {
    type: SET_USER_DOMAIN,
    payload: payload,
  };
};

export const setUserRole = (payload) => {
  return {
    type: SET_USER_ROLE,
    payload: payload,
  };
};
export const setActiveMemberProgramId = (payload) => {
  return {
    type: SET_ACTIVE_MEMBERPROGRAM_ID,
    payload: payload,
  };
};
export const setLogOutRole = (payload) => {
  return {
    type: SET_LOG_OUT_URL,
    payload: payload,
  };
};
