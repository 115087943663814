import axios from 'axios';
import { API_URL } from '../constants/config';
import ls from 'localstorage-slim';
import Log from '../lib/Log';
// enable global encryption
ls.config.encrypt = true;
export const addUser = async (data) => {
  try {
    const { orgId, ...payload } = data;
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token') || payload?.token}`,
        organisationid: `${ls.get('orgId') || payload?.orgId}`,
      },
    };
    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}/admin/member/create`, payload, headers)
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    Log.error(error);
  }
};
/*
 let payload = {
        id: string,
        user_name: string,
        user_country: string,
        user_phone_country_code: string,
        user_phone: string,
        user_image: string,
        roles: [string],
        custom_fields: string,
      };
*/
export const editUser = async (data) => {
  try {
    const { orgId, ...payload } = data;

    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token') || payload?.token}`,
        organisationid: orgId ? orgId : ls.get('orgId'),
      },
    };
    return new Promise((resolve, reject) => {
      return axios
        .patch(`${API_URL}/admin/member/`, payload, headers)
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    Log.error(error?.message);
  }
};

export const deleteUsers = (userData) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };

  return new Promise((resolve, reject) => {
    return axios
      .delete(`${API_URL}/admin/member/`, { headers, data: userData })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const deleteApprentices = (ids) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };

  return new Promise((resolve, reject) => {
    return axios
      .delete(`${API_URL}/admin/apprentice/`, { headers, data: ids })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const listUsers = async (payload) => {
  try {
    const { ...data } = payload;
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };

    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}/admin/members`, data, { headers })
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    Log.error(error);
  }
};

export const getUser = (id) => {
  try {
    if (id) {
      const headers = {
        headers: {
          Authorization: `Bearer ${ls.get('token')}`,
          organisationid: ls.get('orgId'),
        },
      };
      return new Promise((resolve, reject) => {
        return axios
          .get(`${API_URL}/admin/member/${id}`, headers)
          .then((result) => resolve(result))
          .catch((error) => reject(error.response));
      });
    }
  } catch (error) {
    Log.error(error);
  }
};

export const bulkImportUser = async (data) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        organisationid: `${ls.get('orgId')}`,
        'Content-Type': 'multipart/form-data',
      },
    };
    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}/admin/member/bulk-import`, data, headers)
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    Log.error(error);
  }
};
