import axios from 'axios';
import { LANG } from '../../constants/config';
import ls from 'localstorage-slim';

// enable global encryption
ls.config.encrypt = true;
const API_URL = process.env.REACT_APP_API_URL;
const SITE_URL = process.env.REACT_SITE_URL;
const PORT = process.env.REACT_PORT;

const lang = ls.get('lang') ? ls.get('lang') : LANG;

export const rolesPrivilegeLanguage = (payload) => {
  return new Promise((resolve, reject) => {
    return axios
      .get(`/lang/modules/superAdmin/rolesPrivilege/${lang}.json`)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};
