// import * as React from 'react';
import { useState, useEffect } from 'react';

import Offcanvas from 'react-myoneui/lib/Offcanvas';
import Button from 'react-myoneui/lib/Button';

function DmsUploadWidget({ uploadCallback, widgetEndPoint, open = false, showButton = true, onClose = () => {} }) {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    onClose();
  };
  const handleShow = () => setShow(true);
  const [offCanvasWidth, setOffCanvasWidth] = useState('w-75');

  useState(() => {
    if (!showButton) {
      setShow(true);
    }
    if (open) {
      setShow(open);
    }
  }, []);

  const handleEvent = (event) => {
    if (event.data.action == 'insertAndClose') {
      uploadCallback(event.data.data);
      setShow(false);
      onClose();
    }

    if (event.data.action == 'close') {
      setShow(false);
      onClose();
    }
    if (event.data.action == 'offCanvasResizeW75') {
      setOffCanvasWidth('w-75');
    }
    if (event.data.action == 'offCanvasResizeW40') {
      setOffCanvasWidth('w-40');
    }
  };
  useEffect(() => {
    window.addEventListener('message', handleEvent, false);

    // cleanup this component
    return () => {
      window.removeEventListener('message', handleEvent, false);
    };
  }, []);

  return (
    <>
      {showButton && (
        <Button
          onClick={() => handleShow(true)}
          variant="outline"
          className="border border-primary text-primary"
        >
          Document Bank
        </Button>
      )}
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className={offCanvasWidth}
      >
        {show && (
          <iframe
            src={widgetEndPoint}
            height="100%"
          />
        )}

        {/* <iframe src="https://document.uat.mykademy.com/widget/document-manager" height="100%" /> */}
      </Offcanvas>
    </>
  );
}
export default DmsUploadWidget;
