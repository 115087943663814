import { fileUploader } from 'api';
import { addVideoEvidences, updateVideoEvidences } from 'api/learner/section';
import { addIcon, editIcon, removeIcon } from 'constants/icons';
import Log from 'lib/Log';
import { covertSecsToTime, covertSecsToTimeData } from 'lib/common';
import EditVideoTagOffCanvas from 'modules/admin/program/library/components/Resources/EditVideoTagOffCanvas';
import VideoTagOffCanvas from 'modules/admin/program/library/components/Resources/VideoTagOffCanvas';
import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Row, Spinner, Button } from 'react-myoneui';
import { addMemberActivityVideoEvidences } from './section';

const maxAllowedSize = 250 * 1024 * 1024; // 250MB

function AddVideoEvidence(props) {
  const { member_section_id, onSave, oldVideo, removeVideo, member_activity, canAccessAll } = props;

  const [videoTagMode, setVideoTagMode] = useState('');
  const [video, setVideo] = useState({
    url: '',
    tags: [],
  });
  const [selectedVideoTag, setSelectedVideoTag] = useState({
    tag: '',
    hrs: '',
    mins: '',
    secs: '',
  });
  const [evidenceId, setEvidenceId] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const videoRef = useRef(null);
  const featuredVideoInput = useRef(null);

  useEffect(() => {
    if (oldVideo && oldVideo?.length > 0) {
      setVideo({ url: oldVideo?.[0]?.url || '', tags: oldVideo?.[0]?.tags?.map((tag) => ({ ...tag, id: tag?._id })) || [] });
      setEvidenceId(oldVideo?.[0]?.id);
    }
  }, [oldVideo]);

  const uploadVideoHandler = async () => {
    try {
      featuredVideoInput.current.click();
    } catch (error) {
      Log.error(error);
    }
  };

  const uploadVideo = async (e) => {
    try {
      if (e.target.files[0].size > maxAllowedSize) {
        setErrorMsg('Video file size cannot excced 250MB');
        return;
      }
      setErrorMsg('');
      if (loading === false) {
        setLoading(true);
        let result = await fileUploader(e.target.files[0]);
        // let result = true;
        if (result) {
          setVideo({ ...video, url: result?.data?.data[0]?.fileToSend?.Location });

          const payload = evidenceId
            ? {
                video_evidence_id: evidenceId,
                ...video,
                url: result?.data?.data[0]?.fileToSend?.Location,
              }
            : {
                member_section_ids: [member_section_id],
                video_evidences: [
                  {
                    ...video,
                    url: result?.data?.data[0]?.fileToSend?.Location,
                  },
                ],
              };

          //  check if new video or update existing vedio
          const videoEvidence = evidenceId ? await updateVideoEvidences(payload) : await addVideoEvidences(payload);
          if (videoEvidence) {
            !evidenceId && setEvidenceId(videoEvidence?.data?.data?.video_evidences?.at(-1)?.id);
            evidenceId ? onSave(null, result?.data?.data) : onSave(videoEvidence?.data?.data?.video_evidences);
          }
        }
      }
    } catch (error) {
      Log.error(error);
    } finally {
      setLoading(false);
    }
  };

  const uploadOTJVideo = async (e) => {
    try {
      if (e.target.files[0].size > maxAllowedSize) {
        setErrorMsg('Video file size cannot excced 250MB');
        return;
      }
      setErrorMsg('');
      if (loading === false) {
        setLoading(true);
        let result = await fileUploader(e.target.files[0]);
        // let result = true;
        if (result) {
          setVideo({ ...video, url: result?.data?.data[0]?.fileToSend?.Location });

          const payload = evidenceId
            ? {
                video_evidence_id: evidenceId,
                ...video,
                url: result?.data?.data[0]?.fileToSend?.Location,
              }
            : {
                member_activity: member_activity,
                video_evidences: [
                  {
                    ...video,
                    url: result?.data?.data[0]?.fileToSend?.Location,
                  },
                ],
              };

          //  check if new video or update existing vedio
          const videoEvidence = evidenceId ? await updateVideoEvidences(payload) : await addMemberActivityVideoEvidences(payload);
          if (videoEvidence) {
            !evidenceId && setEvidenceId(videoEvidence?.data?.data?.video_evidences?.at(-1)?.id);
            evidenceId ? onSave(null, result?.data?.data) : onSave(videoEvidence?.data?.data?.video_evidences);
          }
        }
      }
    } catch (error) {
      Log.error(error);
    } finally {
      setLoading(false);
    }
  };

  const saveTags = async (data) => {
    const videoWithTags = { ...video, tags: [...video.tags, ...data] };
    setVideo(videoWithTags);
    try {
      const payload = {
        video_evidence_id: evidenceId,
        action: 'add',
        member_section_id: member_section_id,
        ...videoWithTags,
      };
      const result = await updateVideoEvidences(payload);
      if (result) {
        onSave(null, result?.data?.data);
      }
    } catch (error) {}
  };

  const removeTag = async (data) => {
    try {
      let videoClone = { ...video };
      let tagsClone = [...videoClone.tags];

      tagsClone = tagsClone.filter((item) => item.time !== data.time);
      setVideo({ ...video, tags: tagsClone });

      const payload = {
        video_evidence_id: evidenceId,
        ...video,
        tags: tagsClone,
        action: 'delete',
        member_section_id: member_section_id,
      };
      const result = await updateVideoEvidences(payload);
      if (result) {
        setVideo({ ...video, tags: tagsClone });
        onSave(null, result?.data?.data);
        setVideoTagMode('');
      }
    } catch (error) {
      Log.error('Error:removeTag', error);
    }
  };

  const showEditVideoTag = (data) => {
    setSelectedVideoTag({ tag: data.tag, ...covertSecsToTimeData(data.time), id: data.id });
    setVideoTagMode('edit');
  };
  const editVideoTag = async (data) => {
    try {
      var cloneTags = [...video.tags];
      var obj = cloneTags.find((item) => item.id === data.id);
      var index = cloneTags.indexOf(obj);
      cloneTags[index] = data;

      try {
        const payload = {
          video_evidence_id: evidenceId,
          ...video,
          tags: cloneTags,
          action: 'update',
          member_section_id: member_section_id,
        };
        const result = await updateVideoEvidences(payload);
        if (result) {
          setVideo({
            ...video,
            tags: cloneTags,
          });
          setVideoTagMode('');
          onSave(null, result?.data?.data);
        }
      } catch (error) {}
    } catch (error) {
      Log.error('Error on editVideoTag :', error);
    }
  };

  const playVideoTimeLine = (e, time) => {
    try {
      e.preventDefault();
      if (time > videoRef.current.duration) {
        videoRef.current.currentTime = videoRef.current.duration;
      } else {
        videoRef.current.currentTime = time;

        // videoRef.current.play();
        const playPromise = videoRef.current.play();

        if (playPromise !== undefined) {
          playPromise
            .then((_) => {
              // Automatic playback started!
              // Show playing UI.
            })
            .catch((error) => {
              // Auto-play was prevented
              // Show paused UI.
            });
        }
      }
    } catch (error) {
      Log.error('Error on playVideoTimeLine:', error);
    }
  };

  return (
    <>
      {videoTagMode === 'edit' && (
        <EditVideoTagOffCanvas
          show={videoTagMode === 'edit'}
          data={selectedVideoTag}
          oldTags={video.tags}
          onClose={() => setVideoTagMode('')}
          onSave={editVideoTag}
        />
      )}

      {videoTagMode === 'add' && (
        <VideoTagOffCanvas
          show={videoTagMode === 'add'}
          oldTags={video.tags}
          onClose={() => setVideoTagMode('')}
          onSave={saveTags}
        />
      )}

      <Card className="my-3">
        <Card.Header className="d-flex align-items-center justify-content-between gap-2">
          Video Evidence
          {evidenceId && canAccessAll && (
            <Button
              className="video-remove-btn"
              variant="outline"
              onClick={() => removeVideo(evidenceId)}
              title="Remove"
            >
              {removeIcon} Remove
            </Button>
          )}
        </Card.Header>
        <Card.Body>
          <Row className="align-items-center">
            <Col
              sm={5}
              lg={5}
              xl={5}
            >
              {loading === true ? (
                <div className="spinner-wrap">
                  <Spinner
                    animation="border"
                    className="text-white"
                  />
                </div>
              ) : (
                <>
                  {video.url?.length === 0 && <div className="thumnail-image">500x380</div>}
                  {video.url?.length > 0 && (
                    <>
                      <video
                        src={video.url}
                        className="rounded w-100 course-image"
                        alt="Course Thumbnail"
                        controls={true}
                        ref={videoRef}
                      />
                    </>
                  )}
                </>
              )}
            </Col>
            <Col
              sm={7}
              lg={7}
              xl={7}
              className="card-info card-info-res"
            >
              {canAccessAll && (
                <>
                  <Card.Title
                    as="h5"
                    className="mb-3"
                  >
                    Upload Video Evidence
                  </Card.Title>
                  <Button
                    onClick={uploadVideoHandler}
                    variant="outline"
                    className="border border-primary text-primary"
                  >
                    Upload Video
                  </Button>
                </>
              )}

              <input
                type="file"
                accept=".webm,.mp4,.m4v,.mov,.ogg,.3gp"
                className="d-none"
                ref={featuredVideoInput}
                onChange={(e) => (member_activity ? uploadOTJVideo(e) : uploadVideo(e))}
              />
            </Col>
            {errorMsg && (
              <span
                className="invalid-feedback"
                style={{ display: 'block' }}
              >
                {errorMsg}
              </span>
            )}
          </Row>
          {video.url !== '' && canAccessAll && (
            <div className="add-tag-btn justify-content-start mt-2 mb-0 p-0">
              <Button
                onClick={() => setVideoTagMode('add')}
                variant="default"
                className="p-0"
              >
                {addIcon}
                Add Tag
              </Button>
            </div>
          )}

          {video.tags && video?.tags?.length > 0 && (
            <>
              <h4 className="video-tag-title">Video Tags</h4>

              <ul className="styled-list video-tag-list">
                {video.tags.map((item, index) => {
                  return (
                    <li key={index}>
                      <a
                        href="#"
                        onClick={(e) => playVideoTimeLine(e, item.time)}
                      >
                        {covertSecsToTime(item.time)}
                      </a>
                      - {item.tag}
                      {canAccessAll && (
                        <div className="video-btn-wrap">
                          <Button
                            variant="default"
                            onClick={() => showEditVideoTag(item)}
                            title="Edit"
                          >
                            {editIcon}
                          </Button>
                          <Button
                            variant="default"
                            onClick={() => removeTag(item)}
                            title="Remove"
                          >
                            {removeIcon}
                          </Button>
                        </div>
                      )}
                    </li>
                  );
                })}
              </ul>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
}
export default React.memo(AddVideoEvidence);
