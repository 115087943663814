import { format } from 'date-fns';

export const formatDate = (date) => {
  if (date) {
    return format(new Date(date), 'dd-MM-yyyy, hh:mma');
  }
};

export const formatDateddMMyyy = (date) => {
  if (date) {
    return format(new Date(date), 'dd-MM-yyyy');
  }
};

export const displayMonth = (date) => {
  if (date) {
    const onlyDate = new Date(date).toISOString().split('T')[0];
    return format(new Date(onlyDate), 'dd LLL yyyy');
  }
};

export const formatToUTCDate = (date) => {
  const d = new Date(date);
  const month = d.getMonth() + 1 > 9 ? '' + (d.getMonth() + 1) : '0' + (d.getMonth() + 1);
  const day = d.getDate() > 9 ? '' + d.getDate() : '0' + d.getDate();
  const year = d.getFullYear();

  const fullDate = [year, month, day].join('-');
  return `${fullDate}T23:59:59Z`;
};

export const formatToUTC = (date) => {
  const d = new Date(date);
  const month = d.getMonth() + 1 > 9 ? '' + (d.getMonth() + 1) : '0' + (d.getMonth() + 1);
  const day = d.getDate() > 9 ? '' + d.getDate() : '0' + d.getDate();
  const year = d.getFullYear();

  const fullDate = [year, month, day].join('-');
  return `${fullDate}T00:00:00Z`;
};

export const formatUTCDate = (date) => {
  if (date) {
    return new Date(date).toISOString().split('T')[0].split('-').reverse().join('-');
  }
};

export const formatUTCDateTime = (date) => {
  if (date) {
    const onlyDate = new Date(date).toISOString().split('T')[0].split('-').reverse().join('-');
    const onlyTime = new Date(date).toISOString().split('T')[1].split('.')[0].slice(0, 5);
    const ampm = onlyTime.split(':')[0] >= 12 ? `${onlyTime} PM` : `${onlyTime} AM`;
    return `${onlyDate},   ${ampm}  `;
  }
};
