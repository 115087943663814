import Modal from 'react-myoneui/lib/Modal';
import Button from 'react-myoneui/lib/Button';
import { Spinner } from 'react-myoneui';
function SubmitModal(props) {
  let { onClose, onSaveAsDraft = () => {}, onSubmit = () => {}, saveLoder = false, submitLoder = false } = props;
  const programSubmitClose = () => onClose();

  return (
    <Modal
      show={props.show}
      onHide={programSubmitClose}
      contentClassName="bg-transparent"
    >
      <Modal.Header>
        <Modal.Title>Are you Sure you want Submit ?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="description-modal-opacity">Are you sure you want to submit the form? Once submitted, it will be forwarded for review and you will not be able to edit it.</p>
        <p className="description-modal-opacity">Submit, if you want to submit it.</p>
        <p className="description-modal-opacity">Cancel, if you want to cancel the submission.</p>
        <p className="description-modal-opacity">Save, if you want to save.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="link"
          onClick={() => programSubmitClose()}
        >
          Cancel
        </Button>
        <Button
          variant="glitter"
          onClick={() => {
            onSaveAsDraft();
          }}
        >
          {saveLoder && (
            <>
              <Spinner
                animation="border"
                size="sm"
              />{' '}
              &nbsp;{' '}
            </>
          )}
          Save
        </Button>

        <Button
          variant="primary"
          onClick={() => {
            onSubmit();
          }}
        >
          {submitLoder && (
            <>
              <Spinner
                animation="border"
                size="sm"
              />{' '}
              &nbsp;{' '}
            </>
          )}
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SubmitModal;
