import { Link, useLocation } from 'react-router-dom';
import LinkWrapper from '../../../components/LinkWrapper';
import { leftArrow } from '../../../constants/icons';
import { useParams, useNavigate } from 'react-router-dom';
import ls from 'localstorage-slim';

// enable global encryption
ls.config.encrypt = true;
export const profileFilterSidebarList = [
  {
    text: 'Profile ',
    type: 'item',
    link: '#my-profile',
    isReact: true,
  },
  {
    text: 'About',
    type: 'item',
    link: '#about',
    isReact: false,
  },
  {
    text: 'Activity',
    type: 'item',
    link: '#activity',
    isReact: false,
  },
  // {
  //   text: 'Change Password',
  //   type: 'item',
  //   link: '#',
  //   isReact: false,
  // },
];

function ProfileFilterSidebar({ userorApprentice }) {
  const { orgId, programId, id } = useParams();
  const ownId = ls.get('userid');
  const roles = JSON.parse(ls.get('roles'));
  const apprenticeName = roles.filter((role) => role.identifier === 'apprentice')[0].name;
  const idStatus = ownId === id;
  const onLinkClick3 = (e) => {
    const hrefSplit = e.target.href.split('#');
    const id = hrefSplit[hrefSplit.length - 1];
    const item = document.getElementById(id);
    if (item) {
      item.scrollIntoView({ block: 'center' });
    }
  };
  const location = useLocation();
  const navigate = useNavigate();

  let cloneProfilesideList = [...profileFilterSidebarList];
  //if admin views own profile the activity tab is hidden but it admin views learners profile activity tab is shown
  if (idStatus) {
    cloneProfilesideList = profileFilterSidebarList.filter((item) => item.text !== 'Activity');
  }
  const listSidebarItems = cloneProfilesideList.map((child, i) => {
    const isActive = location.hash === child.link;
    if (child.type === 'divider') {
      return (
        <li
          key={i}
          className="divider"
        />
      );
    }
    return (
      <li
        key={i}
        className={isActive ? 'active' : ''}
      >
        <LinkWrapper
          isReact={child.isReact}
          href={child.link}
          className={child.className}
          onClick={onLinkClick3}
        >
          {child.text}
        </LinkWrapper>
      </li>
    );
  });
  // `/admin/users/${userorApprentice}`
  const checkUrl = (url) => {
    if (url.endsWith('/')) {
      url = url.slice(0, -1);
      return url;
    } else return url;
  };

  const removeLastTwoParams = (url) => {
    const FinalURL = checkUrl(url);
    const segments = FinalURL.split('/');
    const newSegments = segments.slice(0, -2);

    if (newSegments.length <= 2) {
      return newSegments.join('/') + '/dashboard';
    }
    return newSegments.join('/') + '/';
  };

  return (
    <div className="dynamic-inner-sidebar">
      <div className="fixed-sidebar-back">
        {/* <Link className="sidebar-back-nav" to={`/organization/${orgId}/program/${programId}`}> */}
        {/* <Link className="sidebar-back-nav" to="/admin/users/apprentices">
          {leftArrow}
          Apprentices
        </Link> */}
        {/* <Link className="sidebar-back-nav text-capitalize" to={idStatus ? '/admin' : `/admin/users/${userorApprentice}`}> */}
        <Link
          className="sidebar-back-nav text-capitalize"
          to={removeLastTwoParams(location.pathname)}
        >
          {leftArrow}
          <span
            className="text-truncate"
            title="Back"
          >
            Back
          </span>
          {/* {!idStatus ? (userorApprentice.split('-')[0] === 'apprentices' ? apprenticeName + 's' : userorApprentice.split('-')[0]) : 'Dashboard'} */}
        </Link>
        <span className="divider" />
      </div>
      <ul className="sidebar-list">{listSidebarItems}</ul>
    </div>
  );
}

export default ProfileFilterSidebar;
