import { React, useState, useEffect } from 'react';
import { forwardRef, Children } from 'react';
import Dropdown from 'react-myoneui/lib/Dropdown';
import { useNavigate } from 'react-router-dom';
import AddHoursOffCanvas from './AddHoursOffCanvas';
import AddEvidenceOffCanvas from './AddEvidenceOffCanvas';
import { useSelector } from 'react-redux';
import DmsUploadWidget from './DmsUploadWidget';
import { getTokenForDMS } from '../../../api/learner/program';
import { addEvidences } from '../../../api';
import { addMemberActivityEvidences } from '../../../api/learner/section';
import ls from 'localstorage-slim';
import Log from '../../../lib/Log';
// enable global encryption
ls.config.encrypt = true;
// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = forwardRef(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
  const [value, setValue] = useState('');

  return (
    <div
      ref={ref}
      style={style}
      className={className}
      aria-labelledby={labeledBy}
    >
      <ul className="list-unstyled mb-0">{Children.toArray(children).filter((child) => !value || child.props.children.toLowerCase().startsWith(value))}</ul>
    </div>
  );
});

function AddActivitiesDropdown({ activeActivity, userId, addedNewHour, addedNewEvidences, canAddManualHours, ...props }) {
  const [show, setShow] = useState(false);
  const [showEvidence, setShowEvidence] = useState(false);
  const handleShow = () => setShow(true);
  const evidenceHandleShow = () => setShowEvidence(true);
  const [dmsToken, setDMSToken] = useState('');
  const selectedComponent = useSelector((state) => state.sections.active_component);
  const member_section_id = selectedComponent?.member_section ? selectedComponent?.member_section?.id : selectedComponent?.id;
  const memberId = ls.get('userid');

  useEffect(() => {
    if (showEvidence && !dmsToken) {
      getToken();
    }
  }, [showEvidence]);

  const getToken = async () => {
    try {
      const { data } = await getTokenForDMS(userId);
      setDMSToken(data.data.token);
    } catch (err) {
      Log.info('Error >> ', err);
    }
  };

  const widgetResponse = async (res) => {
    try {
      if (res) {
        let rawlist = [...res];

        if (Array.isArray(rawlist)) {
          let list = rawlist.map((item) => {
            return {
              item_id: item.id.toString(),
              name: item.i_item_name,
              url: item.download_url,
              type: item.i_document_file_type,
              attached_by: memberId,
            };
          });
          if (list.length > 0) {
            const { data } = await addMemberActivityEvidences({ member_section_id, member_activity: activeActivity, evidences: list });
            addedNewEvidences(data.data.evidences);
            return;
          }
        }
      }
    } catch (error) {
      Log.info(error);
    }
  };

  return (
    <>
      <Dropdown
        className="dropdown-select text-end"
        align="end"
      >
        {props.children}
        <div className="dropdown-menu-wrap">
          <Dropdown.Menu
            as={CustomMenu}
            className="dropdown-menu max-content-dropdown"
          >
            <Dropdown.Item
              eventKey="1"
              className="section-dropdown-item"
              onClick={handleShow}
            >
              <span className="px-2 fw-bold">Add Hours</span>
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="2"
              className="section-dropdown-item"
              onClick={evidenceHandleShow}
            >
              <span className="px-2 fw-bold">Add Evidence</span>
            </Dropdown.Item>
            {/* <Dropdown.Item eventKey="2" className="section-dropdown-item">
              <span className="px-2 fw-bold">Add Comments</span>
            </Dropdown.Item> */}
          </Dropdown.Menu>
        </div>
      </Dropdown>
      <AddHoursOffCanvas
        show={show}
        onClose={() => setShow(false)}
        activeActivity={activeActivity}
        addedNewHour={addedNewHour}
        canAddManualHours={canAddManualHours}
      />
      {showEvidence && dmsToken && (
        <DmsUploadWidget
          showButton={false}
          onClose={() => setShowEvidence(false)}
          buttonTitle={'Document create folder'}
          widgetEndPoint={`https://document.uat.mykademy.com/widget/document-manager?token=${dmsToken}`}
          uploadCallback={widgetResponse}
        />
      )}
      {/* <AddEvidenceOffCanvas user_id={userId} show={showEvidence} onClose={() => setShowEvidence(false)} /> */}
    </>
  );
}

export default AddActivitiesDropdown;
