import { memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from 'react-myoneui';
import Offcanvas from 'react-myoneui/lib/Offcanvas';
import Button from 'react-myoneui/lib/Button';
import Form from 'react-myoneui/lib/Form';
import FloatingLabel from 'react-myoneui/lib/FormFloating';
import Rating from 'components/Rating';
import Log from 'lib/Log';
import { decryptData } from 'lib';
import { addFeedbackRating, getFeedbackRatingById } from 'api/learner/program';
import ls from 'localstorage-slim';

function OffcanvasRating({ triggerTitle, data }) {
  const offCanvasKeyDown = (e) => e.stopPropagation();
  const [show, setShow] = useState(false);
  const [shortDescription, setShortDescription] = useState('');
  const [rating, setRating] = useState(null);
  const [loading, setLoading] = useState(null);
  const [isDetailsLoading, setIsDetailsLoading] = useState(false);
  const orgId = ls.get('orgId');
  const handleClose = () => {
    setShow(false);
    setShortDescription('');
    setRating(undefined);
  };
  const handleShow = () => {
    getById();
    setShow(true);
  };
  const { id } = useParams();
  Log.info(decryptData(id));

  const saveReview = () => {
    try {
      let payload = {
        program: data?.section?.program,
        memberSection: data?.id,
        review: shortDescription,
        rating: rating,
      };
      setLoading(true);
      addFeedbackRating(payload)
        .then((result) => {
          Log.info(result);
          setLoading(false);
          handleClose();
        })
        .catch((error) => {
          Log.error(error);
          setLoading(false);
        });
    } catch (error) {
      Log.error('Error : /......................', error);
      // setToasterStatus(true);
      // setToasterDesc(error?.data?.message);
    }
  };

  const getById = async () => {
    let payload = {
      memberProgramId: data.member_program,
      memberSectionId: data._id,
      memberId: data.member,
    };
    try {
      setIsDetailsLoading(true);
      const result = await getFeedbackRatingById(orgId, payload);
      if (result?.data?.data) {
        setRating(result?.data?.data?.rating);
        setShortDescription(result?.data?.data?.review);
        setLoading(false);
      }
    } catch (error) {
      Log.error('Error : /......................', error);
    } finally {
      setIsDetailsLoading(false);
    }
  };
  const invalidReview = !rating || !shortDescription;

  return (
    <>
      <div className="review-rating-btn">
        <Button
          variant="default"
          className="m-0 p-0"
          onClick={handleShow}
        >
          {triggerTitle}
        </Button>
      </div>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className="pb-0"
        onKeyDown={offCanvasKeyDown}
      >
        <Offcanvas.Header
          closeButton
          backButton="Back"
          className="pb-4 border-0"
        >
          <Offcanvas.Title>Feedback</Offcanvas.Title>
        </Offcanvas.Header>
        {isDetailsLoading ? (
          <div className="d-flex justify-content-center align-items-center h-100 w-100">
            <Spinner animation="border" />
          </div>
        ) : (
          <Offcanvas.Body className="pt-0">
            <Rating
              name="rating"
              onChange={(e) => setRating(e)}
              value={rating}
            />
            <Form.Group className="mb-3">
              <Form.Label htmlFor="floatingTextarea1">Leave a feedback on this bundle</Form.Label>
              <FloatingLabel
                controlId="floatingTextarea"
                label="Short Description"
              >
                <Form.Control
                  as="textarea"
                  placeholder="Short Description"
                  className="review-textarea"
                  value={shortDescription}
                  onChange={(e) => {
                    setShortDescription(e.target.value);
                  }}
                />
                <Form.Label htmlFor="floatingTextarea">Short Description</Form.Label>
              </FloatingLabel>
            </Form.Group>
            <div className="mt-4 text-end">
              <Button
                variant="default"
                onClick={handleClose}
              >
                I'll do this later
              </Button>
              <Button
                variant="primary"
                onClick={() => saveReview()}
                disabled={invalidReview}
              >
                <div className="spinner-align-center">
                  {loading && (
                    <Spinner
                      animation="border"
                      size="sm"
                    />
                  )}
                  Submit
                </div>
              </Button>
            </div>
          </Offcanvas.Body>
        )}
      </Offcanvas>
    </>
  );
}

export default memo(OffcanvasRating);
