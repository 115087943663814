import HeaderSelectionCellTemplate from './HeaderSelectionCellTemplate';
import LinkWrapper from 'components/LinkWrapper';
import { tickIcon, penIcon, viewProfileIcon, trashIcon, ratingIcon, learnerProfileIcon, learnerIcon } from 'constants/icons';
import { Link, useLocation } from 'react-router-dom';
// import EditUserOffCanvas from '../../userManagement/EditUserOffCanvas';
import ls from 'localstorage-slim';
import { encryptData } from 'lib';
import Log from 'lib/Log';
ls.config.encrypt = true;

const ContextMenuTemplate = ({
  assignedViewPrivileges = { can_view_only_assigned_program: true, can_manage_only_assigned_apprentices: true },
  editStatus,
  assessorName,
  setEditStatus,
  members,
  display,
  setDeleteUser,
  onReLoad,
}) => {
  const activeRole = JSON.parse(ls.get('activeRole'));
  const role = activeRole.identifier;
  const apprenticeIds = members.map((member) => member);
  const mapToIQV = (id) => {
    Log.info(id, 'mapToIQV...');
  };

  return ({ tableManager, path }) => {
    // const onClearClick = (e) => {
    //   tableManager.rowSelectionApi.setSelectedRowsIds([]);
    // };

    return (
      <>
        {
          // <EditUserOffCanvas
          //   formName="editApprentice"
          //   onReLoad={() => onReLoad()}
          //   editUserTitle={`Edit ${assessorName}`}
          //   show={editStatus}
          //   user={members[0]}
          //   onClose={() => setEditStatus(false)}
          // />
        }

        <div className="rgt-cell-header-sticky rgt-context-menu">
          <div className="grid-table-header">
            <HeaderSelectionCellTemplate tableManager={tableManager} />
            <div className="grid-table-inner-header">
              {display && (
                <>
                  {activeRole.privileges?.can_edit_apprentices && role === 'admin' && (
                    <LinkWrapper
                      href="#top"
                      onClick={() => setEditStatus(true)}
                    >
                      {penIcon}
                      <span className="fw-bold fs-10">Edit {assessorName}</span>
                    </LinkWrapper>
                  )}

                  {role === 'admin' && (
                    <LinkWrapper
                      href="#top"
                      onClick={() => mapToIQV(apprenticeIds)}
                    >
                      {viewProfileIcon}
                      <span className="fw-bold fs-10">Map to IQV</span>
                    </LinkWrapper>
                  )}

                  <Link to={'view-profile/' + encryptData(members[0].id)}>
                    {viewProfileIcon}
                    <span className="fw-bold fs-10">View Profile</span>
                  </Link>
                  {['iqv', 'eqv'].includes(role) && (
                    <LinkWrapper href={path + `/${encryptData(members[0].id)}/review`}>
                      {ratingIcon}
                      <span className="fw-bold fs-10">Rating &amp; Review </span>
                    </LinkWrapper>
                  )}

                  {['iqv', 'eqv'].includes(role) && (
                    <LinkWrapper href={path + `/${encryptData(members[0].id)}/learner-program`}>
                      {learnerIcon}
                      <span className="fw-bold fs-10">View Learner Program</span>
                    </LinkWrapper>
                  )}
                  {activeRole.privileges?.can_edit_apprentices && (
                    <LinkWrapper
                      href="#top"
                      onClick={() => setDeleteUser(apprenticeIds)}
                    >
                      {trashIcon}
                      <span className="fw-bold fs-10">Remove {display ? `${assessorName}` : `${assessorName}s`}</span>
                    </LinkWrapper>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        {/* {JSON.stringify(members)} */}
        {/* {assignToProgram === true && <AssignToProgramOffCanvas show={assignToProgram} users={members} onClose={() => showAssignToProgram(false)} />} */}
      </>
    );
  };
};

export default ContextMenuTemplate;
