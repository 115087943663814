import React, { useEffect, useState } from 'react';
function DragItem(props) {
  const { data, id, parentid, index, onChange = () => {}, isDraggable } = props;
  const [isDragOver, setDragOver] = useState(false);
  const [showChilds, setShowChilds] = useState(false);
  const [isDragStart, setIsDragStart] = useState(false);

  const [item, setItem] = useState(null);
  const dragStart = (ev) => {
    let obj = { id: ev.target.id, parentid: ev.target.dataset.parentid, content: ev.target.dataset.content, index: ev.target.dataset.index };
    ev.dataTransfer.setData('text', JSON.stringify(obj));
    setIsDragStart(true);
  };

  const allowDrop = (ev) => {
    ev.preventDefault();
    var data = ev.dataTransfer.getData('text');
    setDragOver(true);
  };

  const dragLeave = (ev) => {
    setDragOver(false);
  };
  const dragEnter = (ev) => {};
  const drop = (ev) => {
    // Drag Container
    let dragObj = JSON.parse(ev.dataTransfer.getData('text'));
    if (dragObj.parentid === ev.currentTarget.dataset.parentid) {
      ev.preventDefault();
      let start = dragObj.index;
      let end = ev.currentTarget.dataset.index;
      onChange({ start, end });
    }
    setDragOver(false);

    // ev.target.appendChild(document.getElementById(data));
  };
  const dragEnd = () => {
    setDragOver(false);
    setIsDragStart(false);
  };

  useEffect(() => {
    setItem(data);
  }, [data]);
  return (
    item && (
      <div className={`droppable`}>
        <div
          className={`dragItem ${isDragOver ? 'hover' : ''}`}
          data-parentid={parentid}
          data-index={index}
          onDragEnter={dragEnter}
          onDrop={(ev) => drop(ev, item)}
          onDragOver={allowDrop}
          onDragEnd={dragEnd}
          onDragLeave={dragLeave}
        >
          <div
            className={`itemBlock ${isDragStart ? 'dragStart' : ''}`}
            id={item.id}
            data-parentid={parentid}
            data-index={index}
            onDragStart={dragStart}
            draggable={isDraggable}
          >
            {props.children ? props.children : JSON.stringify(item)}
          </div>
        </div>
      </div>
    )
  );
}
export default DragItem;
