import axios from 'axios';
import { API_URL } from '../constants/config';
import ls from 'localstorage-slim';

// enable global encryption
ls.config.encrypt = true;
export const getRoles = async (orgId) => {
  const headers = {
    headers: {
      organisationid: orgId,
      Authorization: `Bearer ${ls.get('token')}`,
    },
  };
  try {
    return await axios.get(`${API_URL}/admin/roles`, headers);
  } catch (err) {
    throw new Error(err);
  }
};
