import { useEffect } from 'react';
import { Badge, CircleImage, CircleImageContent } from 'react-myoneui';
import { useLocation } from 'react-router-dom';
import { getUserIconFromRoles } from '../../lib';
import ls from 'localstorage-slim';
import { formatDate } from '../../utils/formatTime';
import CircleImg from '../CircleImg';

// enable global encryption
ls.config.encrypt = true;
const FeedBackBlock = (props) => {
  const { data } = props;
  const { pathname } = useLocation();
  const activeRole = JSON.parse(ls.get('activeRole'));
  const pathRole = pathname.split('/')[1];
  const role = ls.get('role');
  const isTutor = role === 'tutor' && pathRole === 'tutor' && activeRole.identifier === 'tutor';
  const isAdmin = role === 'admin' && pathRole === 'admin' && activeRole.identifier === 'admin';
  const isLearner = role === 'learner' && pathRole === 'learner' && activeRole.identifier === 'apprentice';
  const currentActiveRole = isTutor ? 'tutor' : isAdmin ? 'admin' : isLearner ? 'learner' : 'superadmin';

  const showDate = (mydate) => {
    let myhours = parseInt(mydate.slice(11, 12));
    let day = '';
    if (myhours > 12) {
      day = 'pm';
    } else {
      day = 'am';
    }
    return mydate.slice(0, 10) + ', ' + mydate.slice(11, 16) + ' ' + day;
  };
  const feedbackBy = (data) => {
    return data?.created_by?.member?.first_name + ' ' + data?.created_by?.member?.last_name;
  };
  const feedbackTo = (data) => {
    return data?.created_to?.member?.first_name + ' ' + data?.created_to?.member?.last_name;
  };
  const feedbackToLearner = () => {
    return (
      <span>
        {feedbackBy(data)}'s feedback to {feedbackTo(data)}{' '}
      </span>
    );
  };
  const feedbackFrom = () => {
    return <span>{feedbackBy(data)}'s Observation </span>;
  };

  return (
    <div className={`feedback-block success-border-line`}>
      <div className="d-flex gap-2">
        <CircleImg
          defaultIcon={getUserIconFromRoles([{ ...data?.created_by?.role }])}
          src={data?.created_by?.member?.profile_image}
          title={data?.created_by?.member?.first_name}
        />

        <div className="feedback-info-wrap m-0 d-block flex-1">
          <h5 className="small-text-title">{currentActiveRole !== 'learner' ? <>{data?.created_to?.member ? feedbackToLearner() : feedbackFrom()}</> : feedbackBy(data)}</h5>
          <p
            className="feedback-text"
            dangerouslySetInnerHTML={{ __html: data.feedback }}
          ></p>
        </div>
      </div>
      <div className="feedback-mark-wrap justify-content-end">
        {/* <Badge className="badge badge-light-blue">Mark for Resubmission</Badge> */}
        <span className="program-list-update-text">
          {/* Last Update: <strong>{showDate(data.updatedAt)} </strong> */}
          Last Update: <strong>{formatDate(data.updatedAt)} </strong>
        </span>
      </div>
    </div>
  );
};

export default FeedBackBlock;
