import { memo, useEffect, useState } from 'react';
import { Form, Col } from 'react-myoneui';

function InputText({ data }) {
  const [state, setState] = useState({ ...data });
  useEffect(() => {
    setState(data);
  }, [data]);

  return (
    <>
      <Form.Label
        htmlFor={state.label + state.id}
        className="form-builder-label"
      >
        {state.label}
      </Form.Label>
      <Form.Control
        type={state.field_type}
        id={state.label + state.id}
        aria-describedby="passwordHelpBlock"
        placeholder={state.placeholder}
      />{' '}
    </>
  );
}
export default memo(InputText);
