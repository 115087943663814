import React, { useState, forwardRef, Children, useEffect } from 'react';
import Dropdown from 'react-myoneui/lib/Dropdown';
import Form from 'react-myoneui/lib/Form';
import FloatingLabel from 'react-myoneui/lib/FloatingLabel';
import { CircleImage, CircleImageContent } from 'react-myoneui';
import PropTypes from 'prop-types';
import ls from 'localstorage-slim';
import { assessorIcon, learnerIcon } from 'constants/icons';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = forwardRef(({ children, onClick }, ref) => (
  <div
    role="button"
    tabIndex={0}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = forwardRef(({ children, style, className, value, 'aria-labelledby': labeledBy }, ref) => {
  // const [value, setValue] = useState('');

  return (
    <div
      ref={ref}
      style={style}
      className={className}
      aria-labelledby={labeledBy}
    >
      <ul className="list-unstyled mb-0">{Children.toArray(children).filter((child) => !value || child.props.children.toLowerCase().includes(value))}</ul>
    </div>
  );
});

function DropdownFeedback({ children, label, className, items = [], defaultValue = '', onChange = () => {}, empty = null, disable = false, isIQV, isEQV }) {
  const [menuValue, setMenuValue] = useState('');
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState({});
  const [data, setData] = useState([]);
  const [imageError, setImageError] = useState([]);

  const roles = JSON.parse(ls.get('roles'));
  const assessorName = roles.filter((role) => role.identifier === 'assessor')[0]?.name;
  const learnerName = roles.filter((role) => role.identifier === 'apprentice')[0]?.name;
  const iqvName = roles.filter((role) => role.identifier === 'iqv')[0]?.name;

  useEffect(() => {
    if (empty) {
      setData([{ id: 0, name: 'None' }, ...items]);
    } else if (isIQV) {
      // setData([...items, { id: 'everyone', name: 'For Everyone' }]);
      setData([...items]);
    } else {
      setData([...items]);
    }
  }, [items]);
  useEffect(() => {
    if (defaultValue) {
      setSelected(defaultValue);
      (isEQV || isIQV) && onChange([defaultValue]);

      // onChange(defaultValue);
    }
  }, [defaultValue, isEQV, isIQV]);

  // useEffect(() => {
  //   if (isIQV) {
  //     setSelected({ id: 'everyone', name: 'For Everyone' });
  //     onChange(items, 'everyone');
  //   }
  // }, [isIQV, isEQV]);

  const onSelect = (index) => {
    if (isIQV || isEQV) {
      const selectedval = data.find((val) => val.id === index);
      setSelected(selectedval);

      if (index === 'everyone') {
        onChange(items, 'everyone');
      } else {
        onChange([selectedval]);
      }
    } else {
      setSelected(data[index]);
      onChange([data[index]]);
    }
  };

  const onToggle = (show, meta) => {
    setShow(show);
    if (!show) {
      setMenuValue('');
    }
  };
  const onInputChange = (e) => {
    const val = e.target.value ? e.target.value.trim().toLowerCase() : '';

    setSelected(val);
    setMenuValue(val);
    setShow(true);
  };
  const onInputKeyUp = (e) => {
    const val = e.target.value ? e.target.value.trim().toLowerCase() : '';
    if (e.key === 'Enter' && val) {
      const matchedIndex = data.findIndex((item) => item.toLowerCase().includes(val));
      if (matchedIndex > -1) {
        const selected = data[matchedIndex];
        setSelected(selected);
        onChange(selected);
      }
    }
  };

  return (
    <Dropdown
      className={`dropdown-select ${className}`}
      onSelect={onSelect}
      show={show}
      onToggle={onToggle}
      disabled={disable}
    >
      <Dropdown.Toggle
        as={CustomToggle}
        id="dropdown-custom-components"
      >
        <FloatingLabel
          controlId="floatingSelection"
          label={label ? label : 'Choose Option'}
          className="w-100"
        >
          {
            <Form.Control
              className="pe-5 pointer-event-none"
              type="text"
              placeholder="Events"
              onChange={onInputChange}
              onKeyDown={onInputChange}
              value={selected?.name ? selected.name : 'None'}
              autoComplete="off"
            />
          }
          {children}
          <div className="caret-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="6.119"
              height="11.658"
              viewBox="0 0 6.119 11.658"
            >
              <path
                id="Path_21616"
                data-name="Path 21616"
                d="M0,0,4.42,3.71,8.84,0"
                transform="translate(1.409 10.249) rotate(-90)"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </svg>
          </div>
        </FloatingLabel>
      </Dropdown.Toggle>

      {isIQV && data?.length > 2 && (
        <Dropdown.Menu
          className="feedback-dropdown-list"
          value={menuValue}
          as={CustomMenu}
          align={'end'}
        >
          <li>
            <span>For {assessorName}</span>
            {data
              .filter((user) => user.isAssesssor)
              .map((item, i) => (
                <Dropdown.Item
                  key={i}
                  eventKey={item.id}
                  active={selected.id === item.id}
                  className="feedback-user-wrap"
                >
                  <CircleImage className="circle-wrap circle-wrap-xs">
                    {!imageError.includes(item.id) && item?.image?.length > 0 ? (
                      <img
                        src={item?.image}
                        alt={item?.name}
                        onError={() => {
                          setImageError(() => [...imageError, item.id]);
                        }}
                      />
                    ) : (
                      <>{assessorIcon}</>
                    )}
                  </CircleImage>
                  {item.name}
                </Dropdown.Item>
              ))}
          </li>
          {/* 
          <li>
            <span>For {learnerName}</span>
            {data
              .filter((user) => user.isLearner)
              .map((item, i) => (
                <Dropdown.Item
                  key={i}
                  eventKey={item.id}
                  active={selected.id === item.id}
                  className="feedback-user-wrap"
                >
                  <CircleImage className="circle-wrap circle-wrap-xs">
                    {!imageError.includes(item.id) && item?.image?.length > 0 ? (
                      <img
                        src={item?.image}
                        alt={item?.name}
                        onError={() => {
                          setImageError(() => [...imageError, item.id]);
                        }}
                      />
                    ) : (
                      <>{learnerIcon}</>
                    )}
                  </CircleImage>
                  {item.name}
                </Dropdown.Item>
              ))}
          </li>

          <li>
            <Dropdown.Item
              eventKey={'everyone'}
              active={selected.id === 'everyone'}
            >
              For Everyone
            </Dropdown.Item>
          </li> */}
        </Dropdown.Menu>
      )}

      {isEQV && data?.length > 2 && (
        <Dropdown.Menu
          className="feedback-dropdown-list"
          value={menuValue}
          as={CustomMenu}
          align={'end'}
        >
          <li>
            <span>For {iqvName}</span>
            {data
              .filter((user) => user.isIqv)
              .map((item, i) => (
                <Dropdown.Item
                  key={i}
                  eventKey={item.id}
                  active={selected.id === item.id}
                  className="feedback-user-wrap"
                >
                  <CircleImage className="circle-wrap circle-wrap-xs">
                    {!imageError.includes(item.id) && item?.image?.length > 0 ? (
                      <img
                        src={item?.image}
                        alt={item?.name}
                        onError={() => {
                          setImageError(() => [...imageError, item.id]);
                        }}
                      />
                    ) : (
                      <>{assessorIcon}</>
                    )}
                  </CircleImage>
                  {item.name}
                </Dropdown.Item>
              ))}
          </li>
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
}
DropdownFeedback.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  items: PropTypes.array,
  defaultValue: PropTypes.object,
  onChange: PropTypes.func,
};
export default React.memo(DropdownFeedback);
