import { useState, memo } from 'react';
import CircleImage from 'react-myoneui/lib/CircleImage';
import { defaultUserIcon } from '../../constants/icons';

function CircleImg({ src, title, defaultIcon }) {
  const [hasError, setHasError] = useState(false);

  return (
    <CircleImage className="circle-wrap circle-wrap-md bg-profile-color">
      {src?.length > 0 && !hasError ? (
        <img
          src={src}
          alt={title}
          title={title ? title : ''}
          onError={() => setHasError(true)}
        />
      ) : (
        <>{defaultIcon ? defaultIcon : defaultUserIcon}</>
      )}
    </CircleImage>
  );
}
export default memo(CircleImg);
