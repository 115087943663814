import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { LANG } from '../constants/config';
import ls from 'localstorage-slim';
import Log from '../lib/Log';
// enable global encryption
ls.config.encrypt = true;
const lang = ls.get('lang') ? ls.get('lang') : LANG;

Log.info(`Selected lang : ${lang}`);
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(ChainedBackend)
  .init({
    lng: lang,
    fallbackLng: 'en',
    ns: ['common', 'superadmin'],
    defaultNS: 'common',
    fallbackNS: 'common',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: false,
    },
    backend: {
      backends: [
        HttpBackend,
        // if a namespace can't be loaded via normal http-backend loadPath, then the inMemoryLocalBackend will try to return the correct resources
        resourcesToBackend((language, namespace, callback) => {
          import(`../assets/locales/${language}/${namespace}.json`)
            .then(({ default: resources }) => {
              callback(null, resources);
            })
            .catch((error) => {
              callback(error, null);
            });
        }),
      ],
      backendOptions: [
        {
          // loadPath: `${API_URL}/locales/{{lng}}/{{ns}}`,
          // loadPath: `http://localhost/locales/{{lng}}_{{ns}}`,
        },
      ],
    },
  });

export default i18n;
