import { memo, useState, useEffect } from 'react';
import { FloatingLabel, Form } from 'react-myoneui';

function InputTextArea({ data }) {
  const [state, setState] = useState({ ...data });

  useEffect(() => {
    setState(data);
  }, [data]);
  return (
    <>
      <Form.Label className="form-builder-label">{state?.label}</Form.Label>
      <FloatingLabel
        controlId="floatingInputTitle"
        // label={translate(lang)('sample_title')}
        label={state?.placeholder}
        className="form-builder-floating form-builder-textarea-label"
      >
        <Form.Control
          readOnly
          tabIndex="1"
          as={state?.field_type}
          placeholder={state?.placeholder ? state.placeholder : ''}
        />
      </FloatingLabel>
    </>
  );
}
export default memo(InputTextArea);
