import { memo, useState, useEffect } from 'react';
import { Form, FloatingLabel, Button, Spinner } from 'react-myoneui';
import { useSelector, dispatch, useDispatch } from 'react-redux';
import { setElements, setMode, setActiveEle } from '../../../redux/FormBuilderSlice';
import { editFormSubSection } from 'api/formBuilder';
import Log from 'lib/Log';

function EditSubSection() {
  const [state, setState] = useState({});
  const dispatch = useDispatch();
  const elements = useSelector((state) => state.formbuilder.elements);
  const activeEle = useSelector((state) => state.formbuilder.activeEle);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setState(activeEle);
  }, [activeEle]);
  const editHandler = (e) => {
    let cloneState = JSON.parse(JSON.stringify(state));
    cloneState[e.target.name] = e.target.value;
    setState(cloneState);
  };
  const saveHandler = async () => {
    try {
      let cloneElements = JSON.parse(JSON.stringify(elements));
      let obj = cloneElements.find((item) => item.id === activeEle);
      let index = cloneElements.indexOf(obj);
      setLoading('loading');
      let result = await editFormSubSection({ ...state });
      setLoading(true);
      if (result) {
        cloneElements[index] = { ...state };
        dispatch(setActiveEle({ ...state }));
        dispatch(setMode('view'));
      }
    } catch (err) {
      Log.error(err);
      setLoading(true);
    }
  };
  return (
    <div className="form-builder-edit-option">
      <FloatingLabel
        controlId="labelId"
        label="Label *"
        className="mb-28"
      >
        <Form.Control
          type="text"
          placeholder="Label *"
          value={state.heading}
          onChange={editHandler}
          name="heading"
        />
      </FloatingLabel>
      <FloatingLabel
        controlId="labelId"
        label="Description *"
        className="mb-28"
      >
        <Form.Control
          as="textarea"
          placeholder="Description *"
          value={state.sub_heading}
          onChange={editHandler}
          name="sub_heading"
        />
      </FloatingLabel>

      <div className="button-wrap">
        <Button
          variant="secondary"
          onClick={saveHandler}
        >
          {loading === 'loading' && (
            <Spinner
              animation="border"
              size="sm"
            />
          )}
          Save
        </Button>
      </div>
    </div>
  );
}
export default memo(EditSubSection);
