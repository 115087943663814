import { memo } from 'react';
import { translate } from 'lib/';
import { useSelector } from 'react-redux';
const ComponentTitle = ({ section }) => {
  const taxonomy = useSelector((state) => state?.taxonomy?.data);
  return (
    <div className="program-detial-header-wrap">
      <div className="program-detial-info">
        <h4>{section?.title}</h4>
        {section?.weightage !== null && (
          <span>
            {translate(taxonomy)('weightage')} : <strong>{section?.weightage}</strong>
          </span>
        )}
      </div>
    </div>
  );
};

export default memo(ComponentTitle);
