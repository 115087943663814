import { memo } from 'react';
import { Form, Col } from 'react-myoneui';
import { useDispatch, useSelector } from 'react-redux';
import { updateValue } from 'redux/QuizDataSlice';
import Log from 'lib/Log';
function InputTextarea({ data, mode, question_number }) {
  const state = useSelector((state) => state?.quizData?.data?.find((item) => item?.questionId === data?.id));
  const dispatch = useDispatch();
  const questionId = data?.id;

  const valueControl = (e, index) => {
    try {
      const { value, checked } = e.target;
      const updates = {
        value: value,
        checked: checked,
        index: index,
      };
      dispatch(updateValue({ questionId, index, updates }));
    } catch (error) {
      Log.error(error);
    }
  };

  return (
    <>
      <Col
        className="py-2"
        // md={state?.col_width}
        name="formElement"
      >
        <Form.Label
          htmlFor={data?.title}
          className="form-builder-label"
        >
          {question_number}. {data?.title}
        </Form.Label>
        {data?.options?.length > 0
          ? data.options.map((item, index) => {
              return (
                <div
                  className="mb-1 ms-2"
                  key={index}
                >
                  <Form.Control
                    className={`h-25 text-area-height ${mode === 'preview' ? 'disabled' : ''}`}
                    type="text"
                    as="textarea"
                    tabIndex="4"
                    id={item?.index}
                    aria-describedby="passwordHelpBlock"
                    placeholder={''}
                    readOnly={mode === 'readonly'}
                    value={state?.selectedOptions?.find((option) => option.index === index)?.value || ''}
                    onChange={(e) => valueControl(e, index)}
                  />
                </div>
              );
            })
          : ''}
      </Col>
    </>
  );
}
export default memo(InputTextarea);
