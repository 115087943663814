import { Route } from 'react-router-dom';
import { lazyWithRetry } from 'components/lazyWithRetry';

const ProgrammeProgress = lazyWithRetry(() => import('modules/admin/users/programs/programProgress'));
const ProgramDetail = lazyWithRetry(() => import('modules/admin/users/programs/programDetail'));
const ComponentDetail = lazyWithRetry(() => import('modules/learner/program/ComponentDetail'));
const MemberAssessors = lazyWithRetry(() => import('modules/learner/program/assessors/'));
const Resubmission = lazyWithRetry(() => import('modules/learner/program/Resubmission'));
const Reports = lazyWithRetry(() => import('modules/learner/program/Reports'));
const ProfileActivity = lazyWithRetry(() => import('modules/admin/profile'));
const LectureList = lazyWithRetry(() => import('modules/learner/program/LectureList'));
const ActivityDetail = lazyWithRetry(() => import('modules/learner/program/ActivityDetail'));
const Target = lazyWithRetry(() => import('modules/learner/program/Target/'));
const TaskList = lazyWithRetry(() => import('modules/learner/program/TaskList'));
const QuickLinks = lazyWithRetry(() => import('modules/learner/program/QuickLinks/index'));
const CustomFields = lazyWithRetry(() => import('modules/learner/program/CustomFields/'));

export default (
  <>
    <Route
      path="users/:userTab/view-profile/:id"
      exact
      element={<ProfileActivity />}
    />
    <Route
      path="users/:userTab/view-profile/:id/program/:programId/"
      exact
      element={<LectureList />}
    />
    <Route
      path="users/:userTab/view-profile/:id/program/:programId/lecture-list"
      exact
      element={<LectureList />}
    />
    <Route
      path="users/:userTab/view-profile/:id/program/:programId/lecture-list/:sectionid"
      exact
      element={<ComponentDetail backBtnStatus={true} />}
    />
    <Route
      path="users/:userTab/view-profile/:id/program/:programId/lecture-list/:lectureDetail/activitityDetail"
      element={<ActivityDetail />}
    />
    <Route
      path="users/:userTab/view-profile/:id/program/:programId/lecture-list/:lectureDetail/activitityDetail/:activityId/:memberSectionId"
      element={<ActivityDetail backBtnStatus={true} />}
    />
    <Route
      path="users/:userTab/view-profile/:id/program/:programId/lecture-list/:lectureDetail/:otj_id/activitityDetail/:activityId/:memberSectionId"
      element={<ActivityDetail backBtnStatus={true} />}
    />
    {/* users/:userTab/view-profile/:id/program/:programId/quick-links/U2FsdGVkX18olnabVBPMfLHJixFcvzmH7Ah4peAf4K2QIdyWCql4KfdPeyt9P6oq/activitityDetail/U2FsdGVkX19d64iIZv4P8S9sK50iKQZ1dx1sTIEP6OiHZZw2MZLqTIb8%C3%B8x%C3%B8dWLkq/U2FsdGVkX19n0vuFLhLNNqO%C3%B892eSIBfle9TWKVFQn88U4MKhRlsWEEfFXPLAtzLW */}
    <Route
      path="users/:userTab/view-profile/:id/program/:programId/:lectureDetail/activitityDetail"
      element={<ActivityDetail />}
    />
    <Route
      path="users/:userTab/view-profile/:id/program/:programId/task-list/:lectureDetail/activitityDetail"
      exact
      element={<ActivityDetail />}
    />
    <Route
      path="users/:userTab/view-profile/:id/program/:programId/task-list/:otj_id/activitityDetail/:activityId/:memberSectionId"
      element={<ActivityDetail backBtnStatus={true} />}
    />

    <Route
      path="users/:userTab/view-profile/:id/program/:programId/quick-links/:lectureDetail/activitityDetail"
      exact
      element={<ActivityDetail />}
    />
    <Route
      path="users/:userTab/view-profile/:id/program/:programId/quick-links/:otj_id/activitityDetail/:activityId/:memberSectionId"
      element={<ActivityDetail backBtnStatus={true} />}
    />

    <Route
      path="users/:userTab/view-profile/:id/program/:member_program_id/target"
      exact
      element={<Target />}
    />
    <Route
      path="users/:userTab/view-profile/:id/program/:programId/task-list"
      exact
      element={<TaskList />}
    />
    <Route
      path="users/:userTab/view-profile/:id/program/:programId/quick-links"
      exact
      element={<QuickLinks />}
    />
    <Route
      path="users/:userTab/view-profile/:id/program/:programId/resubmission"
      exact
      element={<Resubmission />}
    />
    <Route
      path="users/:userTab/view-profile/:id/program/:programId/reports"
      exact
      element={<Reports />}
    />
    <Route
      path="users/:userTab/view-profile/:id/program/:programId/resubmission/:otj_id/activitityDetail/:activityId/:memberSectionId"
      element={<ActivityDetail backBtnStatus={true} />}
    />
    <Route
      path="users/:userTab/view-profile/:id/program/:programId/assessors"
      exact
      element={<MemberAssessors />}
    />
    <Route
      path="users/:userTab/view-profile/:id/program/:programId/custom-fields"
      exact
      element={<CustomFields />}
    />
    {/* http://rmstesting.localhost:3000/admin/users/programs/progress/6540bcf543cfb5ae75c98ca0 */}
  </>
);
