import React from 'react';
import { formatDate } from '../../../../../../utils/formatTime';
const LastAccessTemplate = ({ value, data, tableManager }) => {
  const {
    config: {
      additionalProps: { cell: additionalProps = {} },
    },
  } = tableManager;

  const classNames = ('rgt-cell-inner rgt-text-truncate ' + (additionalProps.className || '')).trim();

  return (
    <>
      <span className="fw-bold ps-20">{value ? formatDate(value) : '---'}</span>
    </>
  );
};

export default LastAccessTemplate;
