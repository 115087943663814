import React from 'react';
import { getUserIconFromRoleIdentifier } from '../../../../../../lib/common';
import CircleImg from '../../../../../../components/CircleImg';
const TutorNameTemplate = ({ value, data, tableManager }) => {
  const {
    config: {
      additionalProps: { cell: additionalProps = {} },
    },
  } = tableManager;

  const classNames = ('rgt-cell-inner rgt-text-truncate ' + (additionalProps.className || '')).trim();
  return (
    <div
      {...additionalProps}
      className={classNames}
      title={value}
    >
      <div className="d-flex gap-3 align-items-center">
        {data.roles.length > 0 && (
          <CircleImg
            src={data.profile_image}
            defaultIcon={getUserIconFromRoleIdentifier('assessor')}
            title={data?.learner_name}
          />
        )}

        {/* <CircleImage className="circle-wrap circle-wrap-md">{data?.image ? <img src={data?.image} alt={data?.learner_name} /> : <CircleImageContent svgImage="two" />}</CircleImage> */}
        <span className="fw-bold">{value}</span>
      </div>
    </div>
  );
};

export default TutorNameTemplate;
