import axios from 'axios';
import { API_URL } from '../../constants/config';
import ls from 'localstorage-slim';

// enable global encryption
ls.config.encrypt = true;
export const assignTutorApprentices = async ({ orgId, ...payload }) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    },
  };
  try {
    return await axios.post(`${API_URL}/user/assigned-apprentice/create`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const getTutorApprentices = async ({ orgId, ...payload }) => {
  const { member, role, ...params } = payload;
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };
  try {
    return await axios.post(`${API_URL}/user/assigned-apprentice/get`, { member, role }, { headers, params });
  } catch (err) {
    throw new Error(err);
  }
};

export const getTutorLearnerPrograms = async (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    },
  };
  try {
    return await axios.post(`${API_URL}/user/assigned-apprentice/get/programs`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const unAssignTutorLearners = async (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    },
  };
  try {
    return await axios.post(`${API_URL}/user/assigned-apprentice/unassign/apprentices`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const getAssignedApprenticeTutors = async (payload) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    },
  };
  try {
    return await axios.post(`${API_URL}/user/assigned-apprentice/get/assigned-tutor`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};
