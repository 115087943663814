import axios from 'axios';
import { API_URL } from '../../constants/config';
import ls from 'localstorage-slim';

// enable global encryption
ls.config.encrypt = true;
export const createProgramResourceComponent = async (data) => {
  const { orgId, ...payload } = data;
  const headers = {
    headers: {
      Authorization: `Bearer ${ls.get('token') || payload?.token}`,
      organisationid: orgId,
    },
  };
  try {
    return await axios.post(`${API_URL}/admin/program/resource/component/create`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};

export const updateProgramResourceComponent = async (data) => {
  try {
    const { orgId, ...payload } = data;
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token') || payload?.token}`,
        organisationid: orgId,
      },
    };

    return await axios.patch(`${API_URL}/admin/program/resource/component/update`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};
