import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NoMatch from '../../modules/NoMatch';
import { lazyWithRetry } from '../../components/lazyWithRetry';
const MainLayout = lazyWithRetry(() => import('../../layouts/MainLayout'));
const RequireAuth = lazyWithRetry(() => import('../../components/RequireAuth'));
const Taxonomy = lazyWithRetry(() => import('../../modules/superAdmin/taxonomy'));
const LearnersProgram = lazyWithRetry(() => import('../../modules/admin/learner'));
const LearnersTutors = lazyWithRetry(() => import('../../modules/admin/learner/LearnersTutors'));
const OrganizationSetting = lazyWithRetry(() => import('../../modules/superAdmin/organizationSetting/index'));
const SuperRolesPrivilege = lazyWithRetry(() => import('../../modules/superAdmin/rolesPrivilege'));
const Reports = lazyWithRetry(() => import('../../modules/superAdmin/reports'));
// const Dashboard = lazyWithRetry(() => import('../../modules/admin/dashboard'));
const Security = lazyWithRetry(() => import('../../modules/superAdmin/security'));
const Organization = lazyWithRetry(() => import('../../modules/superAdmin/organization'));
const Learner = lazyWithRetry(() => import('../../modules/superAdmin/learner'));
const Program = lazyWithRetry(() => import('../../modules/admin/program'));
const TaskList = lazyWithRetry(() => import('../../modules/learner/program/TaskList'));
const QuickLinks = lazyWithRetry(() => import('../../modules/learner/program/QuickLinks/'));
const Email = lazyWithRetry(() => import('../../modules/superAdmin/email'));
// const Program = lazyWithRetry(() => import('../../modules/superAdmin/program'));

const ProgramImport = lazyWithRetry(() => import('../../modules/superAdmin/programImport'));

const basename = process.env.REACT_APP_BASENAME || '';

function SuperAdminRoute() {
  return (
    <>
      <Routes>
        <Route
          path="/super/"
          element={<RequireAuth />}
        >
          <Route
            path="/super/*"
            element={<MainLayout />}
          >
            {/* <Route
              path="dashboard/"
              element={<Dashboard />}
            /> */}
            <Route
              path="reports/*"
              element={<Reports />}
            />
            <Route
              path="security/*"
              element={<Security />}
            />
            <Route
              path="email/*"
              element={<Email />}
            />
            <Route
              path="programimport-logs/*"
              element={<ProgramImport />}
            />
            <Route
              path="organization/*"
              element={<Organization />}
            />
            <Route
              path="organization/learner/*"
              element={<Learner />}
            />
            <Route
              path="organization/:orgId/organization-setting/"
              element={<OrganizationSetting />}
            />
            <Route
              path="organization/:orgId/roles-and-privilege/*"
              element={<SuperRolesPrivilege />}
            />
            <Route
              path="organization/:orgId/taxonomy/*"
              element={<Taxonomy />}
            />
            <Route
              path="organization/:orgId/program/*"
              element={<Program />}
            />
            <Route
              path="learners/program"
              element={<LearnersProgram />}
            />
            <Route
              path="learners/task-list"
              element={<TaskList />}
            />
            <Route
              path="learners/tutors"
              element={<LearnersTutors />}
            />
            <Route
              path="learners/quick-links"
              element={<QuickLinks />}
            />
            <Route
              path="*"
              element={<NoMatch />}
            />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default SuperAdminRoute;
