import { useState, useEffect, memo } from 'react';
import { Spinner } from 'react-myoneui';
import { useDispatch, useSelector } from 'react-redux';
import { getQuizFields } from 'api/component/quiz';
import { flushQuizData, storeQuizData } from 'redux/QuizDataSlice';
import Log from 'lib/Log';
import InputRadio from './InputRadio';
import InputCheckbox from './InputCheckbox';
import InputSingleLine from './InputSingleLine';
import InputMultiple from './InputMultiple';
import InputTextarea from './InputTextarea';

const EditQuiz = ({ mode = 'preview', status = null, id = null, payloadData, selectedComponent }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // fetch quizData
  const fetchQuizData = async () => {
    try {
      setLoading('loading');
      let payload = { memberProgram: payloadData?.member_program, quizComponent: payloadData?.form };
      let result = await getQuizFields(payload);

      if (result?.data?.data[0]?.answers?.length > 0) {
        const formattedData = result?.data?.data[0]?.answers.map((list) => ({ questionId: list?.questionId?.id, selectedOptions: list?.selectedOptions }));
        // console.log('formatted', formattedData);
        dispatch(storeQuizData(formattedData));
      }
    } catch (error) {
      Log.error(error);
    } finally {
      setLoading('loaded');
    }
  };

  useEffect(() => {
    dispatch(flushQuizData());
    fetchQuizData();
  }, [id]);

  return loading === 'loading' ? (
    <div className="spinner-wrapper">
      <Spinner animation="border" />
    </div>
  ) : selectedComponent?.questions && selectedComponent?.questions?.length > 0 ? (
    <>
      {selectedComponent.questions.map((item, index) => {
        switch (item.type) {
          case 'Radio':
            return (
              <InputRadio
                className="py-2"
                data={item}
                question_number={index + 1}
                mode={mode}
              />
            );
          case 'Checkbox':
            return (
              <InputCheckbox
                className="py-2"
                data={item}
                question_number={index + 1}
                mode={mode}
              />
            );
          case 'Single Input':
            return (
              <InputSingleLine
                className="py-2"
                data={item}
                question_number={index + 1}
                mode={mode}
              />
            );
          case 'Multiple Input':
            return (
              <InputMultiple
                className="py-2"
                data={item}
                question_number={index + 1}
                mode={mode}
              />
            );
          case 'Text Area':
            return (
              <InputTextarea
                className="py-2"
                data={item}
                question_number={index + 1}
                mode={mode}
              />
            );
        }
      })}
    </>
  ) : (
    ''
  );
};

export default memo(EditQuiz);
