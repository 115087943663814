import { Route } from 'react-router-dom';
import { lazyWithRetry } from 'components/lazyWithRetry';

const MCQBank = lazyWithRetry(() => import('modules/admin/quizBank/quizQuestion'));

export default (
  <>
    <Route
      path="quiz-bank"
      element={<MCQBank />}
    />
  </>
);
