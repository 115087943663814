import { memo } from 'react';
import { smallEditIcon } from 'constants/icons';
import ls from 'localstorage-slim';
import { useParams, useLocation } from 'react-router-dom';
ls.config.encrypt = true;
const OTJContent = ({ requiredHours, yourHours, onShowEditBox, status }) => {
  const { pathname } = useLocation();
  const role = ls.get('role');
  const pathRole = pathname.split('/')[1];

  const activeRole = JSON.parse(ls.get('activeRole'));
  const { can_manage_apprentices } = activeRole?.privileges;
  const isLearner = role === 'learner' && pathRole === 'learner' && activeRole.identifier === 'apprentice';
  return (
    <div className="required-hours-wrap">
      <span>
        Required hours - <strong>{requiredHours} Hours</strong>
        {!isLearner && can_manage_apprentices && status !== 'Completed' && (
          <span
            className="edit-hour-wrap"
            onClick={() => onShowEditBox()}
            title="Edit Required Hours"
          >
            {smallEditIcon}
          </span>
        )}
      </span>
      <span>
        Your hours - <strong>{yourHours} Hours</strong>
      </span>
      {requiredHours - yourHours > 0 && <span>&#40; You'r behind {requiredHours} hours &#41;</span>}
    </div>
  );
};

export default memo(OTJContent);
