import { OverlayTrigger } from 'react-myoneui';
import Popover from 'react-myoneui/lib/Popover';
import { explainNoteIcon, usefulNoteIcon } from '../../constants/icons';
function UsefulNoteTooltips(props) {
  const { popoverTitle, popoverInfo } = props;
  return (
    <>
      {['auto'].map((placement) => (
        <OverlayTrigger
          // trigger="click"
          key={placement}
          placement={placement}
          overlay={
            <Popover
              id={`popover-positioned-${placement}`}
              className="useful-note-tooltip"
            >
              <Popover.Header>
                <div className="custom-tooltip-icon">{explainNoteIcon}</div>
                <span className="useful-note-title">{popoverTitle}</span>
              </Popover.Header>
              <Popover.Body>
                <p>{popoverInfo}</p>
              </Popover.Body>
            </Popover>
          }
        >
          {usefulNoteIcon}
        </OverlayTrigger>
      ))}
    </>
  );
}
export default UsefulNoteTooltips;
