import * as React from 'react';
import { useState, useEffect } from 'react';
import Offcanvas from 'react-myoneui/lib/Offcanvas';
import Card from 'react-myoneui/lib/Card';
import Button from 'react-myoneui/lib/Button';
import Form from 'react-myoneui/lib/Form';
import FloatingLabel from 'react-myoneui/lib/FloatingLabel';
import { Row, Col } from 'react-myoneui';
import 'react-quill/dist/quill.snow.css';
import TextEditor from '../../../../components/TextEditor';
import DateTimePicker from 'react-datetime-picker';
import { useSelector } from 'react-redux';
import { addTarget } from '../../../../api/target';
import { ConfirmBox } from '../../../../components/ConfirmBox';
import { setConfirmBoxStatus } from '../../../../redux/confirmBox';
import { useDispatch } from 'react-redux';
import Spinner from 'react-myoneui/lib/Spinner';
import { translate } from '../../../../lib';
import { useParams } from 'react-router-dom';
import { formatToUTCDate } from '../../../../utils/formatTime';
import { setToastBoxStatus, setToastBoxDescription } from '../../../../redux/ToastBoxSlice';
import Log from '../../../../lib/Log';
import ls from 'localstorage-slim';
function AddProgramTargetOffCanvas(props) {
  const taxonomy = useSelector((state) => state.taxonomy.data);
  const { show, onClose, displayMsg, programDetails, updatedTargets } = props;
  const { programId: member_program_id } = useParams();
  const [load, setLoad] = useState(false);
  const [activeClass, setActiveClass] = useState('');
  const [validationError, setValidationError] = useState('');
  const [targetExist, setTargetExist] = useState(false);
  const [targetExistIndex, setTargetExistIndex] = useState(null);
  const confirmBoxStatus = useSelector((state) => state?.confirmBox?.status);
  const dispatch = useDispatch();
  const [programData, setProgramData] = useState({});
  const roles = JSON.parse(ls.get('roles'));
  const learnerName = roles.filter((role) => role.identifier === 'apprentice')[0].name.toLowerCase();

  useEffect(() => {
    setProgramData(JSON.parse(JSON.stringify(programDetails)));
  }, [programDetails]);

  const offCanvasKeyDown = (e) => e.stopPropagation();

  const [target, setTarget] = useState({
    date: '',
    progress: '',
    description: '',
  });

  const [error, setError] = useState({
    target: {
      error: false,
      msg: '',
    },
  });

  const [clicked, setClicked] = useState({
    progress: false,
    description: false,
    date: false,
  });

  const invalidNumbers = ['e', 'E', '.', '+', '-'];

  // text area has default value <p></p>\n which length seems to be 8 thus comparing with 8
  const { date, progress, description } = target;
  const isFormValid = date !== '' && progress !== '' && !error.target.error && description.length > 8;

  const handleClose = () => {
    setTargetExist(false);
    setTargetExistIndex(null);

    // clear state
    setTarget({
      date: '',
      progress: '',
      description: '',
    });
    setValidationError('');
    setClicked({ progress: false, description: false, date: false });
    setLoad(false);
    onClose();
  };

  const updateTarget = async () => {
    dispatch(setConfirmBoxStatus(false));
    const finalTarget = { member_program_id, date: new Date(formatToUTCDate(target.date)), progress: target.progress, description: target.description };
    try {
      const res = await addTarget(finalTarget);
      if (res.data.error) {
        setValidationError(res.data.message);
      } else {
        const existingTargets = programDetails.targets;
        if (targetExist) {
          programData.targets[targetExistIndex] = finalTarget;
        } else {
          programData.targets = [...existingTargets, finalTarget];
        }
        updatedTargets(programData);
        setTargetExist(false);
        setTargetExistIndex(null);
        // displayMsg(res.data.message);
        dispatch(setToastBoxStatus(true));
        dispatch(setToastBoxDescription(error?.data?.message ? error?.data?.message : `Successfully added ${translate(taxonomy)('target')}`));
        onClose();
        // clear state
        setTarget({
          date: '',
          progress: '',
          description: '',
        });
        setValidationError('');
        setClicked({ progress: false, description: false, date: false });
      }
    } catch (err) {
      Log.info('Error >> ', err);
      setValidationError('Something happens wrong');
    } finally {
      setLoad(false);
    }
  };

  const addNewTarget = async () => {
    setValidationError('');
    setLoad(true);
    if (targetExist) {
      // popup box
      dispatch(setConfirmBoxStatus(true));
    } else {
      updateTarget();
    }
  };

  const checkTargetDate = (value) => {
    setTargetExist(false);
    setTarget({ ...target, date: value });
    const dates = programDetails.targets.map((target) => new Date(target.date).getTime());
    const currentDate = new Date(formatToUTCDate(value)).getTime();
    if (dates.includes(currentDate)) {
      setTargetExist(true);
      setTargetExistIndex(dates.indexOf(currentDate));
    }
  };

  const checkProgress = (value) => {
    setTarget({ ...target, progress: value });
    if (value !== '' && value < 1) {
      setError({ ...error, target: { error: true, msg: "Progress can't be less than 1%" } });
    } else if (value > 100) {
      setError({ ...error, target: { error: true, msg: "Progress can't be greater than 100%" } });
    } else {
      setError({ ...error, target: { error: false, msg: '' } });
    }
  };

  const checkDescription = (value) => {
    setTarget({ ...target, description: value });
  };

  const handleChange = (component, value) => {
    switch (component) {
      case 'date':
        checkTargetDate(value);
        break;
      case 'progress':
        checkProgress(value);
        break;
      case 'description':
        checkDescription(value);
        break;
      default:
    }
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        onKeyDown={offCanvasKeyDown}
      >
        {confirmBoxStatus && (
          <ConfirmBox
            show={confirmBoxStatus}
            question={`Are you sure you want to update this ${translate(taxonomy)('target')}`}
            okText="Yes"
            cancelText="No"
            onHide={() => {
              setLoad(false);
              dispatch(setConfirmBoxStatus(false));
            }}
            onOk={updateTarget}
          />
        )}
        <Offcanvas.Header
          closeButton
          backButton
          className="pb-0"
        >
          <Offcanvas.Title>
            Add <br />
            {`${translate(taxonomy)('target')}`}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <p className="help-text  fs-12 mt-0">
            {`${translate(taxonomy)('target')}s are Milestones for the ${learnerName} in ${
              programDetails?.program?.name
            } . Please considere these as progresses ${learnerName} should achieve by certain dates.`}{' '}
          </p>

          <Row>
            <Col
              lg={6}
              className="floating-icon-wrap"
            >
              <FloatingLabel
                className="mb-4 target-end-date-label"
                controlId="targetSartEndDate"
                label={`${translate(taxonomy)('target')} End date`}
              >
                <Form.Control
                  type="text"
                  placeholder="Target start &amp; End date"
                />
                <DateTimePicker
                  className={`remove-time-wrap ${activeClass}`}
                  clearIcon={null}
                  placeholder="Target start &amp; End date"
                  value={target.date}
                  minDate={new Date()}
                  onChange={(value) => handleChange('date', value)}
                  onBlur={() => {
                    setActiveClass('');
                    setClicked({ ...clicked, date: true });
                  }}
                  onFocus={() => {
                    setActiveClass('target-date-focus');
                    setClicked({ ...clicked, date: false });
                  }}
                />
              </FloatingLabel>
              {clicked.date && target.date === '' && (
                <div>
                  <span
                    className="invalid-feedback mb-4"
                    style={{ display: 'block' }}
                  >
                    "Target start &amp; End date"Target Date is required
                  </span>
                </div>
              )}
              {targetExist && (
                <div>
                  <span
                    className="valid-feedback mb-4"
                    style={{ display: 'block' }}
                  >
                    {`Current ${translate(taxonomy)('target')} date has been already exist`}
                  </span>
                </div>
              )}
            </Col>
            <Col lg={6}>
              <FloatingLabel
                className="mb-4"
                controlId="targetPercentage"
                label={`${translate(taxonomy)('target')} Percentage %`}
              >
                <Form.Control
                  type="number"
                  max="100"
                  min="1"
                  placeholder="Target Percentage %"
                  value={target.progress}
                  onKeyDown={(e) => invalidNumbers.includes(e.key) && e.preventDefault()}
                  onChange={(e) => handleChange('progress', e.target.value)}
                  onBlur={() => {
                    setClicked({ ...clicked, progress: true });
                  }}
                  onFocus={() => {
                    setClicked({ ...clicked, progress: false });
                  }}
                />
              </FloatingLabel>
              {error.target.error && (
                <div>
                  <span
                    className="invalid-feedback mb-4"
                    style={{ display: 'block' }}
                  >
                    {error.target.msg}
                  </span>
                </div>
              )}
              {clicked.progress && target.progress.trim() === '' && (
                <div>
                  <span
                    className="invalid-feedback mb-4"
                    style={{ display: 'block' }}
                  >
                    {`${translate(taxonomy)('target')} is required`}
                  </span>
                </div>
              )}
            </Col>
          </Row>

          <Card className="react-quill-editor">
            <TextEditor
              placeholder={`${translate(taxonomy)('target')} Description`}
              className="myeditor"
              theme="snow"
              value={target.description}
              onChange={(value) => handleChange('description', value)}
              onFocus={() => setClicked({ ...clicked, description: false })}
              onBlur={() => {
                setClicked({ ...clicked, description: true });
              }}
            />
          </Card>
          {clicked.description && target.description.length === 8 && (
            <div>
              <span
                className="invalid-feedback mb-4"
                style={{ display: 'block' }}
              >
                <h3>Description is required</h3>
              </span>
            </div>
          )}
          {validationError.trim().length > 1 && (
            <div>
              <span
                className="invalid-feedback mb-4"
                style={{ display: 'block' }}
              >
                <h3>{validationError}</h3>
              </span>
            </div>
          )}
        </Offcanvas.Body>
        <Offcanvas.Footer>
          <Button
            variant="default"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            disabled={load || !isFormValid}
            variant="primary"
            onClick={addNewTarget}
          >
            {load ? (
              <>
                <div className="spinner-align-center">
                  <Spinner
                    animation="border"
                    size="sm"
                  />
                  {`Add ${translate(taxonomy)('target')}`}
                </div>
              </>
            ) : (
              `Add ${translate(taxonomy)('target')}`
            )}
          </Button>
        </Offcanvas.Footer>
      </Offcanvas>
    </>
  );
}

export default AddProgramTargetOffCanvas;
