import React from 'react';

const AssignedProgramNameTemplate = ({ value, data, tableManager }) => {
  const {
    config: {
      additionalProps: { cell: additionalProps = {} },
    },
  } = tableManager;

  const classNames = ('rgt-cell-inner rgt-text-truncate ' + (additionalProps.className || '')).trim();

  return (
    <div
      {...additionalProps}
      className={classNames}
      title={value}
    >
      <div className="d-flex gap-2 align-items-center overflow-hidden">
        <span className="grid-text-ellipsis fw-bold">{value}</span>
      </div>
    </div>
  );
};

export default AssignedProgramNameTemplate;
