import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useState, forwardRef, Children } from 'react';
import Offcanvas from 'react-myoneui/lib/Offcanvas';
import Button from 'react-myoneui/lib/Button';
import Card from 'react-myoneui/lib/Card';
import Form from 'react-myoneui/lib/Form';
import FloatingLabel from 'react-myoneui/lib/FloatingLabel';
import { translate } from '../../lib';
import { useDispatch, useSelector } from 'react-redux';
import { createSection, createSectionBank, getCategories, createUnitBankSection } from 'api';
import { useParams } from 'react-router-dom';
import { setToastBoxStatus, setToastBoxDescription } from '../../redux/ToastBoxSlice';
import DeadLinesType from './DeadLinesType';
import Spinner from 'react-myoneui/lib/Spinner';
import FetchFile from '../../hooks/fetchFile';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import TextEditor from '../TextEditor';
import { Row, Col } from 'react-myoneui';
import CourseInformationBlock from './CourseInformationBlock';
import ChooseExistingUnit from './unitBank/ChooseExistingUnit';
import DropdownCollection from 'modules/admin/program/DropdownCollection';
import TagContent from 'components/tag';
import { removeIcon } from 'constants/icons';
import ls from 'localstorage-slim';
import Log from '../../lib/Log';

// enable global encryption
ls.config.encrypt = true;
function AddSection(props) {
  const { onClose, onCreate, parentSectionId, programInfo, sectionType = 'section', sectionLevel, isFromSectionBank, isFromComponent = false } = props;
  const handleClose = () => onClose();
  const offCanvasKeyDown = (e) => e.stopPropagation();
  const [basicInfo, setBasicInfo] = useState('createNewUnit');

  const [categegoryList, setCategegoryList] = useState([]);
  const [publish, setPublish] = useState(false);
  const [deadlines, setDeadlines] = useState({
    duration: null,
    end_date: null,
    lock_after_duration: null,
    previous_section: null,
    set_deadline_after: null,
    start_date: null,
    validate: true,
  });
  const { programId } = useParams();
  const orgId = ls.get('orgId');
  const [title, setTitle] = useState('');
  const [subTitle, setSubTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [isValidated, setIsValidate] = useState(false);
  const dispatch = useDispatch();
  const taxonomy = useSelector((state) => state?.taxonomy?.data);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [category, setCategory] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [validForm, setValidForm] = useState(false);
  const [programMandatoryStatus, setProgramMandatoryStatus] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState();
  const [statusList, setStatusList] = useState([]);
  const lang = FetchFile(`/lang/components/program/add-section/${ls.get('lang')}.json`);

  useEffect(() => {
    async function fetchSectionCategories() {
      const { data } = await getCategories(parentSectionId ? 'sub_section' : 'section');
      setCategegoryList(data.data);
    }
    fetchSectionCategories();
  }, []);

  useEffect(() => {
    if (taxonomy) {
      const list = [
        { id: 'mandatory', name: translate(taxonomy)('mandatory') },
        { id: 'optional', name: translate(taxonomy)('optional') },
        { id: 'additional', name: translate(taxonomy)('additional') },
      ];
      setStatusList(list);
      setSelectedStatus(list[0]);
    }
  }, [taxonomy]);

  useEffect(() => {
    if (isFromComponent) {
      setBasicInfo('createNewUnit');
    }
  }, [isFromComponent]);

  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = forwardRef(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <ul className="list-unstyled mb-0">{Children.toArray(children).filter((child) => !value || child.props.children.toLowerCase().startsWith(value))}</ul>
      </div>
    );
  });
  const titleHandler = (e) => {
    setTitle(e.target.value);
  };
  const save = () => {
    try {
      if (basicInfo === 'existingUnit') {
        saveSectionBank();
      } else if (basicInfo === 'createNewUnit') {
        saveSection();
      }
    } catch (err) {
      Log.error(err);
    }
  };
  const saveSectionBank = async () => {
    try {
      let payload = {
        section_id: selectedUnits.map((item) => item.id),
        program: programId,
        // is_mandatory: selectedStatus?.name === 'Mandatory',
        status: selectedStatus.id,
      };
      setLoading(true);

      let result = await createSectionBank(payload);
      if (result) {
        setLoading(false);
        dispatch(setToastBoxStatus(true));
        dispatch(setToastBoxDescription(result?.data?.message));
        if (onCreate) {
          onCreate(result);
        } else {
          // onClose();
        }
      }
    } catch (err) {
      setLoading(false);
      dispatch(setToastBoxStatus(true));
      dispatch(setToastBoxDescription(err.data.message));
      onClose();
      Log.error(err);
    }
  };
  const saveSection = async () => {
    try {
      let payload = {
        orgId,
        title,
        sub_title: subTitle,
        description,
        parentSectionId,
        duration: Number(deadlines.duration),
        end_date: deadlines['end_date'],
        lock_after_duration: deadlines['lock_after_duration'],
        previous_section: deadlines['previous_section']?.id,
        set_deadline_after: deadlines['set_deadline_after'],
        start_date: deadlines['start_date'],
        deadline_type: deadlines['deadline_type'],
        status: selectedStatus.id,
      };

      let createdCategories = [];
      for (const cat of category) {
        if (cat?.new) {
          createdCategories.push({ name: cat.name, of: 'section' });
        } else {
          createdCategories.push({ id: cat?.id });
        }
      }
      payload = { ...payload, categories: createdCategories };
      Log.info('******* Saving ************');
      Log.info(payload);
      setLoading(true);
      let result = programId ? await createSection({ ...payload, program: programId, publish_to_section_bank: publish }) : await createUnitBankSection(payload);
      if (result) {
        setLoading(false);
        dispatch(setToastBoxStatus(true));
        dispatch(setToastBoxDescription(result?.data?.message));
        if (onCreate) {
          onCreate(result);
        } else {
          onClose();
        }
      }
    } catch (error) {
      Log.error(error);
      setLoading(false);
      dispatch(setToastBoxStatus(true));
      dispatch(setToastBoxDescription(error?.data?.message));
      onClose();
      // onResponse(error?.data);
      Log.error('Error on  saveSection :', error);
    }
  };

  const deadlineHandler = (data) => {
    // setDeadlines
    setDeadlines(data);
  };

  const basicInfoHandler = (e) => {
    try {
      setBasicInfo(e.target.value);
    } catch (err) {
      Log.error(err);
    }
  };

  const selectUnitHandler = (data) => {
    try {
      let allCategories = [];
      data.map((item) => {
        item.categories.forEach((cat) => {
          if (!allCategories.map((category) => category.id).includes(cat.id)) {
            allCategories.push(cat);
          }
        });
      });
      /* Catgory List */
      setCategory(allCategories);
      setSelectedUnits(data);
      setShow(false);
      setValidForm(true);
    } catch (err) {
      Log.error(err);
    }
  };

  const removeSelectedUnit = (id) => {
    try {
      const updatedSelectedUnits = selectedUnits.filter((selectedUnit) => selectedUnit.id !== id);
      setSelectedUnits(updatedSelectedUnits);
      if (updatedSelectedUnits.length < 1) {
        setValidForm(false);
      }

      /* Remove Category from selected course */
      let uniqueCategories = [];
      const categories = updatedSelectedUnits.map((unit) => unit.categories);
      const selectedCategories = categories.flat();
      selectedCategories.forEach((category) => {
        if (!uniqueCategories.map((ucategory) => ucategory?.id).includes(category.id)) {
          uniqueCategories.push(category);
        }
      });
      setCategory(uniqueCategories);
    } catch (err) {
      Log.error(err);
    }
  };
  const fetchAllCategories = async () => {
    let of = sectionType === 'section' ? 'section' : 'sub_section';
    const { data } = await getCategories(of);
    setCategoryList(data.data);
  };
  const keywordHandler = (data) => {
    setCategory(data);
  };

  useEffect(() => {
    if (!programId) {
      setBasicInfo('createNewUnit');
    }
    fetchAllCategories();
  }, []);

  useEffect(() => {
    if (basicInfo && basicInfo === 'createNewUnit') {
      if (title.length === 0 || deadlines.validate === false) {
        setIsValidate(false);
      } else {
        setIsValidate(true);
      }
    } else if (basicInfo && selectedUnits && selectedUnits.length > 0) {
      setIsValidate(true);
    } else {
      setIsValidate(false);
    }
  }, [title, deadlines, description, basicInfo, selectedUnits]);

  useEffect(() => {
    if (basicInfo && basicInfo === 'createNewUnit') {
      setCategory([]);
    }
  }, [basicInfo]);

  useEffect(() => {
    setProgramMandatoryStatus(programInfo?.enable_mandatory_option);
  }, [programInfo]);

  return (
    <>
      {
        <>
          <ChooseExistingUnit
            sectionType={sectionType}
            onSelected={(data) => selectUnitHandler(data)}
            show={show}
            onClose={() => setShow(false)}
            selectedUnits={selectedUnits}
            categoryList={categoryList}
          />
          <Offcanvas
            className="unit-bank-offcanvas"
            show={props.show}
            onHide={handleClose}
            placement="end"
            onKeyDown={offCanvasKeyDown}
          >
            <>
              {
                <Offcanvas.Header
                  closeButton
                  backButton
                >
                  <Offcanvas.Title>Add {sectionType ? translate(taxonomy)(sectionType) : translate(taxonomy)(sectionLevel)}</Offcanvas.Title>
                </Offcanvas.Header>
              }

              <Offcanvas.Body>
                <Card
                  className="mb-4 border-0"
                  id="basic"
                >
                  {!isFromComponent && programId && <Card.Header className="p-0 mb-2">Basic Information</Card.Header>}
                  <Card.Body className="px-0">
                    {!isFromComponent && (
                      <>
                        {programId && (
                          <Row>
                            <Col lg="8">
                              <Form.Check className="mb-4">
                                <Form.Check.Input
                                  type="radio"
                                  name="basicInfo"
                                  onChange={basicInfoHandler}
                                  checked={basicInfo === 'existingUnit'}
                                  value="existingUnit"
                                  id="flexRadioExisting"
                                />
                                <Form.Check.Label htmlFor="flexRadioExisting">
                                  {`Existing ${translate(taxonomy)(sectionType)}`}
                                  <p className="help-text">{`Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`} </p>
                                </Form.Check.Label>
                              </Form.Check>
                            </Col>
                          </Row>
                        )}
                        {basicInfo === 'existingUnit' && (
                          <div className="ms-2 mb-4 ms-4">
                            {selectedUnits.map((selectedUnit, i) => (
                              <CourseInformationBlock
                                key={i}
                                className="mb-3 information-border information-course-block"
                                id={selectedUnit.id}
                                cirleImage={selectedUnit.image}
                                name={selectedUnit.name}
                                description={selectedUnit.description}
                              >
                                <Button
                                  variant="information-white-bg"
                                  onClick={() => removeSelectedUnit(selectedUnit.id)}
                                  className="btn information-white-bg btn-sm btn-round"
                                >
                                  {removeIcon}
                                  Remove
                                </Button>
                              </CourseInformationBlock>
                            ))}

                            {selectedUnits.length > 0 ? (
                              <Button
                                variant="outline"
                                onClick={handleShow}
                                className="border border-primary text-primary"
                              >
                                Edit this {translate(taxonomy)(sectionType)}
                              </Button>
                            ) : (
                              <Button
                                variant="outline"
                                onClick={handleShow}
                                className="border border-primary text-primary"
                              >
                                Browse {translate(taxonomy)(sectionType)}
                              </Button>
                            )}
                          </div>
                        )}
                        {programId && (
                          <Row>
                            <Col lg="8">
                              <Form.Check>
                                <Form.Check.Input
                                  type="radio"
                                  name="basicInfo"
                                  onChange={basicInfoHandler}
                                  checked={basicInfo === 'createNewUnit'}
                                  value="createNewUnit"
                                  id="flexRadioCreate"
                                />
                                <Form.Check.Label htmlFor="flexRadioCreate">
                                  {`Create New ${translate(taxonomy)(sectionType)}`}
                                  <p className="help-text">{`Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore`}</p>
                                </Form.Check.Label>
                              </Form.Check>
                            </Col>
                          </Row>
                        )}
                      </>
                    )}
                    {basicInfo === 'createNewUnit' && (
                      <Form className={programId && !isFromComponent ? 'ms-2 mb-4 ms-4 mt-4' : ''}>
                        <FloatingLabel
                          label={translate(lang)('sample_title')}
                          className="mb-4 custom-input-textarea"
                        >
                          <Form.Control
                            as="textarea"
                            tabIndex="1"
                            type="text"
                            placeholder={translate(lang)('type-title')}
                            value={title}
                            onChange={titleHandler}
                          />
                        </FloatingLabel>
                        <FloatingLabel
                          label={translate(lang)('sample_sub_title')}
                          className="mb-4"
                        >
                          <Form.Control
                            tabIndex="1"
                            type="text"
                            placeholder={translate(lang)('type-title')}
                            value={subTitle}
                            onChange={(e) => setSubTitle(e.target.value)}
                          />
                        </FloatingLabel>
                        <Card className="react-quill-editor mb-4">
                          {lang.type_description && (
                            <TextEditor
                              value={description}
                              onChange={(data) => setDescription(data)}
                            />
                          )}
                        </Card>

                        {programId && (
                          <>
                            {programMandatoryStatus && (
                              <DropdownCollection
                                className="mb-4"
                                defaultValue={selectedStatus}
                                items={statusList}
                                label={`${translate(taxonomy)(sectionType)} Status`}
                                onChange={(obj) => setSelectedStatus(obj)}
                                onClear={() => setSelectedStatus(null)}
                              />
                            )}
                            <TagContent
                              className="custom-tag-content mb-4"
                              tagTitle="Category *"
                              list={categegoryList}
                              keywords={category}
                              onTags={keywordHandler}
                              placeholder="Add new category and press comma"
                              placeholderTitle="Add new category and press comma"
                            />
                          </>
                        )}

                        <DeadLinesType
                          phase={sectionType ? translate(taxonomy)(sectionType) : translate(taxonomy)(sectionLevel)}
                          onChange={(data) => deadlineHandler(data)}
                          isFromSectionBank={isFromSectionBank}
                        />

                        {programId && !parentSectionId && (
                          <Form.Check className="mt-4">
                            <Form.Check.Input
                              type="checkbox"
                              id="publish_to_section_bank"
                              value={publish}
                              onChange={(e) => setPublish(e.target.checked)}
                            />
                            <Form.Check.Label htmlFor="publish_to_section_bank">
                              Publish this to {translate(taxonomy)(sectionType)} Bank
                              <span className="help-text">
                                When checked, a {translate(taxonomy)(sectionType)} will get published into {translate(taxonomy)(sectionType)} bank.
                              </span>
                            </Form.Check.Label>
                          </Form.Check>
                        )}
                      </Form>
                    )}

                    {basicInfo === 'existingUnit' && programMandatoryStatus && (
                      <>
                        <span className="card-divider my-4"></span>

                        <DropdownCollection
                          autoComplete={false}
                          defaultValue={selectedStatus}
                          items={statusList}
                          label={`${translate(taxonomy)(sectionType)} Status`}
                          onChange={(obj) => setSelectedStatus(obj)}
                          onClear={() => setSelectedStatus([])}
                        />
                      </>
                    )}
                  </Card.Body>
                </Card>
              </Offcanvas.Body>
              <Offcanvas.Footer>
                <Button
                  variant="default"
                  onClick={handleClose}
                >
                  {translate(lang)('cancel')}
                </Button>
                <Button
                  variant="primary"
                  onClick={save}
                  disabled={!isValidated}
                >
                  {loading && (
                    <Spinner
                      animation="border"
                      size="sm"
                    />
                  )}

                  <span> Save</span>
                </Button>
              </Offcanvas.Footer>
            </>
          </Offcanvas>
        </>
      }
    </>
  );
}
AddSection.prototype = {
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
  parentSectionId: PropTypes.string,
};
export default AddSection;
