import { useState, useEffect, memo } from 'react';
import SectionElement from './SectionElement';
import { useParams } from 'react-router-dom';
import { getSections, getSubSections, getFormElements } from 'api/formBuilder';
import { Spinner } from 'react-myoneui';
import { useDispatch, useSelector } from 'react-redux';
import { flushFormData, storeFormData } from '../../../redux/FormDataSlice';
import { setSidebarData } from '../../../reducers/sidebarSlice';
import Log from 'lib/Log';

const PreviewContent = ({ mode = 'preview', status = null, id = null }) => {
  const { formId } = useParams();
  const [componentId, setComponent] = useState(null);
  const elements = useSelector((state) => state.formData?.data?.filter((item) => item?.parent_id === componentId));
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (formId) {
      setComponent(formId);
    } else if (id) {
      setComponent(id);
    }
  }, []);
  useEffect(() => {
    if (componentId) {
      fetchSections();
    }
  }, [componentId]);

  dispatch(setSidebarData({ enableActiveWrapper: true }));
  const fetchSections = async () => {
    try {
      setLoading('loading');

      getSections({ formId: componentId }).then((sectionResult) => {
        let sectionData = sectionResult.data.data;
        var formStore = [];
        sectionData.map((subSectionItem) => {
          //formStore.push(subSectionItem);
          formStore.push(subSectionItem);
          getSubSections({ sectionId: subSectionItem.id }).then((subSectionResult) => {
            let subsectionData = subSectionResult.data.data;

            subsectionData.map((subSection) => {
              formStore.push(subSection);
              //formStore = [...data, ...subSection];
              getFormElements({ subSectionId: subSection.id }).then((elementsResult) => {
                let elementsData = elementsResult.data.data;
                //formStore = [...data, ...elementsData];
                elementsData.map((element) => {
                  formStore.push(element);
                });
                dispatch(storeFormData([...formStore]));
                setLoading('loaded');
              });
            });
          });
        });
      });
    } catch (error) {
      Log.error(error);
      setLoading('loaded');
    }
  };
  useEffect(() => {
    //fetchRoles();

    dispatch(flushFormData());
  }, []);

  return loading === 'loading' ? (
    <div className="spinner-wrapper">
      <Spinner animation="border" />
    </div>
  ) : (
    elements &&
      componentId &&
      elements.length > 0 &&
      elements
        .filter((item) => item.parent_id === componentId)
        .map((ele) => (
          <SectionElement
            data={ele}
            key={ele.id}
            mode={mode}
            status={status}
          />
        ))
  );
};

export default memo(PreviewContent);
