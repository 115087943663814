// Images
import { uploadfileIcon } from '../../constants/icons';

// className="upload-file-block disabled-control"

const UploadEvidenceFile = (props) => {
  return (
    <div className={`upload-file-block ${props?.className || ''}`}>
      {uploadfileIcon}
      <span>
        {' '}
        Upload evidence here, <strong> browser file</strong>
      </span>
      <input type="file" />
    </div>
  );
};
export default UploadEvidenceFile;
