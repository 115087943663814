import { useState, useEffect } from 'react';
import axios from 'axios';
import Log from '../lib/Log';
const FetchFile = (url) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    loadFile();
  }, [url]);

  const loadFile = async () => {
    try {
      let result = await axios.get(url);
      if (result) {
        setData({ ...result?.data });
      }
    } catch (error) {
      Log.error(error);
      setData(null);
    }
  };

  return { ...data };
};

export default FetchFile;
