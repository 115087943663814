import React, { useState, memo, useEffect } from 'react';
import Offcanvas from 'react-myoneui/lib/Offcanvas';
import Button from 'react-myoneui/lib/Button';
import { blackArrowLeftIcon } from '../../../constants/icons';
import ls from 'localstorage-slim';
import { formatDate } from '../../../utils/formatTime';
import OberservationEvidence from 'components/learners/OberservationEvidence';
import ViewModel from '../components/ViewModal';
// enable global encryption
ls.config.encrypt = true;
function ViewReviewOffCanvas({ show, onClose, data }) {
  const offCanvasKeyDown = (e) => e.stopPropagation();
  const [load, setLoad] = useState(false);
  const handleClose = () => {
    setLoad(false);
    onClose();
  };
  const [viewShow, setViewShow] = useState(false);
  const [fileType, setFileType] = useState();
  const [fileLink, setFileLink] = useState();
  const [fileName, setFileName] = useState();

  const handleShow = (item) => {
    console.log(item);
    setFileType(item?.type || item.url.slice(-3));
    setFileLink(item?.url || item?.link);
    setFileName(item?.i_item_name || item?.name || item?.title);
    setViewShow(true);
  };
  async function downloadResoruceFile(myfile) {
    let url = myfile?.link ? myfile.link : myfile?.url;
    const aElement = document.createElement('a');
    aElement.setAttribute('download', myfile.name);
    aElement.href = url;
    aElement.setAttribute('target', '_blank');
    aElement.click();
    URL.revokeObjectURL(url);
  }

  useEffect(() => {
    console.log('a');
  }, []);
  return (
    <>
      <Offcanvas
        className="offcanvas-size size-75"
        show={show}
        onHide={handleClose}
        placement="end"
        onKeyDown={offCanvasKeyDown}
      >
        <Offcanvas.Body>
          {data && (
            <>
              <div className="program-list-detail-block">
                <div className="">
                  <Button
                    variant="text-icon"
                    className="p-0 back-btn"
                    onClick={() => onClose()}
                  >
                    {blackArrowLeftIcon} Back
                  </Button>
                  <div className="program-list-detail-body mt-5">
                    <h3>
                      <strong>{data?.subject}</strong>
                    </h3>
                    <p>{data?.description && <div dangerouslySetInnerHTML={{ __html: data?.description }} />}</p>
                  </div>
                  <div className="activities-detail-info d-flex justify-content-between">
                    <span>
                      <strong>Reviewed By</strong> : {data?.review_by?.first_name + ' ' + data?.review_by?.last_name}
                    </span>
                    {data.createdAt && (
                      <span>
                        <strong>Date:</strong> {formatDate(data?.createdAt)}
                      </span>
                    )}
                  </div>
                  <div className="mt-5">
                    {data?.uploaded_file &&
                      data.uploaded_file?.length > 0 &&
                      data.uploaded_file.map((file, index) => {
                        return (
                          <div key={index}>
                            <OberservationEvidence
                              onShowModel={() => handleShow(file)}
                              link={file?.url}
                              key={file?._id}
                              title={file?.title}
                              type={file?.url ? file.url.slice(-3) : ''}
                              // totalMb={file?.size}
                              removeBtn={false}
                              downloadBtn={() => {
                                downloadResoruceFile(file);
                              }}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <ViewModel
                onClose={() => setViewShow(false)}
                show={viewShow}
                fileType={fileType}
                fileLink={fileLink}
                fileName={fileName}
              />
            </>
          )}
        </Offcanvas.Body>
        <Offcanvas.Footer>
          <div className="program-detial-footer-wrap justify-content-end">
            <div className="program-button-wrap">
              <Button
                variant="text-icon"
                className="p-0 back-btn"
                onClick={() => onClose()}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Offcanvas.Footer>
      </Offcanvas>
      {/*  onEvidence={evidenceHandler} */}
    </>
  );
}

export default memo(ViewReviewOffCanvas);
