import axios from 'axios';
import { API_URL } from '../../constants/config';
import ls from 'localstorage-slim';
import Log from '../../lib/Log';
// enable global encryption
ls.config.encrypt = true;
export const getIQVs = async (payload) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };

  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/admin/list/iqv`, { params: payload, headers })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};
export const removeIQVs = async (payload) => {
  try {
    const { roleId, ...data } = payload;
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };

    return new Promise((resolve, reject) => {
      return axios
        .post(`${API_URL}/admin/remove/${roleId}/iqv`, data, { headers })
        .then((result) => resolve(result))
        .catch((error) => reject(error.response));
    });
  } catch (error) {
    Log.error(error);
  }
};

export const getIQVAssignAssessors = async (payload) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };

  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/admin/assignedIqvs`, { params: payload, headers })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};
export const getUserIQVAssessors = async (payload) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };

  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/iqv/list/assignedAssessor`, { params: payload, headers })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getNonIQVAssessors = async (payload) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };

  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/admin/notAssignedIqvs`, { params: payload, headers })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const assignIQVAssessors = async (payload) => {
  /*
payload: {
"iqv":"",
"assessors":[""]
}
*/
  try {
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };
    return await axios.post(`${API_URL}/admin/iqv/assign`, payload, { headers });
  } catch (err) {
    Log.error(err);
  }
};

export const unAssignIQVAssessors = async (payload) => {
  /*
api: /admin/iqv/unassign
method: POST
payload: {
"iqv":"",
"assessors":[""]
}
*/
  try {
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };
    return await axios.post(`${API_URL}/admin/iqv/unassign`, payload, { headers });
  } catch (err) {
    Log.error(err);
  }
};

export const getIQVPrograms = async (payload) => {
  /*
api: /iqv/list/program/assignedAssessor
method: GET
payload: {iqv}
*/
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };

  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/iqv/list/program/assignedAssessor`, { params: payload, headers })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getIQVLearnerPrograms = async (payload) => {
  /*
api: /iqv/list/assessor/learner/program
method: GET
payload: {memberId}
*/
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };

  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/iqv/list/assessor/learner/program`, { params: payload, headers })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};
export const getIQVProgramLearner = async (payload) => {
  /*
api: /iqv/list/assessor/program/learner
method: GET
payload: {programId}
*/
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };

  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/iqv/list/assessor/program/learner`, { params: payload, headers })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const getIQVApprentices = async (payload) => {
  /*
api: /iqv/list/learner/assignedAssessor
method: GET
payload: {iqv}
*/
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };

  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/iqv/list/learner/assignedAssessor`, { params: payload, headers })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const addReviewRating = async (payload, assessorId) => {
  /*
api: /admin/iqv/unassign
method: POST
payload: {
"iqv":"",
"assessors":[""]
}
*/
  try {
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };
    return await axios.post(`${API_URL}/iqv/review-rating/assessor/${assessorId}/add-review-rating`, payload, { headers });
  } catch (err) {
    Log.error(err);
  }
};

export const getReviewRatingData = async (payload, assessorId) => {
  /*
api: /iqv/list/learner/assignedAssessor
method: GET
payload: {iqv}
*/
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };

  return new Promise((resolve, reject) => {
    return axios
      .get(`${API_URL}/iqv/list/assessor/${assessorId}/review`, { params: payload, headers })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};
export const deleteReviewRating = async (payload, assessorId) => {
  try {
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };
    return await axios.post(`${API_URL}/iqv/remove/review/assessor/${assessorId}`, payload, { headers });
  } catch (err) {
    Log.error(err);
  }
};

export const iqvVerifyMemberProgram = async (memberProgramId) => {
  /*
api: iqv/verify-member-program/:memberProgramId
method: post
*/
  try {
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };
    return await axios.post(`${API_URL}/iqv/verify-member-program/${memberProgramId}`, null, { headers });
  } catch (err) {
    Log.error(err);
  }
};
