import { Route } from 'react-router-dom';
import { lazyWithRetry } from 'components/lazyWithRetry';
import ViewUnitBank from 'modules/admin/unitBank/viewUnitBank';
const CourseComponent = lazyWithRetry(() => import('modules/admin/program/library/components/CourseComponent'));
const EditCourse = lazyWithRetry(() => import('modules/admin/program/library/components/CourseComponent/EditCourse'));
const AddPortfolioEvidence = lazyWithRetry(() => import('modules/admin/program/library/components/PortfolioEvidence'));
const AddOffTheJob = lazyWithRetry(() => import('modules/admin/program/library/components/OffTheJob'));
const EditPortfolioEvidence = lazyWithRetry(() => import('modules/admin/program/library/components/PortfolioEvidence/EditPortfolioEvidence'));
const EditResources = lazyWithRetry(() => import('modules/admin/program/library/components/Resources/EditResources'));
const AddReview = lazyWithRetry(() => import('modules/admin/program/library/components/Review'));
const EditOTJ = lazyWithRetry(() => import('modules/admin/program/library/components/OffTheJob/EditOTJ'));
const EditReview = lazyWithRetry(() => import('modules/admin/program/library/components/Review/EditReview'));
const AddResourses = lazyWithRetry(() => import('modules/admin/program/library/components/Resources/AddResourses'));
const UnitBank = lazyWithRetry(() => import('modules/admin/unitBank'));

export default (
  <>
    <Route
      path="unit-bank/"
      element={<UnitBank />}
    />
    <Route
      path="unit-bank/section/:sectionId/view"
      element={<ViewUnitBank />}
    />
    <Route
      path="unit-bank/section/:sectionId/course-component"
      element={<CourseComponent />}
    />
    <Route
      path="unit-bank/section/:sectionId/:subSectionId/course-component"
      element={<CourseComponent />}
    />
    <Route
      path="unit-bank/section/course-component"
      element={<CourseComponent />}
    />
    <Route
      path="unit-bank/section/:sectionId/course-component/edit/:id"
      element={<EditCourse />}
    />
    <Route
      path="unit-bank/course-component/edit/:id"
      element={<EditCourse />}
    />
    <Route
      path="unit-bank/section/portfolio-evidence"
      element={<AddPortfolioEvidence />}
    />
    <Route
      path="unit-bank/portfolio-evidence"
      element={<AddPortfolioEvidence />}
    />
    <Route
      path="unit-bank/section/:sectionId/portfolio-evidence"
      element={<AddPortfolioEvidence />}
    />
    <Route
      path="unit-bank/section/:sectionId/:subSectionId/portfolio-evidence"
      element={<AddPortfolioEvidence />}
    />
    <Route
      path="unit-bank/section/:sectionId/portfolio-evidence/edit/:id"
      element={<EditPortfolioEvidence />}
    />
    <Route
      path="unit-bank/portfolio-evidence/edit/:id"
      element={<EditPortfolioEvidence />}
    />
    <Route
      path="unit-bank/section/:sectionId/add-resources"
      element={<AddResourses />}
    />
    <Route
      path="unit-bank/section/:sectionId/:subSectionId/add-resources"
      element={<AddResourses />}
    />
    <Route
      path="unit-bank/section/:sectionId/resource-component/edit/:id"
      element={<EditResources />}
    />
    <Route
      path="unit-bank/resource-component/edit/:id"
      element={<EditResources />}
    />
    <Route
      path="unit-bank/add-off-the-job"
      element={<AddOffTheJob />}
    />
    <Route
      path="unit-bank/section/:sectionId/add-off-the-job"
      element={<AddOffTheJob />}
    />
    <Route
      path="unit-bank/section/:sectionId/:subSectionId/add-off-the-job"
      element={<AddOffTheJob />}
    />

    <Route
      path="unit-bank/section/:sectionId/otj-component/edit/:id"
      element={<EditOTJ />}
    />
    <Route
      path="unit-bank/otj-component/edit/:id"
      element={<EditOTJ />}
    />

    <Route
      path="unit-bank/add-review"
      element={<AddReview />}
    />
    <Route
      path="unit-bank/section/:sectionId/add-review"
      element={<AddReview />}
    />
    <Route
      path="unit-bank/section/:sectionId/:subSectionId/add-review"
      element={<AddReview />}
    />

    <Route
      path="unit-bank/section/:sectionId/review-component/edit/:id"
      element={<EditReview />}
    />
    <Route
      path="unit-bank/review-component/edit/:id"
      element={<EditReview />}
    />
  </>
);
