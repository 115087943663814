import { Routes, Route } from 'react-router-dom';
import { lazyWithRetry } from 'components/lazyWithRetry';
import CommonRoute from '../common';
import ApprenticeProgramRoute from '../common/apprenticeProgramRoute';
import ProgramRoute from 'routes/common/programRoute';
const ComponentDetail = lazyWithRetry(() => import('modules/learner/program/ComponentDetail'));
const ProgramDetail = lazyWithRetry(() => import('modules/admin/users/programs/programDetail'));
const ProgrammeProgress = lazyWithRetry(() => import('modules/admin/users/programs/programProgress'));
const ActivePrograms = lazyWithRetry(() => import('modules/admin/users/programs/activePrograms'));
const IQVLayout = lazyWithRetry(() => import('layouts/IQVLayout'));
const TutorLayout = lazyWithRetry(() => import('layouts/TutorLayout'));
const Dashboard = lazyWithRetry(() => import('modules/admin/dashboard'));
const ProfileActivity = lazyWithRetry(() => import('modules/admin/profile'));
const Program = lazyWithRetry(() => import('modules/admin/program'));
const ProgramFieldManagement = lazyWithRetry(() => import('modules/admin/program/FieldManagement'));
const AllComponent = lazyWithRetry(() => import('modules/admin/program/allComponent/index'));
const UserApprentices = lazyWithRetry(() => import('modules/admin/users/apprentices'));
const ProfileFieldManagement = lazyWithRetry(() => import('modules/admin/users/FieldManagement'));
const Taxonomy = lazyWithRetry(() => import('modules/superAdmin/taxonomy'));
const Profile = lazyWithRetry(() => import('modules/admin/profile/Profile'));
const LectureList = lazyWithRetry(() => import('modules/learner/program/LectureList'));
const MemberAssessors = lazyWithRetry(() => import('modules/learner/program/assessors'));
const ActivityDetail = lazyWithRetry(() => import('modules/learner/program/ActivityDetail'));
const TaskList = lazyWithRetry(() => import('modules/learner/program/TaskList/'));
const Target = lazyWithRetry(() => import('modules/learner/program/Target/'));
const Resubmission = lazyWithRetry(() => import('modules/learner/program/Resubmission/'));
const MemberProgramList = lazyWithRetry(() => import('modules/admin/program/MemberProgramList'));
const QuickLinks = lazyWithRetry(() => import('modules/learner/program/QuickLinks/'));
const UserAssessors = lazyWithRetry(() => import('modules/admin/users/assessors'));
const NoMatch = lazyWithRetry(() => import('modules/NoMatch'));
const Notification = lazyWithRetry(() => import('components/notification'));
const AssessorReport = lazyWithRetry(() => import('modules/admin/users/assessors/activeApprentices'));
const ActiveApprentices = lazyWithRetry(() => import('modules/admin/users/apprentices/activeApprentices'));
const WageSchedule = lazyWithRetry(() => import('modules/admin/wage'));
const EnrollmentLink = lazyWithRetry(() => import('modules/admin/program/EnrollmentLink'));
const RatingReview = lazyWithRetry(() => import('modules/admin/program/ratingReview'));

export default (
  <Routes>
    <Route
      path="tutor/"
      element={<TutorLayout />}
    >
      <Route
        index
        element={<Dashboard />}
      />
      <Route
        path="dashboard/"
        element={<Dashboard />}
      />
      <Route
        path="view-profile/:id"
        element={<ProfileActivity />}
      />
      <Route
        path="program/*"
        element={<Program />}
      />
      <Route
        path="notification"
        element={<Notification />}
      />
      <Route
        path="program/:programId/enrollment-link"
        exact
        element={<EnrollmentLink />}
      />
      <Route
        path="program/:programId/field-management"
        exact
        element={<ProgramFieldManagement />}
      />
      <Route
        path="program/:programId/all-component"
        exact
        element={<AllComponent />}
      />
      <Route
        path="apprentices/"
        exact
        element={<UserApprentices />}
      />

      <Route
        path="assessors"
        exact
        element={<UserAssessors />}
      />

      <Route
        path="assessors/view-profile/:id"
        exact
        element={<ProfileActivity />}
      />

      <Route
        path="field-management/apprentices"
        exact
        element={<ProfileFieldManagement />}
      />
      <Route
        path="taxonomy/*"
        element={<Taxonomy />}
      />
      <Route
        path="wage/:memberProgramId"
        element={<WageSchedule />}
      />
      <Route
        path="profile/edit-profile/:id"
        element={<Profile />}
      />
      <Route
        path=":userTab/view-profile/:id"
        exact
        element={<ProfileActivity name="apprentices" />}
      />
      <Route
        path=":userTab/view-profile/:id/program/:programId/"
        exact
        element={<LectureList />}
      />
      <Route
        path=":userTab/view-profile/:id/program/:programId/lecture-list"
        exact
        element={<LectureList />}
      />
      <Route
        path=":userTab/view-profile/:id/program/:programId/:lectureDetail/activitityDetail"
        element={<ActivityDetail />}
      />
      <Route
        path=":userTab/view-profile/:id/program/:programId/lecture-list/:lectureDetail/activitityDetail"
        element={<ActivityDetail />}
      />
      <Route
        path=":userTab/view-profile/:id/program/:member_program_id/target"
        exact
        element={<Target />}
      />
      <Route
        path=":userTab/view-profile/:id/program/:programId/task-list"
        exact
        element={<TaskList />}
      />
      <Route
        path=":userTab/view-profile/:id/program/:programId/task-list/:lectureDetail/activitityDetail"
        exact
        element={<ActivityDetail />}
      />
      <Route
        path=":userTab/view-profile/:id/program/:programId/quick-links"
        exact
        element={<QuickLinks />}
      />
      <Route
        path=":userTab/view-profile/:id/program/:programId/quick-links/:lectureDetail/activitityDetail"
        exact
        element={<ActivityDetail />}
      />
      <Route
        path=":userTab/view-profile/:id/program/:programId/resubmission"
        exact
        element={<Resubmission />}
      />
      <Route
        path=":userTab/view-profile/:id/program/:programId/assessors"
        exact
        element={<MemberAssessors />}
      />
      {/* <Route
        path="program/:programId/learner-list"
        exact
        element={<MemberProgramList />}
      />
      <Route
        path="program/:programId/learner-list/:id/lecture-list/:sectionid/"
        element={<ComponentDetail backBtnStatus={true} />}
      />
      <Route
        path="program/:programId/learner-list/:id/lecture-list/:lectureDetail/activitityDetail"
        exact
        element={<ActivityDetail />}
      />
      <Route
        path="program/:programId/learner-list/:id/lecture-list"
        exact
        element={<LectureList />}
      />
      <Route
        path="program/:programId/learner-list/:id/assessors"
        exact
        element={<MemberAssessors />}
      />
      <Route
        path="program/:programId/learner-list/:id/target"
        exact
        element={<Target />}
      />
      <Route
        path="program/:programId/learner-list/:id/quick-links"
        exact
        element={<QuickLinks />}
      />
      <Route
        path="program/:programId/learner-list/:id/task-list"
        exact
        element={<TaskList />}
      />
      <Route
        path="program/:programId/learner-list/:id/resubmission"
        exact
        element={<Resubmission />}
      />
      <Route
        path="program/:programId/:backpage/:id"
        exact
        element={<LectureList />}
      /> */}
      <Route
        path="assessor/report"
        exact
        element={<AssessorReport />}
      />
      {CommonRoute}
      {ProgramRoute}
      {ApprenticeProgramRoute}
      <Route
        path="users/programs/active"
        exact
        element={<ActivePrograms />}
      />

      <Route
        path="users/programs/progress/:programId"
        exact
        element={<ProgrammeProgress />}
      />
      <Route
        path="users/programs/active/progress/:programId/:memberProgramId"
        exact
        element={<ProgramDetail />}
      />
      <Route
        path="users/programs/active/progress/:memberProgramId"
        exact
        element={<ProgramDetail />}
      />
      <Route
        path="users/programs/active/progress/learner-program-detail"
        exact
        element={<ProgramDetail />}
      />
      <Route
        path="users/apprentices/active"
        exact
        element={<ActiveApprentices />}
      />
      <Route
        path="users/apprentice/program/report"
        exact
        element={<ProgrammeProgress />}
      />
      <Route
        path="program/:programId/rating-review"
        exact
        element={<RatingReview />}
      />
      <Route
        path="*"
        element={<NoMatch />}
      />
    </Route>
  </Routes>
);
