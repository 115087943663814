import React, { useEffect, useState, memo } from 'react';
import SecondaryHeaderInfo from 'react-myoneui/lib/SecondaryHeaderInfo';
import CircleImg from '../CircleImg';
import { getUserIconFromRoleIdentifier } from '../../lib';
import { useParams } from 'react-router-dom';
const UserInfo = ({ data }) => {
  const { userTab } = useParams();
  const userIdentifier = userTab ? userTab.slice(0, -1) : '';

  const [state, setState] = useState({
    name: '',
    email: '',
    profile_image: '',
  });

  useEffect(() => {
    let { last_name, first_name, decrypted_email, profile_image, roles } = data;
    let name = first_name + ' ' + last_name;
    setState({ ...state, email: decrypted_email, name, profile_image: profile_image, roles });
  }, [data]);

  return (
    <div className="secondary-header-wrap align-items-center">
      <CircleImg
        defaultIcon={getUserIconFromRoleIdentifier(userIdentifier)}
        src={state?.profile_image}
        title={state.name}
      />
      <SecondaryHeaderInfo
        SecondaryHeaderTitle={state.name}
        SecondaryHeaderText={state?.email}
        SecondaryText
      />
    </div>
  );
};

export default memo(UserInfo);
