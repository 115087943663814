function AboutCircleInfo(props) {
  const { children, name } = props;
  return (
    <div className={`information-holder about-circle-info ${props?.className || ''}`}>
      {children}
      <div className="information-detail">
        <p>{name}</p>
      </div>
    </div>
  );
}

export default AboutCircleInfo;
