const Log = {
  error: (...args) => {
    let i = 0;
    while (i < args.length) {
      // console.log(args[i]);
      i++;
    }
  },
  info: (...args) => {
    let i = 0;
    while (i < args.length) {
      // console.log(args[i]);
      i++;
    }
  },
};
Object.seal(Log);
export default Log;
