import Form from 'react-myoneui/lib/Form';

import { sortingArrowUp, sortingArrowDown } from '../../../../constants/icons';

const SectionGridHeader = ({ togglesort, sort }) => {
  const sortClick = () => {
    console.log(sort);
    togglesort();
  };
  return (
    <div className="section-grid section-grid-header">
      <div className="section-grid-left">
        {/* <Form.Check>
          <Form.Check.Input className="form-check-input" type="checkbox" id="checkbox1" />
          <Form.Check.Label className="form-check-label" htmlFor="checkbox1"></Form.Check.Label>
        </Form.Check> */}
      </div>
      <div className="section-grid-middle">
        <span
          className="ms-3"
          onClick={() => sortClick()}
        >
          Title {sort == null ? '' : sort ? sortingArrowUp : sortingArrowDown}
        </span>
      </div>
      <div className="section-grid-right">{/* <span className="section-border-left">Status{sortingArrowDown}</span> */}</div>
    </div>
  );
};

export default SectionGridHeader;
