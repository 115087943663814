import { React, useState, memo } from 'react';
import { forwardRef, Children } from 'react';
import Dropdown from 'react-myoneui/lib/Dropdown';
import { dotsIcon } from 'constants/icons';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu

const CustomToggle = forwardRef(({ children, onClick }, ref) => (
  <div
    variant="primary"
    role="button"
    tabIndex={0}
    ref={ref}
    className="btn"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it

const CustomMenu = forwardRef(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
  const [value, setValue] = useState('');

  return (
    <div
      ref={ref}
      style={style}
      className={className}
      aria-labelledby={labeledBy}
    >
      <ul className="list-unstyled mb-0">{Children.toArray(children).filter((child) => !value || child.props.children.toLowerCase().startsWith(value))}</ul>
    </div>
  );
});

function DropElementsDropdown(props) {
  const { onEdit, onDelete, onDuplicate, title } = props;
  return (
    <>
      {['end'].map((direction) => (
        <Dropdown
          className="dropdown-select drop-elements-dropdown"
          key={direction}
          id={`dropdown-button-drop-${direction}`}
          drop={direction}
        >
          <Dropdown.Toggle as={CustomToggle}>
            <span>{title ? title : 'Single line'}</span>
            {dotsIcon}
          </Dropdown.Toggle>

          <Dropdown.Menu
            as={CustomMenu}
            className="dropdown-menu mw-dropdown-default"
          >
            <Dropdown.Item
              eventKey="1"
              onClick={onEdit}
            >
              Change Element
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="2"
              onClick={onDuplicate}
            >
              Duplicate
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="3"
              onClick={onDelete}
            >
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ))}
    </>
  );
}

export default memo(DropElementsDropdown);
