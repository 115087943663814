import { memo } from 'react';
import { Form, Col } from 'react-myoneui';
import { useDispatch, useSelector } from 'react-redux';
import { storeFormData } from '../../../redux/FormDataSlice';
import Log from 'lib/Log';
function InputRadio({ data, mode, question_number }) {
  //   const state = useSelector((state) => state.formData.data.find((item) => item.id === data.id));
  //   const formData = useSelector((state) => state.formData.data);
  //   const dispatch = useDispatch();

  //   const valueControl = (e) => {
  //     try {
  //       let cloneFormData = JSON.parse(JSON.stringify(formData));
  //       let obj = cloneFormData.find((item) => item.id === e.target.id);
  //       let index = cloneFormData.indexOf(obj);
  //       obj.value = e.target.value;
  //       cloneFormData[index] = obj;
  //       dispatch(storeFormData(cloneFormData));
  //     } catch (error) {
  //       Log.error(error);
  //     }
  //   };

  return (
    <>
      <Col
        className="py-2"
        // md={state?.col_width}
        name="formElement"
      >
        <Form.Label
          htmlFor={data?.title}
          className="form-builder-label"
        >
          {question_number}. {data?.title}
        </Form.Label>
        <ol
          type="a"
          className="ol-style"
        >
          {data?.options?.length > 0
            ? data.options.map((item, index) => {
                return (
                  <li
                    key={index}
                    className="align-items-center pt-2 "
                  >
                    <Form.Check
                      value=""
                      label={item.label}
                      name={question_number}
                      type="radio"
                      className="ms-2"
                    />
                  </li>
                );
              })
            : ''}
        </ol>
      </Col>
    </>
  );
}
export default memo(InputRadio);
