import { useState, forwardRef, Children, useEffect } from 'react';
import Dropdown from 'react-myoneui/lib/Dropdown';
import Form from 'react-myoneui/lib/Form';
import FloatingLabel from 'react-myoneui/lib/FloatingLabel';
import { btnClearIcon } from '../../../constants/icons';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = forwardRef(({ children, onClick }, ref) => (
  <div
    role="button"
    tabIndex={0}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = forwardRef(({ children, style, className, value, 'aria-labelledby': labeledBy }, ref) => {
  // const [value, setValue] = useState('');

  return (
    <div
      ref={ref}
      style={style}
      className={className}
      aria-labelledby={labeledBy}
    >
      <ul className="list-unstyled mb-0">{Children.toArray(children).filter((child) => !value || child.props.children.toLowerCase().includes(value))}</ul>
    </div>
  );
});

const sampleItems = [];

function DropdownCollection({ label, className, items = sampleItems, defaultValue = '', onChange = () => {}, onClear = () => {} }) {
  const [menuValue, setMenuValue] = useState('');
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState({});
  useEffect(() => {
    if (defaultValue) {
      setSelected(defaultValue);
    } else {
      setSelected('');
      setMenuValue('');
      setShow(false);
    }
  }, [defaultValue]);

  const onSelect = (index) => {
    setSelected(items[index]);
    onChange(items[index]);
  };
  const onToggle = (show, meta) => {
    setShow(show);
    if (!show) {
      setMenuValue('');
    }
  };
  const onInputChange = (e) => {
    const val = e.target.value ? e.target.value.trim().toLowerCase() : '';

    setSelected(val);
    setMenuValue(val);
    setShow(true);
  };
  const onInputKeyUp = (e) => {
    const val = e.target.value ? e.target.value.trim().toLowerCase() : '';
    if (e.key === 'Enter' && val) {
      const matchedIndex = items.findIndex((item) => item.toLowerCase().includes(val));
      if (matchedIndex > -1) {
        const selected = items[matchedIndex];
        setSelected(selected);
        onChange(selected);
      }
    }
  };
  return (
    <Dropdown
      className={`dropdown-select ${className}`}
      onSelect={onSelect}
      show={show}
      onToggle={onToggle}
    >
      <Dropdown.Toggle
        as={CustomToggle}
        id="dropdown-custom-components"
      >
        <FloatingLabel
          controlId="floatingSelection"
          label={label ? label : 'Choose Option'}
          className="w-100 custom-clear-wrap"
        >
          <Form.Control
            type="text"
            placeholder="Events"
            onChange={onInputChange}
            onKeyDown={onInputChange}
            value={selected?.name ?? selected}
          />
          {!defaultValue ? (
            <div className="caret-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6.119"
                height="11.658"
                viewBox="0 0 6.119 11.658"
              >
                <path
                  id="Path_21616"
                  data-name="Path 21616"
                  d="M0,0,4.42,3.71,8.84,0"
                  transform="translate(1.409 10.249) rotate(-90)"
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
              </svg>
            </div>
          ) : (
            <span
              className="btn-clear"
              onClick={onClear}
              role="button"
              tabIndex={0}
            >
              {btnClearIcon}
            </span>
          )}
        </FloatingLabel>
      </Dropdown.Toggle>
      <Dropdown.Menu
        value={menuValue}
        as={CustomMenu}
      >
        {items.map((item, i) => (
          <Dropdown.Item
            key={i}
            eventKey={i}
            active={selected === item}
          >
            {item.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DropdownCollection;
