import Form from 'react-myoneui/lib/Form';
import { useEffect, useState } from 'react';
import SingleComponent from './SingleComponent';
function MultipleComponentList({ label, showChild = true, parentId = 0, list = [], onChange, noSingleChange = () => {}, onSelectAll, status }) {
  const [allStatus, setAllStatus] = useState(false);
  const [childstatus, setChildStatus] = useState(false);
  useEffect(() => {
    if (status === true) {
      setAllStatus(status);
      setChildStatus(!childstatus);
      setTimeout(() => {
        setChildStatus(true);
      }, 100);
    }
  }, [status]);
  const singleChange = (e) => {
    setAllStatus(false);
    let cloneList = JSON.parse(JSON.stringify(list));
    let obj = cloneList.find((item) => item.memberSectionId === e.target.dataset.id);
    let index = cloneList.indexOf(obj);
    obj.status = e.target.checked;
    cloneList[index] = obj;
    noSingleChange(e, cloneList);
  };
  const selectAllHandler = (e) => {
    setAllStatus(e.target.checked);
    setChildStatus(!childstatus);
    setTimeout(() => {
      setChildStatus(e.target.checked);
    }, 100);

    onSelectAll(e);
  };

  return (
    <>
      <ul className={`multiple-component-list ${showChild === false ? 'd-none' : ''}`}>
        <li>
          {/* This is additional class for check-all */}
          <Form.Check className="check-all">
            <Form.Check.Input
              data-type="component"
              data-label={label}
              data-parentid={parentId}
              type="checkbox"
              checked={allStatus}
              id="multiComponent"
              onChange={selectAllHandler}
            />
            <Form.Check.Label
              htmlFor="multiComponent"
              title="Check All"
            >
              Check All
            </Form.Check.Label>
          </Form.Check>
        </li>

        {list.map((item) => (
          <li key={item.memberSectionId}>
            <SingleComponent
              status={childstatus}
              parentId={parentId}
              label={label}
              item={item}
              onChange={(e) => singleChange(e)}
            />
          </li>
        ))}
      </ul>
    </>
  );
}
/*
<Form.Check>
              <Form.Check.Input type="checkbox"
               checked={item?.status === true} 
               id={`multiComponent_${item.memberSectionId}`} 
               onChange={onChange}
                value={item.memberSectionId} />
              <Form.Check.Label htmlFor={`multiComponent_${item.memberSectionId}`} title={item.sectionTitle}>
                {item.sectionTitle}
              </Form.Check.Label>
            </Form.Check>
*/
export default MultipleComponentList;
