import React, { useState, useEffect, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SecondaryHeader from 'react-myoneui/lib/SecondaryHeader';
import SecondaryHeaderInfo from 'react-myoneui/lib/SecondaryHeaderInfo';
import { setToastBoxDescription, setToastBoxStatus, setTaxonomy } from '../../../redux';
import SectionGridHeader from '../program/grid-table/SectionGridHeader';
import SectionRow from '../program/grid-table/SectionRow';
import { Spinner } from 'react-myoneui';
import ToastBox from 'components/ToastBox';
import { getSectionBundleById, getTaxonomy, removeSectionBankSection } from 'api';
import { useParams } from 'react-router-dom';
import AddSection from 'components/program/AddSection';
import EditSection from 'components/program/EditSection';
import { translate } from 'lib';

import { LANG } from 'constants/config';
import DataNotFound from 'components/DataNotFound';

import UnitBankOffCanvas from './UnitBankOffCanvas';
import ls from 'localstorage-slim';
import Log from 'lib/Log';
import { setSidebarData } from 'reducers/sidebarSlice';
import ViewUnitSidebar from './ViewUnitSidebar';

import Dropdown from 'react-myoneui/lib/Dropdown';
import { blackArrowDownIcon, previewContentIcon, removeIcon } from 'constants/icons';
import Button from 'react-myoneui/lib/Button';
import AddDropdownList from '../program/addDropdown';
import FetchFile from 'hooks/fetchFile';
import PreviewLectureList from 'modules/learner/program/ProgramPreview/PreviewLectureList';
import { ConfirmBox } from 'components/ConfirmBox';
import AddDropDownSectionBank from '../program/AddDropDownSectionBank';

// enable global encryption
ls.config.encrypt = true;
function ViewUnitBank() {
  const { sectionId } = useParams();
  const { orgId } = useParams();
  const organisationId = orgId || ls.get('orgId');

  const [noDataStatus, setNoDataStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [addSectionStatus, setAddSectionStatus] = useState(false);
  const [editSectionStatus, setEditSectionStatus] = useState(false);
  const [viewSectionStatus, setViewSectionStatus] = useState(false);
  const [parentSectionId, setParentSectionId] = useState(null);
  const [eachSectionData, setEachSectionData] = useState(null);
  const [preview, setPreview] = useState(false);
  const taxonomy = useSelector((state) => state.taxonomy.data);
  const language = ls.get('lang') || LANG;
  const [activeSectionId, setActiveSectionId] = useState(null);
  const [confirmBoxStatus, setConfirmBoxStatus] = useState(false);
  const [deleteLoad, setDeleteLoad] = useState(false);
  const [sort, setSort] = useState(null);
  const [sortBy, setSortBy] = useState('index');

  const [activeParentID, setActiveParentID] = useState(null);

  const [show, setShow] = useState(false);

  const activeRole = JSON.parse(ls.get('activeRole'));
  const { can_edit_program } = activeRole?.privileges;

  const libaryLang = FetchFile(`/lang/modules/common/program/library/${ls.get('lang')}.json`);

  const dispatch = useDispatch();

  const setTaxonomyData = async () => {
    try {
      let result = await getTaxonomy({
        orgId: organisationId,
        lang: language,
      });
      dispatch(setTaxonomy(result.data));
    } catch (error) {
      Log.error(error);
    }
  };

  const sectionList = async () => {
    try {
      if (!loading) {
        setLoading(true);

        const params = { sort: sort == null ? 'asc' : sort ? 'asc' : 'desc', sortBy: sortBy };

        let result = await getSectionBundleById(sectionId, params);

        if (result) {
          setLoading(false);

          if (result?.data?.data) {
            setData(result.data.data);
            setParentSectionId(null);
            if (result?.data?.data?.child_sections.length) {
              setNoDataStatus(false);
            } else {
              setNoDataStatus(true);
            }
          }
        }
      }
    } catch (error) {
      setLoading(false);

      Log.error('Error on Library :', error);
      dispatch(setToastBoxStatus(true));
      dispatch(setToastBoxDescription('Something went wrong while fetching data.'));
    }
  };

  const createSectionHandler = () => {
    try {
      setAddSectionStatus(false);
      sectionList();
    } catch (error) {
      Log.error(error);
    }
  };

  const editSectionHandler = (result) => {
    try {
      dispatch(setToastBoxStatus(true));
      dispatch(setToastBoxDescription(result?.data?.message));
      setEditSectionStatus(false);
      setParentSectionId(null);
      sectionList();
    } catch (error) {
      Log.error(error);
    }
  };

  const sectionHandler = (data, parentSectionId) => {
    try {
      setAddSectionStatus(data);
      setParentSectionId(parentSectionId);
    } catch (error) {
      Log.error(error);
    }
  };

  const deleteSectionHandler = async (id) => {
    setActiveSectionId(id);
    setConfirmBoxStatus(true);
  };

  const onSectionDelete = async () => {
    try {
      setDeleteLoad(true);
      await removeSectionBankSection({ section_id: activeSectionId });
      setDeleteLoad(false);
      setConfirmBoxStatus(false);
      setActiveSectionId(null);
      await sectionList();
    } catch (err) {
      Log.error(err);
    }
  };

  const displayComponentCount = (item) => {
    try {
      let components = [];
      let sub = item?.child_sections?.filter((item) => item.component === null).map((item) => item.child_sections.map((item) => item.component));
      sub?.forEach((element) => {
        element.forEach((item) => {
          components.push(item);
        });
      });

      let ownComponent = item.component_count;
      let totalComponents = ownComponent + components.length;
      let zero = totalComponents < 10 && totalComponents > 0 ? '0' : '';
      let s = totalComponents > 1 ? 's' : '';
      return zero + (totalComponents || 0) + ' ' + (item?.sub_title?.trim()?.length > 0 ? item?.sub_title : translate(taxonomy)('component')) + s;
    } catch (error) {
      Log.error(error);
    }
  };

  const showEditSectionBox = (obj) => {
    try {
      if (obj.component === null) {
        setEachSectionData({ ...obj });
        setEditSectionStatus(true);
      }
    } catch (error) {
      Log.error(error);
    }
  };

  const weightageHandler = (index, value) => {
    try {
      let cloneData = [...data];
      cloneData[index].weightage = value;
      setData(cloneData);
    } catch (error) {
      Log.error(error);
    }
  };

  useEffect(() => {
    if (sectionId) {
      sectionList();
    }
  }, [sectionId, sort, sortBy]);

  useEffect(() => {
    if (Object.keys(taxonomy).length === 0) {
      setTaxonomyData();
    }
  }, []);

  const handleClose = (data) => {
    setShow(false);
    if (data) {
      sectionList();
    }
  };

  useEffect(() => {
    dispatch(setSidebarData({ enableActiveWrapper: true }));

    return () => {
      dispatch(setSidebarData({ enableActiveWrapper: false }));
    };
  }, []);

  const enableActiveWrapper = useSelector((state) => state.sidebar.enableActiveWrapper);

  useEffect(() => {
    if (!enableActiveWrapper) {
      setTimeout(() => {
        dispatch(setSidebarData({ enableActiveWrapper: true }));
      }, 1);
    }
  }, [enableActiveWrapper]);

  const shortTogller = () => {
    if (!sort && sortBy === 'title') {
      setSort(null);
      setSortBy('index');
    } else {
      setSort(!sort);
      setSortBy('title');
    }
  };

  const CustomToggle = forwardRef(({ children, onClick }, ref) => (
    <Button
      role="button"
      tabIndex={0}
      ref={ref}
      variant="secondary"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Button>
  ));

  return (
    <div className="content-wrapper">
      <ViewUnitSidebar />
      <SecondaryHeader>
        <div className="secondary-header-wrap">
          <SecondaryHeaderInfo
            SecondaryHeaderTitle={data.title}
            SecondaryHeaderText={`${displayComponentCount(data)}`}
            SecondaryText
          />
        </div>
        <div className="button-wrap">
          {!preview ? (
            <Button
              variant="text-icon"
              className="text-icon"
              onClick={() => setPreview(true)}
            >
              {translate(libaryLang)('preview_content')}
              {previewContentIcon}
            </Button>
          ) : (
            <Button
              variant="text-icon"
              className="text-icon"
              onClick={() => setPreview(false)}
            >
              Close {translate(libaryLang)('preview_content')}
              {removeIcon}
            </Button>
          )}
          {!preview && (
            <AddDropDownSectionBank
              id={sectionId}
              onAddSectionStatus={(data) => sectionHandler(data, sectionId)}
              sectionType="subSection"
            >
              <Dropdown.Toggle
                as={CustomToggle}
                id="dropdown-custom-components"
              >
                {translate(libaryLang)('add')}
                {blackArrowDownIcon}
              </Dropdown.Toggle>
            </AddDropDownSectionBank>
          )}
        </div>
      </SecondaryHeader>

      {addSectionStatus && (
        <AddSection
          parentSectionId={parentSectionId}
          onClose={() => setAddSectionStatus(false)}
          show={true}
          onCreate={createSectionHandler}
          sectionLevel="subSection"
          isFromSectionBank={true}
          sectionType="subSection"
        />
      )}

      {editSectionStatus && (
        <EditSection
          data={eachSectionData}
          parentSectionId={parentSectionId}
          onClose={() => setEditSectionStatus(false)}
          show={true}
          onEdit={(data) => editSectionHandler(data)}
          isFromSectionBank={true}
        />
      )}

      {viewSectionStatus && (
        <EditSection
          viewMode={true}
          data={eachSectionData}
          parentSectionId={parentSectionId}
          onClose={() => setViewSectionStatus(false)}
          show={true}
        />
      )}

      {noDataStatus ? (
        <DataNotFound />
      ) : preview ? (
        <PreviewLectureList
          programDetail={[data]}
          from={`${translate(taxonomy)('section')}`}
        />
      ) : (
        <div className="content-block py-2">
          <div className="section-grid-wrapper">
            <div className="section-grid-holder">
              <SectionGridHeader
                togglesort={() => shortTogller()}
                sort={sort}
              />
              <div className="section-grid-row">
                {loading ? (
                  <div className="spinner-wrapper">
                    <Spinner animation="border" />
                  </div>
                ) : (
                  data &&
                  Object.keys(data).length > 0 && (
                    <SectionRow
                      isFromSectionBank={true}
                      parentId={0}
                      weightageStatus={false}
                      data={data}
                      description={data.description}
                      childsections={data.child_sections}
                      componentCount={data.component_count}
                      itemindex={data.index}
                      id={data._id}
                      key={data._id}
                      sectionType={data.section_type}
                      headding={`${data.component !== null ? translate(taxonomy)('component') : translate(taxonomy)('section')} ${data.index + 1}.`}
                      weightage={data.weightage}
                      subHeadding={data.section_type === 'CourseComponent' ? `${translate(taxonomy)('course')}` : displayComponentCount(data)}
                      title={data.title}
                      sub_title={data?.sub_title}
                      onEditSection={(obj) => showEditSectionBox(obj)}
                      onDeleteSection={(id) => deleteSectionHandler(id)}
                      onAddSectionStatus={() => sectionHandler(data, data._id)}
                      onWeightage={weightageHandler}
                      isPublished={data.is_publish}
                      onHasComponentStatus={(data) => {}}
                      activeParentID={activeParentID}
                      onActiveParentID={(id) => setActiveParentID(id)}
                      // onReOrder={reOrder}
                      isValidRole={can_edit_program}
                    />
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <ConfirmBox
        show={confirmBoxStatus}
        question={`Are you sure you want to delete this from section bank`}
        cancelText="Cancel"
        okText="Delete"
        onHide={() => setConfirmBoxStatus(false)}
        onOk={onSectionDelete}
        loading={deleteLoad}
      />

      <ToastBox />
      <UnitBankOffCanvas
        show={show}
        onClose={(data) => handleClose(data)}
      />
    </div>
  );
}

export default React.memo(ViewUnitBank);
