import { useEffect, useState } from 'react';
import { Card, Button, Spinner } from 'react-myoneui';
import Form from 'react-myoneui/lib/Form';
import FloatingLabel from 'react-myoneui/lib/FloatingLabel';
import Offcanvas from 'react-myoneui/lib/Offcanvas';
import TextEditor from 'components/TextEditor';
import TagContent from 'components/tag';
import Log from 'lib/Log';
import DeadLinesType from 'components/program/DeadLinesType';
import { translate } from 'lib';
import FetchFile from 'hooks/fetchFile';
import ls from 'localstorage-slim';
import { useDispatch, useSelector } from 'react-redux';
import { setToastBoxStatus, setToastBoxDescription } from 'redux/ToastBoxSlice';
import { createUnitBankSection, getCategories } from 'api';

ls.config.encrypt = true;

function UnitBankOffCanvas({ onClose = (data = null) => {}, show = true }) {
  const lang = FetchFile(`/lang/components/program/add-section/${ls.get('lang')}.json`);
  const taxonomy = useSelector((state) => state.taxonomy.data);

  const dispatch = useDispatch();
  const handleClose = () => onClose();
  const offCanvasKeyDown = (e) => e.stopPropagation();
  const [categegoryList, setCategegoryList] = useState([]);
  const [categories, setCategories] = useState([]);
  const keywordHandler = (data) => {
    Log.info('Data :', data);
    setCategories(data);
  };
  const [title, setTitle] = useState('');
  const [subTitle, setSubTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState('');

  const [deadlines, setDeadlines] = useState({
    duration: null,
    end_date: null,
    lock_after_duration: null,
    previous_section: null,
    set_deadline_after: null,
    start_date: null,
    validate: true,
  });

  const deadlineHandler = (data) => {
    setDeadlines(data);
  };

  useEffect(() => {
    setTitle('');
    setSubTitle('');
    setLoading(false);
    setDescription('');
    setCategories([]);
    setDeadlines({
      duration: null,
      end_date: null,
      lock_after_duration: null,
      previous_section: null,
      set_deadline_after: null,
      start_date: null,
      validate: true,
    });
  }, [!show]);

  useEffect(() => {
    if (show) {
      async function fetchSectionCategories() {
        const { data } = await getCategories('section');
        setCategegoryList(data.data);
      }
      fetchSectionCategories();
    }
  }, [show]);

  const createNewSection = async () => {
    try {
      setLoading(true);
      let payload = {
        title,
        sub_title: subTitle,
        description,
        duration: Number(deadlines.duration),
        end_date: deadlines['end_date'],
        lock_after_duration: deadlines['lock_after_duration'],
        previous_section: deadlines['previous_section']?.id,
        set_deadline_after: deadlines['set_deadline_after'],
        start_date: deadlines['start_date'],
        deadline_type: deadlines['deadline_type'],
      };

      let createdCategories = [];
      for (const category of categories) {
        if (category?.new) {
          createdCategories.push({ name: category.name, of: 'section' });
        } else {
          createdCategories.push({ id: category?.id });
        }
      }

      payload = { ...payload, categories: createdCategories };

      let result = await createUnitBankSection(payload);
      if (result) {
        setLoading(false);
        dispatch(setToastBoxStatus(true));
        dispatch(setToastBoxDescription(result?.data?.message));

        onClose(result);
      }
    } catch (error) {
      Log.error(error);
      setLoading(false);
      dispatch(setToastBoxStatus(true));
      dispatch(setToastBoxDescription(error?.data?.message));
      onClose();
      // onResponse(error?.data);
      Log.error('Error on  saveSection :', error);
    }
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        onKeyDown={offCanvasKeyDown}
      >
        <Offcanvas.Header
          closeButton
          backButton="Close"
          icon="close"
        >
          <Offcanvas.Title>{`Add ${translate(taxonomy)('section')}`}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {/* <FloatingLabel controlId="floatingInputTitle" label="Title" className="mb-4">
            <Form.Control tabIndex="1" type="text" placeholder="Title" />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInputTitle" label="Sub Title" className="mb-4">
            <Form.Control tabIndex="1" type="text" placeholder="Sub Title" />
          </FloatingLabel> */}
          <FloatingLabel
            controlId="floatingInputTitle"
            label={translate(lang)('sample_title')}
            className="mb-4"
          >
            <Form.Control
              tabIndex="1"
              type="text"
              placeholder={translate(lang)('type-title')}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="floatingInputTitle"
            label={translate(lang)('sample_sub_title')}
            className="mb-4"
          >
            <Form.Control
              tabIndex="1"
              type="text"
              placeholder={translate(lang)('type-title')}
              value={subTitle}
              onChange={(e) => setSubTitle(e.target.value)}
            />
          </FloatingLabel>
          {/* <Card className="react-quill-editor mb-4">
            <TextEditor placeholder="Description" />
          </Card> */}
          <Card className="react-quill-editor mb-4">
            {lang.type_description && (
              <TextEditor
                value={description}
                onChange={(data) => setDescription(data)}
              />
            )}
          </Card>
          <TagContent
            className="custom-tag-content mb-4"
            tagTitle="Category *"
            list={categegoryList}
            keywords={categories}
            onTags={keywordHandler}
            placeholder="Add new category and press comma"
            placeholderTitle="Add new category and press comma"
          />
          <DeadLinesType
            isFromSectionBank={true}
            onChange={(data) => deadlineHandler(data)}
            phase={translate(taxonomy)('section')}
          />
        </Offcanvas.Body>
        <Offcanvas.Footer>
          <Button
            variant="default"
            onClick={handleClose}
          >
            Cancel
          </Button>

          <Button
            disabled={title.trim().length === 0}
            variant="primary"
            onClick={createNewSection}
          >
            {loading && (
              <>
                <Spinner
                  animation="border"
                  size="sm"
                />{' '}
                &nbsp;
              </>
            )}
            Save
          </Button>
        </Offcanvas.Footer>
      </Offcanvas>
    </>
  );
}

export default UnitBankOffCanvas;
