import axios from 'axios';
import { API_URL } from '../constants/config';
import ls from 'localstorage-slim';

// enable global encryption
ls.config.encrypt = true;
export const login = (payload) => {
  return new Promise((resolve, reject) => {
    return axios
      .post(API_URL + '/login', {
        email: payload.email ? payload.email : '',
        password: payload.password ? payload.password : '',
      })
      .then((result) => resolve(result))
      .catch((error) => reject(error.response));
  });
};

export const switchToOtherRole = async (role) => {
  const headers = {
    Authorization: `Bearer ${ls.get('token')}`,
    organisationid: ls.get('orgId'),
  };
  try {
    return await axios.post(`${API_URL}/switch/role`, { role }, { headers });
  } catch (err) {
    throw new Error(err);
  }
};
