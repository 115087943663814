import React from 'react';
import { CircleImage, CircleImageContent } from 'react-myoneui';
import { useNavigate } from 'react-router-dom';
import DefaultImage from '../../../../../components/defaultImage';

const CourseNameTemplate = ({ value, data, tableManager }) => {
  const { memberProgramId, member_name, member_email } = data;
  const {
    config: {
      additionalProps: { cell: additionalProps = {} },
    },
  } = tableManager;
  const navigate = useNavigate();
  const classNames = ('rgt-cell-inner rgt-text-truncate ' + (additionalProps.className || '')).trim();
  const viewProgramDetail = () => {
    navigate(`program/${memberProgramId}`, { state: { member_name, member_email } });
  };
  {
    /*

const location = useLocation();
  const pathname = location.pathname;

  const viewProgramDetail = () => {
    navigate(`${pathname}/program/${memberProgramId}`, { state: { member_name, member_email } });
  };
*/
  }
  return (
    <div
      {...additionalProps}
      className={classNames}
      title={value}
      onClick={viewProgramDetail}
    >
      <div className="d-flex gap-2 align-items-center overflow-hidden">
        <CircleImage className="circle-wrap circle-wrap-md">
          {data?.program_image_url ? (
            <img
              src={data?.program_image_url}
              alt={data?.name}
            />
          ) : (
            <DefaultImage />
          )}
        </CircleImage>

        {/* <CircleImage className="circle-wrap circle-wrap-md">{data.program_image_url ? <img src={data.program_image_url} /> : <CircleImageContent svgImage="two" />}</CircleImage> */}
        <span className="grid-text-overflow fw-bold">{value}</span>
      </div>
    </div>
  );
};

export default CourseNameTemplate;
