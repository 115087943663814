import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { lazyWithRetry } from 'components/lazyWithRetry';
import SuperAdminRoute from 'routes/superadmin';
import AssessorRoute from 'routes/assessors';
import TutorRoute from 'routes/tutor';
import LearnerRoute from 'routes/learner';
import AdminRoute from './admin';
import IQVRoute from './iqv';
import EQVRoute from './eqv';
const NoMatch = lazyWithRetry(() => import('modules/NoMatch'));
const SignUp = lazyWithRetry(() => import('modules/superAdmin/signUp'));
const Login = lazyWithRetry(() => import('modules/Login'));
const Local = lazyWithRetry(() => import('modules/Local'));
const AdminLocal = lazyWithRetry(() => import('modules/AdminLocal'));
const LearnerLocal = lazyWithRetry(() => import('modules/LearnerLocal'));
const AssessorLocal = lazyWithRetry(() => import('modules/AssessorLocal'));
const TutorLocal = lazyWithRetry(() => import('modules/TutorLocal'));
const IQVLocal = lazyWithRetry(() => import('modules/IQVLocal'));
const Logout = lazyWithRetry(() => import('modules/Logout'));
const SS0Redirect = lazyWithRetry(() => import('modules/SS0Redirect'));
const ContactAdmin = lazyWithRetry(() => import('components/ContactAdmin'));
const CreatePassword = lazyWithRetry(() => import('modules/superAdmin/createPassword'));
const AdditionalDetails = lazyWithRetry(() => import('modules/learner/additionalDetails'));
const ProgramCustomFields = lazyWithRetry(() => import('modules/learner/program/programCustomFields'));
const ChangePassword = lazyWithRetry(() => import('modules/superAdmin/changePassword'));
const Review = lazyWithRetry(() => import('modules/iqv/assessors/Review'));
const MemberEnrollMent = lazyWithRetry(() => import('modules/learner/enrollment'));
const basename = process.env.REACT_APP_BASENAME || '';

export default (
  <Router basename={basename}>
    <SuperAdminRoute />
    {AssessorRoute}
    {TutorRoute}
    {LearnerRoute}
    {AdminRoute}
    {IQVRoute}
    {EQVRoute}
    <Routes>
      <Route
        path="/"
        element={<Login />}
      />
      <Route
        path="/login"
        element={<Login />}
      />
      <Route
        path="/logout"
        element={<Logout />}
      />
      <Route
        path="/local"
        element={<Local />}
      />
      <Route
        path="/learner-local"
        element={<LearnerLocal />}
      />
      <Route
        path="/admin-local"
        element={<AdminLocal />}
      />
      <Route
        path="/assessor-local"
        element={<AssessorLocal />}
      />
      <Route
        path="/iqv-local"
        element={<IQVLocal />}
      />

      <Route
        path="/tutor-local"
        element={<TutorLocal />}
      />
      <Route
        path="/contact-admin"
        element={<ContactAdmin />}
      />
      {/* Super Admin Routes */}
      <Route
        path="/sign-up"
        element={<SignUp />}
      />
      <Route
        path="/create-password"
        element={<CreatePassword />}
      />
      <Route
        path="/change-password"
        element={<ChangePassword />}
      />
      <Route
        path="/additional-details"
        element={<AdditionalDetails />}
      />
      <Route
        path="/sso/redirect"
        element={<SS0Redirect />}
      />
      <Route
        path="/member/enroll"
        element={<MemberEnrollMent />}
      />
      <Route
        path="/customfields"
        element={<AdditionalDetails />}
      />
      <Route
        path="/:memberProgramId/program-custom-fields/"
        element={<ProgramCustomFields />}
      />
      <Route
        path="Review/"
        element={<Review />}
      />

      <Route
        path="/:page"
        element={<NoMatch />}
      />
    </Routes>
  </Router>
);
