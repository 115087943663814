import { useEffect } from 'react';
import { errorIcon } from 'constants/icons';

function NoMatch() {
  useEffect(() => {}, []);
  return (
    <div className="content-wrapper">
      <div className="error-msg-block">
        {errorIcon}
        <h2>Page Not Found ...</h2>
      </div>
    </div>
  );
}

export default NoMatch;
