import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
};

export const EditUserSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      return {
        ...state,
        data: action.payload,
      };
    },
    setEmptyInfo: () => {
      return {
        data: {},
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserInfo, setEmptyInfo } = EditUserSlice.actions;

export default EditUserSlice.reducer;
