import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const ActiveRoleSlice = createSlice({
  name: 'activeRole',
  initialState,
  reducers: {
    storeActiveRole: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const activeRole = (state) => state;
export const { storeActiveRole } = ActiveRoleSlice.actions;
export default ActiveRoleSlice.reducer;
