import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  alertStatus: false,
  msg: null,
  title: null,
};

export const AlertNotificationSlice = createSlice({
  name: 'alertNotification',
  initialState,
  reducers: {
    setAlertNotification: (state, action) => {
      return {
        ...state,
        alertStatus: action.payload.alertStatus,
        msg: action.payload.msg,
        title: action.payload.title,
      };
    },
    resetAlertNotification: (state) => {
      return {
        ...state,
        alertStatus: false,
        msg: null,
        title: null,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAlertNotification, resetAlertNotification } = AlertNotificationSlice.actions;

export default AlertNotificationSlice.reducer;
