import * as React from 'react';
import { useState, forwardRef, Children } from 'react';
import Offcanvas from 'react-myoneui/lib/Offcanvas';
import { Row, Col } from 'react-myoneui';
import { translate } from '../../../lib';
import { useSelector } from 'react-redux';
import Button from 'react-myoneui/lib/Button';
import Form from 'react-myoneui/lib/Form';
import FloatingLabel from 'react-myoneui/lib/FloatingLabel';
import Log from '../../../lib/Log';
function AddComponentScoreOffCanvas(props) {
  const handleClose = () => props.onClose();
  const { title, section_score, achieved_score, component_type } = props;
  const offCanvasKeyDown = (e) => e.stopPropagation();
  const taxonomy = useSelector((state) => state.taxonomy.data);
  const [score, setScore] = useState(achieved_score);
  const [validScore, setValidScore] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleScoreUpdate = (e) => {
    if (e.target.value > section_score) {
      setValidScore(false);
    } else {
      setValidScore(true);
    }
    setScore(e.target.value);
  };
  const handleUpdate = async () => {
    try {
      if (validScore) {
        setLoading(true);
        await props.onUpdate(score);

        setLoading(false);
        handleClose();
      }
    } catch (error) {
      Log.error();
      setLoading(false);
    }
  };
  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = forwardRef(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <ul className="list-unstyled mb-0">{Children.toArray(children).filter((child) => !value || child.props.children.toLowerCase().startsWith(value))}</ul>
      </div>
    );
  });

  return (
    <>
      <Offcanvas
        show={props.show}
        onHide={handleClose}
        placement="end"
        onKeyDown={offCanvasKeyDown}
      >
        <Offcanvas.Header
          closeButton
          backButton
          className="pb-0"
        >
          <Offcanvas.Title>
            {`Update ${translate(taxonomy)('score')}`}
            <p className="help-text w-75 fs-12">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <h4 className="add-score-title">
            {/* {memberProgram?.program?.name} */}
            {title}
          </h4>
          <Row>
            <Col
              sm={5}
              className="mb-3"
            >
              <FloatingLabel
                controlId="addScore"
                label={`Total Obtain ${translate(taxonomy)('score')}`}
              >
                <Form.Control
                  type="number"
                  placeholder="Score"
                  value={score}
                  onChange={handleScoreUpdate}
                />
              </FloatingLabel>
            </Col>
            <Col
              sm={1}
              className="score-division"
            >
              &#x2215;
            </Col>
            <Col sm={4}>
              <FloatingLabel
                controlId="addScore"
                label={`Total ${translate(taxonomy)('score')}`}
              >
                <Form.Control
                  disabled={true}
                  type="number"
                  placeholder="Score"
                  value={section_score}
                  onChange={() => Log.info('')}
                />
              </FloatingLabel>
            </Col>
          </Row>
          {!validScore && (
            <Row>
              <Col>
                <span
                  className="invalid-feedback mb-4"
                  style={{ display: 'block' }}
                >
                  {`input ${translate(taxonomy)('score')} cannot be greater than program ${translate(taxonomy)(component_type)} `}
                </span>
              </Col>
            </Row>
          )}
        </Offcanvas.Body>
        <Offcanvas.Footer>
          <div className="button-wrap">
            <Button
              variant="default"
              onClick={handleClose}
            >
              Cancel
            </Button>
            {!loading ? (
              <Button
                variant="primary"
                onClick={handleUpdate}
              >
                {`Update ${translate(taxonomy)('score')}`}
              </Button>
            ) : (
              <Button
                varient="primary"
                className="text-center btn-loading-txt"
                type="button"
                disabled
              >
                <span className="label">Update</span>
                <span className="btn-loader">
                  <span
                    className="spinner-border spinner-border-vsm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span>Updating...</span>
                </span>
              </Button>
            )}
          </div>
        </Offcanvas.Footer>
      </Offcanvas>
    </>
  );
}

export default AddComponentScoreOffCanvas;
