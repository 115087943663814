import React, { useEffect, useState, memo } from 'react';
import SecondaryHeader from 'react-myoneui/lib/SecondaryHeader';
import SecondaryHeaderInfo from 'react-myoneui/lib/SecondaryHeaderInfo';
import Button from 'react-myoneui/lib/Button';
import { setSidebarData } from 'reducers/sidebarSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Form, Row, Spinner } from 'react-myoneui';
import FloatingLabel from 'react-myoneui/lib/FloatingLabel';
import TagContent from 'components/tag';
import TextEditor from 'components/TextEditor';
import { translate } from 'lib';
import FetchFile from 'hooks/fetchFile';
import { editFormComponent, getCategories, getFormComponent } from 'api';
import Log from 'lib/Log';
import ls from 'localstorage-slim';
import { useNavigate, useParams } from 'react-router-dom';

ls.config.encrypt = true;

function FormBuilderInformation() {
  const { formId } = useParams();
  const lang = FetchFile(`/lang/components/program/add-section/${ls.get('lang')}.json`);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [categegoryList, setCategegoryList] = useState([]);
  const [categories, setCategories] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getFormDetails = async () => {
    try {
      setLoading(true);

      let result = await getFormComponent(formId);
      Log.info(result);
      if (result) {
        setLoading(false);
        let data = result?.data?.data;
        setName(data?.name);
        setDescription(data?.description);
        const selectedCategories = data?.categories?.map((item) => ({
          id: item.id,
          name: item.name,
        }));

        setCategories(selectedCategories);
      }
    } catch (error) {
      Log.info(error);
      setLoading(false);
    }
  };

  const keywordHandler = (data) => {
    Log.info('Data :', data);
    setCategories(data);
  };

  const editFormComponentHandler = async () => {
    try {
      let createdCategories = [];
      for (const category of categories) {
        if (category?.new) {
          createdCategories.push({ name: category.name, of: 'form' });
        } else {
          createdCategories.push({ id: category?.id });
        }
      }

      let payload = {
        _id: formId,
        name,
        description,
        categories: createdCategories,
      };
      setLoading(true);
      const result = await editFormComponent(payload);
      Log.info(result);
      setLoading(false);
      navigate(`./details`);
    } catch (error) {
      Log.error('Error on edit Form Component Handler', error);

      setLoading(false);
    }
  };

  useEffect(() => {
    if (formId) {
      getFormDetails();
    }
  }, [formId]);

  useEffect(() => {
    async function fetchSectionCategories() {
      const { data } = await getCategories('form');
      setCategegoryList(data.data);
    }
    fetchSectionCategories();
  }, []);

  useEffect(() => {
    dispatch(setSidebarData({ enableActiveWrapper: true }));
    return () => {
      dispatch(setSidebarData({ enableActiveWrapper: false }));
    };
  }, [dispatch]);

  const enableActiveWrapper = useSelector((state) => state.sidebar.enableActiveWrapper);

  useEffect(() => {
    if (!enableActiveWrapper) {
      setTimeout(() => {
        dispatch(setSidebarData({ enableActiveWrapper: true }));
      }, 1);
    }
  }, [enableActiveWrapper, dispatch]);

  return (
    <>
      <SecondaryHeader>
        <div className="secondary-header-wrap">
          <SecondaryHeaderInfo
            SecondaryHeaderTitle={`Edit Form`}
            SecondaryHeaderText={'This process takes 3 min.'}
            SecondaryText
          />
        </div>
        <div className="button-wrap">
          <Button
            variant="secondary"
            onClick={editFormComponentHandler}
            // disabled={isValidated === false}
          >
            {`Edit and Next`}
          </Button>
        </div>
      </SecondaryHeader>

      <div className="content-block bg-transparent p-0">
        {loading === true ? (
          <div className="spinner-middle">
            <Spinner animation="border" />
          </div>
        ) : (
          <>
            <Row>
              <Col
                xxl={5}
                xl={6}
                lg={8}
              >
                <h1 className="program-title">Form Information</h1>
                <FloatingLabel
                  controlId="floatingInputTitle"
                  // label={translate(lang)('sample_title')}
                  label={'Sample Name'}
                  className="mb-4"
                >
                  <Form.Control
                    tabIndex="1"
                    type="text"
                    placeholder={translate(lang)('type-title')}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </FloatingLabel>

                <Card className="react-quill-editor mb-4">
                  {lang.type_description && (
                    <TextEditor
                      value={description}
                      onChange={(data) => setDescription(data)}
                    />
                  )}
                </Card>

                <TagContent
                  className="custom-tag-content mb-4"
                  tagTitle="Category *"
                  list={categegoryList}
                  keywords={categories}
                  onTags={keywordHandler}
                  placeholder="Add new category and press comma"
                  placeholderTitle="Add new category and press comma"
                />
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
}

export default memo(FormBuilderInformation);
