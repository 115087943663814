import { useEffect, useRef, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState, Modifier, HtmlToDraft } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { filterIcon } from '../constants/icons';
function CustomOption(props) {
  const addStar = () => {
    props.btn.current.click();
  };

  return (
    <div
      onClick={addStar}
      className="rdw-image-wrapper"
    >
      <div
        className="rdw-option-wrapper refine-wrap"
        title="Refine"
      >
        {filterIcon}
      </div>
    </div>
  );
}
export default function TextEditor({ clear = false, readOnly = false, value = '', onChange = () => {}, onBlur = () => {}, onFocus = () => {}, tabIndex = 2, placeholder = '', className = '' }) {
  const [description, setDescription] = useState('');
  const [copy, setCopy] = useState('');
  const [pasteContent, setPasteContent] = useState('');
  const refineBtn = useRef(null);
  const changeHTML = (editorState) => {
    setDescription(editorState);
    setCopy(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };
  useEffect(() => {
    if (clear === true) {
      const contentBlock = htmlToDraft('');
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      setDescription(editorState);
    }
  }, [clear]);
  const bold = '<i>B</i>';
  const toolbar = {
    options: ['inline', 'list', 'textAlign', 'link', 'image'],
    inline: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['bold', 'italic', 'underline', 'strikethrough'],
    },
    list: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['unordered', 'ordered'],
    },
    textAlign: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['left', 'center', 'right'],
    },
  };

  const refine = (e) => {
    e.preventDefault();
    let div = document.createElement('div');
    div.innerHTML = copy;

    const contentBlock = htmlToDraft(div.textContent);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    setDescription(editorState);
    onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };
  useEffect(() => {
    const contentBlock = htmlToDraft(value ?? '');
    setCopy(value);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      setDescription(editorState);
    }
  }, []);
  return (
    <div className={`textEditor ${className} ${readOnly ? 'readonly' : ''}`}>
      <Editor
        placeholder={placeholder}
        tabIndex={tabIndex}
        toolbar={toolbar}
        editorState={description}
        toolbarClassName="toolbarClassName"
        editorClassName="editorClassName"
        onEditorStateChange={changeHTML}
        onBlur={onBlur}
        onFocus={onFocus}
        readOnly={readOnly}
        toolbarCustomButtons={[
          <CustomOption
            btn={refineBtn}
            pasteContent={pasteContent}
            onRefine={refine}
            value={value}
          />,
        ]}
      />
      <h1
        className="d-none"
        onClick={refine}
        ref={refineBtn}
      >
        Refine
      </h1>
    </div>
  );
}
