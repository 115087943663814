import { memo } from 'react';
import CourseInformationBlock from 'components/program/CourseInformationBlock';
import { addLog } from 'api/log';
import { Row, Col } from 'react-myoneui';
const CourseContent = ({ section, url, image, name, description }) => {
  const showResource = async (data) => {
    await addLog({ model: section.id, model_name: 'MemberSection', from: 'course_component_course' });
    const additionalUrl = window.location.href;
    window.open(`${data.url}&logred=1&redirect_url=${additionalUrl}`, '_blank');
  };
  return (
    <Row>
      <Col sm={8}>
        <CourseInformationBlock
          url={url}
          className="mb-4 information-border information-overflow-text information-course-block "
          cirleImage={image}
          name={name}
          description={description}
          onClick={(data) => showResource(data)}
        />
      </Col>
    </Row>
  );
};
export default memo(CourseContent);
