import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

function LinkWrapper({ isReact, href, className, children, onClick }) {
  if (isReact && href) {
    return (
      <Link
        to={href}
        className={className}
        onClick={onClick}
      >
        {children}
      </Link>
    );
  } else if (href) {
    return (
      <a
        href={href}
        className={className}
        onClick={onClick}
      >
        {children}
      </a>
    );
  }
  return (
    <a
      className={className}
      onClick={onClick}
    >
      {children}
    </a>
  );
}

LinkWrapper.propTypes = {
  isReact: PropTypes.bool,
  href: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

export default LinkWrapper;
