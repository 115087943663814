import React, { useEffect, useState } from 'react';
import Offcanvas from 'react-myoneui/lib/Offcanvas';
import 'react-quill/dist/quill.snow.css';
import { useSelector } from 'react-redux';
import Button from 'react-myoneui/lib/Button';
import PreviewAddTarget from './PreviewAddTarget';
import { translate } from '../../../../lib';
import { addIcon, blackArrowLeftIcon } from '../../../../constants/icons';
import TextEditor from '../../../../components/TextEditor';
import SubmitModal from '../../../../components/learners/SubmitModal';
import { MessageBox } from '../../../../components/MessageBox';
import ls from 'localstorage-slim';
import DOMPurify from 'dompurify';
import Log from '../../../../lib/Log';

// enable global encryption
ls.config.encrypt = true;
function PreviewLectureObservationOffCanvas(props) {
  const purify = DOMPurify(window);
  const taxonomy = useSelector((state) => state.taxonomy.data);
  const { show, onClose, data, onSaveObservation } = props;
  const offCanvasKeyDown = (e) => e.stopPropagation();

  const [programSubmitToggle, setProgramSubmitToggle] = useState(false);
  const [addTargetShow, setAddTargetShow] = useState(false);

  const [displayMsg, setDisplayMsg] = useState(false);
  const [messageImage, setMessageImage] = useState('');

  const [observation, setObservation] = useState('');

  const handleAddTarget = () => setAddTargetShow(true);

  const handleClose = () => {
    onClose();
  };

  const submitClick = () => {
    setProgramSubmitToggle(!programSubmitToggle);
  };
  Log.info(data);

  const saveSubmitHandler = async () => {
    setProgramSubmitToggle(false);
    setObservation('');
    onSaveObservation();
  };

  const handleDMSPreview = () => {
    setMessageImage('dmsImage');
    setDisplayMsg(true);
  };

  return (
    <>
      <Offcanvas
        className="offcanvas-size size-75"
        show={show}
        onHide={handleClose}
        placement="end"
        onKeyDown={offCanvasKeyDown}
      >
        <MessageBox
          show={displayMsg}
          messageImage={messageImage}
          onClose={() => {
            setDisplayMsg(false);
            setMessageImage('');
          }}
          title="Information"
          description="Data Can't Be Saved Since this is Preview Of the Selected Program"
        />
        <Offcanvas.Body>
          <div className="program-list-detail-block">
            <div className="program-fixed-header">
              <Button
                variant="text-icon"
                className="p-0 back-btn"
                onClick={() => onClose()}
              >
                {blackArrowLeftIcon} Back
              </Button>

              <div className="program-button-wrap">
                <button
                  onClick={() => setDisplayMsg(true)}
                  type="button"
                  className="border border-primary text-primary btn btn-outline"
                >
                  Add Evidence
                </button>
                {/* <Button className="border border-primary text-primary btn-outline" varient="outline" onClick={handleDMSPreview}>
                  <span>Document Bank</span>
                </Button> */}
              </div>
            </div>
            <div className="program-list-detail-body">
              <div className="program-detial-header-wrap">
                <div className="program-detial-info">
                  <h4>{data?.title}</h4>

                  {data?.weightage !== null && (
                    <span>
                      {translate(taxonomy)('weightage')} : <strong>{data?.weightage}</strong>
                    </span>
                  )}
                </div>
              </div>

              <Button
                onClick={handleAddTarget}
                variant="leading-icon"
                className="leading-icon add-target-btn"
                type="button"
              >
                {addIcon} Add {translate(taxonomy)('target')}
              </Button>
              <div
                className="program-list-description"
                dangerouslySetInnerHTML={{ __html: purify.sanitize(data?.description) }}
              />
              <>
                <div className="observation-block observation-pointer-block bg-transparent">
                  <h5 className="small-text-title">Observation</h5>

                  <TextEditor
                    onChange={(e) => {
                      setObservation(e);
                    }}
                    value={observation}
                  />
                </div>
              </>
            </div>
          </div>
        </Offcanvas.Body>
        <Offcanvas.Footer>
          <div className="program-detial-footer-wrap justify-content-end">
            <div className="program-button-wrap">
              <Button
                disabled={observation === '<p></p>\n' || observation.length < 1}
                variant="outline"
                className="dark"
                onClick={saveSubmitHandler}
              >
                Save as Draft
              </Button>
              <Button
                disabled={observation === '<p></p>\n' || observation.length < 1}
                variant="primary"
                onClick={submitClick}
              >
                Save &amp; Submit
              </Button>
            </div>
          </div>
        </Offcanvas.Footer>
      </Offcanvas>

      <PreviewAddTarget
        sectionTitle="lol"
        show={addTargetShow}
        onSave={() => {
          setAddTargetShow(false);
          setDisplayMsg(true);
        }}
        onClose={() => setAddTargetShow(false)}
      />

      <SubmitModal
        onSubmit={() => saveSubmitHandler()}
        onSaveAsDraft={() => saveSubmitHandler()}
        show={programSubmitToggle}
        onClose={() => setProgramSubmitToggle(false)}
      />
    </>
  );
}

export default PreviewLectureObservationOffCanvas;
