import { Badge } from 'react-myoneui';
function RenovationList(props) {
  let { title, date, time, name, location, status, children } = props;
  return (
    <>
      <div className="renovation-list">
        {title && <h4>{title}</h4>}
        {date && <span>{date}</span>}
        {time && <span>{time}</span>}
        {name && <span>{name}</span>}
        {location && <span>{location}</span>}
        {status && <Badge className="warning">{status}</Badge>}
        {children}
      </div>
    </>
  );
}
export default RenovationList;
