import axios from 'axios';
import { API_URL } from '../../constants/config';
import ls from 'localstorage-slim';
import Log from '../../lib/Log';
export const programFormComponentList = async (payload) => {
  try {
    /*
    payload: 
        "program":"64b4b9f3016fc0bdf493dccc"
        "category" : condition only
    }
    */
    const { program, ...params } = payload;

    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };
    return await axios.post(`${API_URL}/admin/formComponent/list/program-form-components`, { program }, { headers, params });
  } catch (err) {
    Log.error('Error >> ', err);
  }
};
export const updateFormComponent = async (payload) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token') || payload?.token}`,
        organisationid: `${payload.orgId}`,
      },
    };
    return await axios.patch(`${API_URL}/admin/program/form/component/update`, payload, headers);
  } catch (err) {
    Log.error('Error >> ', err);
    throw new Error(err);
  }
};
export const createFormComponent = async (payload) => {
  try {
    /*
   payload: {
    "form_id":"",
    "program":"",
    }
    */
    const headers = {
      Authorization: `Bearer ${ls.get('token')}`,
      organisationid: ls.get('orgId'),
    };
    return await axios.post(`${API_URL}/admin/program/form/component/create`, payload, { headers });
  } catch (err) {
    Log.error('Error >> ', err);
    throw new Error(err);
  }
};
export const getFormFields = async (payload) => {
  try {
    /*
  payload: {
    "memberProgram":"64ef24f058d647d96e3c7a81", "form":"64dc5a1bc74b76f790a2e178"
    }
  }
    */
    const headers = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        organisationid: ls.get('orgId'),
      },
    };

    return await axios.post(`${API_URL}/admin/get/member-program/form-fields`, payload, headers);
  } catch (err) {
    throw new Error(err);
  }
};
