import { Outlet } from 'react-router-dom';
import FormBuilderSiedBar from 'modules/admin/formBuilder/FormBuilderSideBar';
import { useSelector } from 'react-redux';

function FormBuilderLayout() {
  const enableActiveWrapper = useSelector((state) => state.sidebar.enableActiveWrapper);

  return enableActiveWrapper ? (
    <div className="content-wrapper pb-0">
      {<FormBuilderSiedBar />}
      <Outlet />
    </div>
  ) : (
    <Outlet />
  );
}

export default FormBuilderLayout;
