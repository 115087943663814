import * as React from 'react';
import Offcanvas from 'react-myoneui/lib/Offcanvas';
import Card from 'react-myoneui/lib/Card';
import Button from 'react-myoneui/lib/Button';
import Form from 'react-myoneui/lib/Form';
import FloatingLabel from 'react-myoneui/lib/FloatingLabel';
import DropdownCollection from '../../../../components/DropdownCollection';
import { addIcon } from '../../../../constants/icons';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { translate } from '../../../../lib';
import DateTimePicker from 'react-datetime-picker';
import Spinner from 'react-myoneui/lib/Spinner';

function PreviewAddNewActivityOffCanvas({ onClose, show, activities = [], onSave }) {
  const handleClose = () => {
    setLoad(false);
    setSelectedActivity({});
    setLocation('');
    setDate();
    setTitle('');
    setAddTitle(false);
    onClose();
  };
  const offCanvasKeyDown = (e) => e.stopPropagation();
  const [selectedActivity, setSelectedActivity] = useState({});
  const [activeClass, setActiveClass] = useState('');
  const [date, setDate] = useState();
  const [location, setLocation] = useState('');
  const [load, setLoad] = useState(false);
  const taxonomy = useSelector((state) => state.taxonomy.data);
  const [addTitle, setAddTitle] = useState(false);
  const [title, setTitle] = useState('');
  const createActivity = async () => {
    onSave();
    handleClose();
  };

  const isValid = () => {
    return load || (Object.entries(selectedActivity).length === 0 && title.trim() === '') || location.trim() === '' || date === undefined;
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        onKeyDown={offCanvasKeyDown}
      >
        <Offcanvas.Header
          closeButton
          backButton
          className="pb-0"
        >
          <Offcanvas.Title>
            Add <br /> New {translate(taxonomy)('activity')}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <p className="help-text w-75 fw-bold fs-12 mt-0">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
          <div className="mb-3">
            <DropdownCollection
              disable={title.trim() !== '' || activities?.length < 1}
              defaultValue={selectedActivity}
              items={activities.map((activity) => {
                return {
                  id: activity._id,
                  name: activity.title,
                };
              })}
              label={'Choose Activity'}
              onChange={(obj) => setSelectedActivity(obj)}
            />

            <div className="card-link-wrap mt-3">
              <Card.Link
                onClick={() => setAddTitle(true)}
                className="text-primary cardLinkCursor"
              >
                {addIcon}
                Create New {translate(taxonomy)('activity')}
              </Card.Link>
            </div>
          </div>

          {addTitle && Object.entries(selectedActivity).length === 0 && (
            <FloatingLabel
              className="mb-4"
              controlId="activitityTitle"
              label="Title"
            >
              <Form.Control
                type="text"
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </FloatingLabel>
          )}
          <FloatingLabel
            className="mb-4 target-end-date-label"
            controlId="targetSartEndDate"
            label="Date"
          >
            <Form.Control
              type="text"
              placeholder="Date"
            />
            <DateTimePicker
              className={`remove-time-wrap ${activeClass}`}
              clearIcon={null}
              placeholder="Date"
              value={date}
              onChange={(value) => setDate(value)}
              onBlur={() => {
                setActiveClass('');
              }}
              onFocus={() => {
                setActiveClass('target-date-focus');
              }}
            />
          </FloatingLabel>
          <FloatingLabel
            className="mb-4"
            controlId="activitityLocation"
            label="Location"
          >
            <Form.Control
              type="text"
              placeholder="Location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
          </FloatingLabel>
        </Offcanvas.Body>
        <Offcanvas.Footer>
          <Button
            variant="default"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            disabled={isValid()}
            variant="primary"
            onClick={createActivity}
          >
            {load ? (
              <>
                <div className="spinner-align-center">
                  <Spinner
                    animation="border"
                    size="sm"
                  />
                  Add {translate(taxonomy)('activity')}
                </div>
              </>
            ) : (
              `Add ${translate(taxonomy)('activity')}`
            )}
          </Button>
        </Offcanvas.Footer>
      </Offcanvas>
    </>
  );
}

export default PreviewAddNewActivityOffCanvas;
