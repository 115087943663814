import React from 'react';
import ReactDOM from 'react-dom/client';
import 'myoneui/dist/css/bootstrap.css';
import './assets/scss/main.scss';
import './assets/scss/learners/main.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/store';
import { Provider } from 'react-redux';
import './utils/i18n';
import ErrorBoundary from './components/ErrorBoundary';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </Provider>,
);

reportWebVitals();

window['BUILD_DATE'] = process.env.REACT_APP_BUILD_DATE;
