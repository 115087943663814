import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  total: 0,
};

export const ApprenticeshipSlice = createSlice({
  name: 'apprenticeship',
  initialState,
  reducers: {
    setApprenticeshipData: (state, action) => {
      return {
        ...state,
        list: action.payload,
      };
    },
    setTotalApprenticeshipData: (state, action) => {
      return {
        ...state,
        total: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setApprenticeshipData, setTotalApprenticeshipData } = ApprenticeshipSlice.actions;

export default ApprenticeshipSlice.reducer;
